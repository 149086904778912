import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { cancelBooking, patchBooking, initCancelDone } from 'store/usage/actions';
import styled from 'styled-components';

import Store from 'contexts/store/store';
import { theme } from 'assets/styles/theme';
import filters from 'utils/filters';
import getImageUrl from 'utils/getImageUrl';
import Avatar from 'components/Avatar';
import PlainButton from 'components/PlainButton';
import ConfirmModal from 'components/ConfirmModal';
import MainLayout from 'components/MainLayout';
import { BOOKING_TYPE, TICKET_TYPE, SUCCESS_MESSAGE, HEADER_TITLE } from 'constants/text';
import convertTypeToString from 'utils/convertTypeToString';

const BookingDetail = () => {
  /** hooks */
  const history = useHistory();
  const dispatch = useDispatch();
  const { utils } = useContext(Store);

  /** state */
  const { loading, currentBooking, cancelDone } = useSelector(state => state.usage);
  const { userTicket } = useSelector(state => state.usage.currentBooking);
  const { studios } = useSelector(state => state.studio);

  const [cancelModal, setCancelModal] = useState(false);
  const [texts, setTexts] = useState({ title: '', message: '' });
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    const params = () => {
      if (
        currentBooking.status === BOOKING_TYPE.booked ||
        currentBooking.status === BOOKING_TYPE.bookingConfirmed ||
        currentBooking.status === BOOKING_TYPE.cancel ||
        currentBooking.status === BOOKING_TYPE.absence
      ) {
        return SUCCESS_MESSAGE.bookingCancel;
      }
      if (currentBooking.status === BOOKING_TYPE.bookingWaiting) {
        return SUCCESS_MESSAGE.bookingWaitCancel;
      }
      return SUCCESS_MESSAGE.processDone;
    };

    if (!cancelDone) return;

    if (cancelDone.status === 200) {
      dispatch(initCancelDone());
      return history.push({
        pathname: '/success',
        state: { ...params(), routePath: '/booking/list' },
      });
    }

    if (cancelDone.status === 409) {
      alert({ message: cancelDone.message });
      dispatch(initCancelDone());
    }
  }, [cancelDone, dispatch, currentBooking.status, history]);

  /** func */
  const cancelBooked = id => {
    const after_end = currentBooking.lecture.booking_cancel_end_at && dayjs(currentBooking.lecture.booking_cancel_end_at).isBefore(new Date());
    const result = studios.filter(el => el.id === userTicket?.studio_id);

    setCancelModal(false);

    if (result[0]?.policy?.is_absence_by_user) {
      if (currentBooking.status === BOOKING_TYPE.bookingConfirmed) {
        dispatch(patchBooking(id, { status: BOOKING_TYPE.absence }));
      } else {
        dispatch(cancelBooking(id));
      }
    } else {
      dispatch(cancelBooking(id));
    }

    // if (currentBooking.status === BOOKING_TYPE.bookingConfirmed || (currentBooking.status === BOOKING_TYPE.booked && after_end)) {
    //   dispatch(patchBooking(id, { status: BOOKING_TYPE.absence }));
    // } else {
    //   dispatch(cancelBooking(id));
    // }
  };

  const cancelTexts = () => {
    let title;
    let message;
    const after_end = currentBooking.lecture.booking_cancel_end_at && dayjs(currentBooking.lecture.booking_cancel_end_at).isBefore(new Date());

    switch (currentBooking.status) {
      // 1. 예약가능시간 이내
      case BOOKING_TYPE.booked:
        title = '예약 취소';
        message =
          // 1-1. 기간제 or 취소가능시간 이내 (cancelBooking)
          userTicket?.ticket?.type === TICKET_TYPE.period || !after_end ? (
            <div>
              {filters.dateWithWeekdayWithTime(currentBooking.lecture.start_on)}
              <br />
              {currentBooking.lecture.title} 수업을 취소하시겠습니까?
            </div>
          ) : (
            // 1-2. 취소가능시간 지났을때 (patchBooking)
            <div style={{ textAlign: 'left' }}>
              취소 가능한 시간이 지났습니다.
              <br />
              계속해서 예약을 취소할 경우 <span style={{ color: 'red' }}>잔여횟수는 자동으로 차감</span>되며 <b>결석으로 처리</b>됩니다.
              <br />
              취소하시겠습니까?
            </div>
          );
        break;

      // 2. 예약가능시간 지났을때
      case BOOKING_TYPE.bookingConfirmed:
        title = '예약 취소';
        message =
          // 2-1. 기간제 or 취소가능시간 이내 (patchBooking)
          userTicket?.ticket?.type === TICKET_TYPE.period || !after_end ? (
            <div>
              {filters.dateWithWeekdayWithTime(currentBooking.lecture.start_on)}
              <br />
              {currentBooking.lecture.title} 수업을 취소하시겠습니까?
            </div>
          ) : (
            // 2-2. 취소가능시간 지났을때 (patchBooking)
            <div style={{ textAlign: 'left' }}>
              취소 가능한 시간이 지났습니다.
              <br />
              계속해서 예약을 취소할 경우 <span style={{ color: 'red' }}>잔여횟수는 자동으로 차감</span>되며 <b>결석으로 처리</b>됩니다.
              <br />
              취소하시겠습니까?
            </div>
          );
        break;

      // 3. 예약 대기
      case BOOKING_TYPE.bookingWaiting:
        title = '예약 대기 취소';
        message = (
          <div>
            {currentBooking.status === BOOKING_TYPE.bookingWaiting && '예약 대기 하신'}
            <br />
            {filters.dateWithWeekdayWithTime(currentBooking.lecture.start_on)}
            <br />
            {currentBooking.lecture.title} 수업을 취소하시겠습니까?
          </div>
        );
        break;
      default:
        break;
    }

    setTexts({ title, message });
  };

  const showCancelBtn = () => {
    // 예약 취소 버튼
    const before_start = currentBooking.lecture.booking_cancel_start_at
      ? dayjs(currentBooking.lecture.booking_cancel_start_at).isBefore(new Date())
      : true;
    const before_end = currentBooking.lecture.booking_cancel_end_at && dayjs(currentBooking.lecture.booking_cancel_end_at).isAfter(new Date());
    const after_end = currentBooking.lecture.booking_cancel_end_at && dayjs(currentBooking.lecture.booking_cancel_end_at).isBefore(new Date());

    const result = studios.filter(el => el.id === userTicket?.studio_id);

    if (currentBooking.status !== BOOKING_TYPE.bookingWaiting) {
      // userTicket.remaining_cancel 취소횟수 = 0
      if (userTicket && userTicket.remaining_cancel !== 0) {
        // 취소가능시간 이내
        if (before_start && before_end) {
          setShowCancel(true);
        }

        // 취소가능시간 지났을때
        else if (after_end) {
          // 횟수차감취소설정 true
          if (result[0]?.policy?.is_absence_by_user) {
            setShowCancel(true);
            // 횟수차감취소설정 false
          } else {
            setShowCancel(false);
          }
        }

        // 취소가능시간 전에
        else {
          setShowCancel(false);
        }
      } else {
        // 취소 가능 횟수 = 0일 때
        if (after_end) {
          if (result[0]?.policy?.is_absence_by_user) {
            setShowCancel(true);
          }
        } else {
          setShowCancel(false);
        }
      }
    } else {
      setShowCancel(true);
    }
  };

  useEffect(() => {
    cancelTexts();
    showCancelBtn();
  }, [currentBooking]);

  const bookingStateTime = () => {
    const convertTime = filters.datetimesecond(currentBooking.updated_at);
    switch (currentBooking.status) {
      case BOOKING_TYPE.bookingConfirmed:
      case BOOKING_TYPE.booked:
      case BOOKING_TYPE.bookedAndConfirm:
        return `예약 일시 ${convertTime}`;
      case BOOKING_TYPE.cancel:
        return `취소 일시 ${convertTime}`;
      case BOOKING_TYPE.bookingWaiting:
        return `대기 일시 ${convertTime}`;
      case BOOKING_TYPE.absence:
        return `결석 일시 ${convertTime}`;
      case BOOKING_TYPE.attendance:
        return `출석 일시 ${convertTime}`;
      case BOOKING_TYPE.noshow:
        return `노쇼 일시 ${convertTime}`;
      default:
        return `수정 일시 ${convertTime}`;
    }
  };

  const dailyBookingChangeCondition = () => {
    /**
     * 1. 오늘 수업
     * 2. 현재 당일 예약변경 횟수가 0이상
     * 3. 현재 시간이 당일예약변경 가능시간 이내
     */

    return (
      (currentBooking.status === BOOKING_TYPE.booked || currentBooking.status === BOOKING_TYPE.bookingConfirmed) &&
      dayjs(currentBooking.lecture.start_on).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
      (userTicket?.change_daily_booking_count
        ? userTicket?.ticket?.daily_booking_change_limit - userTicket?.change_daily_booking_count > 0
        : userTicket?.ticket?.daily_booking_change_limit > 0) &&
      dayjs(currentBooking.lecture.daily_change_booking_end_at).isAfter(new Date())
    );
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.bookingListDetail, titleNormal: true, backFunc: history.goBack }} contentsGrid loading={loading}>
      {!loading && (
        <BackgroundStyle>
          <ContainerStyle>
            <div className="studio-name">{currentBooking?.studio_name}</div>

            <Box>
              <div className="title">수업</div>
              <div className="hr" />

              {/* 수업명 */}
              {currentBooking.lecture.type !== 'P' && <div className="lecture">{currentBooking.lecture.title}</div>}

              {/* 날짜 */}
              <div className="date">{filters.dateLecture(currentBooking.lecture.start_on, currentBooking.lecture.end_on)}</div>

              {/* 강사 이름 */}
              <div className="user_flex">
                <Avatar className="user_img" size="small-2" imgUrl={getImageUrl(currentBooking?.lecture?.staff?.avatar, '32x32')} />
                <span className="instructor">{currentBooking.lecture.staff.name} 강사</span>

                {/* status */}
                <div className="status">{convertTypeToString(currentBooking.status, currentBooking?.lecture?.booking_waiting_order)}</div>
              </div>

              {/* 룸 */}
              {currentBooking.lecture.room_name && <div className="room">{currentBooking.lecture.room_name} 룸</div>}
            </Box>

            <Box>
              <div className="title">사용 수강권</div>
              <div className="hr" />

              {userTicket && (
                <>
                  {/* 수강권 이름 */}
                  <div className="lecture">{userTicket.ticket.title}</div>

                  {/* 사용기간 */}
                  <div>
                    <span className="subtitle">사용기간</span>
                    <span className="instructor">
                      {utils.filters.date(userTicket.created_at)} ~ {utils.filters.date(userTicket.expire_at)}
                    </span>
                  </div>

                  {/* 잔여일 */}
                  <div className="gap-top">
                    <span className="subtitle">잔여일</span>
                    <span className="instructor">{utils.getRemainingDaysText(userTicket.expire_at, userTicket.created_at)}</span>
                  </div>

                  {/* 잔여횟수 */}
                  {userTicket?.ticket?.type === TICKET_TYPE.times && (
                    <div className="gap-top">
                      <span className="subtitle">잔여 횟수</span>
                      <span className="instructor">{userTicket.remaining_coupon}회</span>
                    </div>
                  )}
                </>
              )}
            </Box>

            {/* 예약 일시 */}
            <div className="title gap-left gap-bottom">{bookingStateTime()}</div>

            <ConfirmModal title={texts.title} show={cancelModal} setShow={setCancelModal} action={() => cancelBooked(currentBooking.id)}>
              {texts.message}
            </ConfirmModal>
          </ContainerStyle>

          {/* 하단 버튼 */}
          {(currentBooking.status === BOOKING_TYPE.booked ||
            currentBooking.status === BOOKING_TYPE.bookingWaiting ||
            currentBooking.status === BOOKING_TYPE.bookingConfirmed) && (
            <BottomButton cancel={showCancel} change={dailyBookingChangeCondition()}>
              {/* 예약 취소 */}
              {showCancel && (
                <PlainButton className="flex-both-center" onClick={() => setCancelModal(true)} color="danger">
                  {texts.title}
                </PlainButton>
              )}

              {/* 예약 변경 */}
              {dailyBookingChangeCondition() && (
                <CustomBtnChange>
                  <PlainButton className="flex-both-center" onClick={() => history.push('/booking/change')}>
                    예약 변경
                  </PlainButton>
                </CustomBtnChange>
              )}
            </BottomButton>
          )}
        </BackgroundStyle>
      )}
    </MainLayout>
  );
};

export default BookingDetail;

const BackgroundStyle = styled.div`
  display: grid;
  overflow: hidden;
  grid-template-rows: 1fr;
  background-image: linear-gradient(151deg, #3391ed, #9a92f5 100%);
`;

const ContainerStyle = styled.div`
  border-radius: 16px;
  background-color: white;
  margin: 16px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  max-height: 450px;
  overflow-y: auto;

  .title {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.67;
    color: ${theme.color.greyBBB};
  }
  .gap-left {
    margin-left: 28px;
  }
  .gap-bottom {
    margin-bottom: 16px;
  }
  .studio-name {
    font-size: 16px;
    font-weight: 500;
    color: ${theme.color.black500};
    white-space: break-spaces;
    text-align: center;
    margin: 16px;
  }
`;

const Box = styled.div`
  margin: 0 16px 16px 16px;
  padding: 8px 12px;
  border-radius: 8px;
  border: solid 1px ${theme.color.greyBBB};

  .subtitle {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.greyBBB};
    width: 65px;
    display: inline-block;
  }
  .hr {
    background-color: #eeeeee;
    height: 1px;
    margin: 8px 0;
  }
  .lecture {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black300};
    margin-bottom: 10px;
  }
  .date {
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.black300};
    margin-bottom: 8px;
  }
  .user_flex {
    display: flex;
    align-items: center;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .instructor {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    white-space: pre-wrap;
    flex: auto;
  }
  .status {
    // position: absolute;
    // right: 44px;
  }
  .room {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    white-space: pre-wrap;
    margin-top: 8px;
  }
  .gap-top {
    margin-top: 8px;
  }
`;

const BottomButton = styled.div`
  width: 100%;
  padding: 0 16px;
  z-index: 1;

  button {
    font-size: 18px;
    font-weight: bold;
    border: 0px !important;
  }

  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-danger {
    margin-top: 0.58em;
  }

  ${props => {
    if (props.cancel && props.change) {
      return `
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;

        .mbsc-ios.mbsc-btn {
          padding: .625em 4px;
        }
      `;
    }
    if ((props.cancel && !props.change) || (!props.cancel && props.change)) {
      return `
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        grid-gap: 16px;
      `;
    }
    return `
        display: none;
      `;
  }}
`;

const CustomBtnChange = styled.div`
  .mbsc-ios.mbsc-btn-primary.mbsc-btn {
    background-color: #4c2c92;
    font-weight: bold;
  }
`;
