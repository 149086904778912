import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Store from 'contexts/store/store';
import { getUserAccount } from 'store/auth/actions';
import styled from 'styled-components';

import { RootState } from 'store';
import FooterButtons from 'components/Footer/FooterButtons';
import getImageUrl from 'utils/getImageUrl';

import { theme } from 'assets/styles/theme';
import Avatar from 'components/Avatar';
import MainLayout from 'components/MainLayout';

import { AUTH_TYPES, LABEL, ERROR_TYPES, REQUEST_MOBILE, SUCCESS_MESSAGE, HEADER_TITLE, PLACEHOLDER } from 'constants/text';

const Profile = () => {
  const history = useHistory();
  const { apis, utils } = useContext(Store);
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { camera, photoLibrary } = useSelector((state: RootState) => state.permission);

  type DataType = { name?: any; file?: any; avatar?: string };

  const [data, setData] = useState<DataType>({
    name: user?.name,
  });
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getInitData = async () => {
      try {
        setLoading(true);
      } catch (error) {
        if (error.response.status === 403) {
          alert({
            message: ERROR_TYPES.reLogin,
          });
        }
      } finally {
        setLoading(false);
      }
    };
    getInitData();
    // eslint-disable-next-line
  }, [apis.auth, utils]);

  // const requestImage = useCallback(() => {
  //   if (window.ReactNativeWebView) {
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ type: REQUEST_MOBILE.permission }));
  //   } else {
  //     alert({ message: ERROR_TYPES.notMobile });
  //   }
  // }, []);

  const onClickImageUpload = useCallback(
    file => {
      setData({ ...data, file });
    },
    [data, setData],
  );

  const handleDataForm = useCallback(async () => {
    let dataForm = new FormData();
    if (data.file) {
      dataForm = await utils.imageUpload.imageCompressor(data.file, 'representative_avatar');
    }
    dataForm.append('name', data.name);
    return dataForm;
  }, [data, utils.imageUpload]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const dataForm = await handleDataForm();

    try {
      const result = await apis.auth.changeUserAccount(dataForm);
      setLoading(false);
      if (result.status === 202) {
        dispatch(getUserAccount());
        history.push({
          pathname: '/success',
          state: { ...SUCCESS_MESSAGE.changeInfo, routePath: '/mypage/edit' },
        });
      }
    } catch (error) {
      alert(error?.response?.data?.errors?.representative_avatar[0]);
      setLoading(false);
    }
  }, [apis.auth, history, dispatch, handleDataForm]);

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  };

  return (
    <MainLayout
      header={{ title: HEADER_TITLE.changeInfo, titleNormal: true, backFunc: history.goBack }}
      loading={loading}
      contentStyle={contentStyle}>
      <Wrap>
        <div className="flex_row_center avatar">
          <Avatar
            size="x-large"
            onChange={onClickImageUpload}
            edit
            imgUrl={getImageUrl(user?.representative_avatar?.image, '124x124')}
            cameraPermission={camera}
            photoLibraryPermission={photoLibrary}
          />
        </div>
        {/* <ImagePicker
          files={data.avatar}
          onAddImageClick={requestImage}
          onChange={onClickImageUpload}
          selectable={data.avatar.length < 1}
          accept="image/gif,image/jpeg,image/jpg,image/png"
        /> */}

        <div className="name-wrap flex-col">
          <label className="name">이름</label>
          <input
            className="input"
            type="text"
            placeholder={PLACEHOLDER.name}
            value={data.name}
            onChange={e => setData({ ...data, name: e.target.value })}
          />
        </div>
        {/* <TextInput 
          id={AUTH_TYPES.name} 
          name={AUTH_TYPES.name} 
          value={data.name} 
          onChange={setData}
          label={LABEL.name} 
          placeholder={PLACEHOLDER.name} 
        /> */}
      </Wrap>

      <FooterButtons cancelClick={() => history.goBack()} confirmClick={onSubmit} confirmText="프로필 변경" disabled={!data.name} />
    </MainLayout>
  );
};

const Wrap = styled.div`
  padding: 0 16px;
  .avatar {
    padding: 40px 0;
    justify-content: center;
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    color: ${theme.color.black500};
  }
  .input {
    font-weight: normal;
    font-size: 16px;
    color: ${theme.color.black300};
  }

  svg g g {
    fill: ${theme.color.black500};
  }
`;

export default Profile;
