/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './bookingActionStyle.scss';
import React, { useState, useEffect } from 'react';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import getImageUrl from 'utils/getImageUrl';
import { ReactComponent as BookingIcon } from 'assets/icons/icon-16-check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/icon-16-check-red.svg';
import { ReactComponent as Close } from 'assets/icons/icon-24-close-white.svg';
import bookingCancelAvailableTime from 'utils/bookingCancelAvailableTime';
import { theme } from 'assets/styles/theme';
import ConfirmModal from 'components/ConfirmModal';
import PlainButton from 'components/PlainButton';
import Avatar from 'components/Avatar';
import { postPrivateBooking } from 'store/booking/actions';

import moment from 'moment';

const BookingActionPrivate = ({ show, time, staff, id, onRequestClose, dispatchBookingList, beforeOpen, setLimit }) => {
  const { Item } = List;
  const { Brief } = Item;

  /** state */
  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', message: '', func: () => {} });

  /** hooks */
  const dispatch = useDispatch();
  const history = useHistory();
  const { done, calendarData } = useSelector(state => state.booking);
  const { ticketRaw, studio } = useSelector(state => state.studio).currentStudioData;

  const { private_booking_limit_day_term, private_booking_limit_day_term_time, private_booking_limit_date } = useSelector(state => state.studio)
    .currentStudioData.studio.policy;

  /** func */
  useEffect(() => {
    if (Object.keys(done).length && show) {
      return history.push({
        pathname: '/success',
        state: {
          title: done.title,
          body: done.body,
          routePath: `/booking-private/${ticketRaw.id}`,
        },
      });
    }
  }, [done, ticketRaw.id]);

  useEffect(() => {
    if (!window.navigator.onLine) alert({ message: '인터넷 연결을 확인해주세요' });

    // 고급설정 === null
    if (!private_booking_limit_date) {
      const limitTerm = private_booking_limit_day_term; // 7
      const diffTime = Math.abs(+moment() - +moment(ticketRaw.expire_at)); // 2968315324
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 35

      const currentDate = moment()
        .add(limitTerm >= diffDays ? diffDays : limitTerm, 'days')
        .format('YYYY-MM-DD');
      const startDate = moment(currentDate)
        .add(beforeOpen(currentDate) ? 0 : 1, 'days')
        .format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      const whenToShow = moment(new Date(), 'hh:mm:ss').isBefore(moment(private_booking_limit_day_term_time, 'hh:mm:ss')); // 현재 시각 < 12:00:00 ? true

      dispatchBookingList(currentDate, startDate, endDate, whenToShow, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow });
    }

    // 고급설정 !== null
    else {
      const limitTerm = private_booking_limit_day_term; // 7
      let currentDate = private_booking_limit_date; // 2021-04-27 12:00:00

      if (moment(new Date()).isAfter(moment(currentDate))) {
        currentDate = moment(currentDate).add(limitTerm, 'days').format('YYYY-MM-DD hh:mm:ss'); // limitTerm 주기로 업데이트
      }

      const startDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      dispatchBookingList(currentDate, startDate, endDate, false, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow: false });
    }
  }, [window.navigator.onLine]);

  const bookingsConfirm = () => {
    setModalInfo({
      title: '예약하기',
      message: `${time.viewText}\n${staff.name} 강사님의 수업을\n예약하시겠습니까?\n\n시설에 따라 예약이 지연될 수 \n 있는 점 양해 부탁드립니다.`,
      func: () => {
        dispatch(
          postPrivateBooking(
            id,
            staff.id,
            time.startTime,
            'bookings',
            staff.private_start_interval_am === null ? studio.policy.private_start_interval : null,
            staff.private_start_interval_am,
            staff.private_start_interval_pm,
          ),
        );
      },
    });
    setOpenModal(true);
  };

  return (
    <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`}>
      <div className="react-actionsheet-mask" onClick={onRequestClose} />
      <div className="react-actionsheet-wrap">
        <ConfirmModal title={modalInfo.title} show={openModal} setShow={setOpenModal} action={modalInfo.func}>
          <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: modalInfo.message }} />
        </ConfirmModal>

        <ContainerStyle>
          <List>
            <Item>
              {/* 닫기 버튼 */}
              <Close className="close_icon" onClick={onRequestClose} />

              {/* 날짜 */}
              <Brief>
                <div className="date">{time.viewText}</div>
              </Brief>

              {/* 강사 이름 */}
              <Brief>
                <Avatar size="small-2" className="user_img" imgUrl={getImageUrl(staff.avatar, '32x32')} />
                <span className="content">{staff.name} 강사</span>
              </Brief>
            </Item>

            {/* 예약·취소 가능 시간 */}
            {studio?.policy && (
              <Item>
                <Brief>
                  <div className="subTitle">예약·취소 가능 시간</div>
                </Brief>
                <Brief>
                  <BookingIcon />
                  &nbsp;
                  <span className="content">
                    수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy).private.booking }} />
                  </span>
                </Brief>
                <Brief>
                  <CancelIcon />
                  &nbsp;
                  <span className="content">
                    수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy).private.cancel }} />
                  </span>
                </Brief>
              </Item>
            )}

            <Item>
              <Brief>
                <div className="subTitle">강사 프로필</div>
              </Brief>

              <Brief>
                <SubTitleGray>자기소개</SubTitleGray>
              </Brief>
              {staff?.self_introduction ? (
                <Brief>
                  <div className="content gap">{staff?.self_introduction}</div>
                </Brief>
              ) : (
                <Brief>
                  <div className="content gap">자기 소개 내용이 없습니다.</div>
                </Brief>
              )}

              <Brief>
                <SubTitleGray>주요 이력</SubTitleGray>
              </Brief>
              {staff?.careers?.length ? (
                staff?.careers.map(({ id, career }) => (
                  <Brief key={id}>
                    <div className="content">{career}</div>
                  </Brief>
                ))
              ) : (
                <Brief>
                  <div className="content">주요 이력 내용이 없습니다.</div>
                </Brief>
              )}
            </Item>
          </List>
        </ContainerStyle>

        <BottomButton>
          <div className="mbsc-btn-group-block">
            <PlainButton onClick={bookingsConfirm}>
              <b>예약</b>
            </PlainButton>
          </div>
        </BottomButton>
      </div>
    </div>
  );
};

const ContainerStyle = styled.div`
  padding: 0 16px 5px 2px;
  overflow-y: auto;

  .am-list-body::before,
  .am-list-body::after {
    background-color: white !important;
  }
  .am-list-body .am-list-item .am-list-line .am-list-content {
    padding: 16px 0;
  }

  .subTitle {
    color: ${theme.color.black300};
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .date {
    font-weight: bold;
    font-size: 18px;
    color: ${theme.color.black300};
    margin-bottom: 5px;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .content {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    white-space: pre-wrap;
  }
  .gap {
    margin-bottom: 20px;
  }
`;

const SubTitleGray = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${theme.color.sub};
`;

const BottomButton = styled.div`
  width: 100%;
  padding: 0 16px;
  z-index: 1;
  background-color: white;
  border-top: 1px solid #ddd;
`;

export default BookingActionPrivate;
