import React, { useEffect, useContext, useRef } from 'react';
import Store from 'contexts/store/store';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { keepAccessToken } from 'store/auth/actions';
import useGlobalUI from 'hooks/useGlobalUI';
import api from 'api/modules/auth';
import { RootState } from 'store';
import { getPermissionCheck } from 'store/permission/actions';

// const CONSTANT = {
//   refund: 'message.refund.member',
//   enrollTicket: 'message.ticket.member',
//   qnaComment: 'message.board.user?.qna.comment.member',
//   notice: 'message.board.user?.notice.create.member',
//   groupBooking: 'message.group.booking.staff.staff',
//   groupBookingCancel: 'message.group.cancel.staff.member',
//   privateBooking: 'message.private.booking.staff.staff',
//   privateBookingCancel: 'message.private.cancel.staff.member',
//   lecture: 'message.reminder.lecture.start.member',
//   push: '',
// };

/** listen push & route */
const FcmComponent = () => {
  const { closeGlobalMenu } = useGlobalUI(false);
  const dispatch = useDispatch();
  const { isMenuShow } = useSelector((state: RootState) => state.globalUI);
  const history = useHistory();
  const { utils } = useContext(Store);
  const isMenuShowRef = useRef(isMenuShow);

  const patchFcmToken = (token: string) => {
    api.patchFCMToken({ instance_token: token });
  };

  useEffect(() => {
    isMenuShowRef.current = isMenuShow;
  }, [isMenuShow]);

  const listener = (event: any) => {
    console.log('event', event);
    const { data, type } = JSON.parse(event.data);

    if (type === 'TOKEN') {
      utils.localStorage.set('fcm_token', data);

      if (utils.localStorage.get('access_token') && data) {
        patchFcmToken(data);
      }
    } else if (type === 'KEEP_ACCESS_TOKEN') {
      // alert({ message: `token is: ${data}` });
      dispatch(keepAccessToken(data));
    } else if (type === 'HARDWARE_BACK_KEY') {
      switch (history.location.pathname) {
        case '/login':
        case '/':
        case '/select-ticket':
          window.mobilsConfirm({
            message: `앱을 종료하시겠습니까?`,
            callback: (res: boolean) => {
              if (res) {
                window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'EXIT_APP' }));
              }
            },
          });
          break;
        default:
          if (isMenuShowRef.current) {
            closeGlobalMenu();
          } else {
            history.goBack();
          }
          break;
      }
    } else if (type === 'FCM_OPEN_APP') {
      history.push('/notification');
    } else if (type === 'REQUEST_PERMISSION') {
      const { camera, photoLibrary } = data;
      dispatch(getPermissionCheck({ camera, photoLibrary }));
    }
  };

  useEffect(() => {
    /** 지역 다른 경우 시간 차이 구하는 함수 */
    utils.getTime.getServetTime();

    if (window.ReactNativeWebView) {
      /** android */
      document.addEventListener('message', listener);
      /** ios */
      window.addEventListener('message', listener);
    } else {
      utils.localStorage.set('fcm_token', 'not-mobile');
    }
    return window.removeEventListener('message', event => {
      listener(event);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <span
      style={{
        display: 'none',
        position: 'absolute',
        left: '20%',
        opacity: '.5',
        backgroundColor: 'white',
        border: '1px solid olive',
        width: '100px',
      }}>
      <a href="https://www.naver.com">광고</a>
    </span>
  );
};
export default FcmComponent;
