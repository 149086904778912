import React, { useContext } from 'react';
import styled from 'styled-components';

import Store from 'contexts/store/store';

import { sortTypeProps } from 'store/usage/types';
import { ERROR_TYPES } from 'constants/text';
import { LectureType } from 'store/booking/types';
import { theme } from 'assets/styles/theme';
import 'assets/styles/antd.css';

// components
import Calendar from 'components/Calendar/Calendar';
import EmptyLayout from 'components/EmptyLayout';
import BookingCard from 'components/BookingCard';

type bookingListPresenterProps = {
  sortedBy: sortTypeProps[];
  status: sortTypeProps[];
  bookings: { id: number; status?: string; lecture: LectureType }[];
  show: { sort: boolean; status: boolean };
  setShow: React.Dispatch<
    React.SetStateAction<{
      sort: boolean;
      status: boolean;
    }>
  >;
  lastElementRef: (node: any) => void;
  changeType: (e: any) => void;
  currentText: (datas: sortTypeProps[]) => { value: string; content: string };
  goToDetail: (booking: { id: number }) => void;
  goToBooking: () => void;
  markDate: { d: string; color: string }[];
  startDate: any;
  selectDate: any;
  holidays: any[];
  holdings: any;
};

// type sortButtonProps = {
//   defaults: boolean;
// };

type BookingStyleProps = {
  bookingsLength: Number;
};

const BookingListPresenter = ({
  sortedBy,
  status,
  bookings,
  show,
  setShow,
  lastElementRef,
  changeType,
  currentText,
  goToDetail,
  goToBooking,
  markDate,
  startDate,
  selectDate,
  holidays,
  holdings,
}: bookingListPresenterProps) => {
  /** hooks */
  const { utils } = useContext(Store);

  const getList = () => {};

  const BookingListView: Function = () => {
    return bookings
      .filter(booking => selectDate === utils.getTime.getHyperDate(booking.lecture.start_on))
      .map((booking, index) => {
        return (
          <div
            ref={bookings.length === index + 2 ? lastElementRef : null}
            onClick={() => goToDetail(booking)}
            className="each-item"
            role="button"
            tabIndex={0}
            key={booking.id}>
            <BookingCard lecture={booking.lecture} status={booking.status} />
          </div>
        );
      });
  };

  // 휴일
  const HolidayView: Function = () => {
    return holidays.filter(el => !el.deleted_at && selectDate === el.date);
  };

  const PauseTicket: Function = () => {
    return holdings.filter((el: any) => selectDate === el);
  };

  return (
    <>
      <Calendar getList={getList} eventDates={markDate} startDate={startDate} />

      {/* 분류 */}
      {/* <BookingTypeContainer>
        <SortButton defaults={currentText(sortedBy).value === 'asc'} onClick={() => setShow({ sort: true, status: false })}>
          {currentText(sortedBy).content} <Dropdown fill={currentText(sortedBy).value === 'asc' ? '#555' : '#3391ED'} className='dropdown' />
        </SortButton>
        <SortButton defaults={currentText(status).value === BOOKING_TYPE.bookedAndConfirm} onClick={() => setShow({ sort: false, status: true })}>
          {currentText(status).content} <Dropdown fill={currentText(status).value === BOOKING_TYPE.bookedAndConfirm ? '#555' : '#3391ED'} className='dropdown' />
        </SortButton>
      </BookingTypeContainer> */}

      {/* 예약 목록 */}
      <BookingListContainer bookingsLength={bookings.length}>
        {bookings.length ? (
          BookingListView().length !== 0 ? (
            <BookingListView />
          ) : HolidayView().length !== 0 ? (
            <EmptyLayout text={ERROR_TYPES.vacation} color={theme.color.red} />
          ) : PauseTicket().length !== 0 ? (
            <EmptyLayout text={ERROR_TYPES.pauseTicket} color={theme.color.red} />
          ) : (
            <EmptyLayout text={ERROR_TYPES.noBookingList} />
          )
        ) : (
          <NoBookingList>{ERROR_TYPES.noBookingList}</NoBookingList>
        )}
      </BookingListContainer>

      {/* <Actionsheet show={show.sort} menus={sortedBy} onRequestClose={changeType} />
      <Actionsheet show={show.status} menus={status} onRequestClose={changeType} /> */}

      {/* <BottomButton className="mbsc-btn-group-block">
        <PlainButton onClick={goToBooking}><b>예약 하러 가기</b></PlainButton>
      </BottomButton> */}
    </>
  );
};

/** css */
const BookingListContainer = styled.div<BookingStyleProps>`
  width: 100%;
  overflow-y: auto;

  .each-item {
    margin: 12px 16px;
    margin-bottom: 24px;
  }

  margin: ${({ bookingsLength }) => (bookingsLength ? null : 'auto')};
`;

const NoBookingList = styled.div`
  color: ${theme.color.black500};
  font-size: 18px;
  font-weight: normal;
  width: 100%;
  text-align: center;
`;

// const SortButton = styled.div<sortButtonProps>`
//   ${({ defaults }) => {
//     if (defaults) {
//       return css`
//         border: 1px solid ${theme.color.black500};
//         border-radius: 4px;
//         color: ${theme.color.black500};
//       `;
//     }
//     return css`
//       color: ${theme.color.main};
//       border-radius: 4px;
//       border: 1px solid ${theme.color.main};
//     `;
//   }}
//   font-size: 15px;
//   width: 50%;
//   padding: 4px;
//   margin: 16px 8px;
//   text-align: center;

//   .dropdown {
//     float: right;
//   }
// `;

// const BottomButton = styled.div`
//   position: fixed;
//   bottom: 50px;
//   left: 0;
//   border-top: 1px solid #ddd;
//   width: 100%;
//   padding: 0 10px;
//   z-index: 1;
//   background-color: white;
// `;

export default BookingListPresenter;
