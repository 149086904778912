import React, { useCallback, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Store from 'contexts/store/store';
import useInput from 'hooks/useInput';
import MainLayout from 'components/MainLayout';
import QuestionCreatePresenter from 'pages/Board/Qna/QnaCreate/QuestionCreatePresenter';
import { HEADER_TITLE, ERROR_TYPES, NOTICE_TYPE, REQUEST_MOBILE } from 'constants/text';

const QuestionCreateContainer = () => {
  /** hooks */
  const history = useHistory();
  const { apis, utils } = useContext(Store);

  /** state */
  const { currentStudioData } = useSelector(state => state.studio);
  const { currentBoard } = useSelector(state => state.board);
  const { camera, photoLibrary } = useSelector(state => state.permission);
  const [data, setData] = useInput({
    avatar: [],
    title: '',
    content: '',
  });
  const [attachmemt, setAttachment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headerTitle] = useState(history.location.pathname === '/board/qna/create' ? HEADER_TITLE.qnaCreate : HEADER_TITLE.qnaPatch);

  /** init value setting */
  useEffect(() => {
    if (headerTitle === HEADER_TITLE.qnaPatch) {
      const { title, contents, attachments } = currentBoard;
      const initName = {
        initValue: [
          { value: title, name: 'title' },
          { value: contents, name: 'content' },
          { value: attachments, name: 'avatar' },
        ],
      };
      setData(initName);
      setAttachment(attachments.map(({ id }) => id));
    }
    // eslint-disable-next-line
  }, []);

  /** func */
  const requestImage = useCallback(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: REQUEST_MOBILE.permission }));
    } else {
      console.log(ERROR_TYPES.uploadImage);
    }
  }, [camera, photoLibrary]);

  const onClickImageUpload = useCallback(
    async (files, type, index) => {
      setLoading(true);
      if (files.length && headerTitle === HEADER_TITLE.qnaCreate) {
        files[files.length - 1].orientation = 2;
      }

      if (type === 'add') {
        try {
          const dataForm = await utils.imageUpload.imageCompressor(files[files.length - 1].file, 'files[]');
          const res = await apis.board.upload(dataForm);
          if (res.status === 200) {
            setAttachment(attachmemt.concat(res.data[0].id));
          }
        } catch {
          setLoading(false);
          return alert({ message: ERROR_TYPES.uploadImage });
        }
      } else {
        setAttachment(attachmemt.filter((el, i) => i !== index));
      }

      setLoading(false);
      setData({ target: { name: 'avatar', value: files } });
    },
    [setData, apis.board, attachmemt, utils.imageUpload, headerTitle, camera, photoLibrary],
  );

  const createQna = async () => {
    setLoading(true);
    const params = {
      studio_id: currentStudioData?.studio?.id,
      title: data.title,
      type: NOTICE_TYPE.qna,
      contents: data.content,
      attachment: attachmemt,
    };

    try {
      if (headerTitle === HEADER_TITLE.qnaCreate) {
        await apis.board.setQna(params);
      } else {
        await apis.board.patchQna(currentBoard.id, params);
      }
      setLoading(false);
      return history.push({ pathname: '/board' });
    } catch {
      if (headerTitle === HEADER_TITLE.qnaCreate) {
        alert({ message: ERROR_TYPES.qnaCreate });
      } else {
        alert({ message: ERROR_TYPES.qnaPatch });
      }
      setLoading(false);
    }
  };

  const cancelCreateQna = useCallback(() => {
    return history.push({ pathname: '/board' });
  }, [history]);

  return (
    <MainLayout header={{ title: headerTitle, titleBorder: false, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>
      <QuestionCreatePresenter
        cameraPermission={camera}
        photoLibraryPermission={photoLibrary}
        requestImage={requestImage}
        onClickImageUpload={onClickImageUpload}
        setData={setData}
        createQna={createQna}
        data={data}
        loading={loading}
        cancelCreateQna={cancelCreateQna}
      />
    </MainLayout>
  );
};

export default QuestionCreateContainer;
