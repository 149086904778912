/** root reducer */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from 'store/auth/reducer';
import boardStore from 'store/board/reducer';
import studio from 'store/studio/reducer';
import usage from 'store/usage/reducer';
import booking from 'store/booking/reducer';
import fcm from 'store/fcm/reducer';
import globalUI from 'store/globalUI/reducer';
import permission from 'store/permission/reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['studio'],
};

const appReducer = combineReducers({
  auth,
  board: boardStore,
  studio,
  usage,
  booking,
  fcm,
  globalUI,
  permission,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default persistReducer(persistConfig, rootReducer);
