import React, { useEffect } from 'react';
import Toast from 'antd-mobile/lib/toast';
import Header from 'components/Header';
import TabBarExample from 'components/TabBarExample';
import styled from 'styled-components';

type mainLayoutProps = {
  header?: {
    title: string;
    backFunc?: any;
    params?: any;
    notification?: boolean;
    noBackBtn?: boolean;
    noLeftIcon?: boolean;
    titleNormal?: boolean;
    titleBorder?: boolean;
  };
  invisibleFooter?: boolean;
  loading?: boolean | null;
  children: React.ReactNode;
  btmButtonsLayout?: boolean;
  contentsGrid?: boolean;
  bookingListGrid?: boolean;
  scroll?: boolean;
  notiGrid?: boolean;
  contentStyle?: object;
};

const MainLayout = ({
  header,
  invisibleFooter = false,
  children,
  loading,
  btmButtonsLayout,
  contentsGrid,
  bookingListGrid,
  scroll,
  notiGrid,
  contentStyle,
}: mainLayoutProps) => {
  /** func */
  useEffect(() => (loading ? Toast.loading('Loading', 50000) : Toast.hide()), [loading]);

  return (
    <WrapMainLayout>
      {header && (
        <Header
          title={header.title}
          backFunc={header.backFunc}
          params={header.params}
          noBackBtn={header.noBackBtn}
          noLeftIcon={header.noLeftIcon}
          notification={header.notification}
          titleNormal={header.titleNormal}
          titleBorder={header.titleBorder}
        />
      )}
      <PageWrap
        btmButtonsLayout={btmButtonsLayout}
        contentsGrid={contentsGrid}
        bookingListGrid={bookingListGrid}
        scroll={scroll}
        notiGrid={notiGrid}
        style={contentStyle}>
        {children}
      </PageWrap>
      {!invisibleFooter && <TabBarExample />}
    </WrapMainLayout>
  );
};

export default MainLayout;

export const PageWrap = styled.div<mainLayoutProps>`
  .wrap-scroll {
    overflow-y: auto;
    &.no-btm {
      height: calc(100vh - 45px - 24px);
    }
  }

  ${props => {
    if (props.btmButtonsLayout) {
      return `
        display: grid;
        grid-template-rows: 1fr 60px;
      `;
    }
    if (props.contentsGrid) {
      return `
        display: grid;
        overflow: hidden;
        grid-template-rows: 1fr;
      `;
    }
    if (props.bookingListGrid) {
      return `
        display: grid;
        overflow: hidden;
        grid-template-rows: auto auto 1fr;
      `;
    }
    if (props.scroll) {
      return `
        overflow: hidden;
        overflow-y: auto;
      `;
    }
    if (props.notiGrid) {
      return `
        overflow: hidden;
        display: grid;
        grid-template-rows: auto 1fr;
      `;
    }
  }}
`;

export const WrapMainLayout = styled.div<mainLayoutProps>`
  display: grid;
  grid-template-rows: ${props => (!props.invisibleFooter ? 'auto 1fr auto' : 'auto 1fr')};
  height: 100vh;
  width: 100vw;
  grid-template-columns: 1fr;
`;
