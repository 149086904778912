import React, { useState } from 'react';
import * as apis from 'api/index';
import * as utils from 'utils';
import Store from './store';

type storeProps = {
  storeMsg: boolean;
  changeStore: Function;
  apis: any;
  utils: any;
};

const ChangeStore = ({ children }: { children: React.ReactNode }) => {
  let changeStore = () => {};
  const [storeState, setStoreState] = useState<storeProps>({
    storeMsg: false,
    changeStore,
    apis: apis.default,
    utils: utils.default,
  });

  changeStore = () => {
    if (storeState.storeMsg === false) {
      setStoreState({
        ...storeState,
        storeMsg: true,
      });
    } else {
      setStoreState({
        ...storeState,
        storeMsg: false,
      });
    }
  };

  return <Store.Provider value={storeState}>{children}</Store.Provider>;
};

export default ChangeStore;
