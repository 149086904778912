import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import { HEADER_TITLE, LABEL, AUTH_TYPES, PLACEHOLDER } from 'constants/text';
import Checkbox from 'antd-mobile/lib/checkbox';
import MainLayout from 'components/MainLayout';
import PlainButton from 'components/PlainButton';
import TextInput from 'components/TextInput';
import Store from 'contexts/store/store';
import ConfirmModal from 'components/ConfirmModal';

const DeleteAccount = () => {
  /** hooks */
  const history = useHistory();

  /** state */
  const { apis } = useContext(Store);
  const [loading, setLoading] = useState<boolean>(false);
  const [accept, setAccept] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState<boolean>(false);

  /** func */
  const onSubmit = async () => {
    try {
      const res = await apis.auth.deleteAccount({ password });
      if (res) {
        alert({ message: LABEL.deleteAccount });
        localStorage.clear();
        history.push('/login');
      }
    } catch (err) {
      alert({ message: "현재 비밀번호가 일치하지 않습니다." });
    }
  }

  const onChange = (e: any) => {
    setPassword(e.target.value);
  }

  return (
    <MainLayout header={{ title: HEADER_TITLE.deleteAccount, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>

      <ConfirmModal title='' show={showModal} setShow={setShowModal} action={onSubmit}>
        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: LABEL.warnDeleteAccount }} />
      </ConfirmModal>
      
      <Wrap>
        <div className='desc'>
          <p>본인 확인을 위해 비밀번호를 입력 후 회원 탈퇴 버튼을 눌러 주세요. 탈퇴된 계정은 복구되지 않습니다.</p>
          <p className='desc-blue'>회원 탈퇴 전 아래 내용을 꼭 확인해 주시기 바랍니다.</p>
          <p>
            1. 회원 탈퇴 시 고객 정보는 전자상거래 법률에 의거하여 탈퇴 후 5년간 보관됩니다.<br />
            2. 회원 탈퇴 시 수업 예약 내역을 포함한 수강과 관련된 모든 정보는 일괄 삭제됩니다.<br />
            3. 회원 탈퇴 시 이용했던 시설에 등록되어 있는 회원정보는 시설 측으로 직접 요청해 주셔야 삭제 가능합니다.<br />
          </p>
        </div>

        <div className='text-input'>
          <TextInput
            id={AUTH_TYPES.currentPassword}
            name={AUTH_TYPES.currentPassword}
            value={password}
            onChange={onChange}
            type="password"
            label={LABEL.password}
            placeholder={PLACEHOLDER.passwordGuide}
            className='input'
        />
          <Link to="/reset-password" className="changePassword">비밀번호 찾기</Link>
        </div>


        <Checkbox.AgreeItem 
          checked={accept}
          onChange={(e: any) => setAccept(e.target.checked)}
        >
          <CheckboxText>모든 정보를 삭제하고 회원 탈퇴에 동의합니다.</CheckboxText>
        </Checkbox.AgreeItem>
      </Wrap>

      <FooterWrap>
        <div className="mbsc-btn-group-block">
          <PlainButton
            disabled={!accept || !password}
            color="danger"
            onClick={() => setShowModal(true)}
            // onClick={() => history.push('/settings/delete-account-detail')}
        >
            회원 탈퇴 신청
          </PlainButton>
        </div>
      </FooterWrap>
      
    </MainLayout>
  );
};

const Wrap = styled.div`
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;

  .desc {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5;
    color: ${theme.color.black300};
    margin-bottom: 16px;

    .desc-blue {
      color: ${theme.color.blue};
    }
  }
  .am-checkbox.am-checkbox-checked .am-checkbox-inner {
    background: ${theme.color.red};
    border-color: ${theme.color.red};
  }

  .text-input {
    margin-bottom: 16px;

    .changePassword {
      color: ${theme.color.blue};
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
    }
  
    .input {
      margin-bottom: 16px;
    }
  }
  
`;
const CheckboxText = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: ${theme.color.black300};
  margin: 1px 0 0 8px;
`;

const FooterWrap = styled.div`
  border-top: 1px solid #eee;
  padding: 0 16px;

  button {
    font-size: 18px;
    font-weight: bold;
  }
`;

export default DeleteAccount;