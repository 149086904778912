import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import dayjs from 'dayjs';

import UserTicketCard from 'components/UserTicketCard';
import NoSelectionBox from 'components/NoSelectionBox';
import TicketDetail from 'pages/SelectTicket/TicketDetail';
import Header from 'components/Header';
import HeaderSwiper from 'components/HeaderSwiper';
import TabBarExample from 'components/TabBarExample';
import StudioAction from 'components/StudioAction';
import NoticePopup from './Notice';

import { theme } from 'assets/styles/theme';
import getDaysDiff from 'utils/getDaysDiff';
import GuidePopup from 'components/GuidePopup';

const SelectTicketPresenter = ({
  companies,
  studios,
  loading,
  info,
  currentStudioData,
  setStudio,
  setTicket,
  rebuild,
  setRebuild,
  currentIdx,
  setCurrentIdx,
  tabIndex,
  setTabIndex,
  setCalendarData,
  getUserTicket,
  getUserStudio,
}) => {
  /** state */
  const { ticketRaw, studio } = currentStudioData;
  const ref = useRef(null);
  const [show, setShow] = useState(false); // 시설정보
  const [ticketArr, setTicketArr] = useState([]);
  const guide = localStorage.getItem('guide');
  const countObj = companies.filter(el => el?.studio?.id === studio?.id);

  /** hooks */
  const history = useHistory();

  /** func */
  // 안드로이드 goback
  useEffect(() => {
    if (history.location.pathname === '/select-ticket') {
      setShow(false);
    }
  }, [history.location.pathname, show]);

  useEffect(() => {
    setRebuild(true);

    if (info.ticketCardData !== null) {
      // 수강권 존재할 때
      if (info.ticketCardData.length > 0) {
        const lastIdx = info.ticketCardData.findIndex(i => i.id === currentStudioData.ticket.id); // 마지막으로 저장된 수강권 인덱스

        if (lastIdx === -1) {
          info.ticket.map(el => ticketArr.push(el.ticket_usable));

          // 사용가능 수강권이 하나라도 있으면
          if (ticketArr.includes(true)) {
            info.ticket.some((el, index) => {
              if (el.ticket_usable) {
                setCurrentIdx(index);
                setTicket(info.ticketCardData[index], info.ticket[index]);
                return el;
              }
            });

            // 사용가능 수강권이 없으면
          } else {
            setCurrentIdx(0);
            setTicket(info.ticketCardData[0], info.ticket[0]);
          }

          setTicketArr([]); // 배열 초기화
        } else {
          setCurrentIdx(lastIdx);
          setTicket(info.ticketCardData[lastIdx], info.ticket[lastIdx]);
        }

        // 수강권 존재하지 않을때
      } else {
        setCurrentIdx(0);
        setTicket({ id: 0, period: '' }, []);
      }
    }
  }, [info]);

  const params = {
    slidesPerView: 'auto',
    // spaceBetween: 16,
    centeredSlides: true,
    slideToClickedSlide: true,
    rebuildOnUpdate: rebuild,
    observer: true,
    on: {
      slideChangeTransitionStart: () => {
        setRebuild(false);

        if (ref.current) {
          const ticket = info.ticketCardData[ref.current.swiper.realIndex];
          const idx = ref.current.swiper.realIndex;

          setTicket(ticket, info.ticket[idx]);
          setCurrentIdx(idx);

          const expiredBeforeToday = dayjs(info.ticket[idx].expire_at).isBefore(dayjs());
          setCalendarData(new Date(), expiredBeforeToday ? dayjs(info.ticket[idx].availability_start_at) : new Date());
        }
      },
    },
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    initialSlide: currentIdx,
  };

  const swiperEvent = (tickets, idx) => {
    setRebuild(false);

    if (ref.current !== null) {
      if (ref.current.swiper.realIndex < idx) {
        ref.current.swiper.slideNext();
      } else if (ref.current.swiper.realIndex > idx) {
        ref.current.swiper.slidePrev();
      }
    }

    if (currentStudioData.ticket.id === tickets.id) {
      if (tickets.active && tickets.ticketStatus !== '이용만료') {
        if (tickets.classType === '그룹') {
          history.push(`/booking-group/${tickets.id}`);
        } else {
          history.push(`/booking-private/${tickets.id}`);
        }
      }
    }
  };

  const refreshTicket = () => {
    getUserTicket();
    getUserStudio();
  };

  return (
    <Container
      colors={ticketRaw?.ticket?.colors}
      classType={ticketRaw?.ticket?.available_class_type}
      active={ticketRaw?.active}
      paused={ticketRaw?.is_holding}
      expireAt={getDaysDiff(ticketRaw?.expire_at)}
      remainingCoupon={ticketRaw?.remaining_coupon}
      count={countObj && countObj[0]?.ticket.length}>
      <Header title={currentStudioData?.studio?.name} titleNormal titleBorder={false} notification noBackBtn length={studios.length}>
        <HeaderSwiper
          studios={studios}
          setStudio={setStudio}
          setShow={setShow}
          history={history}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          currentStudioData={currentStudioData}
        />
      </Header>

      {!loading && currentStudioData?.ticket?.id !== 0 ? (
        <>
          <div className="detail">
            <Swiper {...params} ref={ref}>
              {info.ticketCardData &&
                info.ticketCardData.map((tickets, idx) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div className="card" onClick={() => swiperEvent(tickets, idx)} key={tickets.id}>
                      <UserTicketCard tickets={tickets} />
                    </div>
                    // onClick={() => setTicket(tickets, info.ticket[idx], tickets.classType === '그룹' ? `/booking-group/${tickets.id}` : `/booking-private/${tickets.id}`)}
                  );
                })}
            </Swiper>
          </div>

          <TicketDetail currentStudioData={currentStudioData} />
        </>
      ) : (
        <>
          <NoSelectionBox onClick={refreshTicket} />
          <div style={{ height: '100vh' }} />
        </>
      )}

      {/* 수강권 이용 안내 */}
      {!guide && <GuidePopup />}

      <TabBarExample />

      <StudioAction
        show={show}
        data={studio}
        onRequestClose={() => {
          setShow(false);
          history.goBack();
        }}
      />

      <NoticePopup />
      
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100vh;
  width: 100vw;

  .detail {
    .swiper-container {
      ${props => {
        if (props.active === false || props.expireAt < 0 || props.remainingCoupon <= 0) {
          return `background-image: linear-gradient(to bottom, rgba(77, 77, 77, 0.85), rgba(0, 0, 0, 0.9) );`;
        }
        if (props.paused) {
          return `background-image: linear-gradient(to bottom, rgba(136, 136, 136, 0.3), #888888);`;
        }
        if (props.colors && props.colors.length) {
          return `background-image: linear-gradient(to bottom, #${props.colors[0]}, #${props.colors[1]} );`;
        }
        if (props.classType === 'G') {
          return `background-image: linear-gradient(122deg, ${theme.color.deepSkyBlue}, ${theme.color.lightPurple} );`;
        }
        if (props.classType === 'P') {
          return `background-image: linear-gradient(to bottom, ${theme.color.lightPurple}, ${theme.color.pink} );`;
        }
      }}
    }

    .card {
      width: 296px;
      display: flex;
      align-items: center;
      margin: ${props => (props.count > 15 ? '55px' : '40px')} 8px;
    }

    .swiper-pagination {
      width: 296px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-pagination-bullet {
      border: 1px solid white;
      background: transparent;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: white;
    }

    @media (max-width: 320px) {
      .card,
      .swiper-pagination {
        width: 256px;
      }
    }

    .swiper-pagination {
      --swiper-theme-color: white;
    }
  }
`;

export default SelectTicketPresenter;
