import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';

import { postPrivateBooking } from 'store/booking/actions';
import { RootState } from 'store';
import bookingCancelAvailableTime from 'utils/bookingCancelAvailableTime';
import getImageUrl from 'utils/getImageUrl';
import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';
import { staff } from 'pages/Booking/Private/BookingPrivateStaffList';
import PlainButton from 'components/PlainButton';
import Avatar from 'components/Avatar';
import { theme } from 'assets/styles/theme';
import { ReactComponent as BookingIcon } from 'assets/icons/icon-16-check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/icon-16-check-red.svg';
import ConfirmModal from 'components/ConfirmModal';

const { Item } = List;
const { Brief } = Item;

type LocationType = {
  state: {
    id: number;
    time: { startTime: string; viewText: string };
    staff: staff & { id: number; self_introduction: string; name: string };
  };
};

const BookingPrivateDetail = ({ location }: { location: LocationType }) => {
  /** state */
  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', message: '', func: () => {} });

  /** hook */
  const dispatch = useDispatch();
  const history = useHistory();
  const { time, staff, id } = location.state;

  const { loading, done } = useSelector((state: RootState) => state.booking);
  const { studio } = useSelector((state: RootState) => state.studio).currentStudioData;

  useEffect(() => {
    if (Object.keys(done).length) {
      return history.push({
        pathname: '/success',
        state: {
          title: done.title,
          body: done.body,
          routePath: `/booking/list`,
        },
      });
    }
  }, [done, history]);

  const bookingsConfirm = () => {
    // setModalInfo({
    //   title: '예약하기',
    //   message: `${time.viewText}\n${staff.name} 강사님의 수업을\n예약하시겠습니까?`,
    //   func: () => {
    //     dispatch(postPrivateBooking(id, staff.id, time.startTime, 'bookings'));
    //   },
    // });
    // setOpenModal(true);
  };

  const onPrivateBooking = () => {
    // window.mobilsConfirm({
    //   title: '예약하기',
    //   message: `${time.viewText} 수업을 예약하시겠습니까?`,
    //   callback: (res: boolean) => {
    //     if (res) dispatch(postPrivateBooking(id, staff.id, time.startTime));
    //   },
    // });
    // dispatch(postPrivateBooking(id, staff.id, time.startTime, 'bookings'));
    // booking/list
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.privateDetail, titleNormal: true, backFunc: history.goBack }} contentsGrid loading={loading}>
      <ConfirmModal title={modalInfo.title} show={openModal} setShow={setOpenModal} action={modalInfo.func}>
        <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: modalInfo.message }} />
      </ConfirmModal>

      <ContainerStyle>
        <List>
          <Item>
            {/* 날짜 */}
            <Brief>
              <div className="date">{time.viewText}</div>
            </Brief>

            {/* 강사 이름 */}
            <Brief>
              <Avatar className="user_img" size="small-2" imgUrl={getImageUrl(staff.avatar, '32x32')} />
              <span className="content">{staff.name} 강사</span>
            </Brief>
          </Item>

          {/* 예약·취소 가능 시간 */}
          {studio?.policy && (
            <Item>
              <Brief>
                <div className="subTitle">예약·취소 가능 시간</div>
              </Brief>
              <Brief>
                <BookingIcon />
                &nbsp;
                <span className="content">
                  수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy)?.private?.booking }} />
                </span>
              </Brief>
              <Brief>
                <CancelIcon />
                &nbsp;
                <span className="content">
                  수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy)?.private?.cancel }} />
                </span>
              </Brief>
            </Item>
          )}

          <Item>
            <Brief>
              <div className="subTitle">강사 프로필</div>
            </Brief>

            <Brief>
              <SubTitleGray>자기소개</SubTitleGray>
            </Brief>
            {staff.self_introduction ? (
              <Brief>
                <div className="content gap">{staff.self_introduction}</div>
              </Brief>
            ) : (
              <Brief>
                <div className="content gap">자기 소개 내용이 없습니다.</div>
              </Brief>
            )}

            <Brief>
              <SubTitleGray>주요 이력</SubTitleGray>
            </Brief>
            {staff.careers.length ? (
              staff.careers.map(({ id, career }) => (
                <Brief key={id}>
                  <div className="content">{career}</div>
                </Brief>
              ))
            ) : (
              <Brief>
                <div className="content">주요 이력 내용이 없습니다.</div>
              </Brief>
            )}
          </Item>
        </List>
      </ContainerStyle>

      <BottomButton>
        <div className="mbsc-btn-group-block">
          <PlainButton onClick={bookingsConfirm}>
            <b>예약</b>
          </PlainButton>
        </div>
      </BottomButton>
    </MainLayout>
  );
};

const ContainerStyle = styled.div`
  padding: 0 16px 5px 2px;
  overflow-y: auto;

  .am-list-body::before,
  .am-list-body::after {
    background-color: white !important;
  }
  .am-list-body .am-list-item .am-list-line .am-list-content {
    padding: 16px 0;
  }

  .subTitle {
    color: ${theme.color.black300};
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 8px;
  }
  .date {
    font-weight: bold;
    font-size: 18px;
    color: ${theme.color.black300};
    margin-bottom: 5px;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .content {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    white-space: pre-wrap;
  }
  .gap {
    margin-bottom: 20px;
  }
`;

const SubTitleGray = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${theme.color.sub};
`;

const BottomButton = styled.div`
  width: 100%;
  padding: 0 16px;
  z-index: 1;
  background-color: white;
  border-top: 1px solid #ddd;
`;

export default BookingPrivateDetail;
