import React, { useState } from 'react';
import { setCurrentBoard, getNoticePopup } from 'store/board/reducer';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { RootState } from 'store';
import styled from 'styled-components';
import { theme } from 'assets/styles/theme';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import getImageUrl from 'utils/getImageUrl';
import { isAndroid } from 'react-device-detect';

// components
import NoticeModal from 'components/NoticeModal';
import UrlToLink from 'components/UrlToLink';
import { useEffect } from 'react';

dayjs.extend(isToday);

const NoticePopup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isCloseNotice, setIsCloseNotice] = useState(true);
  const [cookies, setCookies] = useCookies();
  const { noticePopup, noticePopupLoading }: any = useSelector((state: RootState) => state.board);
  const { currentStudioData } = useSelector((state: RootState) => state.studio);

  /** constants */
  const DAY_NOTICE_KEY = `${currentStudioData?.studio?.id}_${noticePopup?.notice?.id}_DAY`;
  const WEEK_NOTICE_KEY = `${currentStudioData?.studio?.id}_${noticePopup?.notice?.id}_WEEK`;

  const DAY_STM_NOTICE_KEY = `${currentStudioData?.studio?.id}_${noticePopup?.stmNotice?.id}_STM_DAY`;
  const WEEK_STM_NOTICE_KEY = `${currentStudioData?.studio?.id}_${noticePopup?.stmNotice?.id}_STM_WEEK`;

  useEffect(() => {
    dispatch(getNoticePopup());
  }, [currentStudioData?.studio?.id]);

  const expiredDate = (day: number) => {
    const date = new Date();
    date.setDate(date.getDate() + day);
    // date.setMinutes(date.getMinutes() + day);
    return date;
  };

  const isShowNotice = () => {
    const isShowPopup = !!noticePopup?.notice?.popup_on;
    const isDayCookie = !cookies[DAY_NOTICE_KEY];
    const isWeekCookie = !cookies[WEEK_NOTICE_KEY];
    return isCloseNotice && isShowPopup && isDayCookie && isWeekCookie;
  };

  const isShowStmNotice = () => {
    const today = dayjs();
    const endDate = dayjs(noticePopup?.stmNotice?.end_date);
    const isShow = today.isBefore(endDate) || endDate.isToday();
    const isDayCookie = !cookies[DAY_STM_NOTICE_KEY];
    const isWeekCookie = !cookies[WEEK_STM_NOTICE_KEY];
    return isCloseNotice && isShow && isDayCookie && isWeekCookie;
  };

  const goToBoard = () => {
    const attachments = noticePopup?.notice?.attachments.map((el: { id: number; path: any }) => {
      return { id: el.id, url: getImageUrl(el.path, '0x0') };
    });

    dispatch(
      setCurrentBoard({
        id: noticePopup?.notice?.id,
        studio_id: currentStudioData?.studio?.id,
        staff: noticePopup?.notice?.created_by,
        title: noticePopup?.notice?.title,
        created: noticePopup?.notice?.created_at,
        contents: noticePopup?.notice?.contents,
        attachments,
        comments: [],
        type: 'notice',
      }),
    );

    history.push(`/board-detail`);
  };

  const goToStmBoard = () => {
    dispatch(
      setCurrentBoard({
        id: noticePopup?.stmNotice?.id,
        studio_id: currentStudioData?.studio?.id,
        staff: noticePopup?.stmNotice?.created_by,
        title: noticePopup?.stmNotice?.title,
        created: noticePopup?.stmNotice?.created_at,
        contents: noticePopup?.stmNotice?.context,
        attachments: [],
        comments: [],
      }),
    );
    history.push(`/board-detail`);
  };

  const closeNoticeWeek = () => {
    if (isAndroid) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SET_COOKIE',
            data: {
              name: WEEK_NOTICE_KEY,
              value: WEEK_NOTICE_KEY,
              expires: dayjs(expiredDate(7)).format('YYYY-MM-DDTHH:mm:ss.ssZ'),
            },
          }),
        );
        setIsCloseNotice(false);
      }
    } else {
      setCookies(WEEK_NOTICE_KEY, true, {
        expires: expiredDate(7),
        path: '/',
      });
    }
  };

  const closeNoticeDay = () => {
    if (isAndroid) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SET_COOKIE',
            data: {
              name: DAY_NOTICE_KEY,
              value: DAY_NOTICE_KEY,
              // rn cookie manager date 포맷에 맞춰야함.
              expires: dayjs(expiredDate(1)).format('YYYY-MM-DDTHH:mm:ss.ssZ'),
            },
          }),
        );
        setIsCloseNotice(false);
      }
    } else {
      setCookies(DAY_NOTICE_KEY, true, {
        expires: expiredDate(1),
        path: '/',
      });
    }
  };

  const closeStmNoticeWeek = () => {
    if (isAndroid) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SET_COOKIE',
            data: {
              name: WEEK_STM_NOTICE_KEY,
              value: WEEK_STM_NOTICE_KEY,
              expires: dayjs(expiredDate(7)).format('YYYY-MM-DDTHH:mm:ss.ssZ'),
            },
          }),
        );
        setIsCloseNotice(false);
      }
    } else {
      setCookies(WEEK_STM_NOTICE_KEY, true, {
        expires: expiredDate(7),
        path: '/',
      });
    }
  };

  const closeStmNoticeDay = () => {
    if (isAndroid) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'SET_COOKIE',
            data: {
              name: DAY_STM_NOTICE_KEY,
              value: DAY_NOTICE_KEY,
              expires: dayjs(expiredDate(1)).format('YYYY-MM-DDTHH:mm:ss.ssZ'),
            },
          }),
        );
        setIsCloseNotice(false);
      }
    } else {
      setCookies(DAY_STM_NOTICE_KEY, true, {
        expires: expiredDate(1),
        path: '/',
      });
    }
  };

  const NoticeContent = () => {
    return (
      <ModalContent className="modal-style-detail" onClick={() => goToBoard()}>
        {!!noticePopup?.notice?.attachments.length &&
          noticePopup?.notice?.attachments.map((item: any) => {
            return (
              <img
                src={getImageUrl(item?.path, '0x0')}
                key={item?.id}
                alt="notice"
                style={{ width: '100%', height: 'auto', display: 'block', paddingBottom: '16px' }}
              />
            );
          })}
        <div>
          <UrlToLink text={noticePopup?.notice?.contents} />
        </div>
      </ModalContent>
    );
  };

  const StmNoticeContent = () => {
    return (
      <ModalContent className="modal-style-detail" onClick={() => goToStmBoard()}>
        {noticePopup?.stmNotice?.context && <div dangerouslySetInnerHTML={{ __html: noticePopup?.stmNotice?.context }} />}
      </ModalContent>
    );
  };

  return (
    <>
      {/* 공지사항 팝업 */}
      {!noticePopupLoading && noticePopup.notice && (
        <NoticeModal
          show={isShowNotice()}
          title={noticePopup?.notice?.title}
          footer={[
            {
              text: '7일간열지않기',
              onPress: () => closeNoticeWeek(),
            },
            {
              text: '오늘그만보기',
              onPress: () => closeNoticeDay(),
            },
          ]}>
          <NoticeContent />
        </NoticeModal>
      )}

      {!noticePopupLoading && noticePopup.stmNotice && (
        <NoticeModal
          show={isShowStmNotice()}
          title={noticePopup?.stmNotice?.title}
          footer={[
            {
              text: '7일간열지않기',
              onPress: () => closeStmNoticeWeek(),
            },
            {
              text: '오늘그만보기',
              onPress: () => closeStmNoticeDay(),
            },
          ]}>
          <StmNoticeContent />
        </NoticeModal>
      )}
    </>
  );
};

const ModalContent = styled.div`
  height: 250px;
  overflow: auto;
  padding: 0 16px;
  font-size: 14px;
  font-weight: normal;
  color: ${theme.color.black500};
  line-height: 1.71;
  text-align: left;
  white-space: pre-wrap;
  a {
    overflow: hidden;
    word-break: break-word;
  }
`;

export default NoticePopup;
