import { useState, useCallback } from 'react';
import utils from 'utils';

export default (initialValue = null) => {
  const [valid, setValid] = useState(initialValue);
  const handler = useCallback(
    e => {
      const { value, name } = e.target;
      let isValid = true;
      switch (name) {
        case 'loginEmail':
          isValid = utils.validate.email(value);
          if (!isValid) {
            isValid = utils.validate.mobile(value);
          }
          break;
        case 'mobile':
          isValid = utils.validate.mobile(value);
          if (!value.length) isValid = false;
          break;
        case 'name':
          isValid = value.length >= 2 || value.length === 0;
          break;
        case 'email':
        case 'changeEmail':
          isValid = utils.validate.email(value) && value.indexOf('.') > -1;
          break;
        case 'confirmChangeEmail':
          isValid = utils.validate.email(value) && value.indexOf('.') > -1 || value === initialValue.confirmChangeEmail || value.length === 0;
          break;
        case 'currentPassword':
          isValid = utils.validate.password(value);
          // if (!value.length) isValid = false;
          break;
        case 'password':
        case 'newPassword':
          isValid = utils.validate.password(value);
          break;
        case 'newPasswordConfirm':
          isValid = utils.validate.password(value) || value === initialValue.passwordValue || value.length === 0;
          break;
        default:
          break;
      }

      setValid({
        ...valid,
        [name]: isValid,
      });
    },
    [valid, initialValue.passwordValue]
  );
  return [valid, handler, initialValue.passwordValue];
};