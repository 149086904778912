import produce from 'immer';

import { GET_PERMISSION_CHECK, Permission } from './types';
import { ActionRequest } from './actions';

export const initalState: Permission = {
  camera: 'denied',
  photoLibrary: 'denied',
};

export const permission = (state: Permission = initalState, action: ActionRequest) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_PERMISSION_CHECK:
        draft.camera = action.payload.camera;
        draft.photoLibrary = action.payload.photoLibrary;
        break;
    }
  });

export default permission;
