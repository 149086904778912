export const SET_USERS = 'SET_USERS' as const;
export const LOGOUT = 'LOGOUT' as const;
export const KEEP_ACCESS_TOKEN = 'KEEP_ACCESS_TOKEN' as const;
export const DELETE_TOKEN = 'DELETE_TOKEN' as const;
export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST' as const;
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS' as const;
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE' as const;

export type IAuthState = {
  accessToken: string | null;
  expired_in: number | null;
  loading: boolean;
  user: any | null;
  fcmToken: string | null;
  isLogin: boolean
};

export type userAccount = {
  created_at: string;
  deleted_at: null;
  email: string;
  id: number;
  lang: string;
  mobile: string;
  mobile_invalid_at: null | string | boolean;
  name: string;
  recent_password_changed_at: null | string;
  updated_at: string;
};

export type getAccountRes = {
  user_account: userAccount & {
    representative_avatar: {
      created_at: string;
      deleted_at: null | string;
      id: number;
      image: { host: string; name: string; path: string };
      updated_at: string;
    };
    updated_at: string;
  };
};
