/* eslint-disable no-undef */
import { ERROR_TYPES } from 'constants/text';
import {
  GET_BOOKING_LIST_REQUEST,
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_FAILURE,
  GET_BOOKING_LIST_ALL_REQUEST,
  GET_BOOKING_LIST_ALL_SUCCESS,
  GET_BOOKING_LIST_ALL_FAILURE,
  GET_BOOKING_DETAIL_REQUEST,
  GET_BOOKING_DETAIL_SUCCESS,
  GET_BOOKING_DETAIL_FAILURE,
  GET_BOOKING_COUNT_REQUEST,
  GET_BOOKING_COUNT_SUCCESS,
  GET_BOOKING_COUNT_FAILURE,
  GET_BOOKING_COUNT_ALL_REQUEST,
  GET_BOOKING_COUNT_ALL_SUCCESS,
  GET_BOOKING_COUNT_ALL_FAILURE,
  SET_BOOKING_FILTER,
  INITIALIZE_FILTER,
  INITIALIZE_BOOKING,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  PATCH_BOOKING_STATUS_REQUEST,
  INIT_CANCEL_DONE,
  PATCH_BOOKING_STATUS_SUCCESS,
  PATCH_BOOKING_STATUS_FAILURE,
  GET_CALENDAR_MARK,
} from './types';

type bookingCountProps = {
  absence_count: number;
  attendance_count: number;
  booked_count: number;
  booking_waiting_count: number;
  cancel_count: number;
  noshow_count: number;
  total_count: number;
};

export const getBookingList = (id: number, payload: Record<string, any>, date?: string | Date) => ({ type: GET_BOOKING_LIST_REQUEST, id, payload, date });
export const getBookingS = (result: { current_page: number; last_page: number; data: any }) => ({ type: GET_BOOKING_LIST_SUCCESS, data: result });
export const getBookingF = () => ({ type: GET_BOOKING_LIST_FAILURE, data: ERROR_TYPES.unUsableTicket });

export const getBookingCount = (payload: number) => ({ type: GET_BOOKING_COUNT_REQUEST, payload });
export const getBookingCountS = (data: bookingCountProps) => ({ type: GET_BOOKING_COUNT_SUCCESS, data });
export const getBookingCountF = () => ({ type: GET_BOOKING_COUNT_FAILURE, data: ERROR_TYPES.unUsableTicket });

export const getBookingDetail = (payload: number) => ({ type: GET_BOOKING_DETAIL_REQUEST, payload });
export const getBookingDetailS = (data: any) => ({ type: GET_BOOKING_DETAIL_SUCCESS, data });
export const getBookingDetailF = () => ({ type: GET_BOOKING_DETAIL_FAILURE, data: 'error' });

export const initializeBooking = () => ({ type: INITIALIZE_BOOKING });

export const getBookingListAll = (payload: Record<string, any>) => ({ type: GET_BOOKING_LIST_ALL_REQUEST, payload });
export const getBookingAllS = (result: { current_page: number; last_page: number; data: any }) => ({ type: GET_BOOKING_LIST_ALL_SUCCESS, data: result });
export const getBookingAllF = () => ({ type: GET_BOOKING_LIST_ALL_FAILURE, data: ERROR_TYPES.unUsableTicket });

export const getBookingCountAll = (payload: number) => ({ type: GET_BOOKING_COUNT_ALL_REQUEST, payload });
export const getBookingCountAllS = (data: bookingCountProps) => ({ type: GET_BOOKING_COUNT_ALL_SUCCESS, data });
export const getBookingCountAllF = () => ({ type: GET_BOOKING_COUNT_ALL_FAILURE, data: ERROR_TYPES.unUsableTicket });

export const setBookingFilter = (payload: any) => ({ type: SET_BOOKING_FILTER, payload });
export const initializeFilter = () => ({ type: INITIALIZE_FILTER });

export const cancelBooking = (payload: number) => ({ type: CANCEL_BOOKING_REQUEST, payload });
export const cancelBookingS = () => ({ type: CANCEL_BOOKING_SUCCESS, data: { status: 200, message: 'success' } });
export const cancelBookingF = (err: any) => ({
  type: CANCEL_BOOKING_FAILURE,
  data: { status: err?.response?.status, message: err?.response?.data.message },
});
export const initCancelDone = () => ({ type: INIT_CANCEL_DONE });

export const patchBooking = (id: number, payload: { statue: string }) => ({ type: PATCH_BOOKING_STATUS_REQUEST, id, payload });
export const patchBookingS = () => ({ type: PATCH_BOOKING_STATUS_SUCCESS, data: { status: 200, message: 'success' } });
export const patchBookingF = (err: any) => ({
  type: PATCH_BOOKING_STATUS_FAILURE,
  data: { status: err?.response?.status, message: err?.response?.data.message },
});

export const getCalendarMark = (payload: { d: string; color: string }[]) => ({ type: GET_CALENDAR_MARK, payload });

export type ActionRequest =
  | ReturnType<typeof getBookingList>
  | ReturnType<typeof getBookingS>
  | ReturnType<typeof getBookingF>
  | ReturnType<typeof getBookingCount>
  | ReturnType<typeof getBookingCountS>
  | ReturnType<typeof getBookingCountF>
  | ReturnType<typeof setBookingFilter>
  | ReturnType<typeof initializeFilter>
  | ReturnType<typeof getBookingDetail>
  | ReturnType<typeof getBookingDetailS>
  | ReturnType<typeof getBookingDetailF>
  | ReturnType<typeof getBookingListAll>
  | ReturnType<typeof getBookingAllS>
  | ReturnType<typeof getBookingAllF>
  | ReturnType<typeof getBookingCountAll>
  | ReturnType<typeof getBookingCountAllS>
  | ReturnType<typeof getBookingCountAllF>
  | ReturnType<typeof initializeBooking>
  | ReturnType<typeof cancelBooking>
  | ReturnType<typeof cancelBookingS>
  | ReturnType<typeof cancelBookingF>
  | ReturnType<typeof patchBooking>
  | ReturnType<typeof patchBookingS>
  | ReturnType<typeof patchBookingF>
  | ReturnType<typeof initCancelDone>
  | ReturnType<typeof getCalendarMark>;
