import React from 'react';
import Iframe from 'react-iframe';
import { useHistory, useLocation } from 'react-router-dom';

import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';

const Policy = () => {
  /** hooks */
  const history = useHistory();
  const location = useLocation();

  const TERM_OF_SERVICE = '/settings/service-center/term-of-service';

  return (
    <MainLayout
      header={{
        title: location.pathname === TERM_OF_SERVICE ? HEADER_TITLE.termOfService : HEADER_TITLE.privacyPolicy,
        titleNormal: true,
        backFunc: history.goBack,
        params: null,
      }}
      scroll>
      <Iframe
        url={`https://studiomate.kr/${location.pathname === TERM_OF_SERVICE ? 'terms-of-service' : 'privacy-policy'}.html`}
        width="100%"
        height="100%"
        id='myinfo_iframe'
        frameBorder={0}
        />
    </MainLayout>
  )
};

export default Policy;
