import { all, fork, put, call, takeLatest, getContext } from 'redux-saga/effects';
import booking from 'api/modules/booking';
import { store } from 'index';
import { ACTION_BOOKING } from 'constants/text';
import { AVAILABLE_BOOKING_LIST_R, POST_GROUP_BOOKING_R, DELETE_GROUP_BOOKING_R, AVAILABLE_PRIVATE_LIST_R, POST_PRIVATE_BOOKING_R } from './types';
import {
  getAvailableBookingListS,
  getAvailableBookingListF,
  postGroupBookingS,
  postGroupBookingF,
  deleteGroupBookingS,
  deleteGroupBookingF,
  getAvailablePrivateListS,
  getAvailablePrivateListF,
  postPrivateBookingS,
  postPrivateBookingF,
} from './actions';

function* getAvailableBookingListSaga(action) {
  try {
    const result = yield call(() => booking.getAvailableBookingList(action.id, action.date));
    yield put(getAvailableBookingListS(result.data, store.getState().studio.currentStudioData.studio));
  } catch (err) {
    yield put(getAvailableBookingListF(err));
  }
}

function* watchAvailableBookingList() {
  yield takeLatest(AVAILABLE_BOOKING_LIST_R, getAvailableBookingListSaga);
}

function* postGroupBookingSaga(action) {
  try {
    const result = yield call(() => booking.postGroupBooking(action.types, action.userTicketId, action.lectureId));
    yield put(postGroupBookingS(action.types, result.status));
  } catch (err) {
    yield put(postGroupBookingF(err?.response?.data.message));
  }
}

function* watchPostGroupBooking() {
  yield takeLatest(POST_GROUP_BOOKING_R, postGroupBookingSaga);
}

function* deleteGroupBookingSaga(action) {
  try {
    const is_absence_by_user = store.getState().studio.currentStudioData.studio?.policy?.is_absence_by_user;

    const apis = is_absence_by_user
      ? action.bookingType === ACTION_BOOKING.bookingConfirm
        ? booking.patchBooking(action.id, { status: ACTION_BOOKING.absence })
        : booking.deleteBooking(action.id)
      : booking.deleteBooking(action.id);
    const result = yield call(() => apis);
    yield put(deleteGroupBookingS(action.bookingType, result.status));
  } catch (err) {
    yield put(deleteGroupBookingF(err?.response?.data.message));
  }
}

function* watchDeleteGroupBooking() {
  yield takeLatest(DELETE_GROUP_BOOKING_R, deleteGroupBookingSaga);
}

function* getAvailableBookingPrivateListSaga(action) {
  try {
    const result = yield call(() => booking.getAvailablePrivateList(action.id, action.date));
    yield put(getAvailablePrivateListS(result.data, store.getState().studio.currentStudioData));
  } catch (err) {
    yield put(getAvailablePrivateListF(err?.response?.data.message));
  }
}

function* watchAvailablePrivateList() {
  yield takeLatest(AVAILABLE_PRIVATE_LIST_R, getAvailableBookingPrivateListSaga);
}

function* postPrivateBookingSaga(action) {
  try {
    const result = yield call(() =>
      booking.postPrivateBooking(
        action.userTicketId,
        action.staffId,
        action.startOn,
        action.studioPrivateInterval,
        action.staffPrivateIntervalAm,
        action.staffPrivateIntervalPm,
      ),
    );
    yield put(postPrivateBookingS(action.types, result.data));
  } catch (err) {
    const code = err?.response?.data?.code;
    if (code === 10507) {
      const history = yield getContext('history');
      alert({
        title: '잠시 후 다시 시도해주세요.',
        message: err?.response?.data.message,
        callback: () => {
          history.push('/select-ticket');
        },
      });
      yield put(postPrivateBookingF());
    } else {
      yield put(postPrivateBookingF(err?.response?.data.message));
    }
  }
}

function* watchPostPrivateBooking() {
  yield takeLatest(POST_PRIVATE_BOOKING_R, postPrivateBookingSaga);
}

export default function* bookingSaga() {
  yield all([
    fork(watchAvailableBookingList),
    fork(watchPostGroupBooking),
    fork(watchDeleteGroupBooking),
    fork(watchAvailablePrivateList),
    fork(watchPostPrivateBooking),
  ]);
}
