import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import Store from 'contexts/store/store';

import { theme } from 'assets/styles/theme';
import MainLayout from 'components/MainLayout';
import { HEADER_TITLE, LOCAL_STORAGE } from 'constants/text';

import UrlToLink from 'components/UrlToLink';

const NotificationDetail = ({ location }: any) => {
  const history = useHistory();
  const { utils } = useContext(Store);

  const notiDetail = location.state;

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE.notification, dayjs().format('YYYY-MM-DD HH:mm:ss'));
  }, []);

  return (
    <MainLayout
      header={{
        title: HEADER_TITLE.notificationDetail,
        titleNormal: true,
        titleBorder: false,
        backFunc: history.goBack,
      }}
      contentsGrid>
      <NotiWrap>
        <div className="card">
          <NotiDetail>
            <div className="detail_title">{notiDetail.title}</div>
            <div className="detail_createdTime">{utils.getTime.getFullDateTime(notiDetail.created_at)}</div>
          </NotiDetail>
          <NotiContent>
            <div className="detail_content">
              <UrlToLink text={notiDetail?.message} />
              {notiDetail?.board_id && <p>* 자세한 내용은 게시판에서 확인해주세요.</p>}
            </div>
          </NotiContent>
        </div>
      </NotiWrap>
    </MainLayout>
  );
};

/** css */

const NotiWrap = styled.div`
  padding: 16px;
  height: 100%;
  overflow-y: auto;
  .card {
    border-radius: 16px;
    box-shadow: 0px 1px 7px -3px grey;
    padding: 16px 0;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;

const NotiDetail = styled.div`
  margin-bottom: 16px;
  padding: 0 16px;
  .detail_title {
    color: ${theme.color.black500};
    font-weight: 500;
    font-size: 16px;
    white-space: pre-wrap;
    line-height: 1.4;
  }

  .detail_createdTime {
    color: ${theme.color.black500};
    font-weight: normal;
    font-size: 14px;
    padding: 16px 0;
    text-align: right;
    border-bottom: 1px solid #bbb;
  }
`;

const NotiContent = styled.div`
  overflow-y: auto;
  padding: 0 16px;
  .detail_content {
    color: ${theme.color.black300};
    font-weight: normal;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 1.5;
    padding-bottom: 16px;
  }
`;

export default NotificationDetail;
