import React from 'react';
import { useHistory } from 'react-router-dom';
import TabBar from 'antd-mobile/lib/tab-bar';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import { ReactComponent as Menu } from 'assets/icons/icon-24-menu.svg';

import { ReactComponent as Ticket } from 'assets/icons/icon-24-ticket.svg'; // 수강권
import { ReactComponent as Booking } from 'assets/icons/icon-24-calendar.svg'; // 수업예약
import { ReactComponent as Reservation } from 'assets/icons/icon-24-reservation.svg'; // 이용내역
import { ReactComponent as Profile } from 'assets/icons/icon-24-profile.svg'; // 마이페이지
import { ReactComponent as Qna } from 'assets/icons/icon-24-qa.svg'; // 게시판

import { TICKET_TYPE, ERROR_TYPES } from 'constants/text';
import { theme } from 'assets/styles/theme';
import getDaysDiff from 'utils/getDaysDiff';

type tabBarProps = {
  history: any;
};

const TabBarExample = () => {
  /** hooks */
  const history = useHistory();

  /** state */
  const { currentStudioData } = useSelector((state: RootState) => state.studio);
  const { ticketRaw, ticket } = currentStudioData;
  const studioId = localStorage.getItem('studio_id'); // 수강권 유무

  const changeTab = (type: string, state?: any) => {

    const condition = ticketRaw.id !== 0 && (ticketRaw.active === false || (ticketRaw.remaining_coupon <= 0 || getDaysDiff(ticketRaw.expire_at) < 0));
    const booking_page = type.indexOf('/booking-group') !== -1 || type.indexOf('/booking-private') !== -1;
    const list_page = type.indexOf('/booking/list') !== -1;
    
    if (booking_page && condition) {
      alert({ message: ERROR_TYPES.expireTicket });
    } else if ((booking_page || list_page) && ticket.id === 0) {
      alert({ message: ERROR_TYPES.noTicket });
    } else {
      return state ? history.push({pathname: type, state}) : history.push(type);
    } 

  };

  return (
    <TabBarContainer history={history}>
      <TabBar unselectedTintColor={theme.color.lightGray} tintColor={theme.color.blue} barTintColor="white" tabBarPosition="bottom" prerenderingSiblingsNumber={0}>

        <TabBar.Item
          icon={<Ticket />}
          selectedIcon={<Ticket />}
          title="수강권"
          key="ticket"
          selected={
            history.location.pathname === '/' ||
            history.location.pathname === '/select-ticket'
          }
          onPress={() => changeTab('/select-ticket')}
      />
        <TabBar.Item
          icon={<Booking />}
          selectedIcon={<Booking />}
          title="수업예약"
          key="booking"
          selected={
            history.location.pathname.split('/').includes('booking-group') ||
            history.location.pathname.split('/').includes('booking-private')
          }
          onPress={() => changeTab(`/booking-${currentStudioData?.ticketRaw?.ticket?.available_class_type === TICKET_TYPE.group ? 'group' : 'private'}/${currentStudioData?.ticket?.id}`)}
      />
        <TabBar.Item
          icon={<Reservation fill={theme.color.lightGray} />}
          selectedIcon={<Reservation fill={theme.color.blue} />}
          title="이용내역"
          key="booking/list"
          selected={
            history.location.pathname === '/booking/list' ||
            history.location.pathname === '/booking/detail' ||
            history.location.pathname === '/booking/change'
          }
          onPress={() => changeTab('/booking/list')}
      />
        <TabBar.Item
          title="마이페이지"
          key="mypage"
          icon={<Profile fill={theme.color.lightGray} />}
          selectedIcon={<Profile fill={theme.color.blue} />}
          selected={history.location.pathname === '/mypage'}
          onPress={() => changeTab('/mypage')}
      />
        <TabBar.Item
          icon={<Qna fill={theme.color.lightGray} />}
          selectedIcon={<Qna fill={theme.color.blue} />}
          title="게시판"
          key="board"
          selected={
            history.location.pathname === '/board' ||
            history.location.pathname === '/board-detail' ||
            history.location.pathname === '/board-detail/image' ||
            history.location.pathname === '/board/qna/create' ||
            history.location.pathname === '/board/qna/edit' ||
            history.location.pathname === '/board/qna/comment/edit'
          }
          onPress={() => changeTab('/board')}
      />
      </TabBar>
    </TabBarContainer>
  );
};

const TabBarContainer = styled.div<tabBarProps>`

  // 수강권
  .am-tab-bar-tab:first-child .am-tab-bar-tab-icon svg g g {
    ${props => {
      if (
        props.history.location.pathname === '/' ||
        props.history.location.pathname === '/select-ticket'
        ) {
        return `fill: ${theme.color.blue};`;
      } 
        return `
          fill: ${theme.color.lightGray};
        `;
      }
    }
  }


  // 수업예약
  .am-tab-bar-tab:nth-child(2) .am-tab-bar-tab-icon svg g g {
    ${props => {
      if (
        props.history.location.pathname.split('/').includes('booking-group') ||
        props.history.location.pathname.split('/').includes('booking-private')
        ) {
        return `fill: ${theme.color.blue};`;
      } 
        return `
          fill: ${theme.color.lightGray};
        `;
      }
    }
  }

  // 마이페이지
  .am-tab-bar-tab:nth-child(4) .am-tab-bar-tab-icon svg g g {
    ${props => {
      if (props.history.location.pathname === '/mypage') {
        return `fill: ${theme.color.blue};`;
      } 
        return `
          fill: ${theme.color.lightGray};
        `;
      }
    }
  }

  // 게시판
  .am-tab-bar-tab:nth-child(5) .am-tab-bar-tab-icon svg g g {
    ${props => {
      if (
        props.history.location.pathname === '/board' ||
        props.history.location.pathname === '/board-detail' ||
        props.history.location.pathname === '/board-detail/image' ||
        props.history.location.pathname === '/board/qna/create' ||
        props.history.location.pathname === '/board/qna/edit' ||
        props.history.location.pathname === '/board/qna/comment/edit') {
        return `fill: ${theme.color.blue};`;
      } 
        return `
          fill: ${theme.color.lightGray};
        `;
      }
    }
  }
`;


export default React.memo(TabBarExample);