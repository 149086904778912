import moment from 'moment';
import filters from 'utils/filters';

export default (policy, deadline, interval, selectDate, classPeriod, staffList) => {
  // interval=30 프라이빗 수업 예약 시간 단위 설정 (30분마다) : 시설 정보 변경
  // classPeriod=90 수업 시간 (90분) : 수강권 정보 변경
  // deadline=120 (2 * 60)분 전까지 예약가능

  // 1. R : 수업시간 기준 - 마감시간
  // 2. RR : 수업시간 기준 - 시작시간 - 마감시간
  // 3. A : 수업일 기준 - 마감시간
  // 4. AR : 수업일 기준 - 시작시간 - 마감시간
  let booking_start = '';

  // 시작시간 부터 마감시간 까지
  // 마감시간 A, AR
  const end_day = moment(selectDate).subtract(policy?.private_booking_end_days, 'day');
  const e_hour = moment(policy?.private_booking_end_time, 'HH:mm:ss').hour();
  const e_minute = moment(policy?.private_booking_end_time, 'HH:mm:ss').minute();
  const booking_end = moment(end_day).hours(e_hour).minutes(e_minute).second(0).format('YYYY-MM-DD HH:mm:ss');
  // 시작시간 AR
  const start_day = moment(selectDate).subtract(policy?.private_booking_start_days, 'day');
  const s_hour = moment(policy?.private_booking_start_time, 'HH:mm:ss').hour();
  const s_minute = moment(policy?.private_booking_start_time, 'HH:mm:ss').minute();
  booking_start = moment(start_day).hours(s_hour).minutes(s_minute).second(0).format('YYYY-MM-DD HH:mm:ss');

  const staffSlot = [];

  for (let i = 0; i < staffList.length; i++) {
    staffSlot.push({ staff: staffList[i], time: [] }); // 강사 정보 push

    const privateIntervalOn = !!staffList[i].private_start_interval_am;
    const privateIntervalAm = staffList[i].private_start_interval_am;
    const privateIntervalPm = staffList[i].private_start_interval_pm;

    // 00:00 ~ 24:00 까지 총 수업 타임 수
    const newInterval = privateIntervalOn ? 30 : interval;
    const intervalLoop = privateIntervalOn ? 47 : 24 * (60 / newInterval) - 1;

    for (let j = 0; j <= intervalLoop; j++) {
      let startTime = '';

      const hours = Math.floor((j * newInterval) / 60);
      const minutes = (j * newInterval) % 60;

      if (privateIntervalOn) {
        // 오전
        if (hours < 13) {
          if (privateIntervalAm % 60 !== minutes) continue;
        }
        // 오후
        else {
          if (privateIntervalPm % 60 !== minutes) continue;
        }
      }

      startTime = moment(selectDate).hours(hours).minutes(minutes).second(0).format('YYYY-MM-DD HH:mm:ss');
      const endTime = moment(startTime).add(classPeriod, 'm').format('YYYY-MM-DD HH:mm:ss');
      const viewText = filters.dateLecture(startTime, endTime);
      // 강사정보 비교
      if (!staffList[i].working_hours) break;

      // 강사 근무시간 이내 시간
      if (moment(staffList[i].working_hours.start_time) <= moment(startTime) && moment(staffList[i].working_hours.end_time) >= moment(endTime)) {
        let result = true;

        // 강사 휴식 or 예약 있으면 제외
        for (let k = 0; k < staffList[i].events.length; k++) {
          const event = staffList[i].events[k];
          if (moment(event.end_time).isAfter(moment(startTime)) && moment(event.start_time).isBefore(endTime)) {
            result = false;
          }
        }

        if (result) {
          const end_deadline = moment(startTime).subtract(deadline, 'minutes'); // R, RR
          const start_deadline = moment(startTime).subtract(policy?.private_booking_startline, 'minutes'); // RR
          // 검색일이 오늘이면 현재 시간보다 이전 수업 시작시간을 가진 수업은 제외
          if (moment(selectDate).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
            if (moment() < moment(startTime)) {
              // switch start
              switch (policy?.private_booking_rule_type) {
                case 'R':
                  if (moment().isSameOrBefore(end_deadline)) staffSlot[i].time.push({ startTime, endTime, viewText });
                  break;
                case 'RR':
                  if (moment().isSameOrBefore(end_deadline) && moment().isAfter(start_deadline))
                    staffSlot[i].time.push({ startTime, endTime, viewText });
                  break;
                case 'A':
                  if (moment().isSameOrBefore(booking_end)) staffSlot[i].time.push({ startTime, endTime, viewText });
                  break;
                case 'AR':
                  if (moment().isSameOrBefore(booking_end) && moment().isAfter(booking_start))
                    staffSlot[i].time.push({ startTime, endTime, viewText });
                  break;
                default:
                  break;
              }
              // switch end
            }
            // 검색일이 오늘이 아니면
          } else {
            // switch start
            switch (policy?.private_booking_rule_type) {
              case 'R':
                if (moment().isSameOrBefore(end_deadline)) staffSlot[i].time.push({ startTime, endTime, viewText });
                break;
              case 'RR':
                if (moment().isSameOrBefore(end_deadline) && moment().isAfter(start_deadline))
                  staffSlot[i].time.push({ startTime, endTime, viewText });
                break;
              case 'A':
                if (moment().isSameOrBefore(booking_end)) staffSlot[i].time.push({ startTime, endTime, viewText });
                break;
              case 'AR':
                if (moment().isSameOrBefore(booking_end) && moment().isAfter(booking_start)) staffSlot[i].time.push({ startTime, endTime, viewText });
                break;
              default:
                break;
            }
            // switch end
          }
        } // end result
      }
    }
  }

  return staffSlot;
};
