import produce from 'immer';

import { 
  globalUITypes,
  OPEN_MENU,
  CLOSE_MENU,
 } from './types';
import { 
  menuActions
} from './actions';

export const initalState: globalUITypes = {
  isMenuShow: false,
};

export const globalUI = (state: globalUITypes = initalState, action: menuActions) =>
  produce(state, draft => {
    switch (action.type) {
      case OPEN_MENU:
        draft.isMenuShow = action.payload;
        break;
      case CLOSE_MENU:
        draft.isMenuShow = action.payload;
        break;
    }
  });

export default globalUI;
