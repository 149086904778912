/**
 * 이미지 URL 생성
 *
 */

type imageProps = {
  host: string,
  name: string,
  path: string
}

export default (image: imageProps | null, size = '32x32') => {
  try {
    if (image) {
      const [filename, extension] = image?.name.split('.');
      return `${image.host}${image.path}/${filename}_${size}.${extension}`;
    }
  } catch (error) {
    console.error(error);
  }
};
