import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import List from 'antd-mobile/lib/list';
import dayjs from 'dayjs';
import { RootState } from 'store';

import getImageUrl from 'utils/getImageUrl';
import convertTypeToString from 'utils/convertTypeToString';
import filters from 'utils/filters';
import { LectureType } from 'store/booking/types';
import styled from 'styled-components';
import TagButton from 'components/TagButton';
import { theme } from 'assets/styles/theme';
import isPastBookingTime from 'utils/isPastBookingTime';
import isPastCourseEndTime from 'utils/isPastCourseEndTime';
import Avatar from './Avatar';

type bookingCardProps = {
  lecture: LectureType;
  status?: string;
  currentBookingCount?: number;
  history?: any;
};

const BookingCard = ({ lecture, status, currentBookingCount }: bookingCardProps) => {
  const history = useHistory();
  const { studio } = useSelector((state: RootState) => state.studio.currentStudioData);

  const bookingEnd = () => {
    // 수업종료 X
    if (!isPastCourseEndTime(lecture.end_on)) {
      // 예약대기 가능 시설
      if (studio?.policy?.weekly_waiting_limit !== 0) {
        // 수업시간 지남
        if (isPastBookingTime(lecture.start_on, lecture.booking_end_at)) {
          return (
            <StatusBox>
              <TagButton text="예약마감" color={theme.tagColor.darkGrey} />
            </StatusBox>
          );
        }
        // 인원마감
        if (currentBookingCount && currentBookingCount >= lecture.max_trainee) {
          return (
            <StatusBox>
              <TagButton text="인원마감" color={theme.tagColor.darkGrey} />
            </StatusBox>
          );
        }
      }

      // 예약대기 불가 시설 | 인원마감 || 수업시간 지남
      else if ((currentBookingCount && currentBookingCount >= lecture.max_trainee) || isPastBookingTime(lecture.start_on, lecture.booking_end_at)) {
        return (
          <StatusBox>
            <TagButton text="예약마감" color={theme.tagColor.darkGrey} />
          </StatusBox>
        );
      }
    }
  };

  return (
    <ListStyle lecture={lecture} status={status} history={history}>
      <div className="item">
        {/* 날짜 */}
        {history.location.pathname === '/mypage' ||
        history.location.pathname === '/ticket-detail' ||
        history.location.pathname === '/select-ticket' ||
        history.location.pathname === '/' ? (
          <div className="date">{filters.dateLecture(lecture.start_on, lecture.end_on)}</div>
        ) : (
          <div className="date">{filters.dateLectureOnlyTime(lecture.start_on, lecture.end_on)}</div>
        )}

        <div className="status">
          {/* 수업명 */}
          <div className="title flex-grow">{lecture.type === 'G' ? lecture.title : `${lecture.max_trainee}:1 프라이빗 수업`}</div>

          {/* status */}
          <div>
            {status && <StatusBox>{convertTypeToString(status)}</StatusBox>}
            {!status && bookingEnd()}
            {/* {!status && ((currentBookingCount && currentBookingCount === lecture.max_trainee) || isPastBookingTime(lecture.start_on, lecture.booking_end_at)) ? <StatusBox><TagButton text='예약마감' color={theme.tagColor.darkGrey} /></StatusBox> : null}  */}
            {!status && lecture.max_trainee && isPastCourseEndTime(lecture.end_on) && (
              <StatusBox>
                <TagButton text="수업종료" color={theme.tagColor.black} />
              </StatusBox>
            )}
          </div>
        </div>

        {/* 강사 이름 */}
        <div className="user-info">
          <Avatar size="small-2" imgUrl={getImageUrl(lecture?.instructor?.representative_avatar?.image, '32x32')} />
          {/* <img
            className='user-img'
            role="presentation"
            src={getImageUrl(lecture.instructor.representative_avatar, '32x32')}
            alt="유저이미지"
            /> */}
          <span className="instructor flex-grow">{lecture.instructor.name} 강사</span>

          {/* 룸 */}
          {lecture.room && (
            <div className="room max-width">
              <div className="room-overflow">{lecture.room.name}</div>&nbsp;<div>룸</div>
            </div>
          )}
        </div>

        {/* 그룹일 경우 수강인원 */}
        {lecture.type === 'G' &&
          lecture.max_trainee &&
          (studio?.policy?.is_show_current_count ? (
            <div className="max_wait">
              <div className="flex-grow">
                <span className="sub">예약인원/최대수강인원</span>
                <span className="content">
                  {lecture.current_trainee_count}/{lecture.max_trainee}
                </span>
              </div>
              {studio?.policy?.is_show_booking_waiting_count && !!lecture.booking_waiting_count && (
                <div>
                  <span className="sub">총 대기 인원</span>
                  <span className="content">{lecture.booking_waiting_count}</span>
                </div>
              )}
            </div>
          ) : (
            <div className="max_wait">
              <div className="flex-grow">
                <span className="sub">최대수강인원</span>
                <span className="content">{lecture.max_trainee}</span>
              </div>
              {studio?.policy?.is_show_booking_waiting_count && lecture.booking_waiting_count ? (
                <div>
                  <span className="sub">총 대기 인원</span>
                  <span className="content">{lecture.booking_waiting_count}</span>
                </div>
              ) : null}
            </div>
          ))}
      </div>
    </ListStyle>
  );
};

export default BookingCard;

const StatusBox = styled.span`
  // position: absolute;
  // right: 0;
`;

const ListStyle = styled.div<bookingCardProps>`
  .status {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .item {
    border-radius: 16px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    padding: 12px;
    height: ${props => (props.lecture.type === 'G' ? '146px' : '121px')};

    // div:not(:first-child) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // }
  }
  .flex-grow {
    flex-grow: 1;
  }
  .title {
    color: ${theme.color.black300};
    font-size: 14px;
    font-weight: normal;
  }
  .date {
    color: ${theme.color.black300};
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .user-info {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: ${props => (props.lecture.type === 'G' ? '8px' : '')};
  }
  .user-img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .instructor {
    margin-left: 5px;
  }
  .instructor,
  .room {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black500};
  }
  .max-width {
    max-width: 128px;
    display: flex;
  }
  .room-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .max_wait {
    display: flex;

    .sub {
      font-size: 14px;
      font-weight: normal;
      color: #aaa;
      margin-right: 6px;
    }

    .content {
      font-size: 14px;
      font-weight: normal;
      color: ${theme.color.black500};
    }
  }

  ${props => {
    if (!props.status && props.lecture.max_trainee && dayjs(props.lecture.end_on) < dayjs()) {
      return `
        .item {
          background-color: #f8f8f8;
        }
      `;
    }
  }}
`;
