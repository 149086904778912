import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { AUTH_TYPES } from 'constants/text';
import { ReactComponent as Eye } from 'assets/icons/icon-24-eye.svg';
import { ReactComponent as NoEye } from 'assets/icons/icon-24-eye-disable.svg';


function TextInput({ id, value, type, placeholder, label, name, onChange, onFocus, error, errorMessage, elseMessage, refs, className, tempMessage }) {

  /** hooks */
  const [show, setShow] = useState(false);

  /** func */
  function ifPassword() {
    return id === AUTH_TYPES.password || id === AUTH_TYPES.confirmPassword || id === AUTH_TYPES.currentPassword || id === AUTH_TYPES.newPassword || id === AUTH_TYPES.newPasswordConfirm;
  }

  return (
    <Container className={className || null}>
      <label>{label}</label>
      <input ref={refs || null} id={id} name={name} type={show ? 'text' : type} value={value} placeholder={placeholder} onChange={onChange} onFocus={onFocus} />
      {ifPassword() && <EyeStyle onClick={() => setShow(!show)}>{show ? <NoEye /> : <Eye />}</EyeStyle>}
      {error ? <ErrorInfoTxt>{errorMessage}</ErrorInfoTxt> : <InfoTxt>{elseMessage}</InfoTxt>}
      {ifPassword() && <InfoTxt>{tempMessage}</InfoTxt>}
    </Container>
  );
}

TextInput.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  elseMessage: PropTypes.string,
  refs: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
  tempMessage: PropTypes.string
};

TextInput.defaultProps = {
  id: '',
  value: '',
  type: 'text',
  placeholder: '',
  label: '',
  name: '',
  onChange: {},
  onFocus: ()=>{},
  error: false,
  errorMessage: '',
  elseMessage: '',
  refs: null,
  className: '',
  tempMessage: ''
};

/** css */
const Container = styled.div`
margin-bottom:24px;
  label,input,span {
    display: block
  }
  input {
    width: 100%
  }
  svg {
    position: absolute;
    right: 0;
  }
`;

const EyeStyle = styled.div`
  position: relative;
  top: -30px;
`;

const ErrorInfoTxt = styled.span`
  color: ${props => props.theme.color.red};
  ${props => props.theme.fonts.font12};
  ${props => props.theme.margins.t8};
  ${props => props.theme.displays.inlineBlock};
`;

const InfoTxt = styled.span`
  color: ${props => props.theme.color.black500};
  ${props => props.theme.fonts.font12};
  ${props => props.theme.margins.t8};
  ${props => props.theme.displays.inlineBlock};
`;

export default React.memo(TextInput);
