import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Store from 'contexts/store/store';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

import { RootState } from 'store';
import { getBookingCount, setBookingFilter, getBookingList, initializeFilter, initializeBooking, getBookingDetail } from 'store/usage/actions';
import { sortTypeProps } from 'store/usage/types';
import MainLayout from 'components/MainLayout';
import BookingListPresenter from 'pages/BookingList/BookingListPresenter';
import { HEADER_TITLE, TICKET_TYPE } from 'constants/text';

dayjs.extend(isToday);

const BookingListContainer = () => {
  /** hooks */
  const history = useHistory();
  const dispatch = useDispatch();
  const isLastNoti = useRef(false);
  const observer = useRef<any>(null);
  const { utils } = useContext(Store);

  /** state */
  const { currentStudioData } = useSelector((state: RootState) => state.studio);
  const { availability_start_at, holdings } = useSelector((state: RootState) => state.studio.currentStudioData.ticketRaw);
  const { sortedBy, status } = useSelector((state: RootState) => state.usage.filters);
  const { loading, params, bookings, markDate } = useSelector((state: RootState) => state.usage);
  const { selectDate } = useSelector((state: RootState) => state.booking.usageCalendarData);
  const { holidays } = currentStudioData.studio;

  const [show, setShow] = useState<{ sort: boolean; status: boolean }>({ sort: false, status: false });
  const [holding] = useState<any>([]);

  /** func */

  useEffect(() => {
    /** 이용내역 count */
    dispatch(getBookingCount(currentStudioData.ticket.id));

    return () => {
      dispatch(initializeBooking());
      dispatch(initializeFilter());
    };
  }, []);

  /** 이용 내역 무한 스크롤 */
  useEffect(() => {
    if (params.page === 1) {
      if (params.page <= params.last_page) {
        // 전체내역으로 강제 필터
        dispatch(
          setBookingFilter({
            index: '6',
            value: '',
            type: 'status',
          }),
        );
        dispatch(getBookingList(currentStudioData.ticket.id, { ...params, limit: 500 }));
        isLastNoti.current = false;
      }
    }
    // eslint-disable-next-line
  }, [params.sortedBy, params.status, params.page, params.limit, dispatch, currentStudioData.ticket.id]);

  /** 날짜 바꾸면 current true, param 초기화 */
  // const changeDate = () => {
  //   isLastNoti.current = true;
  // };

  // limit=15 마다 스크롤
  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isLastNoti.current) {
          if (params.page <= params.last_page) {
            // dispatch(getBookingList(currentStudioData.ticket.id, params));
          } else {
            isLastNoti.current = true;
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, params],
  );

  const changeType = (event: { target: { dataset: {} } }) => {
    if (Object.keys(event.target.dataset).length) dispatch(setBookingFilter(event.target.dataset));
    setShow({ sort: false, status: false });
  };

  const currentText = (datas: sortTypeProps[]): { value: string; content: string } => {
    return datas.filter(({ current }) => current)[0];
  };

  const goToDetail = (booking: { id: number }): void => {
    dispatch(getBookingDetail(booking.id));
    return history.push('/booking/detail');
  };

  const goToBooking = () =>
    history.push(
      `/booking-${currentStudioData.ticketRaw.ticket.available_class_type === TICKET_TYPE.group ? 'group' : 'private'}/${
        currentStudioData.ticket.id
      }`,
    );

  // 수강권 정지
  useEffect(() => {
    if (holdings) {
      holdings.map(el => {
        let startDate = el.start_on;
        const endDate = el.end_on;

        holding.push(startDate);

        while (dayjs(startDate).isBefore(endDate)) {
          startDate = dayjs(startDate).add(1, 'days').format('YYYY-MM-DD');
          holding.push(startDate);
        }
      });
    }
  }, []);

  return (
    <MainLayout
      header={{ title: HEADER_TITLE.bookingList, titleNormal: true, notification: true, titleBorder: false, noBackBtn: true }}
      notiGrid
      loading={loading}>
      <BookingListPresenter
        sortedBy={sortedBy}
        status={status}
        bookings={bookings}
        show={show}
        setShow={setShow}
        lastElementRef={lastElementRef}
        changeType={changeType}
        currentText={currentText}
        goToDetail={goToDetail}
        goToBooking={goToBooking}
        markDate={markDate}
        startDate={availability_start_at}
        selectDate={utils.getTime.getHyperDate(selectDate)}
        holidays={holidays}
        holdings={holding}
      />
    </MainLayout>
  );
};

export default BookingListContainer;
