import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useInput from 'hooks/useInput';
import useValid from 'hooks/useValid';
import Store from 'contexts/store/store';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import MainLayout from 'components/MainLayout';
import FooterButtons from 'components/Footer/FooterButtons';
import TextInput from 'components/TextInput';
import PlainButton from 'components/PlainButton';
import { AUTH_TYPES, VALIDATE_MESSAGE, SUCCESS_MESSAGE, HEADER_TITLE, MODAL_TEXT, LABEL, PLACEHOLDER } from 'constants/text';
// import mobiscroll from '@mobiscroll/react';

export default function ChangeMobile() {
  /** hook */
  const history = useHistory();
  const { apis } = useContext(Store);
  const mobileRef = useRef<HTMLInputElement>();

  const [data, setData] = useInput({
    mobile: '',
    validateId: null,
    validateCode: '',
  });

  const [valid, validate] = useValid({
    mobile: false,
    validateCode: false,
  });

  const [isClickValied, setClick] = useState<boolean>(false);

  const [smsValid, setSmsValid] = useState<boolean>(true);

  const [loading, setLoading] = useState<boolean>(false);

  /** func */
  useEffect(() => {
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  }, []);

  const onChange = useCallback(
    e => {
      setData(e);
      validate(e);
    },
    [setData, validate],
  );

  const sendValidCode = async () => {
    try {
      // 휴대폰 중복 체크
      const duplicationCheck = await apis.auth.checkDuplicationMobile({ value: data.mobile });
      if (duplicationCheck.data.count) {
        alert({ message: '중복된 휴대폰 번호가 있습니다.' });
      } else {
        const res = await apis.auth.requestSmSCode({ mobile: data.mobile });
        if (res?.status === 200) {
          setData({ target: { value: res.data.id, name: 'validateId' } });
          alert({
            message: MODAL_TEXT.sendValidCode,
            callback: () => setClick(true),
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const confirmSmsCode = async () => {
    const res = await apis.auth.validateSmSCode(data.validateId, data.validateCode);
    if (res.data.result) {
      alert({
        message: '인증번호가 확인되었습니다.',
        callback: () => setSmsValid(false),
      });
    } else {
      alert({
        message: '인증번호를 다시 확인해주세요.',
      });
    }
  };

  const onChangeMobile = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apis.auth.changeAccount({
        user_account: { mobile: data.mobile },
        validation_id: data.validateId,
        validation_code: data.validateCode,
      });
      if (res.status === 200) {
        setLoading(false);
        return history.push({
          pathname: '/success',
          state: { ...SUCCESS_MESSAGE.changeMobile, routePath: '/mypage/edit' },
        });
      }
    } catch (error) {
      alert({
        message: error?.response?.data?.message,
        callback: () => setLoading(false),
      });
    }
  }, [history, apis.auth, data]);

  return (
    <MainLayout header={{ title: HEADER_TITLE.changeMobile, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>
      <Wrap>
        <div className="desc">변경할 휴대폰 번호를 입력하고 휴대폰 인증을 해주세요.</div>

        <StyledSection>
          <TextInput
            id={AUTH_TYPES.mobile}
            name={AUTH_TYPES.mobile}
            type="number"
            refs={mobileRef}
            value={data.mobile}
            label={LABEL.mobile}
            placeholder={LABEL.mobilePlaceholder}
            error={!valid.mobile}
            errorMessage={data.mobile && VALIDATE_MESSAGE.incorrectNumber}
            onChange={onChange}
          />
          <StyledBtnArea>
            <PlainButton className="flex-both-center" color="primary" onClick={sendValidCode} disabled={!valid.mobile}>
              인증번호 받기
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>

        <StyledSection>
          <TextInput
            id={AUTH_TYPES.validateCode}
            name={AUTH_TYPES.validateCode}
            type="number"
            value={data.validateCode}
            label={LABEL.validateCode}
            placeholder={PLACEHOLDER.validateCode}
            error={!valid.validateCode}
            onChange={onChange}
          />
          <StyledBtnArea>
            <PlainButton className="flex-both-center" color="primary" onClick={confirmSmsCode} disabled={!isClickValied || !data.validateCode}>
              인증번호 확인
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>
      </Wrap>

      <BtnWrap>
        <FooterButtons cancelClick={() => history.goBack()} confirmClick={onChangeMobile} confirmText="휴대폰 번호 변경" disabled={smsValid} />
      </BtnWrap>
    </MainLayout>
  );
}

const Wrap = styled.div`
  padding: 0 16px;
  .desc {
    padding: 40px 0 40px 0;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    color: ${theme.color.black300};
  }
  .flex-row-between > *:first-child {
    flex: 1;
    margin: 0 10px 16px 0;
  }
  .message {
    font-size: 12px;
    color: grey;
  }

  button {
    font-size: 14px;
    font-weight: 500;
    height: 33px;
  }
`;

const StyledSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 107px;
  grid-column-gap: 10px;
`;

const StyledBtnArea = styled.div`
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    padding: 10px;
    margin: 27px 0 0 0;
  }
`;

const BtnWrap = styled.div`
  .mbsc-ios.mbsc-btn {
    padding: 0.625em 4px;
  }
`;
