export default (year, month) => {

  const firstOfMonth = new Date(year, month - 1, 1);

  let day = firstOfMonth.getDay() || 6;
  day = day === 1 ? 0 : day;

  if (day) { day-- }

  let diff = 7 - day;

  const lastOfMonth = new Date(year, month, 0);
  const lastDate = lastOfMonth.getDate();

  if (lastOfMonth.getDay() === 1) {
  	diff--;
  }
  
  const result = Math.ceil((lastDate - diff) / 7);
  return result + 1;
};