import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getUserTicket, getUserStudio, setStudio, setTicket, setCurrentIdx, setTabIndex } from 'store/studio/reducer';
import { changeCalendarData, changeUsageCalendarData } from 'store/booking/actions';

import SelectTicketPresenter from 'pages/SelectTicket/SelectTicketPresenter';
import { ERROR_TYPES } from 'constants/text';

import Toast from 'antd-mobile/lib/toast';

const SelectTicketContainer = () => {
  /** state */
  const { data, studios, currentStudioData, loading, currentIdx, tabIndex } = useSelector(state => state.studio);
  const { user } = useSelector(state => state.auth);
  const [info, setInfo] = useState({ ticketCardData: null, ticket: null });

  const [rebuild, setRebuild] = useState(false);
  let isValid = false;

  /** hooks */
  const dispatch = useDispatch();

  /** func */
  useEffect(() => (loading ? Toast.loading('Loading', 50000) : Toast.hide()), [loading]);

  useEffect(() => {
    // GET 스튜디오 정보
    // dispatch(getUserTicket());
    dispatch(getUserStudio());

    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'notification/CHECK_PERMISSION' }));

    // 휴대폰 인증 만료 < 현재시각
    if (!loading && user?.mobile_invalid_at && !isValid) {
      isValid = true;
      alert({ message: ERROR_TYPES.mobileExpire });
    }
  }, []);

  // currentStudioData 없을때 setStudio[0], setTicket[0] 디폴트
  useEffect(() => {
    if (data.length > 0 && studios.length > 0) {
      const headerIdx = data.findIndex(({ studio }) => studio.id === studios[0].id);

      if (currentStudioData?.studio?.id === 0) {
        if (headerIdx === -1) {
          dispatch(setStudio(studios[0]));
        } else {
          dispatch(setStudio(studios[0]));
          // dispatch(setTicket(data[headerIdx].ticketCardData[0], data[headerIdx].ticket[0]));

          // 1번째 사용가능 수강권
          data[headerIdx].ticket.some((el, index) => {
            if (el.ticket_usable) {
              dispatch(setTicket(data[headerIdx].ticketCardData[index], data[headerIdx].ticket[index]));
              return el;
            }
          });
        }
      }
    }
  }, [studios.length === 0 || data.length === 0]);

  // 스튜디오 바뀌면 스튜디오 info 업데이트
  useEffect(() => {
    const existed = data.findIndex(({ studio }) => studio?.id === currentStudioData?.studio?.id);

    if (currentStudioData?.studio?.id !== 0) {
      if (existed > -1) {
        data.map(({ studio, ticketCardData, ticket }) => {
          if (studio.id === currentStudioData?.studio?.id) {
            // 만료된 수강권 숨기기
            if (studio?.policy?.hide_tickets_deleted)
              setInfo({ ticketCardData: ticketCardData.filter(el => el.ticketUsable), ticket: ticket.filter(el => el.ticket_usable) });
            else setInfo({ ticketCardData, ticket });
            // dispatch(setTicket(ticketCardData[0], ticket[0]));
          }
        });
      } else {
        setInfo({ ticketCardData: [], ticket: [] });
      }
    }
  }, [currentStudioData?.studio?.id, data]);

  useEffect(() => {
    dispatch(getUserTicket());
  }, [currentStudioData?.studio?.id]);

  const setUserTicket = () => {
    dispatch(getUserTicket());
  };

  const setUserStudio = () => {
    dispatch(getUserStudio());
  };

  // 현재 스튜디오로 저장
  const onSetStudio = studio => {
    dispatch(setStudio(studio));
  };

  // 현재 수강권으로 저장
  const onSetTicket = (ticket, ticketRaw) => {
    dispatch(setTicket(ticket, ticketRaw));
  };

  // 시설 인덱스
  const onSetTabIndex = index => {
    dispatch(setTabIndex(index));
  };

  // 수강권 인덱스
  const onSetCurrentIdx = index => {
    dispatch(setCurrentIdx(index));
  };

  // 수강권 날짜 업데이트
  const onSetCalendarData = (cal, usageCal) => {
    dispatch(changeCalendarData({ selectDate: cal })); // 만료된 수강권 체크
    dispatch(changeUsageCalendarData({ selectDate: usageCal }));
  };

  return (
    <SelectTicketPresenter
      companies={data}
      studios={studios}
      loading={loading}
      info={info}
      setInfo={setInfo}
      currentStudioData={currentStudioData}
      setStudio={onSetStudio}
      setTicket={onSetTicket}
      tabIndex={tabIndex}
      setTabIndex={onSetTabIndex}
      currentIdx={currentIdx}
      setCurrentIdx={onSetCurrentIdx}
      rebuild={rebuild}
      setRebuild={setRebuild}
      setCalendarData={onSetCalendarData}
      getUserTicket={setUserTicket}
      getUserStudio={setUserStudio}
    />
  );
};

export default SelectTicketContainer;
