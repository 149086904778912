import React, { useState, useEffect } from 'react';
import 'assets/styles/animations.scss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import List from 'antd-mobile/lib/list';
import Toast from 'antd-mobile/lib/toast';

import useGlobalUI from 'hooks/useGlobalUI';
import { theme } from 'assets/styles/theme';
import { RootState } from 'store';
import url from 'utils/getImageUrl';
import Ad from 'components/Ad';
import { ERROR_TYPES, TICKET_TYPE } from 'constants/text';

import { ReactComponent as Ticket } from 'assets/icons/icon-24-ticket.svg';
import { ReactComponent as Reservation } from 'assets/icons/icon-24-reservation.svg';
import { ReactComponent as Noti } from 'assets/icons/icon-24-noti.svg';
import { ReactComponent as Qna } from 'assets/icons/icon-24-qa.svg';
import { ReactComponent as Setting } from 'assets/icons/icon-24-setting.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-24-close.svg';
import { ReactComponent as Booking } from 'assets/icons/icon-24-calendar.svg';
import { ReactComponent as Profile } from 'assets/icons/icon-24-profile.svg';
import getDaysDiff from 'utils/getDaysDiff';

const MenuContainer = () => {
  /** hook */
  const history = useHistory();
  const { closeGlobalMenu } = useGlobalUI(false);
  const [loading, setLoading] = useState(false);

  /** state */
  const { currentStudioData } = useSelector((state: RootState) => state.studio);
  const { ticketRaw, ticket } = currentStudioData;
  const { isMenuShow } = useSelector((state: RootState) => state.globalUI);
  // const studioId = localStorage.getItem('studio_id'); // 수강권 유무

  /** func */
  useEffect(() => (loading ? Toast.loading('Loading', 50000) : Toast.hide()), [loading]);

  const goToRoute = (route: string) => history.push(route);

  const changeMenu = (type: string, tab?: number) => {

    const condition = ticketRaw.id !== 0 && (ticketRaw.active === false || (ticketRaw.remaining_coupon <= 0 || getDaysDiff(ticketRaw.expire_at) < 0));
    const booking_page = type.indexOf('/booking-group') !== -1 || type.indexOf('/booking-private') !== -1;
    const list_page = type.indexOf('/booking/list') !== -1;

    if (booking_page && condition) {
      alert({ message: ERROR_TYPES.expireTicket });
    } else if ((booking_page || list_page) && ticket.id === 0) {
      alert({ message: ERROR_TYPES.noTicket });
    } else {
      goToRoute(type);
      closeGlobalMenu();
    }
  }

  const ListData = [
    {
      name: '수강권',
      icon: <Ticket />,
      func: () => {
        // changeMenu('/select-studio', 1);
        changeMenu('/select-ticket');
      }
    },
    {
      name: '수업예약',
      icon: <Booking />,
      func: () => {
        changeMenu(`/booking-${currentStudioData?.ticketRaw?.ticket?.available_class_type === TICKET_TYPE.group ? 'group' : 'private'}/${currentStudioData?.ticket?.id}`);
      }
    },
    {
      name: '이용내역',
      icon: <Reservation />,
      func: () => {
        changeMenu('/booking/list');
      }
    },
    {
      name: '마이페이지',
      icon: <Profile />,
      func: () => {
        changeMenu('/mypage');
      }
    },
    {
      name: '알림',
      icon: <Noti />,
      func: () => {
        changeMenu('/notification');
      },
    },
    {
      name: '게시판',
      icon: <Qna />,
      func: () => {
        changeMenu('/board');
      },
    },
    {
      name: '설정',
      icon: <Setting />,
      func: () => {
        changeMenu('/settings');
      },
    },
  ];

  const menuStatus = isMenuShow ? 'isopen' : '';

  return (
    <div className={menuStatus} style={{ zIndex: 102, position: 'absolute', width: '85%', height: '100%', transform: `${menuStatus === '' ? 'translateX(-100%)' : 'none'}`, transition: 'transform 300ms' }}>
      <WrapContent>

        {/* <UserContainer>
          <WrapNameLine>
            <img role="presentation" src={avatar} alt="유저이미지" onLoad={() => setLoading(false)} onError={() => setAvatar(defaultImage)} />
            <div className="area-name">{user?.name}</div>
            <Close onClick={closeGlobalMenu} />
          </WrapNameLine>

          <p>{filter.mobile(user?.mobile)}</p>
          <p>{user?.email}</p>

          {!!studio.policy.is_locker &&
            studio.locker.map(el => (
              <p key={el.id}>
                락커 {el.no}번 {el.name} {filter.date(el.start_on)}~{filter.date(el.end_on)}
              </p>
            ))}
        </UserContainer> */}

        <IconStyle>
          <CloseIcon className='icon' onClick={closeGlobalMenu} />
        </IconStyle>

        <WrapList>
          <List>
            {ListData.map(el => (
              <List.Item key={el.name} thumb={el.icon} onClick={el.func}>
                {el.name}
              </List.Item>
            ))}
          </List>
        </WrapList>

        {/* <Ad /> */}
        
      </WrapContent>

    </div>
  );
};

/** css */
const IconStyle = styled.div`
  background: white;
  text-align: right;
  height: 56px;

  .icon {
    margin: 16px;
  }
`;

const UserContainer = styled.div`
  background-color: ${theme.color.blue};
  padding: 24px 16px 16px 24px;
  p {
    margin: 0 0 0 40px;
    color: #fff;
    padding-bottom: 8px;
    font-size: 14px;
    font-weight: normal;
  }
`;

const WrapContent = styled.div`
  // height: calc(100vh - 110px);
  height: 100%;
  
  display: grid;
  grid-template-rows: auto 1fr auto;
  .am-list-item .am-list-thumb:first-child {
    margin-right: 8px;
    margin-top: 3px;
  }
`;

const WrapList = styled.div`
  background-color: white;
  
  .am-list {
    .am-list-body::before, .am-list-body::after {
      visibility: hidden;
    }

    .am-list-item {
      padding-left: 4px;
      margin-left: 24px;
      height: 50px;
      border-bottom: 0.5px solid #ddd;
      &:last-child {
        border: none;
      }
      .am-list-line {
        border: none;
      }
      
      .am-list-thumb svg, .am-list-thumb svg g {

        fill: ${theme.color.blue};
      }

      .am-list-line {
        padding: 16px 24px 16px 0;
        &::after {
          visibility: hidden;
        }
        .am-list-content {
          font-size: 16px;
          font-weight: 500;
          color: ${theme.color.black300};
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
        }
      }
    }
    
  }
`;

const WrapNameLine = styled.div`
  color: #fff;
  display: grid;
  grid-template-columns: 40px 1fr 24px;
  line-height: 30px;
  margin-bottom: 11px;
  img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    border: 1px solid white;
  }
  svg {
    margin-top: 4px;
  }
  .area-name {
    font-size: 16px;
    font-weight: 500;
  }
`;
export default MenuContainer;
