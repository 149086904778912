import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import useInput from 'hooks/useInput';
import useValid from 'hooks/useValid';
import Store from 'contexts/store/store';
import { setUsers } from 'store/auth/actions';
import { ERROR_TYPES } from 'constants/text';
import Toast from 'antd-mobile/lib/toast';
import LoginPresenter from 'pages/Login/LoginPresenter';
import { RootState } from 'store';

function LoginContainer() {
  const { user } = useSelector((state: RootState) => state.auth);

  /** hook */
  const history = useHistory();
  const { apis, utils } = useContext(Store);
  const dispatch = useDispatch();

  /** state */
  const [text, setText] = useInput({
    loginEmail: '',
    password: '',
  });
  const [valid, validate] = useValid({
    loginEmail: true,
    password: true,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { loginEmail, password } = text;

  /** useEffect */
  useEffect(() => {
    if (utils.localStorage.get('access_token')) {
      history.push('/select-ticket');
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'notification/CHECK_PERMISSION' }));
    }

    const localStorageEmail = utils.localStorage.get('loginEmail');
    if (localStorageEmail) {
      const e = { target: { value: localStorageEmail, name: 'loginEmail' } };
      setText(e);
    }

    return () => setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (utils.localStorage.get('access_token')) {
      history.push('/select-ticket');
    }
  }, [user]);

  useEffect(() => (loading ? Toast.loading('Loading', 50000) : Toast.hide()), [loading]);

  /** func */
  const onChange = useCallback(
    async e => {
      if (error) setError(null);

      validate(e);
      setText(e);
    },
    [error, setText, validate],
  );

  const onSubmit = async (e: any) => {
    console.log('isMobile', isMobile, process.env.REACT_APP_ENV);

    if (!isMobile) {
      alert({ message: ERROR_TYPES.needMobile });
      return;
    }

    e.preventDefault();
    setLoading(true);
    try {
      const fcmToken = utils.localStorage.get('fcm_token');

      let params: { email: string | null; password: string; mobile: string | null; fcm_token?: string | null } = {
        email: loginEmail,
        password,
        mobile: null,
      };
      if (utils.validate.mobile(loginEmail)) {
        params = { email: null, mobile: loginEmail, password };
        if (fcmToken) params.fcm_token = fcmToken;
      }

      const loginRes = await apis.auth.login(params);

      if (loginRes) {
        dispatch(setUsers(loginRes.data));
        utils.localStorage.set('loginEmail', loginEmail);
        if (fcmToken) {
          await apis.auth.patchFCMToken({ instance_token: fcmToken });
        } else {
          if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'notification/CHECK_PERMISSION' }));
        }
        history.push('/select-ticket');
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        alert({ message: ERROR_TYPES.incorrectAuth });
      }
      setLoading(false);
    }
  };

  return <LoginPresenter loginEmail={loginEmail} password={password} valid={valid} loading={loading} onChange={onChange} onSubmit={onSubmit} />;
}

export default LoginContainer;
