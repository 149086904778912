import api from 'api/modules/notification';
import { LOCAL_STORAGE } from 'constants/text';
import dayjs from 'dayjs';

export default async () => {
  try {
    const res = await api.getNotice({ limit: 1 });
    const newNotiDate = res?.data?.user_notices[0]?.created_at;
    const lastReadNotiDate = localStorage.getItem(LOCAL_STORAGE.notification);
    if (lastReadNotiDate && newNotiDate) {
      return dayjs(newNotiDate).isAfter(dayjs(lastReadNotiDate));
    }
    return false;
  } catch (error) {
    console.log('noti error', error);
  }
};
