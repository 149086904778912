/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './bookingActionStyle.scss';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';
import moment from 'moment';

import { ReactComponent as Close } from 'assets/icons/icon-24-close-white.svg';
import TagButton from 'components/TagButton';
import convertTypeToString from 'utils/convertTypeToString';
import convertAvailbleTime from 'utils/convertAvailbleTime';
import isPastBookingTime from 'utils/isPastBookingTime';
import isPastCourseEndTime from 'utils/isPastCourseEndTime';
import getImageUrl from 'utils/getImageUrl';
import filters from 'utils/filters';
import { theme } from 'assets/styles/theme';
import { BOOKING_TYPE, TICKET_TYPE } from 'constants/text';
import PlainButton from 'components/PlainButton';
import { postGroupBooking, deleteGroupBooking } from 'store/booking/actions';
import ConfirmModal from 'components/ConfirmModal';
import Avatar from 'components/Avatar';

const { Item } = List;
const { Brief } = Item;

const BookingActionGroup = ({ show, data, onRequestClose, dispatchBookingList, beforeOpen, setLimit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  /** state */
  const { loading, done, calendarData, availableBookingList } = useSelector(state => state.booking);
  const { studio, ticket, ticketRaw } = useSelector(state => state.studio.currentStudioData);
  const [condition, setCondition] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalInfo, setModalInfo] = useState({ title: '', message: '', func: () => {} });

  const { group_booking_limit_day_term, group_booking_limit_day_term_time, group_booking_limit_date } = useSelector(state => state.studio)
    .currentStudioData.studio.policy;

  useEffect(() => {
    if (Object.keys(done).length && show) {
      return history.push({
        pathname: '/success',
        state: {
          title: done.title,
          body: done.body,
          routePath: `/booking-group/${ticketRaw.id}`,
        },
      });
    }
  }, [done, ticketRaw.id]);

  useEffect(() => {
    if (!window.navigator.onLine) alert({ message: '인터넷 연결을 확인해주세요' });

    if (dayjs(calendarData.selectDate).format('YYYY-MM-DD') !== dayjs(availableBookingList?.data[0]?.start_on).format('YYYY-MM-DD')) {
      // 예약가능기한
      // 고급설정 === null
      if (!group_booking_limit_date) {
        const limitTerm = group_booking_limit_day_term; // 7
        const diffTime = Math.abs(+moment() - +moment(ticketRaw.expire_at)); // 2968315324
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 35

        const currentDate = moment()
          .add(limitTerm >= diffDays ? diffDays : limitTerm, 'days')
          .format('YYYY-MM-DD');
        const startDate = moment(currentDate)
          .add(beforeOpen(currentDate) ? 0 : 1, 'days')
          .format('YYYY-MM-DD');
        const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

        const whenToShow = moment(new Date(), 'hh:mm:ss').isBefore(moment(group_booking_limit_day_term_time, 'hh:mm:ss')); // 현재 시각 < 12:00:00 ? true

        dispatchBookingList(currentDate, startDate, endDate, whenToShow, moment(calendarData.selectDate).format('YYYY-MM-DD'));
        setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow });
      }

      // 고급설정 !== null
      else {
        const limitTerm = group_booking_limit_day_term; // 7
        let currentDate = group_booking_limit_date; // 2021-04-27 12:00:00

        if (moment(new Date()).isAfter(moment(currentDate))) {
          currentDate = moment(currentDate).add(limitTerm, 'days').format('YYYY-MM-DD hh:mm:ss'); // limitTerm 주기로 업데이트
        }

        const startDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
        const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

        dispatchBookingList(currentDate, startDate, endDate, false, moment(calendarData.selectDate).format('YYYY-MM-DD'));
        setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow: false });
      }
    }
  }, [window.navigator.onLine]);

  const bookings = () => {
    setModalInfo({
      title: '예약하기',
      message: `${filters.datetimedate(data.start_on)}\n${data.title} 수업을\n예약하시겠습니까?`,
      func: () => dispatch(postGroupBooking('bookings', Number(ticketRaw.id), Number(data.id))),
    });
    setOpenModal(true);
  };

  const bookingWaiting = () => {
    setModalInfo({
      title: '결원 발생시 자동 예약 안내',
      message: '결원 발생시 자동으로 예약되며, \n푸쉬 알림을 통해 알려드립니다. \n예약대기 하시겠습니까?',
      func: () => dispatch(postGroupBooking('waitings', Number(ticketRaw.id), Number(data.id))),
    });
    setOpenModal(true);
  };

  const deleteBooking = status => {
    setModalInfo({
      title: '예약 취소',
      message: `${filters.datetimedate(data.start_on)}\n${data.title} 수업을\n취소하시겠습니까?`,
      func: () => dispatch(deleteGroupBooking(status, data.booking.id)),
    });
    setOpenModal(true);
  };

  const deleteCountBooking = () => {
    setModalInfo({
      title: '예약 취소',
      message:
        '취소 가능한 시간이 지났습니다.<br />계속해서 예약을 취소할 경우 <span style="color: red">잔여횟수는 자동으로 차감</span>되며 <b>결석으로 처리</b>됩니다. 취소하시겠습니까?',
      func: () => dispatch(deleteGroupBooking('bookingConfirm', data.booking.id)),
    });
    setOpenModal(true);
  };

  const deleteAbsenceBooking = () => {
    setModalInfo({
      title: '예약 취소',
      message: '예약을 취소할 경우\n<b>결석으로 처리</b>됩니다.\n취소하시겠습니까?',
      func: () => dispatch(deleteGroupBooking('bookingConfirm', data.booking.id)),
    });
    setOpenModal(true);
  };

  const deleteBookingWaiting = () => {
    setModalInfo({
      title: '예약 대기 취소',
      message: `예약 대기 하신 ${filters.datetimedate(data.start_on)}<br />${data.title} 수업을\n취소하시겠습니까?`,
      func: () => dispatch(deleteGroupBooking('waitings', data.booking.id)),
    });
    setOpenModal(true);
  };

  const cancelText = () => {
    const after_end = data.booking_cancel_end_at && dayjs(data.booking_cancel_end_at).isBefore(new Date());

    switch (data.booking.status) {
      case BOOKING_TYPE.booked:
        return ticketRaw.ticket.type === TICKET_TYPE.period || !after_end
          ? deleteBooking('bookings')
          : studio?.policy?.is_absence_by_user
          ? deleteCountBooking()
          : deleteBooking('bookings');
      case BOOKING_TYPE.bookingConfirmed:
        return ticketRaw.ticket.type === TICKET_TYPE.period || !after_end
          ? deleteBooking('bookingConfirm')
          : studio?.policy?.is_absence_by_user
          ? deleteCountBooking()
          : deleteBooking('bookingConfirm');
    }
  };

  useEffect(() => {
    const result = [];
    const {
      booking_end_at,
      current_trainee_count,
      max_trainee,
      booking,
      daily_change_booking_end_at,
      booking_start_at,
      start_on,
      booking_cancel_start_at,
      booking_cancel_end_at,
    } = data;

    // 예약 취소 버튼
    const before_start = booking_cancel_start_at ? dayjs(booking_cancel_start_at).isBefore(new Date()) : true;
    const before_end = booking_cancel_end_at && dayjs(booking_cancel_end_at).isAfter(new Date());
    const after_end = booking_cancel_end_at && dayjs(booking_cancel_end_at).isBefore(new Date());

    const isFamilyCondition = ({ text, func, color, outline }) => {
      if (ticketRaw.is_shared) {
        if (booking?.status !== BOOKING_TYPE.noshow && booking?.status !== BOOKING_TYPE.absence) result.push({ text, func, color, outline });
      } else if (!booking?.status) result.push({ text, func, color, outline });
    };

    // 예약완료시
    if (booking?.status === BOOKING_TYPE.booked || booking?.status === BOOKING_TYPE.bookingConfirmed) {
      // 취소 가능 횟수가 0회가 아닐 때
      if (ticketRaw && ticketRaw.remaining_cancel !== 0) {
        // 취소가능시간 이내
        if (before_start && before_end) {
          result.push({
            text: '예약 취소',
            func: cancelText,
            color: 'danger',
            outline: false,
          });
        }

        // 취소가능시간 지났을때
        else if (after_end) {
          // 횟수차감취소설정 true
          if (studio?.policy?.is_absence_by_user) {
            result.push({
              text: '예약 취소',
              func: cancelText,
              color: 'danger',
              outline: false,
            });
            // 횟수차감취소설정 false
          } else {
            // 없음
          }
        }
        // 취소 가능 횟수 0회일 때
      } else {
        // 취소 가능 시간 지났을 때
        if (after_end) {
          if (studio?.policy?.is_absence_by_user) {
            result.push({
              text: '예약 취소',
              func: cancelText,
              color: 'danger',
              outline: false,
            });
          } else {
            // 없음
          }
        }
      }

      // 예약변경
      /**
       * 1. 오늘 수업
       * 2. 현재 당일 예약변경 횟수가 0이상
       * 3. 현재 시간이 당일예약변경 가능시간 이내
       */
      if (
        dayjs(start_on).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD') &&
        ticketRaw.ticket.daily_booking_change_limit - ticketRaw.change_daily_booking_count > 0 &&
        dayjs(daily_change_booking_end_at).isAfter(new Date())
      ) {
        result.push({
          text: '예약 변경',
          func: () => history.push({ pathname: '/booking/change', state: { bookingId: data.booking.id, lectureId: data.id } }),
          color: 'primary',
          outline: false,
        });
      }
    }

    // 예약시작시간 < 현재 < 예약마감시간
    if ((booking_end_at ? dayjs() < dayjs(booking_end_at) : true) && (booking_start_at ? dayjs() > dayjs(booking_start_at) : true)) {
      if (current_trainee_count < max_trainee) {
        isFamilyCondition({ text: '예약', func: bookings, color: 'primary', outline: false });
      } else if (studio?.policy?.weekly_waiting_limit !== 0) {
        isFamilyCondition({ text: '예약 대기', func: bookingWaiting, color: 'dark', outline: false });
      }
    }

    // 예약대기중
    if (booking?.status === BOOKING_TYPE.bookingWaiting) {
      result.push({ text: '예약 대기 취소', func: deleteBookingWaiting, color: 'danger', outline: false });
    }

    setCondition(result);
    // eslint-disable-next-line
  }, [data]);

  const bookingEnd = () => {
    // 수업종료 X
    if (!isPastCourseEndTime(data.end_on)) {
      // 예약대기 가능 시설
      if (studio?.policy?.weekly_waiting_limit !== 0) {
        // 수업시간 지남
        if (isPastBookingTime(data.start_on, data.booking_end_at)) {
          return (
            <StatusBox>
              <TagButton text="예약마감" color={theme.tagColor.darkGrey} />
            </StatusBox>
          );
        }
        // 인원마감
        if (data.current_trainee_count && data.current_trainee_count === data.max_trainee) {
          return (
            <StatusBox>
              <TagButton text="인원마감" color={theme.tagColor.darkGrey} />
            </StatusBox>
          );
        }
      }

      // 예약대기 불가 시설 | 인원마감 || 수업시간 지남
      else if (
        (data.current_trainee_count && data.current_trainee_count === data.max_trainee) ||
        isPastBookingTime(data.start_on, data.booking_end_at)
      ) {
        return (
          <StatusBox>
            <TagButton text="예약마감" color={theme.tagColor.darkGrey} />
          </StatusBox>
        );
      }
    }
  };

  return (
    <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`}>
      <div className="react-actionsheet-mask" onClick={onRequestClose} />
      <div className="react-actionsheet-wrap">
        <ConfirmModal title={modalInfo.title} show={openModal} setShow={setOpenModal} action={modalInfo.func}>
          <div style={{ whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: modalInfo.message }} />
        </ConfirmModal>

        {!loading && (
          <MainStyle>
            {/* 예약 대기 순번 */}
            {data.booking?.status === BOOKING_TYPE.bookingWaiting && (
              <BookWait>예약 대기 중인 수업입니다 - 순번: {data.booking_waiting_order}</BookWait>
            )}

            <ContainerStyle>
              <ListStyle>
                <List>
                  <Item>
                    {/* 닫기 버튼 */}
                    <Close className="close_icon" onClick={onRequestClose} />

                    {/* 수업명 */}
                    <Brief>
                      <div className="title">{data.title}</div>
                    </Brief>

                    {/* 날짜 */}
                    <Brief>
                      <div className="date">{filters.dateLecture(data.start_on, data.end_on)}</div>
                    </Brief>

                    {/* status */}
                    <Brief>
                      {data.booking && <StatusBox>{convertTypeToString(data.booking.status)}</StatusBox>}
                      {!data.booking && bookingEnd()}
                      {!data.booking && data.max_trainee && isPastCourseEndTime(data.end_on) && (
                        <StatusBox>
                          <TagButton text="수업종료" color={theme.tagColor.black} />
                        </StatusBox>
                      )}
                    </Brief>

                    {/* 강사 이름 */}
                    <Brief>
                      <Avatar className="user_img" size="small-2" imgUrl={getImageUrl(data?.staff?.avatar, '32x32')} />
                      <span className="content">{data?.staff?.name} 강사</span>
                    </Brief>

                    {/* 룸 */}
                    {!!data.room && (
                      <Brief>
                        <div className="content">{data.room.name} 룸</div>
                      </Brief>
                    )}

                    {/* 예약인원/최대수강인원 */}
                    <Brief>
                      {studio?.policy?.is_show_current_count ? (
                        <div className="content">
                          예약인원/최대수강인원 {data.current_trainee_count}/{data.max_trainee}
                          {studio?.policy?.is_show_booking_waiting_count && data.booking_waiting_count && (
                            <span> · 대기 {data.booking_waiting_count}</span>
                          )}
                        </div>
                      ) : (
                        <div className="content">
                          최대수강인원 {data.max_trainee}
                          {studio?.policy?.is_show_booking_waiting_count && data.booking_waiting_count && (
                            <span> · 대기 {data.booking_waiting_count}</span>
                          )}
                        </div>
                      )}
                    </Brief>

                    {/* 대기인원 */}
                    {/* {!!data.booking_waiting_count && <Brief><div className='content'>대기인원&nbsp;&nbsp;{data.booking_waiting_count}</div></Brief>} */}

                    {/* 최소수강인원 안내 */}
                    {!!data.min_trainee && (
                      <Brief>
                        <div className="content">
                          최소 수강인원 {data.min_trainee}명이 미달되면 <b style={{ color: theme.color.red }}>예약이 취소</b>될 수 있습니다.
                        </div>
                      </Brief>
                    )}
                  </Item>

                  {/* 예약 취소 가능 시간  */}
                  <Item>
                    <Brief>
                      <SubTitle>예약·취소 가능 시간</SubTitle>
                    </Brief>
                    <Brief>
                      <div className="content">{convertAvailbleTime(data.booking_start_at, data.booking_end_at, 'booking')}</div>
                    </Brief>
                    <Brief>
                      <div className="content">{convertAvailbleTime(data.booking_cancel_start_at, data.booking_cancel_end_at, 'cancel')}</div>
                    </Brief>

                    {/* 예약변경가능시간 */}
                    {ticket.dailyBookingChangeLimit && (
                      <Brief>
                        <div className="content">{convertAvailbleTime(null, data.daily_change_booking_end_at, 'daily')}</div>
                      </Brief>
                    )}
                  </Item>

                  {/* 수업 소개 */}
                  <Item>
                    <Brief>
                      <SubTitle>수업 소개</SubTitle>
                    </Brief>
                    {data.description ? (
                      <Brief>
                        <div className="content">{data.description}</div>
                      </Brief>
                    ) : (
                      <Brief>
                        <div className="content">내용이 없습니다.</div>
                      </Brief>
                    )}
                  </Item>

                  {/* 강사 프로필 */}
                  <Item>
                    <Brief>
                      <SubTitle>강사 프로필</SubTitle>
                    </Brief>

                    <Brief>
                      <SubTitleGray>자기 소개</SubTitleGray>
                    </Brief>

                    {data.staff?.self_introduction ? (
                      <Brief>
                        <div className="content gap">{data.staff?.self_introduction}</div>
                      </Brief>
                    ) : (
                      <Brief>
                        <div className="content gap">자기 소개 내용이 없습니다.</div>
                      </Brief>
                    )}

                    <Brief>
                      <SubTitleGray>주요 이력</SubTitleGray>
                    </Brief>
                    {data.staff?.careers.length ? (
                      <ul>
                        {data.staff?.careers.map(({ id, career }) => (
                          <li key={id}>
                            <Brief>
                              <div className="content">{career}</div>
                            </Brief>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <Brief>
                        <div className="content">주요 이력 내용이 없습니다.</div>
                      </Brief>
                    )}
                  </Item>
                </List>
              </ListStyle>
            </ContainerStyle>
          </MainStyle>
        )}

        {!loading && !!condition.length && (
          <BottomButton count={condition}>
            {condition.map((button, idx) => {
              if (button.text === '예약 변경') {
                return (
                  <CustomBookingChange key={idx}>
                    <PlainButton className="flex-both-center" onClick={button.func}>
                      {button.text}
                    </PlainButton>
                  </CustomBookingChange>
                );
              }
              if (button.text === '예약 대기') {
                return (
                  <CustomBookingWait key={idx}>
                    <PlainButton className="flex-both-center" onClick={button.func}>
                      {button.text}
                    </PlainButton>
                  </CustomBookingWait>
                );
              }
              if (button.text === '예약 대기 취소') {
                return (
                  <CustomBookingWaitCancel key={idx}>
                    <PlainButton className="flex-both-center" onClick={button.func}>
                      {button.text}
                    </PlainButton>
                  </CustomBookingWaitCancel>
                );
              }

              return (
                <PlainButton key={idx} className="flex-both-center" onClick={button.func} color={button.color} outline={button.outline}>
                  {button.text}
                </PlainButton>
              );
            })}
          </BottomButton>
        )}
      </div>
    </div>
  );
};

const MainStyle = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const ContainerStyle = styled.div`
  padding: 0 16px 5px 2px;
  overflow-y: auto;
`;

const StatusBox = styled.div`
  display: inline-block;
  position: absolute;
  right: 0;
  margin-top: 5px;
`;

const SubTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  color: ${theme.color.black300};
  margin-bottom: 8px;
`;

const SubTitleGray = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: ${theme.color.sub};
`;

const ListStyle = styled.div`
  .am-list-body::before,
  .am-list-body::after {
    background-color: white !important;
  }
  .am-list-body .am-list-item .am-list-line .am-list-content {
    padding: 16px 0;
  }
  .title {
    font-size: 18px;
    color: ${theme.color.black300};
    font-weight: bold;
  }
  .date {
    font-size: 18px;
    color: ${theme.color.black300};
    font-weight: 500;
    white-space: break-spaces;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .content {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    white-space: pre-wrap;
  }
  .gap {
    margin-bottom: 20px;
  }
`;

const BottomButton = styled.div`
  width: 100%;
  z-index: 1;
  background-color: white;
  border-top: 1px solid #ddd;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 16px;
  padding: 0 16px;
  grid-template-columns: ${props => {
    let style = '';
    Object.keys(props.count).forEach(data => (style = style.concat('1fr ')));
    return style;
  }};
  .mbsc-ios.mbsc-btn {
    font-size: 18px;
    font-weight: bold;
    padding: 0.625em 4px;
  }
  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-danger {
    margin-top: 0.6em;
  }
`;

// 예약 변경
const CustomBookingChange = styled.div`
  .mbsc-ios.mbsc-btn-primary.mbsc-btn {
    background-color: #4c2c92;
    font-weight: bold;
  }
`;

// 예약 대기
const CustomBookingWait = styled.div`
  .mbsc-ios.mbsc-btn-primary.mbsc-btn {
    background-color: ${theme.tagColor.cyan};
    font-weight: bold;
  }
`;

// 예약 대기 취소
const CustomBookingWaitCancel = styled.div`
  .mbsc-ios.mbsc-btn-primary.mbsc-btn {
    background-color: ${theme.color.newGray};
    font-weight: bold;
  }
`;

const BookWait = styled.div`
  background-color: ${theme.tagColor.cyan};
  padding: 8px 16px;
  height: 36px;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: white;
  white-space: pre-wrap;
`;

export default BookingActionGroup;
