import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/icon-24-close.svg';
import { theme } from 'assets/styles/theme';
import { changeTab } from 'store/board/reducer';

const GuidePopup = () => {

  /** hooks */
  const hisotry = useHistory();
  const dispatch = useDispatch();
  const { studio } = useSelector(state => state.studio.currentStudioData);

  /** func */
  const goToBoard = () => {
    hisotry.push('/board');
    if (studio?.policy?.is_visible_qna) dispatch(changeTab(2)); // 스튜디오메이트 공지
    else dispatch(changeTab(1));
    localStorage.setItem('guide', true);
  }

  return (
    <MainStyle>
      <GuideStyle id='guide-style'>
        <div className='text' onClick={goToBoard}>현재 사용 중인 수강권이 보이지 않으시나요?</div>
        <CloseIcon onClick={() => document.getElementById('guide-style').style.display = 'none'} />
      </GuideStyle>
    </MainStyle>
  );
};

const MainStyle = styled.div`
  #guide-style:focus, #guide-style:active {
    background-color: #5ea9fd;

    svg g, .text {
      fill: white;
      color: white;
    }
  }
`;

const GuideStyle = styled.div`
  margin: 16px;
  padding: 14px 16px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #83bdff;
  border-radius: 8px;
  background-color: white;
  width: ${window.innerWidth - 32}px;
  display: flex;
  align-items: center;
  cursor: pointer;

  position: absolute;
  bottom: 50px;

  svg g {
    fill: ${theme.color.blue};
  }
  
  .text {
    font-size: 13px;
    font-weight: 500;
    color: ${theme.color.blue};
    flex-grow: 1;
  }
`;

export default GuidePopup;