export default {
  // email
  email(value) {
    const regExp = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

    if (value.match(regExp) !== null || !value.length) return true;
    return false;
  },

  // password
  password(password) {
    if (!password) return true;
    if (!/^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,32}$/.test(password)) {
      return false;
    }
    return true;
  },

  // 이름
  name: name => /^[가-힣|a-z|A-Z|0-9|\s]+$/.test(name),

  // 휴대폰 번호
  mobile: mobile => {
    if (!mobile) return true;
    return /^(01)[0,1,6,7,8,9][0-9]{7,8}$/i.test(mobile);
  },

  // 전화번호 (휴대폰+일반전화)
  phone: phone => /^(01)[0,1,6,7,8,9][0-9]{7,8}|(0)[2-8]\d{7,9}$/i.test(phone),

  // 날짜형식 ('YYYY-MM-DD')
  date: date => /([0-9]{4})-([0-9]{2})-([0-9]{2})/i.test(date),
};
