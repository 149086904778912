import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'

import { ReactComponent as Dropdown } from 'assets/icons/dropdown.svg';
import { BOOKING_TYPE, ERROR_TYPES } from 'constants/text';
import { theme } from 'assets/styles/theme';
import BookingCard from 'components/BookingCard';

const BookingListPartPresenter = ({
  lastElementRef,
	currentText,
	goToDetail,
	sortedBy,
	status,
	show,
	setShow,
  bookings,
  history
}) => {
  
  const { studios } = useSelector((state) => state.studio);

  /** func */
  const BookingListView = () => {
      return bookings.map((booking, index) => {

        return (
          <div
            ref={bookings.length === index + 2 ? lastElementRef : null}
            onClick={() => goToDetail(booking)}
            className='each-item'
            role='button'
            tabIndex={0}
            key={booking.id}
          >
            {history.location.pathname === '/mypage' && studios.length > 1 && (
              <StudioFlex status={booking.status}>
                <div className='circle' />
                <div className="text-ellipsis studio-name">{booking.studio.name}</div>
              </StudioFlex>
            )}
            <BookingCard lecture={booking.lecture} status={booking.status} />
          </div>
        );
      });
  };


	return (
  <PartContainer>

    {/* 분류 */}
    <BookingType>
      <SortButton defaults={currentText(sortedBy).value === 'asc'} onClick={() => setShow({ sort: true, status: false })}>
        {currentText(sortedBy).content} <Dropdown fill={currentText(sortedBy).value === 'asc' ? '#555' : theme.color.main} className='dropdown' />
      </SortButton>
      <SortButton defaults={currentText(status).value === BOOKING_TYPE.bookedAndConfirm} onClick={() => setShow({ sort: false, status: true })}>
        {currentText(status).content} <Dropdown fill={currentText(status).value === BOOKING_TYPE.bookedAndConfirm ? '#555' : theme.color.main} className='dropdown' />
      </SortButton>
    </BookingType>

    {/* 예약 목록 */}
    <BookingList>
      {
        bookings.length ? 
          <BookingListView /> : 
          <NoBookingList>{ERROR_TYPES.noBookingList}</NoBookingList>
      }
    </BookingList>

  </PartContainer>
	);
};

const PartContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  // display: flex;
  
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;
`;

const BookingType = styled.div`
	display: flex;
  width: 100%;
  padding: 0 10px;
  border-bottom: 1px solid #ddd;
`;

const SortButton = styled.div`
  ${( props ) => {
    if (props.defaults) {
      return css`
        border: 1px solid ${theme.color.black500};
        border-radius: 4px;
        color: ${theme.color.black500};
      `;
    } 
			return css`
      color: ${theme.color.main};
      border-radius: 4px;
      border: 1px solid ${theme.color.main};
    `;
  }}
  font-size: 15px;
  width: 50%;
  padding: 4px;
  margin: 16px 8px;
  text-align: center;

  .dropdown {
    float: right;
  }
`;

const BookingList = styled.div`
  width: 100%;
  overflow-y: scroll;

  .each-item {
    margin: 15px 16px;
    margin-bottom: 24px;
  }

  // margin: ${props => props.bookingsLength ? null : 'auto' };
`;

const StudioFlex = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .studio-name {
    color: ${props => {
      switch (props.status) {
        case BOOKING_TYPE.booked:
        case BOOKING_TYPE.bookingConfirmed:
        case BOOKING_TYPE.bookingWaiting:
        case BOOKING_TYPE.bookedAndConfirm:
          return `${theme.color.newGray}`;
        case BOOKING_TYPE.absence:
        case BOOKING_TYPE.cancel:
        case BOOKING_TYPE.attendance:
        case BOOKING_TYPE.noshow:
          return '#bcbcbc';
      }
    }};
    font-size: 15px;
    font-weight: 500;
    line-height: normal;
  }

  .circle {
    background-color: ${props => {
      switch (props.status) {
        case BOOKING_TYPE.booked:
        case BOOKING_TYPE.bookingConfirmed:
        case BOOKING_TYPE.bookingWaiting:
        case BOOKING_TYPE.bookedAndConfirm:
          return `${theme.color.newBlue}`;
        case BOOKING_TYPE.absence:
        case BOOKING_TYPE.cancel:
        case BOOKING_TYPE.attendance:
        case BOOKING_TYPE.noshow:
          return `${theme.color.newGray}`
      }
    }};
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 10px;
    flex: 0 0 10px;
  }
`;

const NoBookingList = styled.div`
  color: ${theme.color.black500};
  font-size: 18px;
  font-weight: normal;
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
`;

BookingListPartPresenter.propTypes = {
	lastElementRef: PropTypes.func,
	currentText: PropTypes.func,
	goToDetail: PropTypes.func,
  sortedBy: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  show: PropTypes.oneOfType([PropTypes.object]),
	setShow: PropTypes.func,
  bookings: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

BookingListPartPresenter.defaultProps = {
	lastElementRef: {},
	currentText: {},
	goToDetail: {},
  sortedBy: {},
  status: {},
  show: {},
  setShow: {},
  bookings: {}
};



export default BookingListPartPresenter;