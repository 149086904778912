import dayjs from 'dayjs';

/**
 * 날짜 차이 계산
 *
 * @param {Date | string} start_on
 * @param {string | undefined} end_on
 */

export default (start_on, end_on) => {
    return dayjs().isAfter(start_on) || dayjs().isAfter(end_on);
}

// 수업시작시간 < 현재시각 start_on
// 예약마감시간 < 현재시각 end_on