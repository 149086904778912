export default {
  get(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },

  set(key, value) {
    const stringToValue = JSON.stringify(value || null);
    window.localStorage.setItem(key, stringToValue);
    return JSON.parse(stringToValue);
  },

  asyncSet(key, value) {
    const stringToValue = JSON.stringify(value || null);
    return Promise.resolve().then(() => {
      window.localStorage.setItem(key, stringToValue);
    });
  },

  remove() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('expired_in');
    localStorage.removeItem('fcm_token');
    localStorage.removeItem('diff-time');
    localStorage.removeItem('studio_id');

    localStorage.removeItem('cal_book');
    localStorage.removeItem('cal_list');
  },
};
