import React, { useState, useEffect, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import dayjs from 'dayjs';

import { RootState } from 'store';
import { getAvailableBookingList, getAvailableBookingListS } from 'store/booking/actions';
import MainLayout from 'components/MainLayout';
import Calendar from 'components/Calendar/Calendar';
import BookingCard from 'components/BookingCard';
import { HEADER_TITLE, ERROR_TYPES } from 'constants/text';
import EmptyLayout from 'components/EmptyLayout';
import BookingActionGroup from 'components/BookingAction/BookingActionGroup';
import { theme } from 'assets/styles/theme';
import filters from 'utils/filters';
import isPastCourseEndTime from 'utils/isPastCourseEndTime';
import localStorage from 'utils/localStorage';
import { getUserStudio, setStudio } from 'store/studio/reducer';

import socket from 'socket';

const BookingGroup = () => {
  /** hook */
  const history = useHistory();
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const bookingNow = useRef<any>();
  const scrollRef = useRef<any>();
  const isCheckedScrollTarget = useRef<boolean>(true);
  /** state */
  const { loading, availableBookingList, calendarData } = useSelector((state: RootState) => state.booking);
  const { ticketRaw } = useSelector((state: RootState) => state.studio).currentStudioData;
  const studio = useSelector((state: RootState) => state.studio);
  const prevCurrentStudio = useSelector((state: RootState) => state.studio.currentStudioData.studio);

  useEffect(() => {
    dispatch(getUserStudio());
  }, [calendarData.selectDate]);

  useEffect(() => {
    const currentStudio = studio.studios.find(studio => studio.id === prevCurrentStudio.id);
    if (currentStudio) dispatch(setStudio(currentStudio));
    else history.replace('/select-ticket');
  }, [studio.studios]);

  const { group_booking_limit_day_term, group_booking_limit_day_term_time, group_booking_limit_date } = useSelector(
    (state: RootState) => state.studio,
  ).currentStudioData.studio.policy;

  const [holding] = useState<any>([]);
  const [limit, setLimit] = useState<any>({
    currentLimit: '',
    startLimit: '',
    endLimit: '',
    whenToShow: false,
  });

  /** booking */
  const [booking, setBooking] = useState<any>({
    show: false,
    data: {},
  });

  // 안드로이드 goback
  useEffect(() => {
    if (history.location.pathname === `/booking-group/${ticketRaw.id}`) {
      setBooking({ ...booking, show: false });
    }
  }, [history.location.pathname, booking.show]);

  // 수강권 정지
  useEffect(() => {
    if (ticketRaw.holdings) {
      ticketRaw.holdings.map(el => {
        let startDate = el.start_on;
        const endDate = el.end_on;

        holding.push(startDate);

        while (moment(startDate).isBefore(endDate)) {
          startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
          holding.push(startDate);
        }
      });
    }
  }, []);

  const beforeOpen = (selectedDate: string) => {
    const boundary = moment(`${selectedDate} ${group_booking_limit_day_term_time}`).subtract(group_booking_limit_day_term, 'days');
    const today = moment();
    return today.isBefore(boundary);
  };

  const dispatchBookingList = (current: string, start: string, end: string, whenToShow: boolean, date: string) => {
    // 수강권 시작일 전 날짜일때
    if (moment(date).isBefore(ticketRaw.availability_start_at)) {
      return dispatch(getAvailableBookingListS({ reason: ERROR_TYPES.beforeTicket, data: [] }, null));
    }

    // 정지
    if (holding.includes(date)) {
      return dispatch(getAvailableBookingListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    }
    // if (ticketRaw.now_holding && moment(date).isBetween(ticketRaw.now_holding?.start_on, ticketRaw.now_holding?.end_on, null, '[]')) {
    //   return dispatch(getAvailableBookingListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    // }

    // 휴일이 아닌 날짜
    if (studio.currentStudioData.studio.holidays.filter(el => !el.deleted_at && date === el.date).length === 0) {
      // 예약가능기한
      // 고급설정 === null
      if (!group_booking_limit_date) {
        if (group_booking_limit_day_term >= 0 && moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
          return dispatch(
            getAvailableBookingListS(
              {
                reason: `수업일 기준 ${group_booking_limit_day_term}일 전 ${group_booking_limit_day_term_time.slice(
                  0,
                  5,
                )}부터 <br />예약 가능합니다.`,
                data: [],
              },
              null,
            ),
          );
        }
        if (group_booking_limit_day_term >= 0 && whenToShow && moment(date).isSame(current) && current <= end && beforeOpen(date)) {
          return dispatch(
            getAvailableBookingListS(
              {
                reason: `수업일 기준 ${group_booking_limit_day_term}일 전 ${group_booking_limit_day_term_time.slice(
                  0,
                  5,
                )}부터 <br />예약 가능합니다.`,
                data: [],
              },
              null,
            ),
          );
        }

        // 고급설정 !== null
      } else if (moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
        const reason =
          group_booking_limit_day_term === 0
            ? `${filters.dateWithWeekdayKor(current, 'zeroValue')}까지<br />예약이 가능합니다`
            : `${filters.dateWithWeekdayKor(current)}부터<br />예약이 가능합니다`;

        return dispatch(getAvailableBookingListS({ reason, data: [] }, null));
      }
    }

    dispatch(getAvailableBookingList(Number(id), date));
  };

  useEffect(() => {
    // 예약가능기한
    // 고급설정 === null
    if (!group_booking_limit_date) {
      const limitTerm = group_booking_limit_day_term; // 7
      const diffTime = Math.abs(+moment() - +moment(ticketRaw.expire_at)); // 2968315324
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 35

      const currentDate = moment()
        .add(limitTerm >= diffDays ? diffDays : limitTerm, 'days')
        .format('YYYY-MM-DD');
      const startDate = moment(currentDate)
        .add(beforeOpen(currentDate) ? 0 : 1, 'days')
        .format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      const whenToShow = moment(new Date(), 'hh:mm:ss').isBefore(moment(group_booking_limit_day_term_time, 'hh:mm:ss')); // 현재 시각 < 12:00:00 ? true
      dispatchBookingList(currentDate, startDate, endDate, whenToShow, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow });
    }

    // 고급설정 !== null
    else {
      const limitTerm = group_booking_limit_day_term; // 7
      let currentDate = group_booking_limit_date; // 2021-04-27 12:00:00

      if (moment(new Date()).isAfter(moment(currentDate))) {
        currentDate = moment(currentDate).add(limitTerm, 'days').format('YYYY-MM-DD hh:mm:ss'); // limitTerm 주기로 업데이트
      }

      const startDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      dispatchBookingList(currentDate, startDate, endDate, false, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow: false });
    }
  }, [studio.currentStudioData.studio]);

  const getList = (date: string) => {
    // dispatchBookingList(limit.currentLimit, limit.startLimit, limit.endLimit, limit.whenToShow, date);
  };

  const goToDetail = (data: any) => {
    if (dayjs(calendarData.selectDate).format('YYYY-MM-DD') !== dayjs(availableBookingList?.data[0]?.start_on).format('YYYY-MM-DD')) {
      return history.push(`/booking-group/${ticketRaw.id}`);
    }

    socket({ token: localStorage.get('access_token'), lectureId: data.id });
    setBooking({ show: true, data });
    history.push(`/booking-group/${ticketRaw.id}/`);
  };

  // 예약가능기한 회색 표시 날짜
  const bookingMaxDate = () => {
    return { start: limit.startLimit, end: limit.endLimit, background: theme.color.greyCCC };
  };

  // 수강권 시작 전 기간
  const beforeAvailable = () => {
    return {
      start: moment().format('YYYY-MM-DD'),
      end: moment(ticketRaw.availability_start_at).subtract(1, 'days').format('YYYY-MM-DD'),
      background: theme.color.greyDDD,
    };
  };

  // 예약가능기한 월 이동 안되게
  const overDeadline = () => {
    const over = moment(limit.startLimit).endOf('month').format('YYYY-MM-DD');
    return over;
  };

  const BookingList = () => {
    isCheckedScrollTarget.current = true;

    if (!availableBookingList.data.length) {
      if (availableBookingList.reason) {
        if (availableBookingList.reason === ERROR_TYPES.pauseTicket || availableBookingList.reason === ERROR_TYPES.vacation)
          return <EmptyLayout text={availableBookingList.reason} color={theme.color.red} />;
        return <EmptyLayout text={availableBookingList.reason} />;
      }

      return <EmptyLayout text={ERROR_TYPES.noAvailableBookingClass} />;
    }

    return availableBookingList.data.map((data: any, idx: number) => {
      const lecture = {
        title: data.title,
        room: data.room,
        instructor: { name: data.staff.name, representative_avatar: { image: data?.staff?.avatar } },
        start_on: data.start_on,
        end_on: data.end_on,
        booking_end_at: data.booking_end_at,
        type: data.type,
        max_trainee: data.max_trainee,
        current_trainee_count: data.current_trainee_count,
        booking_waiting_count: data.booking_waiting_count,
        booking_waiting_order: data.booking_waiting_order,
      };

      const handleRef = () => {
        if (!isPastCourseEndTime(data.start_on) && isCheckedScrollTarget.current) {
          isCheckedScrollTarget.current = false;
          return bookingNow;
        }
      };

      return (
        <div key={data.id} ref={handleRef()} onClick={() => goToDetail(data)} className="each-item" tabIndex={0} role="button">
          <BookingCard status={data.booking && data.booking.status} lecture={lecture} currentBookingCount={data.current_trainee_count} />
        </div>
      );
    });
  };

  useEffect(() => {
    if (!moment(calendarData.selectDate).isAfter(moment(), 'day') && bookingNow.current) {
      bookingNow.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  }, [availableBookingList]);

  return (
    <MainLayout
      header={{ title: HEADER_TITLE.groupBooking, titleNormal: true, notification: true, titleBorder: false, noBackBtn: true }}
      loading={loading}
      notiGrid>
      <Calendar
        getList={getList}
        // startDate={!!moment().startOf('day').isBefore(ticketRaw.availability_start_at) && ticketRaw.availability_start_at}
        deadline={bookingMaxDate()}
        beforeAvailable={beforeAvailable()}
        customMax={overDeadline()}
      />

      <BookingContainer ref={scrollRef}>
        <BookingList />
      </BookingContainer>

      <BookingActionGroup
        show={booking?.show}
        data={booking?.data}
        onRequestClose={() => {
          setBooking({ ...booking, show: false });
          history.goBack();
        }}
        dispatchBookingList={dispatchBookingList}
        beforeOpen={beforeOpen}
        setLimit={setLimit}
      />
    </MainLayout>
  );
};

const BookingContainer = styled.div`
  overflow-y: auto;

  .each-item {
    margin: 12px 16px;
    margin-bottom: 24px;
  }
`;

export default BookingGroup;

/**
 * 전제조건: 전체 예약리스트를 줘야함
 * 1. bookinglist를 가져옴
 * 2. 날짜순으로 {d: 2020/x/x, color: blue}로 배열에 push
 * 3. calender 조회 기간은 예역의 가장 처음과 가장 끝
 * 4. 날짜 누르면 해당 예약 리스트 출력
 */
