/** 예약 */
import produce from 'immer';
import dayjs from 'dayjs';
import { BOOKING_TYPE } from 'constants/text';
import {
  GET_BOOKING_LIST_REQUEST,
  GET_BOOKING_LIST_SUCCESS,
  GET_BOOKING_LIST_FAILURE,
  GET_BOOKING_LIST_ALL_REQUEST,
  GET_BOOKING_LIST_ALL_SUCCESS,
  GET_BOOKING_LIST_ALL_FAILURE,
  GET_BOOKING_DETAIL_REQUEST,
  GET_BOOKING_DETAIL_SUCCESS,
  GET_BOOKING_DETAIL_FAILURE,
  GET_BOOKING_COUNT_REQUEST,
  GET_BOOKING_COUNT_SUCCESS,
  GET_BOOKING_COUNT_FAILURE,
  GET_BOOKING_COUNT_ALL_REQUEST,
  GET_BOOKING_COUNT_ALL_SUCCESS,
  GET_BOOKING_COUNT_ALL_FAILURE,
  SET_BOOKING_FILTER,
  INITIALIZE_FILTER,
  INITIALIZE_BOOKING,
  CANCEL_BOOKING_REQUEST,
  CANCEL_BOOKING_SUCCESS,
  CANCEL_BOOKING_FAILURE,
  INIT_CANCEL_DONE,
  PATCH_BOOKING_STATUS_REQUEST,
  PATCH_BOOKING_STATUS_SUCCESS,
  PATCH_BOOKING_STATUS_FAILURE,
  GET_CALENDAR_MARK,
  IUsageProps,
  sortTypeProps,
} from './types';

import { ActionRequest } from './actions';

export const initialState: IUsageProps = {
  bookings: [],
  bookingsAll: [],
  filters: {
    sortedBy: [
      { type: 'sortedBy', value: 'asc', content: '수업일 오름차순', current: true },
      { type: 'sortedBy', value: 'desc', content: '수업일 내림차순', current: false },
    ],
    status: [
      { type: 'status', value: BOOKING_TYPE.bookedAndConfirm, content: '예약 (0)', current: true },
      { type: 'status', value: BOOKING_TYPE.bookingWaiting, content: '예약대기 (0)', current: false },
      { type: 'status', value: BOOKING_TYPE.attendance, content: '출석 (0)', current: false },
      { type: 'status', value: BOOKING_TYPE.absence, content: '결석 (0)', current: false },
      { type: 'status', value: BOOKING_TYPE.noshow, content: '노쇼 (0)', current: false },
      { type: 'status', value: BOOKING_TYPE.cancel, content: '예약 취소 (0)', current: false },
      { type: 'status', value: '', content: '전체 내역 (0)', current: false },
    ],
  },
  params: { sortedBy: 'asc', status: BOOKING_TYPE.bookedAndConfirm, limit: 15, page: 1, last_page: 1 },
  currentBooking: {
    id: 0,
    lecture: {
      id: 0,
      title: '',
      room_name: null,
      staff: { avatar: '', name: '' },
      start_on: '',
      end_on: '',
      current_trainee_count: 0,
      max_trainee: 0,
      booking_waiting_count: null,
      description: null,
    },
    status: 'booked',
    updated_at: '',
  },
  loading: false,
  cancelDone: { status: 0 },
  markDate: [],
};

export const usage = (state: IUsageProps = initialState, action: ActionRequest) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_BOOKING_LIST_REQUEST:
      case GET_BOOKING_LIST_ALL_REQUEST:
      case GET_BOOKING_COUNT_REQUEST:
      case GET_BOOKING_COUNT_ALL_REQUEST:
        draft.loading = true;
        break;
      case GET_BOOKING_LIST_SUCCESS:
        if (action.data.current_page === 1) {
          draft.bookings = action.data.data;
          draft.params.last_page = action.data.last_page;
        } else {
          draft.bookings = state.bookings.concat(action.data.data);
        }
        draft.params.page++;
        draft.loading = false;
        break;
      case GET_BOOKING_LIST_ALL_SUCCESS:
        if (action.data.current_page === 1) {
          draft.bookingsAll = action.data.data;
          draft.params.last_page = action.data.last_page;
        } else {
          draft.bookingsAll = state.bookingsAll.concat(action.data.data);
        }
        draft.params.page++;
        draft.loading = false;
        break;
      case GET_BOOKING_LIST_FAILURE:
        draft.bookings = [];
        draft.loading = false;
        break;
      case GET_BOOKING_LIST_ALL_FAILURE:
        draft.bookingsAll = [];
        draft.loading = false;
        break;
      case GET_BOOKING_COUNT_SUCCESS:
      case GET_BOOKING_COUNT_ALL_SUCCESS:
        draft.filters.status[0].content = `예약 (${action.data.booked_count})`;
        draft.filters.status[1].content = `예약 대기 (${action.data.booking_waiting_count})`;
        draft.filters.status[2].content = `출석 (${action.data.attendance_count})`;
        draft.filters.status[3].content = `결석 (${action.data.absence_count})`;
        draft.filters.status[4].content = `노쇼 (${action.data.noshow_count})`;
        draft.filters.status[5].content = `예약 취소 (${action.data.cancel_count})`;
        draft.filters.status[6].content = `전체 내역 (${action.data.total_count})`;
        draft.loading = false;
        break;
      case GET_BOOKING_COUNT_FAILURE:
      case GET_BOOKING_COUNT_ALL_FAILURE:
        draft.filters.status = initialState.filters.status;
        draft.loading = false;
        break;
      case SET_BOOKING_FILTER:
        const changeFilter = state.filters[action.payload.type].map((el: sortTypeProps) =>
          el.value === action.payload.value ? { ...el, current: true } : { ...el, current: false },
        );
        draft.filters[action.payload.type] = changeFilter;
        draft.params[action.payload.type] = action.payload.value;
        draft.params.page = 1;
        break;
      case INITIALIZE_FILTER:
        draft.filters.sortedBy[0].current = true;
        draft.filters.sortedBy[1].current = false;
        
        draft.filters.status[0].current = true;
        draft.filters.status[1].current = false;
        draft.filters.status[2].current = false;
        draft.filters.status[3].current = false;
        draft.filters.status[4].current = false;
        draft.filters.status[5].current = false;
        draft.filters.status[6].current = false;
        draft.params.sortedBy = 'asc';
        draft.params.status = 'booked,booking_confirmed';
        draft.params.page = 1;
        break;
      case INITIALIZE_BOOKING:
        draft.bookings = [];
        draft.bookingsAll = [];
        draft.params.page = 1;
        draft.params.last_page = 1;
        break;
      case CANCEL_BOOKING_REQUEST:
      case PATCH_BOOKING_STATUS_REQUEST:
        draft.loading = true;
        draft.cancelDone.status = 0;
        break;
      case CANCEL_BOOKING_SUCCESS:
        draft.loading = false;
        draft.cancelDone.status = action.data.status;
        draft.currentBooking.status = BOOKING_TYPE.cancel;
        draft.currentBooking.updated_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
        break;
      case CANCEL_BOOKING_FAILURE:
        draft.cancelDone = action.data;
        draft.loading = false;
        break;
      case PATCH_BOOKING_STATUS_FAILURE:
        draft.loading = false;
        draft.cancelDone = action.data;
        break;
      case INIT_CANCEL_DONE:
        draft.cancelDone.status = 0;
        break;
      case PATCH_BOOKING_STATUS_SUCCESS:
        draft.loading = false;
        draft.cancelDone = action.data;
        draft.currentBooking.status = BOOKING_TYPE.absence;
        draft.currentBooking.updated_at = dayjs().format('YYYY-MM-DD HH:mm:ss');
        break;
      case GET_BOOKING_DETAIL_REQUEST:
        draft.loading = true;
        break;
      case GET_BOOKING_DETAIL_SUCCESS:
        draft.currentBooking = action.data;
        draft.loading = false;
        break;
      case GET_BOOKING_DETAIL_FAILURE:
        draft.loading = false;
        draft.currentBooking = initialState.currentBooking;
        break;
      case GET_CALENDAR_MARK:
        draft.markDate = action.payload;
        break;
      default:
        return state;
    }
  });

export default usage;
