import React, { useCallback, useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Store from 'contexts/store/store';

// @ts-ignore
import PullRefresh from 'react-pullrefresh';

import { theme } from 'assets/styles/theme';
import MainLayout from 'components/MainLayout';
import Ad from 'components/Ad';
import Avatar from 'components/Avatar';
import { HEADER_TITLE, LOCAL_STORAGE, ERROR_TYPES } from 'constants/text';
import EmptyLayout from 'components/EmptyLayout';
import { notificationPresenterProps } from './types';
import getImageUrl from 'utils/getImageUrl';

const NotificationPresenter = ({
  notiData,
  getInitNoti,
  convertLocalTime,
  goToDetail,
  checkNoReadNoti,
  lastElementRef,
  loading
}: notificationPresenterProps) => {
  const history = useHistory();
  const { utils } = useContext(Store);
  const ptr = useRef() as React.MutableRefObject<HTMLInputElement>;
  const getImage = (image: any) => getImageUrl(image, '40x40'); 

  const convertIcon = (noti: any) => {
    if (noti.ref_type === "studio_message") {
      return 'message'
    } else {
      return noti.staff_name ? 'avatar' : 'studio'
    }
  };
  
  const handleRefresh = async () => {
    if (ptr.current.scrollTop < 1 && !loading) {
      await getInitNoti();
    }
  }
  
  const List = useCallback(({ children }: any) => {
    return (
      <>
        {/* <Ad /> */}
        <div ref={ptr} style={{ position: 'relative', overflowY: 'auto', height: '100%' }}>
          <PullRefresh onRefresh={handleRefresh}>
            { children }
          </PullRefresh>
        </div>
      </>
    )
  }, []);

  const ListItem = () => {
    return (
      <>
        {
          notiData.map((noti, index) => {
            return (
              <NotiContainer
                role="button"
                ref={notiData.length - 1 === index ? lastElementRef : null}
                key={noti.id}
                onClick={() => goToDetail(noti)}
              >
                <Avatar
                  size='medium'
                  defaultType={convertIcon(noti)}
                  imgUrl={getImage(noti?.staff_image)}
                />

                <div className="content_wrap">
                  <div className="head">
                    <div className="text-ellipsis">
                      <span className="head__name">{noti.title}</span>
                    </div>
                    <div className="flex_row_center flex-0">
                      {checkNoReadNoti(noti.created_at) && <Circle />}
                      <span className="head__created">{convertLocalTime(noti.created_at, null)}</span>
                    </div>
                  </div>

                  <div className="body">{noti.message}</div>
                </div>

              </NotiContainer>
            )
          })
        }
      </>
    )
  };

  return (
    <MainLayout 
      header={{ 
        title: HEADER_TITLE.notification, 
        titleNormal: true,
        titleBorder: true,
        backFunc: history.goBack
      }}
      loading={loading}
      contentsGrid
      >
      {notiData.length > 0 ? (
        <List>
          <ListItem />
        </List>
      ) : (
        <>
          {/* <Ad /> */}
          <EmptyLayout text={ERROR_TYPES.noNotification} />
        </>
      )}

    </MainLayout>
  );
};

/** css */
const NotiContainer = styled.div`
  padding-left: 16px;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  &:active {
    background: lightgrey;
  }
  .content_wrap {
    margin-left: 16px;
    border-bottom: 1px solid #ccc;
    padding: 16px 16px 16px 0;

    .head {
      display: grid;
      grid-template-columns: 1fr auto;
      /* display: flex; */
      justify-content: space-between;
      width: 100%;
      margin-bottom: 4px;
      font-size: 14px;

      &__name {
        font-weight: bold;
        color: ${theme.color.black300};
        font-size: 13px;
      }

      &__created {
        color: ${theme.color.black500};
        font-size: 12px;
        font-weight: normal;
      }

      .noti_unread {
        margin-right: 16px;
      }
    }
  }

  .body {
    line-height: 1.42;
    color: ${theme.color.black500};
    font-size: 12px;
    font-weight: normal;
  }
`;

const Circle = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #3391ed;
  margin: 4px 16px 0 0;
`;

// 전달받는 prop이 객체인 경우 deep comparison을 수행하거나, 여러개 중 특정 prop의 변화만을 고려하기 위해 아래와 같이 구현할 수도 있습니다.
// const equalComparison = (prevProps, nextProps) => prevProps.notiParams.min_id === nextProps.notiParams.min_id;

// export default React.memo(NotificationPresenter, equalComparison);
export default NotificationPresenter;
