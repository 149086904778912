import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import MyInfoChangePresenter from 'pages/MyPage/EditAccount/EditAccountPresenter';
import { HEADER_TITLE } from 'constants/text';
import MainLayout from 'components/MainLayout';
import { getUserAccount } from 'store/auth/actions';

const SettingsContainer = () => {
  /** hook */
  const history = useHistory();
  const dispatch = useDispatch();

  /** func */

  useEffect(() => {
    dispatch(getUserAccount());
  }, []);

  const goToEditAccount = (path: string) => {
    history.push(`/mypage/edit/${path}`);
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.editAccount, titleNormal: true, backFunc: () => history.push('/mypage') }} notiGrid>
      <MyInfoChangePresenter goToEditAccount={goToEditAccount} />
    </MainLayout>
  )
};

export default SettingsContainer;
