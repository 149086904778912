export const AUTH_TYPES = {
  name: 'name',
  email: 'email',
  loginEmail: 'loginEmail',
  changeEmail: 'changeEmail',
  confirmChangeEmail: 'confirmChangeEmail',
  password: 'password',
  confirmPassword: 'confirmPassword',
  mobile: 'mobile',
  validateCode: 'validateCode',
  title: 'title',
  content: 'content',
  currentPassword: 'currentPassword',
  newPassword: 'newPassword',
  newPasswordConfirm: 'newPasswordConfirm',
} as const;

export const LABEL = {
  mobileOrEmail: '휴대폰 번호 또는 이메일',
  mobile: '휴대폰 번호',
  email: '이메일',
  name: '이름',
  validateCode: '인증번호',
  currentPassword: '현재 비밀번호',
  newPassword: '새로운 비밀번호',
  newPasswordConfirm: '새로운 비밀번호 확인',
  emailPlaceholder: 'studiomate@email.com',
  mobilePlaceholder: '휴대폰 번호를 입력해주세요.',
  password: '비밀번호',
  passwordConfirm: '비밀번호 확인',
  passwordAgain: '비밀번호를 한번 더 입력해주세요.',
  engAndNums: '영문, 숫자를 포함한 8글자 이상의 비밀번호를 입력해주세요.',
  mobileGuide: '-를 제외한 휴대폰 번호를 입력해주세요.',
  emailGuild: '이메일을 입력해주세요.',
  changeEmail: '변경할 이메일',
  confirmChangeEmail: '변경할 이메일 확인',
  changeMobile: '변경 할 휴대폰 번호',
  commentPlaceholder: '댓글을 작성하세요.',
  pullRefresh: '당겨서 새로고침',
  refreshing: '새로고침 중',
  pushMessage: '설정에서 알림(Push) 수신을 변경할 수 있습니다.',
  deleteAccount: '<p>회원 탈퇴가 완료되었습니다.</p><p>그동안 스튜디오메이트를 이용해 주셔서 감사합니다.</p>',
  warnDeleteAccount: '회원 탈퇴를 하면 회원님의 모든 데이터가 삭제됩니다. 그래도 회원 탈퇴를 계속하시겠습니까?'
} as const;

export const PLACEHOLDER = {
  title: '제목을 입력하세요.',
  content: '내용을 입력하세요.',
  name: '실명 입력',
  nameGuide: '이름을 입력해주세요.',
  passwordGuide: '비밀번호를 입력해주세요.',
  validateCode: '인증번호를 입력해주세요.'
} as const;

export const VALIDATE_MESSAGE = {
  incorrectEmail: '이메일 형식이 올바르지 않습니다.',
  incorrectConfirmChangeEmail: '이메일이 일치하지 않습니다.',
  incorrectEmailOrMobile: '이메일 또는 휴대폰 번호 형식이 올바르지 않습니다.',
  incorrectNumber: '휴대폰 번호 형식이 올바르지 않습니다.',
  incorrectName: '2글자 이상의 이름을 입력해주세요.',
  incorrectPassword: '비밀번호가 일치하지 않습니다.',
  incorrectEngNum: '영문, 숫자를 포함한 8글자 이상의 비밀번호를 입력해주세요.',
  changeEmail: '변경할 이메일을 입력해주세요.',
  validPassword: '비밀번호 형식이 올바르지 않습니다.',
  confirmChangeEmail: '변경할 이메일을 다시 한번 입력해주세요.',
  incorrectCurrentPassword: '현재 비밀번호가 일치하지 않습니다.',
  incorrectNewPassword: '영문, 숫자를 포함한 8글자 이상의 비밀번호를 입력해주세요.',
  incorrectNewPasswordConfirm: '영문, 숫자를 포함한 8글자 이상의 비밀번호를 입력해주세요.',
  diffPassword: '새로운 비밀번호는 현재 비밀번호와 달라야 합니다.',
  noSpecialChar: '영문, 숫자 조합 8자리 이상 (특수문자 가능)'
} as const;

export const ERROR_TYPES = {
  incorrectAuth: '정확한 아이디와 암호를 입력하세요.',
  needMobile: '모바일 앱을 설치한 후 이용해주세요.',
  noAccount: '해당하는 계정이 없습니다.',
  alreadyHasEmail: '이미 사용 중인 이메일입니다.',
  uploadImage: '이미지 업로드 실패',
  notMobile: 'window.ReactNativeWebView.postMessage가 없습니다',
  qnaCreate: '문의 생성 실패',
  qnaPatch: '문의 수정 실패',
  noComment: '없는 댓글입니다.',
  unUsableTicket: '티켓이 유효하지 않습니다. 앱을 재실행해주세요.',
  noBookingList: '이용 내역이 없습니다.',
  reLogin: '다시 로그인 해주세요.',
  incorrectValidCode: '인증코드가 유효하지 않습니다.',
  alreadyUseEmail: '이미 사용중인 이메일입니다.',
  noAvailableBookingList: '이용 가능한 수업이 없습니다.',
  noAvailableBookingClass: '예약 가능한 수업이 없습니다.',
  noStudio: '연결된 시설이 없습니다.',
  noTicket: '사용중인 수강권이 없습니다.',
  requestTicket: '보유한 수강권이 없습니다. \n시설에 수강권 등록을 요청하세요.',
  noNotification: '알림 내역이 없습니다.',
  checkStudio: '시설을 먼저 선택해주세요.',
  checkTicket: '수강권을 먼저 선택해주세요.',
  pauseTicket: '수강권 이용 정지 기간입니다.',
  vacation: '휴일 입니다.',
  beforeTicket: '수강권 이용 전 기간입니다.',
  expireTicket: '만료된 수강권 입니다.<br />시설에 문의하세요.',
  mobileExpire: '휴대폰 인증이 만료되었습니다.<br />휴대폰 번호 인증을 다시 해주세요.<br />(마이페이지 > 회원정보변경 > 휴대폰 번호 변경)',
  serverError: '서버 오류입니다. 잠시 후 다시 이용해주세요.'
} as const;

export const SUCCESS_MESSAGE = {
  findEmail: {
    title: '이메일 찾기 완료',
    body: '계정 정보가 카카오톡 또는 문자 메시지로 전송되었습니다.',
  },
  findPassword: {
    title: '비밀번호 찾기 완료',
    body: '회원님의 비밀번호가 성공적으로 변경되었습니다.',
  },
  changeEmail: {
    title: '이메일 변경 완료',
    body: '회원님의 이메일이 성공적으로 변경되었습니다.',
  },
  changeInfo: {
    title: '회원 정보 변경',
    body: '회원 정보가 성공적으로 변경되었습니다.',
  },
  changePassword: {
    title: '비밀번호 변경 완료',
    body: '비밀번호가 성공적으로 변경되었습니다.',
  },
  bookingWait: {
    title: '예약 대기 완료',
    body: '예약 대기가 완료되었습니다.<br />결원 발생시 예약이 자동으로 진행되며, 취소 없이 수업 불참 시 노쇼/횟수차감<br />될 수 있으니 주의바랍니다.',
  },
  bookingWaitCancel: {
    title: '예약 대기 취소',
    body: '예약 대기 취소가 완료되었습니다.',
  },
  booking: { title: '수업 예약 완료', body: '예약이 성공적으로 완료되었습니다.' },
  bookingCancel: { title: '예약 취소 완료', body: '예약 취소가 완료되었습니다.' },
  bookingChange: { title: '예약 변경 완료', body: '수업 예약이 변경되었습니다.' },
  processDone: { title: '처리 완료', body: '처리가 완료되었습니다.' },
  changeMobile: { title: '변경 완료', body: '휴대폰 번호가 성공적으로 변경되었습니다.' },
  signUp: { title: '회원 가입 완료', body: '회원 가입이 완료되었습니다. <br />로그인 해 주세요.' },
} as const;

export const BOOKING_TYPE = {
  booked: 'booked',
  cancel: 'cancel',
  bookingConfirmed: 'booking_confirmed',
  bookingWaiting: 'booking_waiting',
  absence: 'absence',
  attendance: 'attendance',
  noshow: 'noshow',
  bookedAndConfirm: 'booked,booking_confirmed',
} as const;

export const ACTION_BOOKING = {
  bookings: 'bookings',
  waitings: 'waitings',
  bookingConfirm: 'bookingConfirm',
  absence: 'absence',
} as const;

export const TICKET_TYPE = {
  private: 'P',
  group: 'G',
  period: 'P',
  times: 'T',
} as const;

export const NOTICE_TYPE = {
  notice: 'notice',
  qna: 'qna',
  snotice: 'studioNotice'
} as const;

export const MODAL_TEXT = {
  cancel: '취소',
  delete: '삭제',
  ok: '확인',
  qnaDeleteComplete: '1:1 문의가 삭제되었습니다.',
  commentDeleteComplete: '댓글이 삭제되었습니다.',
  sendValidCode: '인증번호를 발송하였습니다.',
} as const;

export const  HEADER_TITLE = {
  qnaDetail: '1:1 문의 상세보기',
  noticeDetail: '공지사항 상세보기',
  SNoticeDetail: '스튜디오메이트 공지 상세보기',
  qnaCreate: '1:1 문의 작성',
  qnaPatch: '문의 수정',
  commentPatch: '댓글 수정',
  bookingChange: '그룹 수업 당일 예약 변경',
  bookingList: '이용 내역',
  bookingListDetail: '이용 내역 상세',
  findEmail: '이메일 찾기',
  findPassword: '비밀번호 찾기',
  signup: '회원가입',
  editAccount: '회원 정보 변경',
  changeEmail: '이메일 변경',
  changeInfo: '프로필 변경',
  changeMobile: '휴대폰 번호 변경',
  changePassword: '비밀번호 변경',
  serviceCenter: '고객센터',
  termOfService: '이용약관',
  privacyPolicy: '개인정보처리방침',
  notification: '알림',
  notificationDetail: '알림 상세보기',
  groupBooking: '그룹 수업 예약',
  privateBooking: '프라이빗 수업 예약',
  courseDetail: '수업 상세',
  groupDetail: '그룹 수업 상세',
  privateDetail: '프라이빗 수업 상세',
  selectTicket: '수강권 선택',
  selectStudio: '시설 선택',
  ticketDetail: '수강권 상세 정보',
  previousTicket: '이전 수강권',
  previousTicketBtn: '이전 수강권 보기',
  cancelTicket: '수강권 정지',
  board: '게시판',
  deleteAccount: '회원 탈퇴',
  pushSetting: '알림 설정',
  mypage: '마이 페이지',
  setting: '설정',
  locker: '락커룸 정보'
} as const;

export const REQUEST_MOBILE = {
  permission: 'REQUEST_PERMISSION',
};

export const LOCAL_STORAGE = {
  notification: 'notification',
};