import { all, fork, put, call, takeLatest, getContext, takeEvery } from 'redux-saga/effects';
import auth from 'api/modules/auth';
import { AxiosResponse } from 'axios';
import { GET_ACCOUNT_REQUEST, getAccountRes, KEEP_ACCESS_TOKEN, LOGOUT } from 'store/auth/types';
import { setAuthHeaders, getUserAccountSuccess, getUserAccountFailure, getUserAccount } from 'store/auth/actions';
import localStorage from 'utils/localStorage';
import storage from 'redux-persist/lib/storage';

function* keepAccessTokenSaga({ payload }: any) {
  try {
    yield call(() => localStorage.asyncSet('access_token', payload.data));
    yield call(() => setAuthHeaders(localStorage.get('access_token')));
    yield put(getUserAccount());
  } catch (error) {
    alert({ message: error });
  }
}

function* watchkeepAccessToken() {
  yield takeLatest(KEEP_ACCESS_TOKEN, keepAccessTokenSaga);
}

function* getUserAccountSaga() {
  try {
    // yield alert({ message: `why ${localStorage.get('access_token')}` });
    const result: AxiosResponse<getAccountRes> = yield call(auth.getAccount);
    yield put(getUserAccountSuccess(result.data.user_account));
  } catch (err) {
    // yield alert({ message: err });
    yield put(getUserAccountFailure());
  }
}

function* watchGetUserAccount() {
  yield takeLatest(GET_ACCOUNT_REQUEST, getUserAccountSaga);
}

function* logout(): any {
  const history = yield getContext('history');
  yield localStorage.remove();
  yield storage.removeItem('persist:root');
  yield history.push('/login');
}

function* watchLogout() {
  yield takeEvery(LOGOUT, logout);
}

export default function* authSaga() {
  yield all([fork(watchGetUserAccount), fork(watchkeepAccessToken), fork(watchLogout)]);
}
