import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from 'store/auth/actions';
import SettingsPresenter from 'pages/Settings/SettingsPresenter';
import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';

const SettingsContainer = () => {
  /** hook */
  const history = useHistory();
  const dispatch = useDispatch();

  /** func */

  const goToSettings = useCallback((path: string) => {
    history.push(`/settings/${path}`);
  }, [history]);

  const logOut = () => {
    window.mobilsConfirm({
      message: `로그아웃 하시겠습니까?`,
      callback: (res: boolean) => {
        if (res) {
          dispatch(logout());
        }
      },
    });
  };

  const goToServiceCenter = () => history.push('/settings/service-center');

  return (
    <MainLayout header={{ title: HEADER_TITLE.setting, titleNormal: true, backFunc: history.goBack }} notiGrid>
      <SettingsPresenter goToServiceCenter={goToServiceCenter} goToSettings={goToSettings} logOut={logOut} />
    </MainLayout>
  )
};

export default SettingsContainer;
