import React, { useState, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import useValid from 'hooks/useValid';
import useInput from 'hooks/useInput';
import styled from 'styled-components';
import { Input } from '@mobiscroll/react';

import Store from 'contexts/store/store';
import MainLayout from 'components/MainLayout';
import FooterButtons from 'components/Footer/FooterButtons';
import PlainButton from 'components/PlainButton';
import TextInput from 'components/TextInput';
import { AUTH_TYPES, LABEL, SUCCESS_MESSAGE, PLACEHOLDER, HEADER_TITLE, VALIDATE_MESSAGE } from 'constants/text';
import { theme } from 'assets/styles/theme';

const ChangePassword = () => {
  /** hooks */
  const history = useHistory();
  const { apis } = useContext(Store);

  /** state */
  const [passwords, setPasswords] = useInput({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  });

  const [valid, setValid] = useValid({
    currentPassword: true,
    newPassword: true,
    newPasswordConfirm: true,
    passwordValue: passwords.newPassword,
  });

  const [loading, setLoading] = useState(false);

  const inputData = [
    {
      id: AUTH_TYPES.currentPassword,
      name: AUTH_TYPES.currentPassword,
      value: passwords.currentPassword,
      type: 'password',
      label: LABEL.currentPassword,
      placeholder: PLACEHOLDER.passwordGuide,
      error: !valid.currentPassword,
      errorMessage: VALIDATE_MESSAGE.validPassword
    },
    {
      id: AUTH_TYPES.newPassword,
      name: AUTH_TYPES.newPassword,
      value: passwords.newPassword,
      type: 'password',
      label: LABEL.newPassword,
      placeholder: PLACEHOLDER.passwordGuide,
      error: !valid.newPassword,
      errorMessage: VALIDATE_MESSAGE.noSpecialChar
    },
    {
      id: AUTH_TYPES.newPasswordConfirm,
      name: AUTH_TYPES.newPasswordConfirm,
      value: passwords.newPasswordConfirm,
      type: 'password',
      label: LABEL.newPasswordConfirm,
      placeholder: LABEL.passwordAgain,
      error: !valid.newPasswordConfirm,
      errorMessage:
        passwords.newPassword !== passwords.newPasswordConfirm ? VALIDATE_MESSAGE.incorrectPassword : ''
    },
  ];

  /** func */
  const onChange = useCallback(
    e => {
      setValid(e);
      setPasswords(e);
    },
    [setPasswords, setValid]    
  );

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apis.auth.changePassword({
        current_password: passwords.currentPassword,
        new_password: passwords.newPassword,
        new_password_confirmation: passwords.newPasswordConfirm,
      });
      if (res.status === 202) {
        setLoading(false);
        return history.push({
          pathname: '/success',
          state: { ...SUCCESS_MESSAGE.changePassword, routePath: '/mypage/edit' },
        });
      }
    } catch (error) {
      if (error?.response?.data?.status_code === 401) {
        alert({ message: VALIDATE_MESSAGE.incorrectCurrentPassword });
      } else if (error?.response?.data?.status_code === 422) {
        alert({ message: VALIDATE_MESSAGE.diffPassword });
      }
      setLoading(false);
    }
  }, [apis.auth, history, passwords]);
  
  return (
    <MainLayout header={{ title: HEADER_TITLE.changePassword, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>
      <Wrap>
        <div className="desc">
          현재 비밀번호와 새로운 비밀번호를 입력해주세요.
        </div>

        <div className="passwordWrap">
          {inputData.map(el => (
            <div key={el.id} style={{ marginBottom: '40px' }}>
              <TextInput
                id={el.id}
                key={el.id}
                name={el.name}
                value={el.value}
                onChange={onChange}
                type="password"
                label={el.label}
                placeholder={el.placeholder}
                error={el.error}
                errorMessage={el.errorMessage}
                // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
              />
            </div>
          ))}
        </div>
      </Wrap>
      <FooterStyle>
        <FooterButtons 
          cancelClick={() => history.goBack()} 
          confirmClick={onSubmit} 
          confirmText="비밀번호 변경"
          disabled={
            !(valid.currentPassword && 
              valid.newPassword && 
              valid.newPasswordConfirm) ||
            (!passwords.currentPassword || 
            !passwords.newPassword || 
            !passwords.newPasswordConfirm || 
            passwords.newPassword !== 
            passwords.newPasswordConfirm)
          }
      />
      </FooterStyle>
    </MainLayout>
  );
};

const Wrap = styled.div`
  .desc {
    padding: 40px 0 40px 16px;
    font-size: 14px;
    color: ${theme.color.black300};
    font-weight: 500;
  }
  .passwordWrap {
    padding: 0 16px;
  }
`;

const FooterStyle = styled.div`
  .mbsc-ios.mbsc-btn {
    padding: .625em 4px;
  }
`;


export default ChangePassword;
