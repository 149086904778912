import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  basicWidth: '320px',

  color: {
    main: '#3391ED',
    sub: '#bbbbbb',
    black500: '#555',
    black300: '#333',
    greyCCC: '#ccc',
    greyBBB: '#bbb',
    greyDDD: '#ddd',
    red: '#cc3311',
    blue: '#3391ed',
    deepSkyBlue: '#64aeff',
    lightPurple: '#b286f0',
    pink: '#ff99b9',
    black000: '#000',
    black4d: '#4d4d4d',
    lightGray: '#949494',
    newGray: '#505050',
    newBlue: '#67b4ff'
  },
  fonts: {
    font10: 'font-size: 1rem',
    font12: 'font-size: 1.2rem',
    font14: 'font-size: 1.4rem',
    font18: 'font-size: 1.8rem',
  },
  displays: {
    inlineBlock: 'display: inline-block',
  },
  flexBox: {
    displayFlex: `display: flex`,
    flexDirection: (directionValue: string) => `flex-direction: ${directionValue}`,
    flexJustify: (justify: string) => `justify-content: ${justify}`,
    flexAlign: (align: string) => `align-items: ${align}`,
  },
  margins: {
    pageBasicMargins: 'margin: 0 16px',
    t8: 'margin-top: 8px',
  },
  tagColor: {
    blue: '#3391ED', /* 예약 */
    cyan: '#33bbee', /* 예약대기 */
    red: '#cc3311', /* 예약취소 */ 
    orange: '#ee7733', /* 결석 */
    lightPurple: '#aa4499', /* 노쇼 */
    magenta: '#ee3377', /* 전)수업종료 */
    black: '#000000', /* 현)수업종료 */
    teal: '#009988', /* 출석 */
    darkGrey: '#555555', /* 예약마감 */
  }
};

export { theme };
