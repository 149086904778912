import React, { useState, useEffect } from 'react';
import TextInput from 'components/TextInput';
import PlainButton from 'components/PlainButton';
import { AUTH_TYPES, LABEL, PLACEHOLDER , VALIDATE_MESSAGE } from 'constants/text';
import styled from 'styled-components';
import { stepTwoPresenterProps } from './types';


/** step2 - 새로운 비밀번호 입력 */
const StepTwoPresenter = ({ text, onChange, valid, changePassword }: stepTwoPresenterProps) => {
  
  const [checkPassword, setCheckPassword] = useState<boolean>(true);

  useEffect(() => {

    if (text.password === text.confirmPassword || text.confirmPassword === '') {
      setCheckPassword(true);
    } else {
      setCheckPassword(false);
    }
    
  }, [text.password, text.confirmPassword]);

  return (
    <WrapLayout>
      <section className="wrap-scroll">
        <TitleTxt>새로운 비밀번호를 입력해주세요.</TitleTxt>
        <TextInput
          id={AUTH_TYPES.password}
          name={AUTH_TYPES.password}
          value={text.password}
          label={LABEL.newPassword}
          type="password"
          onChange={onChange}
          error={!valid.password}
          errorMessage={VALIDATE_MESSAGE.noSpecialChar}
          elseMessage={text.password ? '' : VALIDATE_MESSAGE.incorrectEngNum}
          placeholder={PLACEHOLDER.passwordGuide}
          // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
        />
        <div style={{ paddingTop: '20px' }}>
          <TextInput
            id={AUTH_TYPES.confirmPassword}
            name={AUTH_TYPES.confirmPassword}
            value={text.confirmPassword}
            label={LABEL.newPasswordConfirm}
            type="password"
            onChange={onChange}
            error={!checkPassword}
            errorMessage={VALIDATE_MESSAGE.incorrectPassword}
            elseMessage=''
            placeholder={LABEL.passwordAgain}
            // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
        />
        </div>
      </section>
      <BtmButton>
        <PlainButton
          color="primary"
          disabled={!(valid.password && valid.confirmPassword && text.password && text.confirmPassword && checkPassword)}
          onClick={changePassword}>
          <b style={{ fontSize: '18px' }}>비밀번호 변경</b>
        </PlainButton>
      </BtmButton>
    </WrapLayout>
  );
};

export default StepTwoPresenter;

export const WrapLayout = styled.div`
  height: calc(100vh - 45px - 24px); /** 45px : header height, 24px : top padding */
  display: grid;
  grid-template-rows: 1fr 60px;
  margin: 24px 16px;
`;

export const BtmButton = styled.div`
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    display: inline-block;
    margin: 0;
  }
`;

export const TitleTxt = styled.div`
  ${props => props.theme.fonts.font14};
  margin-bottom: 32px;
`;
