import React, { useContext, useState, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import getImageUrl from 'utils/getImageUrl';
import MainLayout from 'components/MainLayout';
import DetaliPresenter from 'pages/Board/Detail/DetaliPresenter';
import { NOTICE_TYPE, MODAL_TEXT, HEADER_TITLE } from 'constants/text';
import { getUserAccount } from 'store/auth/actions';
import { deleteQna, setQnaComment, deleteQnaComment, initializeComment } from 'store/board/reducer';
import Store from 'contexts/store/store';

const DetailContainer = () => {
  /** hooks */
  const history = useHistory();
  const { utils } = useContext(Store);
  const dispatch = useDispatch();

  /** state */
  const { user } = useSelector(state => state.auth);
  const { loading, currentBoard, done, editDone } = useSelector(state => state.board);
  const scrollRef = useRef();
  const { apis } = useContext(Store);

  useEffect(() => {
    dispatch(getUserAccount());
  }, [apis.auth, history, dispatch])

  const getTime = useCallback(
    time => {
      return utils.getTime.getFullDateTime(time);
    },
    [utils.getTime],
  );

  // Avatar
  const getImage = image => getImageUrl(image, '24x24'); 

  const confirmDeleteQna = (type, id) => {
    const title = type === NOTICE_TYPE.qna ? '1:1 문의를' : '댓글을';
    window.mobilsConfirm({
      message: `${title} 삭제하시겠습니까?`,
      callback: res => {
        if (res) dispatch(type === NOTICE_TYPE.qna ? deleteQna(currentBoard.id) : deleteQnaComment(id));
      },
    });
  };

  const goToBoard = useCallback(() => {
    if (done) {
      alert({ message: MODAL_TEXT.qnaDeleteComplete, callback: () => history.push('/board') });
    }
  }, [done, history]);

  const goToEdit = useCallback(
    data => {
      return history.push({
        pathname: '/board/qna/edit',
        state: data,
      });
    },
    [history],
  );

  const goToCommentEdit = useCallback(
    (id, content) => {
      return history.push({
        pathname: '/board/qna/comment/edit',
        state: { id, content },
      });
    },
    [history],
  );
  
  // const isCanPatchComment = commentAccountId => user?.id === commentAccountId;

  const saveComment = useCallback(value => {
    dispatch(setQnaComment({ studio_id: currentBoard.studio_id, contents: value, board_id: currentBoard.id }));
  });

  const goToScrollBottom = useCallback(() => {
    if (editDone === 'addCommit') {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    } else if (editDone === 'deleteCommit') {
      alert({ message: MODAL_TEXT.commentDeleteComplete });
      dispatch(initializeComment());
    }
  }, [editDone]);

  return (
    <MainLayout header={{ title: currentBoard.type === NOTICE_TYPE.notice ? HEADER_TITLE.noticeDetail : currentBoard.type === NOTICE_TYPE.qna ? HEADER_TITLE.qnaDetail : HEADER_TITLE.SNoticeDetail, titleNormal: true, titleBorder: false, backFunc: history.goBack }} loading={loading} contentsGrid>
      <DetaliPresenter
        currentBoard={currentBoard}
        getTime={getTime}
        getImage={getImage}
        confirmDeleteQna={confirmDeleteQna}
        goToBoard={goToBoard}
        saveComment={saveComment}
        scrollRef={scrollRef}
        goToScrollBottom={goToScrollBottom}
        goToCommentEdit={goToCommentEdit}
        goToEdit={goToEdit}
        user={user}
      />
    </MainLayout>
  );
};

export default React.memo(DetailContainer);
