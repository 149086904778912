/** Usage */
export const GET_BOOKING_COUNT_REQUEST = 'USAGE/GET_BOOKING_COUNT_REQUEST' as const;
export const GET_BOOKING_COUNT_SUCCESS = 'USAGE/GET_BOOKING_COUNT_SUCCESS' as const;
export const GET_BOOKING_COUNT_FAILURE = 'USAGE/GET_BOOKING_COUNT_FAILURE' as const;

export const GET_BOOKING_COUNT_ALL_REQUEST = 'USAGE/GET_BOOKING_COUNT_ALL_REQUEST' as const;
export const GET_BOOKING_COUNT_ALL_SUCCESS = 'USAGE/GET_BOOKING_COUNT_ALL_SUCCESS' as const;
export const GET_BOOKING_COUNT_ALL_FAILURE = 'USAGE/GET_BOOKING_COUNT_ALL_FAILURE' as const;

export const GET_BOOKING_LIST_REQUEST = 'USAGE/GET_BOOKING_LIST_REQUEST' as const;
export const GET_BOOKING_LIST_SUCCESS = 'USAGE/GET_BOOKING_LIST_SUCCESS' as const;
export const GET_BOOKING_LIST_FAILURE = 'USAGE/GET_BOOKING_LIST_FAILURE' as const;

export const GET_BOOKING_LIST_ALL_REQUEST = 'USAGE/GET_BOOKING_LIST_ALL_REQUEST' as const;
export const GET_BOOKING_LIST_ALL_SUCCESS = 'USAGE/GET_BOOKING_LIST_ALL_SUCCESS' as const;
export const GET_BOOKING_LIST_ALL_FAILURE = 'USAGE/GET_BOOKING_LIST_ALL_FAILURE' as const;

export const GET_BOOKING_DETAIL_REQUEST = 'USAGE/GET_BOOKING_DETAIL_REQUEST' as const;
export const GET_BOOKING_DETAIL_SUCCESS = 'USAGE/GET_BOOKING_DETAIL_SUCCESS' as const;
export const GET_BOOKING_DETAIL_FAILURE = 'USAGE/GET_BOOKING_DETAIL_FAILURE' as const;

export const SET_BOOKING_FILTER = 'USAGE/SET_BOOKING_FILTER' as const;
export const INITIALIZE_FILTER = 'USAGE/INITIALIZE_FILTER' as const;

export const INITIALIZE_BOOKING = 'USAGE/INITIALIZE_BOOKING' as const;

export const PATCH_BOOKING_STATUS_REQUEST = 'USAGE/PATCH_BOOKING_STATUS_REQUEST' as const;
export const PATCH_BOOKING_STATUS_SUCCESS = 'USAGE/PATCH_BOOKING_STATUS_SUCCESS' as const;
export const PATCH_BOOKING_STATUS_FAILURE = 'USAGE/PATCH_BOOKING_STATUS_FAILURE' as const;

export const CANCEL_BOOKING_REQUEST = 'USAGE/CANCEL_BOOKING_REQUEST' as const;
export const CANCEL_BOOKING_SUCCESS = 'USAGE/CANCEL_BOOKING_SUCCESS' as const;
export const CANCEL_BOOKING_FAILURE = 'USAGE/CANCEL_BOOKING_FAILURE' as const;

export const INIT_CANCEL_DONE = 'INIT_CANCEL_DONE' as const;
export const GET_CALENDAR_MARK = 'GET_CALENDAR_MARK' as const;

export type sortTypeProps = { type: string; value: string; content: string; current: boolean };

// type filterProps = {
//   [key: string]: sortTypeProps[];
// };

export type IUsageProps = {
  bookings: any;
  bookingsAll: any;
  filters: Record<string, sortTypeProps[]>;
  params: Record<string, any>;
  currentBooking: {
    id: number;
    lecture: {
      id: number;
      title: string;
      room_name: null;
      staff: { avatar: string; name: string };
      start_on: string;
      end_on: string;
      current_trainee_count: number;
      max_trainee: number;
      booking_waiting_count: null | number;
      description: null | string;
    };
    status: string;
    updated_at: string;
  };
  loading: boolean;
  cancelDone: { status: number };
  markDate: { d: string; color: string }[];
};
