import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Checkbox from 'antd-mobile/lib/checkbox';

import { RootState } from 'store';
import api from 'api/modules/booking';
import MainLayout from 'components/MainLayout';
import PlainButton from 'components/PlainButton';
import Avatar from 'components/Avatar';
import getImageUrl from 'utils/getImageUrl';
import filters from 'utils/filters';
import { _ } from 'utils/fx';
import { SUCCESS_MESSAGE, HEADER_TITLE } from 'constants/text';
import EmptyLayout from 'components/EmptyLayout';
import { theme } from 'assets/styles/theme';

type bookingType = {
  title: string;
  id: number;
  staff: { name: string, avatar: any };
  start_on: string;
  end_on: string;
  booking_end_at: string;
  current_trainee_count: number;
  max_trainee: number;
  room: { name: string };
  booking_start_at: string | null;
};

type LocationType = {
  location: { state: { bookingId: number; lectureId: number } };
};

type StyleProps = {
  disabled: boolean;
}

const BookingChange = ({ location }: LocationType) => {

  /** hooks */
  const history = useHistory();
  const [bookingList, setBookingList] = useState<bookingType[]>([]);
  const [loading, setLoading] = useState(false);
  const [targetBooking, setTargetBooking] = useState<{ id: number }>({ id: 0 });
  const [checked, setChecked] = useState(new Map());

  /** state */
  const { studio, ticket } = useSelector((state: RootState) => state.studio).currentStudioData;
  const { currentBooking } = useSelector((state: RootState) => state.usage);

  /** func */
  const filterBookingChangeList = (bookings: { data: bookingType[] }) => {
    const lectureId = location.state ? location.state.lectureId : currentBooking.lecture.id;
    let res = [];
    
    if (studio?.policy?.is_visible_all_lectures) {
      res = _.go(
        bookings.data,
        _.filter((el: bookingType) => dayjs(el.booking_end_at).isAfter(dayjs()) && lectureId !== el.id && el.current_trainee_count !== el.max_trainee),
        (res: bookingType[]) => [...res].sort((a: bookingType, b: bookingType) => dayjs(a.start_on).valueOf() - dayjs(b.start_on).valueOf())
      );
    } else {
      res = _.go(
        bookings.data,
        _.filter((el: bookingType) => dayjs(el.booking_end_at).isAfter(dayjs()) && lectureId !== el.id && el.current_trainee_count !== el.max_trainee 
        && dayjs(el.start_on) > dayjs() && (el.booking_start_at ? dayjs() > dayjs(el.booking_start_at) : true)),
        (res: bookingType[]) => [...res].sort((a: bookingType, b: bookingType) => dayjs(a.start_on).valueOf() - dayjs(b.start_on).valueOf())
      );
    }

    setBookingList(res);
    setLoading(false);
  };

  const getChangeBookingList = async () => {
    const res = await api.getBookingList(ticket.id, dayjs().format('YYYY-MM-DD'));
    filterBookingChangeList(res.data);
  };

  useEffect(() => {
    setLoading(true);
    getChangeBookingList();
    // eslint-disable-next-line
  }, []);

  const onChangeBooking = async () => {
    setLoading(true);
    try {
      const targetId = location.state ? location.state.bookingId : currentBooking.id;
      const res = await api.changeBooking(targetId, targetBooking.id);
      if (res.status === 201) {
        setLoading(false);
        return history.push({
          pathname: '/success',
          state: { ...SUCCESS_MESSAGE.bookingChange, routePath: location.state ? `/booking-group/${ticket.id}` : '/booking/list' },
        });
      }
      const badRequest = Math.floor(Number(res.status) / 100) == 4;
      if (badRequest && !!res.data.message) {
        alert({
          message: res.data.message
        })
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const selectTargetBooking = (data: bookingType) => {
    setTargetBooking(data);
    setChecked(checked.set(data.id, true));

    checked.forEach((key, value) => {
      if (value !== data.id) setChecked(checked.set(value, false));
    });
  };


  return (
    <MainLayout header={{ title: HEADER_TITLE.bookingChange, titleNormal: true, backFunc: history.goBack }} contentsGrid loading={loading}>

      <ContainerStyle>
        {!loading ? (
            bookingList.length ? (
                    bookingList.map(el => (
                      <ListStyle onClick={() => selectTargetBooking(el)} key={el.id}>

                        <div className='content'>

                          {/* 날짜 */}
                          <div className='date'>{filters.dateLecture(el.start_on, el.end_on)}</div>

                          {/* 수업명 */}
                          <div className='status'>
                            <div className='title gap'>{el.title}</div>
                            <StatusBox>
                              <Checkbox.AgreeItem checked={checked.get(el.id)} />
                            </StatusBox>
                          </div>

                          {/* 강사 이름 */}
                          <div className='gap user-info'>
                            <Avatar
                              className='user_img' 
                              size="small-2"
                              imgUrl={getImageUrl(el.staff?.avatar, '32x32')}
                            />
                            <span className='instructor'>{el.staff.name} 강사</span>

                            {/* 룸 */}
                            {!!el.room && <div className='room'><div className='room-overflow'>{el.room.name} 룸</div></div>}
                          </div>

                          {/* 예약인원/최대수강인원 */}
                          {/* {studio.policy.is_show_current_count === null || studio.policy.is_show_current_count === 1 ? (
                            <div className='room'>예약인원/최대수강인원&nbsp;&nbsp;&nbsp;{el.current_trainee_count}/{el.max_trainee}</div>
                          ) : (
                            <div className='room'>최대수강인원&nbsp;&nbsp;&nbsp;{el.max_trainee}</div>
                          )} */}
                        </div>

                      </ListStyle>
                    ))
            )
            : <EmptyLayout text="예약 변경 가능한 수업이 없습니다." />
          )
          : null
        }
      </ContainerStyle>

      {!loading && bookingList.length > 0 && (
        <StyledButton>
          <PlainButton className='flex-both-center' onClick={history.goBack} color="dark" outline>취소</PlainButton>
          <CustomBtnChange disabled={checked.size === 0}><PlainButton className='flex-both-center' onClick={onChangeBooking} disabled={checked.size === 0}>예약 변경</PlainButton></CustomBtnChange>
        </StyledButton>
      )}

    </MainLayout>
  );
};

const StyledButton = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid #ddd;
  grid-gap: 16px;
  padding: 0 16px;

  button {
    font-size: 18px;
    font-weight: 500;
  }

  .mbsc-ios.mbsc-btn-outline.mbsc-btn {
    margin-top: .58em;
  }
`;

const CustomBtnChange = styled.div<StyleProps>`
  .mbsc-ios.mbsc-btn-primary.mbsc-btn {
    background-color: #4c2c92;
    border: 1px solid #4c2c92;
    font-weight: bold;
  }

  ${props => {
    if (props.disabled) {
      return `
        .mbsc-ios.mbsc-btn-primary.mbsc-btn {
          background: #d1d1e0 !important;
        }
      `;
    }
  }}
`;

const ListStyle = styled.div`
  border-radius: 16px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  padding: 12px;
  margin: 12px 16px;
  margin-bottom: 24px;
  height: 146px;

  .status {
    display: flex;
    align-items: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    margin: auto 0;
  }
  .gap {
    margin-top: 10px;
  }
  .title {
    color: ${theme.color.black300};
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    white-space: pre-wrap;
    flex-grow: 1;
  }
  .date {
    color: ${theme.color.black300};
    font-weight: 500;
    font-size: 18px;
    line-height: normal;
  }
  .user-info {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .room, .instructor {
    white-space: pre-wrap;
    font-size: 14px;
    color: ${theme.color.black500};
    font-weight: normal;
  }
  .room {
    max-width: 128px;
  }
  .instructor {
    flex-grow: 1;
  }
  .room-overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; 
  }
`;

const ContainerStyle = styled.div`
  overflow-y: auto;
`;

const StatusBox = styled.div`
  margin-top: -17px;

  .am-checkbox.am-checkbox-checked .am-checkbox-inner {
    border-color: #4c2c92;
    background: #4c2c92;
  }
`;

export default BookingChange;
