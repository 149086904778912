/** 예약 */
import produce from 'immer';
import moment from 'moment';

import { _ } from 'utils/fx';
import mapPrivateAvailableTime from 'utils/mapPrivateAvailableTime';
import mapPrivateAvailableStaff from 'utils/mapPrivateAvailableStaff';
import { SUCCESS_MESSAGE, ACTION_BOOKING } from 'constants/text';
import { ActionRequest } from './actions';
import {
  IBookingState,
  CHANGE_CALENDAR_DATA,
  CHANGE_USAGE_CALENDAR_DATA,
  AVAILABLE_BOOKING_LIST_R,
  AVAILABLE_BOOKING_LIST_S,
  AVAILABLE_BOOKING_LIST_F,
  POST_GROUP_BOOKING_R,
  POST_GROUP_BOOKING_S,
  POST_GROUP_BOOKING_F,
  DELETE_GROUP_BOOKING_R,
  DELETE_GROUP_BOOKING_S,
  DELETE_GROUP_BOOKING_F,
  AVAILABLE_PRIVATE_LIST_R,
  AVAILABLE_PRIVATE_LIST_S,
  AVAILABLE_PRIVATE_LIST_F,
  POST_PRIVATE_BOOKING_R,
  POST_PRIVATE_BOOKING_S,
  POST_PRIVATE_BOOKING_F,
} from './types';

export const initialState: IBookingState = {
  calendarData: {
    selectDate: new Date(),
    weeks: 1,
  },
  usageCalendarData: {
    selectDate: new Date(),
    weeks: 1,
  },
  availableBookingList: { data: [], reason: null },
  availablePrivateList: { data: [], reason: null },
  loading: false,
  done: {},
};

export const booking = (state: IBookingState = initialState, action: ActionRequest) =>
  produce(state, draft => {
    switch (action.type) {
      case CHANGE_CALENDAR_DATA:
        draft.calendarData = { ...state.calendarData, ...action.payload };
        break;
      case CHANGE_USAGE_CALENDAR_DATA:
        draft.usageCalendarData = { ...state.usageCalendarData, ...action.payload };
        break;
      case AVAILABLE_BOOKING_LIST_R:
      case AVAILABLE_PRIVATE_LIST_R:
      case POST_GROUP_BOOKING_R:
      case POST_PRIVATE_BOOKING_R:
      case DELETE_GROUP_BOOKING_R:
        draft.loading = true;
        draft.done = {};
        break;
      case AVAILABLE_BOOKING_LIST_F:
      case AVAILABLE_PRIVATE_LIST_F:
        draft.loading = false;
        break;
      case POST_GROUP_BOOKING_F:
      case POST_PRIVATE_BOOKING_F:
      case DELETE_GROUP_BOOKING_F:
        if (action.error) {
          alert({ message: action.error });
        }
        draft.loading = false;
        draft.done = {};
        break;
      case AVAILABLE_BOOKING_LIST_S:
        type bookingType = {
          start_on: string;
          staff: { name: string };
          booking_end_at: string;
          booking_start_at: string | null;
          max_trainee: number;
        };
        let res = [];
        if (action.studio) {
          if (action.studio.policy.is_visible_all_lectures) {
            res = _.go(action.data.data, (res: bookingType[]) =>
              [...res].sort((a: bookingType, b: bookingType) => moment(a.start_on).valueOf() - moment(b.start_on).valueOf()),
            );
          } else {
            /** is_visible_all_lectures false일시, 현시간 기준 수업 시작했거나, 예약가능 시간 지났을 경우 필터링 */
            res = _.go(
              action.data.data,
              _.filter(
                (res: Omit<bookingType, 'staff'>) =>
                  moment(res.start_on) > moment() &&
                  moment(res.booking_end_at) > moment() &&
                  (res.booking_start_at ? moment() > moment(res.booking_start_at) : true),
              ),
              (res: bookingType[]) => [...res].sort((a: bookingType, b: bookingType) => moment(a.start_on).valueOf() - moment(b.start_on).valueOf()),
            );
          }
        }

        draft.availableBookingList.reason = action.data.reason;
        draft.availableBookingList.data = res;
        draft.loading = false;
        break;
      case POST_GROUP_BOOKING_S:
      case POST_PRIVATE_BOOKING_S:
        draft.loading = false;
        if (action.bookingType === ACTION_BOOKING.bookings) {
          draft.done = SUCCESS_MESSAGE.booking;
        } else if (action.bookingType === ACTION_BOOKING.waitings) {
          draft.done = SUCCESS_MESSAGE.bookingWait;
        }
        break;
      case DELETE_GROUP_BOOKING_S:
        draft.loading = false;
        if (action.bookingType === ACTION_BOOKING.bookings || action.bookingType === ACTION_BOOKING.bookingConfirm) {
          draft.done = SUCCESS_MESSAGE.bookingCancel;
        } else if (action.bookingType === ACTION_BOOKING.waitings) {
          draft.done = SUCCESS_MESSAGE.bookingWaitCancel;
        }
        break;
      case AVAILABLE_PRIVATE_LIST_S:
        const timeTable = mapPrivateAvailableStaff(
          action.studio?.studio?.policy,
          action.studio?.studio?.policy?.private_booking_deadline,
          action.studio?.studio?.policy?.private_start_interval,
          state.calendarData?.selectDate,
          action.studio?.ticketRaw?.ticket?.class_period,
          action?.data?.data,
        );
        // 예약시간을 체크해야하는듯
        draft.availablePrivateList.reason = action.data.reason;
        draft.availablePrivateList.data = timeTable;
        draft.loading = false;
        break;
      default:
        return state;
    }
  });

export default booking;
