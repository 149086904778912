import React from 'react';
import mobiscroll from '@mobiscroll/react';
import styled from 'styled-components';

type PlainButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | undefined;
  outline?: boolean | undefined;
  onClick: (e: any) => void | Promise<void>;
  className?: string;
};

const PlainButton = ({ children, color, outline, disabled, onClick, className }: PlainButtonProps) => {
  return (
    <BtnStyle>
      <mobiscroll.Button className={className} color={color} disabled={disabled} outline={outline} onClick={onClick}>
        {children}
      </mobiscroll.Button>
    </BtnStyle>
  );
};

PlainButton.defaultProps = {
  color: 'primary',
  disabled: false,
  loading: false,
};

const BtnStyle = styled.div`
  button {
    height: 47px;
    width: 100%;
    padding: 5px !important;

    border-radius: 8px !important;
    border: solid 2px !important;

    font-family: 'Noto Sans KR', sans-serif !important;
    line-height: 1.5;
  }

  // 아웃라인 버튼
  .mbsc-btn-outline {
    height: 43px;
    margin-top: 9px;
  }

  .mbsc-ios.mbsc-btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .disabledLogin .mbsc-ios.mbsc-btn:not(.mbsc-btn-flat):disabled,
  .mbsc-ios.mbsc-btn:not(.mbsc-btn-flat):disabled,
  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active,
  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active,
  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active,
  .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    color: white !important;
  }
`;

export default PlainButton;