import dayjs from 'dayjs';

export default {
  /**
   * 천단위 콤마
   * @param {*} value
   */
  comma(value) {
    if (!value) return 0;
    value = String(value).replace(/,/g, '');
    return value.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
  },

  /**
   * 날짜 형식 (년-월-일)
   * @param {Date|String} value
   */
  date(value) {
    return value ? dayjs(value).format('YYYY. M. D.') : null;
  },

  dateWithWeekday(value) {
    return value ? dayjs(value).format('YYYY. M. D. (ddd)') : null;
  },

  dateWithWeekdayKor(value, type) {
    const format = type ? 'YYYY년 M월 D일 ddd요일' : 'YYYY년 M월 D일 ddd요일 HH:mm';
    return value ? dayjs(value).format(format) : null;
  },

  dateWithWeekdayWithTime(value) {
    return value ? dayjs(value).format('YYYY. M. D. (ddd) HH:mm') : null;
  },

  /**
   * 시간 형식 (시:분)
   * @param {Date|String} value
   */
  time(value) {
    return value ? dayjs(value).format('HH:mm') : null;
  },

  /**
   * 날짜 시간 형식 (년.월.일. 시:분)
   * @param {Date|String} value
   */
  datetime(value) {
    return value ? dayjs(value).format('YYYY. M. D. HH:mm') : null;
  },

  datetimesecond(value) {
    return value ? dayjs(value).format('YYYY. M. D. HH:mm:ss') : null;
  },

  datetimedate(value) {
    return value ? dayjs(value).format('YYYY. M. D. HH:mm (ddd)') : null;
  },

  /**
   * 날짜 형식 (YYYY년 MM월 DD일)
   * @param {Date|String} value
   */
  dateKorean(value) {
    return value ? dayjs(value).format('YYYY년 M월 D일') : null;
  },

  dateKoreanWithWeekday(value) {
    return value ? dayjs(value).format('YYYY년 M월 D일 (ddd)') : null;
  },

  // 수업 시간 (2020. 10. 1.(목) 16:00 ~ 16:50)
  dateLecture(startOn, endOn) {
    return `${dayjs(startOn).format('YYYY. M. D. (ddd) HH:mm')} ~ ${dayjs(endOn).format('HH:mm')}`;
  },

  // 수업 시간 (16:00 ~ 16:50)
  dateLectureOnlyTime(startOn, endOn) {
    return `${dayjs(startOn).format('HH:mm')} ~ ${dayjs(endOn).format('HH:mm')}`;
  },

  /**
   * 휴대폰 번호 형식
   * @param {*} value
   */
  mobileMask(value) {
    try {
      if (value.length >= 11) {
        return `${value.slice(0, 3)}-✳✳✳✳-✳✳✳✳`;
      }
      if (value.length === 10) {
        return `${value.slice(0, 3)}-✳✳✳-✳✳✳✳`;
      }
      return value;
    } catch (e) {
      return value;
    }
  },

  /**
   * 휴대폰 번호 형식 (000-0000-0000 | 000-000-0000)
   * @param {*} value
   */
  mobile(value) {
    try {
      if (value.length === 11) {
        return `${value.slice(0, 3)}-${value.slice(3, 7)}-${value.slice(7)}`;
      }
      if (value.length === 10) {
        return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6)}`;
      }
      return value;
    } catch (e) {
      return value;
    }
  },

  /**
   * 카드 마스킹
   * @param {Number} value
   */
  cardMask(value) {
    try {
      if (value.length === 16) {
        return `${value.slice(0, 4)}-${value.slice(4, 6)}✳✳-✳✳✳✳-${value.slice(12, 16)}`;
      }
      if (value.length === 15) {
        return `${value.slice(0, 4)}-${value.slice(4, 6)}✳✳✳✳-✳${value.slice(12, 15)}`;
      }
      return value;
    } catch (e) {
      return value;
    }
  },

  phoneFomatter(num, type) {
    let formatNum = '';
    if (num.length === 11) {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else if (num.length === 8) {
      formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else if (num.indexOf('02') === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
    } else {
      formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return formatNum;
  },
};
