import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';
import { theme } from 'assets/styles/theme';
import Avatar from 'components/Avatar'
import BookingListPartContainer from 'pages/BookingList/BookingListPartContainer';
import Actionsheet from 'components/ActionSheet';
import { setBookingFilter } from 'store/usage/actions';
import { getUserAccount } from 'store/auth/actions';
import getImageUrl from 'utils/getImageUrl';
import { Flex } from 'antd-mobile';
import { ReactComponent as RightArrow } from 'assets/icons/icon_arrow_right_24.svg';

const MyPagePresenter = ({
  apis,
	user,
	studios,
	loading
}) => {
  
  /** state */
  const [show, setShow] = useState({ sort: false, status: false });
  const { sortedBy, status } = useSelector(state => state.usage.filters);
  const [locker] = useState([]);

  /** hooks */
  const dispatch = useDispatch();
  const history = useHistory();


  /** func */
  useEffect(() => {
    dispatch(getUserAccount());
  }, [apis.auth, history, dispatch]);

  // 락커
  useEffect(() => {
    if (studios) {
      studios.map(({ studio }) => {
        if (studio?.policy?.is_locker) studio.locker.map(el => locker.push([el, studio.name]));
      })
    }
  }, []);
  
  const changeType = (event) => {
    if (Object.keys(event.target.dataset).length) dispatch(setBookingFilter(event.target.dataset));
    setShow({ sort: false, status: false });
  };

  const addDashMobile = (mobile) => {
    if (!mobile) return
    const newMobile = `${mobile.slice(0, 3)  }-${  mobile.slice(3, 7)  }-${  mobile.slice(7)}`;
    return newMobile;
  };

  const mainLayoutStyle = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.mypage, titleNormal: true, titleBorder: false, noBackBtn: true }} loading={loading} contentStyle={mainLayoutStyle}>
      
      <MyInfoWrap locker={locker}>
        <div className="flex-row-between">
          <div className="flex-col">
            <span className='username'>{user?.name}</span>
            <span className='content'>{addDashMobile(user?.mobile)}</span>
          </div>
          <Avatar 
            imgUrl={getImageUrl(user?.representative_avatar?.image, '64x64')} 
            size="large"
          />
        </div>

        <span className='content'>{user?.email}</span>
        {locker && locker.length !== 0 && (
          <div className='locker' onClick={() => history.push({ pathname: '/mypage/locker', state: locker })}>
            <div className='text'>락커룸 사용 정보</div>
            <RightArrow />
          </div>
        )}
      </MyInfoWrap>

      <BookingListPartContainer 
        show={show}
        setShow={setShow}
        sortedBy={sortedBy}
        status={status} />

      <Actionsheet show={show.sort} menus={sortedBy} onRequestClose={changeType} />
      <Actionsheet show={show.status} menus={status} onRequestClose={changeType} />
    
    </MainLayout>
  );
};

/** css */
const MyInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px 0px 24px;

  .username {
    font-size: 16px;
    font-weight: 500;
    color: ${theme.color.black300};
    margin: 8px 0;
  }
  .content {
    color: ${theme.color.black500};
    font-size: 14px;
    font-weight: normal;
    margin-top: 8px;
  }
  .locker {
    margin-top: 16px;
    border-top: 1px solid ${theme.color.greyBBB};
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.black500};
    padding: 16px 0;

    display: flex;
    align-items: center;

    .text {
      flex-grow: 1;
    }
  }
`;

/** prop-types */
MyPagePresenter.propTypes = {
  apis: PropTypes.oneOfType([PropTypes.object]),
	user: PropTypes.oneOfType([PropTypes.object]),
	studios: PropTypes.oneOfType([PropTypes.array]),
	loading: PropTypes.bool
};

MyPagePresenter.defaultProps = {
  apis: {},
	user: {},
	studios: {},
	loading: false
};

export default MyPagePresenter;