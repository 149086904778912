import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { theme } from './theme';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  .full-width {
    width: 100%;
  }
  .full-height {
    height: 100%;
  }
  .overflow-h {
    overflow: hidden;
  }
  .flex_row_center {
    display: flex;
    align-items: center;
  }

  .flex-row-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-col {
    display: flex;
    flex-direction: column;
  }
  .flex-both-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .flex-0 {
    flex: 0 0 auto;
  }
  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* html,
  body {
    overflow: hidden;
  } */

  #root {
    overflow: hidden;
  }
  * {
    font-family: 'Noto Sans KR', sans-serif;
    box-sizing: border-box;
  }
  html {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 10px;
  }
  body {
    background-color: #fff;
    font-size: 1.6rem;
    margin: 0px; 
    touch-action: pan-x pan-y;
    font-family: 'Noto Sans KR', sans-serif;
    color: ${theme.color.black300};
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  label {
    margin-bottom: 8px;
    color: ${theme.color.black500};
  }
  input {
    border-width: 0 0 1px 0;
    border-color: #ccc;
    padding-bottom: 5px;
    font-family: 'Noto Sans KR', sans-serif;
    color: ${theme.color.black300};
    line-height: 1.5;
    -webkit-appearance: none;
    -webkit-border-radius: 0;
  }
  input:focus {
    font-family: 'Noto Sans KR', sans-serif;
    border-color: ${theme.color.main};
  }
  input::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    color: ${theme.color.greyCCC};
  }
  a {
    font-family: 'Noto Sans KR', sans-serif;
    color: ${theme.color.main};
    text-decoration: none;
  }
  ul {
    font-family: 'Noto Sans KR', sans-serif;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  li {
    font-family: 'Noto Sans KR', sans-serif;
    list-style:none
  }


  /**mixins */
  @mixin flex($direction: 'row', $align: 'center', $justify: 'center') {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
  }

  @mixin mq($size) {
    @media only screen and (min-width: $size) {
      @content;
    }
  }

  @mixin mq-max($size) {
    @media only screen and (max-width: $size) {
      @content;
    }
  }

  @mixin mq-height($size) {
    @media only screen and (min-height: $size) {
      @content;
    }
  }

  @mixin mq-height-max($size) {
    @media only screen and (max-height: $size) {
      @content;
    }
  }

  @mixin ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /** reset antd mobile */
  .am-checkbox-agree {
    margin-left: 0px;
    padding-bottom: 0px;
    .am-checkbox-agree-label {
      margin: 2px 0 5px 25px;
    }
  }
  .wrap-iframe {
    margin-bottom: 20px;
    width: 100%;
    height: 80%;
    border-width: 1px;
    border-style: solid;
    border-color: ${theme.color.sub};
    border-radius: 4px;
    overflow: hidden;
    overflow-x: hidden;
  }
`;

export default GlobalStyle;
