import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BoardPresenter from 'pages/Board/BoardPresenter';
import { changeTab } from 'store/board/reducer';

const BoardContainer = () => {
  /** hooks */
  const dispatch = useDispatch();

  /** state */
  const { tabIndex } = useSelector(state => state.board);

  /** func */
  const changeTabIndex = (tab, index) => dispatch(changeTab(index));

  return (
    <BoardPresenter
      changeTab={changeTabIndex}
      tabIndex={tabIndex}
      // count={{ notice: notice.unReadCount, qna: qna.unReadCount, snotice: studioNotice.unReadCount }}
    />
  );
};

export default BoardContainer;
