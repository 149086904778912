import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { patchQnaComment, changeEditDone } from 'store/board/reducer';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { theme } from 'assets/styles/theme';
import FooterButtons from 'components/Footer/FooterButtons';
import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';

const QnaCommentEdit = ({ location }) => {
  /** hooks */
  const history = useHistory();
  const dispatch = useDispatch();

  /** state */
  const [contents, setContent] = useState(location.state.content);
  const { currentBoard, loading, editDone } = useSelector(state => state.board);

  useEffect(() => {
    if (editDone === 'editCommit') {
      dispatch(changeEditDone(false));
      alert({ message: '댓글이 수정되었습니다.' });
      history.goBack(2);
    }
  }, [editDone, history, dispatch]);

  const saveContent = () => {
    const params = { board_id: currentBoard.id, contents, studio_id: currentBoard.studio_id };
    dispatch(patchQnaComment(location.state.id, params));
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.commentPatch, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>

      <Content 
        value={contents} 
        onChange={(e) => setContent(e.target.value)} 
      />

      <FooterButtons 
        cancelClick={() => history.goBack(2)} 
        disabled={!contents}
        confirmClick={saveContent}
        confirmText="수정"
        cancelText="취소"
      />
    </MainLayout>
  );
};

const Content = styled.textarea`
  border: none;
  padding: 16px;
  line-height: 1.5;
  resize: none;

  font-weight: normal;
  font-size: 14px;
  color: ${theme.color.black300};
`;

export default QnaCommentEdit;

QnaCommentEdit.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      content: PropTypes.string,
    }),
  }).isRequired,
};
