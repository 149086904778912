import React, { useState, useEffect } from 'react';
import MainLayout from 'components/MainLayout';
import TextInput from 'components/TextInput';
import VerficationInput from 'components/VerficationInput';
import PlainButton from 'components/PlainButton';
import { HEADER_TITLE, AUTH_TYPES, LABEL, VALIDATE_MESSAGE, PLACEHOLDER } from 'constants/text';
import styled from 'styled-components';
import { SignUpTwoType } from './types';
// import { isConstructorDeclaration } from 'typescript';

const SignUpTwo = ({
  text,
  valid,
  isClickValied,
  loading,
  onChange,
  sendValidCode,
  setValidCode,
  checkCode,
  onSignUp,
  disabled,
  signUpData,
  checkEmail,
  setSignUpData,
}: SignUpTwoType) => {
  const [checkPassword, setCheckPassword] = useState(true);

  const backFunc = () => {
    setSignUpData({ step: 1 });
  };

  useEffect(() => {
    if (text.password === text.confirmPassword || text.confirmPassword === '') {
      setCheckPassword(true);
    } else {
      setCheckPassword(false);
    }
  }, [text.confirmPassword]);

  return (
    <MainLayout header={{ title: HEADER_TITLE.signup, backFunc }} loading={loading} contentsGrid invisibleFooter>
      <Wrap>
        <TextInput
          id={AUTH_TYPES.name}
          name={AUTH_TYPES.name}
          value={text.name}
          label={LABEL.name}
          onChange={onChange}
          error={!valid.name}
          placeholder={PLACEHOLDER.nameGuide}
          errorMessage={VALIDATE_MESSAGE.incorrectName}
        />
        <StyledSection>
          <TextInput
            id={AUTH_TYPES.email}
            name={AUTH_TYPES.email}
            value={text.email}
            label={LABEL.email}
            onChange={onChange}
            error={!valid.email}
            placeholder={LABEL.emailPlaceholder}
            errorMessage={VALIDATE_MESSAGE.incorrectEmail}
          />
          <StyledBtnArea>
            <PlainButton className="flex-both-center" color="primary" onClick={checkEmail} disabled={!(valid.email && text.email)}>
              중복확인
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>
        <TextInput
          id={AUTH_TYPES.password}
          name={AUTH_TYPES.password}
          value={text.password}
          type="password"
          label={LABEL.password}
          onChange={onChange}
          error={!valid.password}
          errorMessage={VALIDATE_MESSAGE.noSpecialChar}
          elseMessage={text.password ? '' : VALIDATE_MESSAGE.incorrectEngNum}
          placeholder={PLACEHOLDER.passwordGuide}
          // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
        />
        <TextInput
          id={AUTH_TYPES.confirmPassword}
          name={AUTH_TYPES.confirmPassword}
          value={text.confirmPassword}
          type="password"
          label={LABEL.passwordConfirm}
          onChange={onChange}
          error={!checkPassword}
          errorMessage={VALIDATE_MESSAGE.incorrectPassword}
          elseMessage=""
          placeholder={LABEL.passwordAgain}
          // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
        />
        <StyledSection>
          <TextInput
            id={AUTH_TYPES.mobile}
            name={AUTH_TYPES.mobile}
            type="number"
            value={text.mobile}
            label={LABEL.mobile}
            placeholder={LABEL.mobilePlaceholder}
            error={!valid.mobile}
            errorMessage={text.mobile ? VALIDATE_MESSAGE.incorrectNumber : ''}
            elseMessage={text.mobile ? '' : LABEL.mobileGuide}
            onChange={onChange}
          />
          <StyledBtnArea>
            <PlainButton className="flex-both-center" color="primary" onClick={sendValidCode} disabled={!valid.mobile}>
              인증번호받기
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>

        <StyledSection>
          {/* <div className="flex-row-between"> */}
          {/* <VerficationInput placeholder="0" onChange={setValidCode} length={6} /> */}
          <TextInput
            id={AUTH_TYPES.validateCode}
            name={AUTH_TYPES.validateCode}
            type="number"
            value={text.validateCode}
            label={LABEL.validateCode}
            placeholder={PLACEHOLDER.validateCode}
            error={!valid.validateCode}
            onChange={onChange}
          />

          <StyledBtnArea>
            <PlainButton
              className="flex-both-center"
              color="primary"
              onClick={checkCode}
              disabled={!(isClickValied && text.validateCode.length === 6)}>
              인증번호확인
            </PlainButton>
          </StyledBtnArea>
          {/* </div> */}
        </StyledSection>
        {/* 인증번호 확인했는지 추가 */}
      </Wrap>

      <BtmButton>
        <PlainButton color="primary" disabled={disabled} onClick={onSignUp}>
          <b>회원가입</b>
        </PlainButton>
      </BtmButton>
    </MainLayout>
  );
};

export default React.memo(SignUpTwo);

const Wrap = styled.section`
  padding: 24px 16px;
  overflow-y: auto;

  .flex-row-between > *:first-child {
    flex: 1;
    margin-right: 10px;
  }
`;

export const StyledSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 107px;
  grid-column-gap: 10px;
`;

export const StyledBtnArea = styled.div`
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin: 27px 0 0;
  }
`;

export const BtmButton = styled.div`
  margin: 8px 16px 16px 16px;
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    display: inline-block;
    margin: 0;
    font-size: 18px;
  }
`;
