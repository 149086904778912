import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Store from 'contexts/store/store';
import NotificationPresenter from 'pages/Notification/NotificationPresenter';
import { LOCAL_STORAGE } from 'constants/text';
import { notiParamsProps, notiDetailProps } from './types';


const NotificationContainer = () => {
  /** hook */
  const { apis, utils } = useContext(Store);
  const history = useHistory();

  /** state */
  const [notiParams, setNotiParams] = useState<notiParamsProps>({
    limit: 10,
    min_id: null,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [notiData, setNotiData] = useState<notiDetailProps[]>([]);
  const isLastNoti = useRef<HTMLInputElement | boolean>(false);

  const observer = useRef<any>(null);

  /** func */

  const convertLocalTime = (value: string, type: string | null) =>
    type === 'stringTime' ? utils.getTime.getFullDateTime(value) : utils.getTime.getTimeStampString(value);

  const goToDetail = (data?: notiDetailProps) => {
    history.push(`/notification/${data?.id}`, data);
  };

  const checkNoReadNoti = (createTime: any) => {
    const time = localStorage.getItem(LOCAL_STORAGE.notification);
    if (time) return dayjs(createTime) > dayjs(time);
    return false;
  };

  const getNewNotification = useCallback(async () => {
    try {
      setLoading(true);
      const res = await apis.notification.getNotice(notiParams);
      if (res.data.user_notices.length) {
        setNotiData([...notiData, ...res.data.user_notices]);
        setNotiParams(state => ({ ...state, min_id: res.data.user_notices[res.data.user_notices.length - 1].id }));
      } else {
        isLastNoti.current = true;
      }
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }, [notiParams]);

  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isLastNoti.current) {
          getNewNotification();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getInitNoti = async () => {
    try {
      setLoading(true);
      const res = await apis.notification.getNotice({ limit: 10 });
  
      if (res?.data?.user_notices?.length) {
        setNotiData(res?.data?.user_notices);
        setNotiParams({ ...notiParams, min_id: res?.data?.user_notices[res?.data?.user_notices?.length - 1].id });
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  /** useEffect */
  useEffect(() => {
    getInitNoti();
    return (() => {
      localStorage.setItem(LOCAL_STORAGE.notification, dayjs().format('YYYY-MM-DDTHH:mm:ss'));
    })
  }, []);
  
  return (
    <NotificationPresenter
      getInitNoti={getInitNoti}
      notiData={notiData}
      convertLocalTime={convertLocalTime}
      goToDetail={goToDetail}
      checkNoReadNoti={checkNoReadNoti}
      lastElementRef={lastElementRef}
      loading={loading}
    />
  );
};

export default NotificationContainer;
