import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import { useHistory } from 'react-router-dom';

import { ReactComponent as CloseIcon } from 'assets/icons/icon-24-close.svg';

const PostImagesDetail = ({ location }) => {
  /** state */
  const ref = useRef(null);
  const history = useHistory();
  const { idx, images } = location.state;

  const [crntIdx, setCrntIdx] = useState(idx);
  const [scrollOp, setScrollOp] = useState(false);

  const handleImgError = e => {
    e.target.src = require('assets/images/board-dummy-img.png');
  };

  const params = {
    slidesPerView: 1,
    // loop: true,
    zoom: {
      maxRatio: 5,
    },
    initialSlide: idx,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      slideChangeTransitionStart: () => {
        setCrntIdx(ref.current.swiper.realIndex);
      },
    },
  };

  useEffect(() => {
    const img_height = document.getElementById(`image-${crntIdx}`).clientHeight;

    if (img_height && window.innerHeight < img_height) {
      setScrollOp(true);
    } else {
      setScrollOp(false);
    }
  }, [crntIdx]);

  return (
    <>
      <IconStyle>
        <CloseIcon className="icon" onClick={history.goBack} />
      </IconStyle>

      <SliderContainer scroll={scrollOp}>
        <Swiper {...params} ref={ref}>
          {images &&
            images.map((data, index) => {
              return (
                <div className="swiper-zoom-container" key={data.id}>
                  <img src={data.url} alt={data.url} onError={handleImgError} id={`image-${index}`} />
                </div>
              );
            })}
        </Swiper>
      </SliderContainer>
    </>
  );
};

const SliderContainer = styled.div`
  overflow-y: ${props => (props.scroll ? 'auto' : 'unset')};

  display: flex;
  align-items: ${props => !props.scroll && 'center'};
  height: ${window.innerHeight}px;

  .swiper-container {
    position: unset;
    overflow: ${props => props.scroll && 'auto'};
  }

  .swiper-wrapper {
    align-items: ${props => props.scroll && 'auto'};
    height: fit-content;
    display: flex;
    align-items: center;
  }

  .swiper-pagination {
    bottom: 48px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 6px !important;

    box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    content: '';
    background-color: white;
    border: solid 1px white;
  }
`;

const IconStyle = styled.div`
  position: fixed;
  z-index: 2;
  right: 0;

  .icon {
    margin: 16px;
  }
`;

/** PropTypes */
PostImagesDetail.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]),
};

PostImagesDetail.defaultProps = {
  location: {},
};

export default PostImagesDetail;
