import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Toast from 'antd-mobile/lib/toast';

import { getBookingCount, initializeBooking, getBookingList, getBookingDetail, initializeFilter, getBookingCountAll, getBookingListAll } from 'store/usage/actions';
import BookingListPartPresenter from 'pages/BookingList/BookingListPartPresenter';

const BookingListPartContainer = ({
	show,
	setShow,
	sortedBy,
	status
}) => {
    /** hooks */
    const dispatch = useDispatch();
		const isLastNoti = useRef(false);
		const observer = useRef(null);
		const history = useHistory();

    /** state */
    const { currentStudioData } = useSelector(state => state.studio);
		const { params, loading, bookings, bookingsAll } = useSelector(state => state.usage);
		
		/** func */
		useEffect(() => (loading ? Toast.loading('Loading', 50000) : Toast.hide()), [loading]);
		
    useEffect(() => {
        /** 이용내역 count */
				if (history.location.pathname === '/mypage') {
					dispatch(getBookingCountAll());
				}

				/** 수강권 상세내역에 들어올 때 filter default */
				// dispatch(initializeFilter());

				// /** 다른 페이지로 나가면서 실행 */
				// return () => {
				// 	dispatch(initializeFilter());
				// 	dispatch(initializeBooking());
				// };
    }, []);

		  /** 수강권 화면 SET_TICKET 발생시마다 */
			useEffect(() => {
				
				return () => {
					// dispatch(initializeFilter());
					dispatch(initializeBooking());
				};
			}, [currentStudioData.ticket.id]); 

		/** 이용내역 무한 스크롤 */
		useEffect(() => {

			if (params.page === 1) {
				if (params.page <= params.last_page) {
					if (history.location.pathname !== '/mypage' && currentStudioData.ticket.id !== 0) {
						dispatch(getBookingCount(currentStudioData.ticket.id));
						dispatch(getBookingList(currentStudioData.ticket.id, params));
					}
					else
						dispatch(getBookingListAll(params));

					isLastNoti.current = false;
				}
			}
			
		}, [params.sortedBy, params.status, params.page, dispatch, currentStudioData.ticket.id]);



		/** lastElementRef */
		const lastElementRef = useCallback(
			node => {
				if (loading) return;
				if (observer.current) observer.current.disconnect();
				observer.current = new IntersectionObserver(entries => {
					if (entries[0].isIntersecting && !isLastNoti.current) {
						if (params.page <= params.last_page) {
							if (history.location.pathname !== '/mypage') 
								dispatch(getBookingList(currentStudioData.ticket.id, params));
							else
								dispatch(getBookingListAll(params));
						} else {
							isLastNoti.current = true;
						}
					}
				});
				if (node) observer.current.observe(node);
			},
			[loading, dispatch, currentStudioData.ticket.id, params]
		);

		/** 분류 텍스트 */
		const currentText = (datas) => {
			return datas.filter(({ current }) => current)[0];
		};
		
		/** 이용내역 상세로 가기 */
		const goToDetail = (booking) => {
			dispatch(getBookingDetail(booking.id));
			return history.push('/booking/detail');
		};

		const returnedBooking = () => {
			if (history.location.pathname === '/mypage')
				return bookingsAll;

			return bookings;
		}


    return (
      <BookingListPartPresenter 
        lastElementRef={lastElementRef}
        currentText={currentText}
        goToDetail={goToDetail}
        sortedBy={sortedBy}
        status={status}
        show={show}
        setShow={setShow}
        bookings={returnedBooking()}
        history={history}		
			/>
    );
};

BookingListPartContainer.propTypes = {
	show: PropTypes.oneOfType([PropTypes.object]),
	setShow: PropTypes.func,
	sortedBy: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  status: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

BookingListPartContainer.defaultProps = {
	show: {},
	setShow: {},
	sortedBy: {},
	status: {}
};

export default BookingListPartContainer;