/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import PostImages from 'components/PostImages';
import FooterComment from 'components/Footer/FooterComment';
import Avatar from 'components/Avatar';
import UrlToLink from 'components/UrlToLink';

const DetaliPresenter = ({
  currentBoard,
  getTime,
  getImage,
  confirmDeleteQna,
  goToBoard,
  scrollRef,
  goToScrollBottom,
  goToCommentEdit,
  goToEdit,
  user,
  saveComment,
}) => {

  useEffect(() => {
    goToBoard();
  }, [goToBoard]);

  useEffect(() => {
    goToScrollBottom();
  }, [goToScrollBottom]);

  const CommentList = () => {

    return (
      <CommentsWrap ref={scrollRef}>
        <div className="cmt_header">댓글 {currentBoard.comments.length}</div>

        {currentBoard.comments.map(({ id, contents, staff, created_at }) => {
          return (
            <List key={id} className='cmt_list'>
              <div className='flex-row-between'>
                {/* 유저이미지 */}
                <div className="flex_row_center">
                  {/* TODO: 백엔드에서 보내주는 key값로 맵핑 */}
                  {staff ? (
                    <Avatar
                      size="small"
                      imgUrl={Array.isArray(staff?.avatars) ? getImage(staff?.avatars[0]?.image) : getImage(staff?.image)}
                      />
                  ) : (
                    <Avatar
                      size="small"
                      imgUrl={getImage(user?.representative_avatar?.image)}
                    />
                  )}
                          
                  {/* 이름 */}
                  <span className='cmt_list_name'>
                    {staff ? `${staff?.name } ${staff?.roles[0]?.display_name}` : `${user?.name} 회원`}
                  </span>
                </div>

                {!staff && (
                  <div className='cmt_btn'>
                    <a onClick={() => {confirmDeleteQna('qnaComment', id)}} className='cmt_btn_remove'>삭제</a>
                    <a onClick={() => {goToCommentEdit(id, contents)}}>수정</a>
                  </div>
                )}
              </div>

              <div className='cmt_list_date'>{getTime(created_at)}</div>
              <div className='cmt_list_content'>{contents}</div>
            </List>
          );
        })}

      </CommentsWrap>
    );
  };
  
  const NoticeDetail = () => {
    return (
      <ContainerStyle type={currentBoard.type}>
        {/* 제목 */}
        <div className='title'>{currentBoard.title}</div>

        <div className='header'>
          {/* 날짜 */}
          <div className='createdTime'>{getTime(currentBoard.created)}</div>

          {/* image */}
          {currentBoard.attachments.length
          ? <PostImages images={currentBoard.attachments} /> 
          : null}
        </div>

        {/* 내용 */}
        <div className='content'>
          <UrlToLink text={currentBoard?.contents} />
        </div>

      </ContainerStyle>
    );
  }

  const QnaDetail = () => {
    return (
      <ContainerStyle type={currentBoard.type}>
        <div>
          {/* image */}
          {currentBoard.attachments.length
            ? <PostImages images={currentBoard.attachments} /> 
            : null}

          {/* 제목 */}
          <div className='title'>{currentBoard.title}</div>
        </div>

        <WriterWrap>
          <div className="writer">
            {/* 유저 이름 */}
            <div className="flex_row_center">
              <Avatar
                size="small"
                imgUrl={getImage(user?.representative_avatar?.image)}
              />
              <span className="writer_name">{user?.name}</span>
            </div>

            {/* 날짜 */}
            <span>{getTime(currentBoard.created)}</span>
          </div>

          <div className="remove_modify">
            <span onClick={() => confirmDeleteQna('qna')}>삭제</span>
            <span onClick={() => goToEdit(currentBoard)} className="modify_btn">수정</span>
          </div>
        </WriterWrap>

        <div style={{ overflowY: 'auto' }}>
          {/* 내용 */}
          <div className='content'>
            <UrlToLink text={currentBoard?.contents} />
          </div>

          {/* 댓글 */}
          {currentBoard.comments.length > 0 && <CommentList />}
        </div>
      </ContainerStyle>
    );
  }

  const SNoticeDetail = () => {
    return (
      <ContainerStyle type={currentBoard.type}>
        {/* 제목 */}
        <div className='title'>{currentBoard.title}</div>

        {/* 날짜 */}
        <div className='header'>
          <div className='createdTime'>{currentBoard.created ? getTime(currentBoard.created) : getTime(currentBoard.created_at)}</div>
        </div>

        {/* 내용 */}
        <div className='content' dangerouslySetInnerHTML={{ __html: currentBoard.contents }} />

      </ContainerStyle>
    );
  }
  
  return (
    <>
      {currentBoard.type === 'notice' ? <NoticeDetail /> : currentBoard.type === 'qna' ? <QnaDetail /> : <SNoticeDetail />} 
        
      {/* 댓글 작성란 */}
      {currentBoard.type === 'qna' && (
      <FooterComment 
        saveComment={saveComment}
          />
        )}
    </>
  );
};

export default React.memo(DetaliPresenter);

/** prop-types */
DetaliPresenter.propTypes = {
  currentBoard: PropTypes.oneOfType([PropTypes.object]),
  getTime: PropTypes.func,
  getImage: PropTypes.func,
  confirmDeleteQna: PropTypes.func,
  goToBoard: PropTypes.func,
  saveComment: PropTypes.func,
  scrollRef: PropTypes.oneOfType([PropTypes.object]),
  goToScrollBottom: PropTypes.func,
  goToCommentEdit: PropTypes.func,
  goToEdit: PropTypes.func,
};

DetaliPresenter.defaultProps = {
  currentBoard: {},
  getTime: {},
  getImage: {},
  confirmDeleteQna: {},
  goToBoard: {},
  saveComment: {},
  scrollRef: {},
  goToScrollBottom: {},
  goToCommentEdit: {},
  goToEdit: {},
};

/** css */
const ContainerStyle = styled.div`

    ${props => (
      props.type === 'notice' ? 
        `display: grid;
        grid-template-rows: auto auto 1fr;
        overflow: hidden;`
      :
      `display: grid;
        grid-template-rows: auto auto 1fr auto;
        overflow: hidden;`
    )}

  .title {
    color: ${theme.color.black500};
    font-weight: 500;
    font-size: 16px;
    // border-bottom: 0.5px solid #eee;
    padding: 8px 16px;
    min-height: 40px;
    white-space: pre-wrap;
    line-height: normal;
  }
  .header {
    border-bottom: 1px solid #ccc;
    box-shadow: 0 3px 3px -2px #ccc;
  }
  .createdTime {
    color: ${theme.color.black500};
    font-weight: normal;
    font-size: 14px;
    padding: 8px 16px;
    text-align: right;
    height: 36px;
  }
  .content {
    padding: 16px;
    line-height: 1.71;
    color: ${theme.color.black300};
    font-size: 14px;
    font-weight: normal;
    white-space: pre-wrap;
    overflow-y: auto;
    word-break: break-all;
  }
`;

const WriterWrap = styled.div`
  box-shadow: 0 3px 3px -2px #ccc;
  padding: 10px 16px;
  z-index: 1;

  .writer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};

    &_name {
      margin-left: 5px;
    }
  }
  .remove_modify {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${theme.color.black500};
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;

    .modify_btn {
      color: ${theme.color.main};
      margin-left: 16px;
    }
  }
`;

const CommentsWrap = styled.div`
  .cmt_header {
    font-size: 14px;
    font-weight: bold;
    color: ${theme.color.black300};
    background-color: #eee;
    height: 45px;
    padding: 16px;
    border-top: 1px solid ${theme.color.black500};
  }
  .cmt_list {
    font-weight: normal;
    font-size: 14px;
    color: ${theme.color.black500};
    .am-list-body {
      padding: 12px 16px;
    }

    &_name {
      margin-left: 5px;
    }
    &_date {
      margin-top: 8px;
    }
    &_content {
      margin-top: 8px;
      line-height: 1.71;
      white-space: pre-wrap;
      word-break: break-all;
    }

    .cmt_btn {
      right: 0;
      font-weight: 500;

      &_remove {
        color: ${theme.color.black500};
        margin-right: 16px;
      }
    }

`;