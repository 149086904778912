/* eslint-disable no-undef */
import axios from 'api/axios';
import { DELETE_TOKEN, SET_USERS, GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE, LOGOUT, userAccount, KEEP_ACCESS_TOKEN } from 'store/auth/types';

type setAcessTokenProps = { access_token: string; expires_in: number; token_type: string; user_account: userAccount; fcmToken: string | null };

export function setAuthHeaders(token: string) {
  axios.defaults.headers = {
    ...axios.defaults.headers,
    Authorization: `Bearer ${token}`,
  };
}

export const setUsers = (data: setAcessTokenProps) => ({ type: SET_USERS, payload: { data } });
export const deleteToken = () => ({ type: DELETE_TOKEN });
export const getUserAccount = () => ({ type: GET_ACCOUNT_REQUEST });
export const getUserAccountSuccess = (data: userAccount) => ({ type: GET_ACCOUNT_SUCCESS, payload: { data } });
export const getUserAccountFailure = () => ({ type: GET_ACCOUNT_FAILURE });
export const keepAccessToken = (data: string) => ({ type: KEEP_ACCESS_TOKEN, payload: { data } });
export const logout = () => ({ type: LOGOUT });

export type ActionRequest =
  | ReturnType<typeof setUsers>
  | ReturnType<typeof deleteToken>
  | ReturnType<typeof getUserAccount>
  | ReturnType<typeof getUserAccountSuccess>
  | ReturnType<typeof logout>
  | ReturnType<typeof getUserAccountFailure>;
