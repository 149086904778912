import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { AUTH_TYPES, LABEL, VALIDATE_MESSAGE , HEADER_TITLE } from 'constants/text';
import MainLayout from 'components/MainLayout';
import TextInput from 'components/TextInput';
import PlainButton from 'components/PlainButton';

import styled from 'styled-components';

type FindEmailPresenterProps = {
  onSubmit: () => void;
  mobile: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validNumber: boolean;
  history: () => void;
};

const FindEmailPresenter = ({ onSubmit, mobile, onChange, validNumber }: FindEmailPresenterProps) => {
  /** hook */
  const history = useHistory();
  return (
    <MainLayout header={{ title: HEADER_TITLE.findEmail, titleNormal: true, backFunc: () => history.push('/login') }} contentsGrid invisibleFooter>
      <section className="wrap-scroll" style={{ margin: '24px 16px', overflowY: 'auto' }}>
        <InfoTxt>
          <span>카카오톡 또는 문자 메시지로 이메일 정보를 보내드립니다.</span>
          <span>휴대폰 번호를 입력해주세요.</span>
        </InfoTxt>
        <TextInput
          id={AUTH_TYPES.mobile}
          name={AUTH_TYPES.mobile}
          value={mobile}
          type="number"
          label={LABEL.mobile}
          placeholder={LABEL.mobilePlaceholder}
          onChange={onChange}
          error={!validNumber}
          errorMessage={VALIDATE_MESSAGE.incorrectNumber}
          elseMessage={LABEL.mobileGuide}
        />
      </section>
      <BtmButton>
        <PlainButton color="primary" disabled={!(validNumber && mobile.length >= 1)} onClick={onSubmit}>
          <b style={{ fontSize: '18px' }}>이메일 찾기</b>
        </PlainButton>
      </BtmButton>
    </MainLayout>
  );
};

export default FindEmailPresenter;

FindEmailPresenter.propTypes = {
  history: PropTypes.func,
  onSubmit: PropTypes.func,
  mobile: PropTypes.string,
  onChange: PropTypes.func,
  validNumber: PropTypes.bool,
}

FindEmailPresenter.defaultProps = {
  history: () => {},
  onSubmit: {},
  mobile: '',
  onChange: {},
  validNumber: true,
};

export const InfoTxt = styled.div`
  span {
    ${props => props.theme.fonts.font14};
    display: block;
    &:last-child {
      margin: 10px 0 32px;
    }
  }
`;

export const BtmButton = styled.div`
  margin: 16px;

  button.mbsc-ios.mbsc-btn {
    width: 100%;
    display: inline-block;
    margin: 0;
  }
`;
