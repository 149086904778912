import React, { useContext } from 'react';
import styled from 'styled-components';
import List from 'antd-mobile/lib/list';
import { useSelector } from 'react-redux';
import Store from 'contexts/store/store';
import { RootState } from 'store';
import { theme } from 'assets/styles/theme';

type SettingPresenterType = {
  goToServiceCenter: () => void;
  logOut: () => void;
  goToSettings: (path: string) => void;
};


const SettingsPresenter = ({ goToServiceCenter, logOut, goToSettings }: SettingPresenterType) => {

  return (
    <Wrap>
      
      <List>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToSettings('push-setting')}>
          알림 설정
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={goToServiceCenter}>
          고객센터
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={logOut}>
          로그아웃
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToSettings('delete-account')}>
          회원 탈퇴
        </List.Item>
      </List>
    </Wrap>
  );
};

export const Wrap = styled.div`
  .am-list-item {
    padding-left: 24px;
    .am-list-line {
      padding: 16px 24px 16px 0;
      .am-list-content {
        color: ${theme.color.black300};
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .am-list-body::after {
    margin-left: 24px;
  }
`;

export const SettingList = styled.div`
  .change-user {
    background: #bbb;
    height: 50px;
  }

  .push {
    background: grey;
    height: 50px;
  }

  .service-center {
    background: #ccc;
    height: 50px;
  }

  .logOut {
    background: #eee;
    height: 50px;
  }
`;

export default SettingsPresenter;
