import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { theme } from 'assets/styles/theme';
import { HEADER_TITLE, LABEL } from 'constants/text';
import MainLayout from 'components/MainLayout';
import PlainButton from 'components/PlainButton';

const PushSetting = () => {
    /** state */
    const [loading, setLoading] = useState(false);

    /** hooks */
    const history = useHistory();

    /** func */
    const handlePush = useCallback(() => {
			if (window.ReactNativeWebView) {
				window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_SETTING' }));
			} else {
				alert('window.ReactNativeWebView.postMessage가 없습니다');
			}
    }, []);

    return (
      <MainLayout 
        header={{ title: HEADER_TITLE.pushSetting, titleNormal: true, backFunc: history.goBack }}
        loading={loading}
        footer>

        <Container>
          <div className='desc'>{LABEL.pushMessage}</div>

          <div className="mbsc-btn-group-block">
            <PlainButton outline onClick={handlePush}>{HEADER_TITLE.pushSetting}</PlainButton>
          </div>
        </Container>

      </MainLayout>
    );
};

const Container = styled.div`
    padding: 16px;
    .desc {
        lien-height: normal;
        color: ${theme.color.black300};
        font-size: 14px;
        font-weight: 500;
        margin: 40px 0;
    }
    
    button {
      font-size: 18px;
      font-weight: 500;
    }
`;

export default PushSetting;