import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import booking from 'api/modules/booking';
import { ERROR_TYPES, BOOKING_TYPE } from 'constants/text';
import { theme } from 'assets/styles/theme';
import {
  GET_BOOKING_COUNT_ALL_REQUEST,
  GET_BOOKING_LIST_ALL_REQUEST,
  GET_BOOKING_COUNT_REQUEST,
  GET_BOOKING_LIST_REQUEST,
  GET_BOOKING_DETAIL_REQUEST,
  PATCH_BOOKING_STATUS_REQUEST,
  CANCEL_BOOKING_REQUEST,
} from './types';

import {
  getBookingCountAllS,
  getBookingCountAllF,
  getBookingAllS,
  getBookingAllF,
  getBookingS,
  getBookingF,
  getBookingCountS,
  getBookingCountF,
  getBookingDetailS,
  getBookingDetailF,
  cancelBookingS,
  cancelBookingF,
  patchBookingS,
  patchBookingF,
  getCalendarMark,
} from './actions';

// type actionProps = {
//   type: string;
//   id: number;
// };

/** booking patch */

function* patchBookingSaga(action) {
  try {
    yield call(() => booking.patchBooking(action.id, action.payload));
    yield put(patchBookingS());
  } catch (err) {
    yield put(patchBookingF(err));
  }
}

function* watchPatchBooking() {
  yield takeLatest(PATCH_BOOKING_STATUS_REQUEST, patchBookingSaga);
}

/** booking cancel */
function* cancelBookingSaga(action) {
  try {
    yield call(() => booking.deleteBooking(action.payload));
    yield put(cancelBookingS());
  } catch (err) {
    yield put(cancelBookingF(err));
  }
}

function* watchCancelBooking() {
  yield takeLatest(CANCEL_BOOKING_REQUEST, cancelBookingSaga);
}

/** booking detail */
function* getBookingDetailSaga(action) {
  try {
    const result = yield call(() => booking.getBookingDetail(action.payload));
    yield put(getBookingDetailS(result.data));
  } catch {
    yield put(getBookingDetailF());
  }
}

function* watchGetBookingDetail() {
  yield takeLatest(GET_BOOKING_DETAIL_REQUEST, getBookingDetailSaga);
}

/** booking list */
// type getBookingProps = actionProps & {
//   payload: { last_page: number; limit: number; page: number; sortedBy: string; status: string };
//   date?: string | Date;
// };

function* getBookingListSaga(action) {
  try {
    const result = yield call(() => booking.getBookingsByUserTicketId(action.id, action.payload, action.date));
    yield put(getBookingS(result.data));

    const markDate = result.data.data.map(el => {

        switch (el.status) {
          case BOOKING_TYPE.booked:
          case BOOKING_TYPE.bookingConfirmed:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.blue };
          case BOOKING_TYPE.bookingWaiting:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.cyan };
          case BOOKING_TYPE.absence:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.orange };
          case BOOKING_TYPE.cancel:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.red };
          case BOOKING_TYPE.attendance:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.teal };
          case BOOKING_TYPE.noshow:
            return { d : dayjs(el.lecture.start_on).format('YYYY/MM/DD'), color: theme.tagColor.lightPurple };          
        }

      }
    );

    const filtered = markDate.filter((e, i, a) => a.findIndex(x => x.d === e.d && x.color === e.color) === i);

    /** todo: 날짜만 주는 api 생기면 따로 빼기 */
    // let markDate = result.data.data.map(({ lecture }) => dayjs(lecture.start_on).format('YYYY/MM/DD'));
    // markDate = [...new Set(markDate)].map(date => ({ d: date, color: '#007bff' }));

    yield put(getCalendarMark(filtered));
  } catch (err) {
    yield put(getBookingF());
  }
}

function* watchGetBookingList() {
  yield takeLatest(GET_BOOKING_LIST_REQUEST, getBookingListSaga);
}

/** booking count */
function* getBookingCountSaga(action) {
  try {
    const result = yield call(() => booking.getBookingCount(action.payload));
    yield put(getBookingCountS(result.data));
  } catch (err) {
    yield put(getBookingCountF());
  }
}

function* watchGetBookingCount() {
  yield takeLatest(GET_BOOKING_COUNT_REQUEST, getBookingCountSaga);
}

/** mypage booking count */
function* getBookingCountAllSaga() {
  try {
    const result = yield call(() => booking.getBookingCountAll());
    yield put(getBookingCountAllS(result.data));
  } catch (err) {
    yield put(getBookingCountAllF());
  }
}

function* watchGetBookingCountAll() {
  yield takeLatest(GET_BOOKING_COUNT_ALL_REQUEST, getBookingCountAllSaga);
}

/** mypage booking list */
function* getBookingListAllSaga(action) {
  try {
    const result = yield call(() => booking.getBookingListAll(action.payload));
    yield put(getBookingAllS(result.data));
  } catch (err) {
    yield put(getBookingAllF());
  }
}

function* watchGetBookingListAll() {
  yield takeLatest(GET_BOOKING_LIST_ALL_REQUEST, getBookingListAllSaga);
}

/** root */
export default function* usageSaga() {
  yield all([fork(watchGetBookingCount), fork(watchGetBookingList), fork(watchGetBookingDetail), fork(watchCancelBooking), fork(watchPatchBooking), fork(watchGetBookingCountAll), fork(watchGetBookingListAll)]);
}
