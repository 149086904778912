import React, { useState, useContext, useCallback, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserAccount } from 'store/auth/actions';
import styled from 'styled-components';

import useInput from 'hooks/useInput';
import useValid from 'hooks/useValid';
import Store from 'contexts/store/store';
import { theme } from 'assets/styles/theme';
import FooterButtons from 'components/Footer/FooterButtons';
import MainLayout from 'components/MainLayout';
import TextInput from 'components/TextInput';

import { HEADER_TITLE, ERROR_TYPES, AUTH_TYPES, LABEL, VALIDATE_MESSAGE, SUCCESS_MESSAGE } from 'constants/text';

const ChangeEmail = () => {
  /** hooks */
  const history = useHistory();
  const dispatch = useDispatch();
  const { apis } = useContext(Store);
  const EmailRef = useRef<HTMLInputElement>();

  /** state */
  const [email, setEmail] = useInput({
    changeEmail: '',
    confirmChangeEmail: ''
  });

  const [valid, setValid] = useValid({
    changeEmail: true,
    confirmChangeEmail: email.changeEmail ? email.changeEmail : true
  });

  const [loading, setLoading] = useState(false);

  /** func */

  useEffect(() => {
    if (EmailRef.current) {
      EmailRef.current.focus();
    }
  }, []);

  const onChange = useCallback(
    e => {
      setValid(e);
      setEmail(e);
    },
    [setEmail, setValid],
  );

  const onSubmit = useCallback(async () => {

    // 이메일 중복 확인
    try {
      const cnt = await apis.auth.checkDuplicationEmail({
        value: email.changeEmail
      })
      if (cnt.data.count > 0) {
        alert({ message: ERROR_TYPES.alreadyHasEmail });
      } else {
        
        // 이메일 변경
        try {
          setLoading(true);
          const res = await apis.auth.changeAccount({
            user_account: {
              email: email.changeEmail,
            },
          });
          if (res.status === 200) {
            dispatch(getUserAccount());
            setLoading(false);
            return history.push({
              pathname: '/success',
              state: { ...SUCCESS_MESSAGE.changeEmail, routePath: '/mypage/edit' },
            });
          }
        } catch (error) {
          if (error?.response?.data?.status_code === 422) {
            alert({ message: ERROR_TYPES.alreadyHasEmail });
          }
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
    }

  }, [apis.auth, email.changeEmail, history, dispatch]);

  return (
    <MainLayout header={{ title: HEADER_TITLE.changeEmail, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>
      <Wrap>
        <div className="desc">
          변경할 이메일을 입력해 주세요.
        </div>
        <div style={{marginBottom: '40px'}}>
          <TextInput
            id={AUTH_TYPES.changeEmail}
            name={AUTH_TYPES.changeEmail}
            value={email.changeEmail}
            onChange={onChange}
            type="email"
            refs={EmailRef}
            label={LABEL.changeEmail}
            placeholder={LABEL.emailPlaceholder}
            error={!valid.changeEmail}
            errorMessage={VALIDATE_MESSAGE.incorrectEmail}
            elseMessage={valid.changeEmail && !email.changeEmail ? VALIDATE_MESSAGE.changeEmail : ''}
          />
        </div>
        <TextInput
          id={AUTH_TYPES.confirmChangeEmail}
          name={AUTH_TYPES.confirmChangeEmail}
          value={email.confirmChangeEmail}
          onChange={onChange}
          type="email"
          label={LABEL.confirmChangeEmail}
          placeholder={LABEL.emailPlaceholder}
          error={!valid.confirmChangeEmail}
          errorMessage={VALIDATE_MESSAGE.incorrectConfirmChangeEmail}
          elseMessage={valid.confirmChangeEmail && !email.confirmChangeEmail ? VALIDATE_MESSAGE.confirmChangeEmail : ''}
        />
      </Wrap>

      <FooterButtons 
        cancelClick={() => history.goBack()} 
        confirmClick={onSubmit} 
        confirmText="이메일 변경"
        disabled={!valid.changeEmail || !valid.confirmChangeEmail || !email.confirmChangeEmail || (email.changeEmail !== email.confirmChangeEmail)}
      />
      {/* <PlainButton color="primary" onClick={onSubmit} disabled={!(valid.changeEmail && email.changeEmail && !loading)}>
        변경
      </PlainButton> */}
    </MainLayout>
  );
};

const Wrap = styled.div`
  padding: 0 16px;

  input {
    color: ${theme.color.black300};
    font-size: 16px;
    font-weight: normal;
  }
  .desc {
    margin: 40px 0;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.black300};
  }

`;

export default ChangeEmail;
