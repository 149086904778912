import React, { useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { AUTH_TYPES, VALIDATE_MESSAGE, LABEL, PLACEHOLDER } from 'constants/text';
import TextInput from 'components/TextInput';
import PlainButton from 'components/PlainButton';
import { ReactComponent as LogoImg } from 'assets/icons/image-only-logo.svg';
import { useSelector, shallowEqual } from 'react-redux';
import localStorage from 'utils/localStorage';
import { RootState } from 'store';

type IdProps = {
  loginEmail: string | number;
  password: string;
};

type LoginProps = IdProps & {
  valid: IdProps;
  loading: boolean;
  onChange: (e: any) => Promise<void>;
  onSubmit: (e: any) => Promise<void>;
};

function Login({ loginEmail, password, valid, loading, onChange, onSubmit }: LoginProps) {
  // const history = useHistory();
  // const WingBlank = (window as { [key: string]: any })["antd-mobile"] as string;
  const wrapRef = useRef<any>();
  
  const handleInputFocus = () => {    
    setTimeout(() => {
      if (wrapRef?.current) {
        wrapRef.current.scrollTop = wrapRef?.current?.scrollHeight;
      }
    }, 200);
  };

  return (
    <PageWrap ref={wrapRef}>
      <div className="top_contents">
        <div className="logo">
          <LogoImg />
        </div>

        <div className="intro">
          <div className="intro-title">
            안녕하세요.<br />
            스튜디오메이트 입니다.
          </div>
          <span className="intro-desc">회원 서비스 이용을 위해 로그인해주세요.</span>
        </div>
      </div>
        
      <div className='up-grid'>
        <div>
          {/* 휴대폰 번호 및 이메일 */}
          <div className='form-block'>
            <TextInput
              id={AUTH_TYPES.loginEmail}
              name={AUTH_TYPES.loginEmail}
              value={loginEmail}
              placeholder={LABEL.mobileOrEmail}
              onChange={onChange}
              error={!valid.loginEmail}
              errorMessage={VALIDATE_MESSAGE.incorrectEmailOrMobile}
              onFocus={handleInputFocus}
          />
          </div>

          {/* 비밀번호 */}
          <div className='form-block'>
            <TextInput
              id={AUTH_TYPES.password}
              name={AUTH_TYPES.password}
              type={AUTH_TYPES.password}
              value={password}
              placeholder={PLACEHOLDER.passwordGuide}
              onChange={onChange}
              error={!valid.password}
              errorMessage={VALIDATE_MESSAGE.noSpecialChar}
              onFocus={handleInputFocus}
              // tempMessage={VALIDATE_MESSAGE.noSpecialChar}
            />
          </div>

        </div>

        {/* 로그인 버튼 */}
        <div className="mbsc-btn-group-block disabledLogin">
          <PlainButton
            loading={loading}
            onClick={onSubmit}
            color="primary"
            disabled={!(valid.loginEmail && valid.password && loginEmail && password)}>
            <b style={{ fontSize: '18px' }}>로그인</b>
          </PlainButton>
        </div>

        <div className="help">
          {/* 이메일 찾기 / 비밀번호 찾기 / 회원가입 */}
          <span><Link to="/find-email">이메일 찾기</Link></span>
          <div className="vertical-line" />
          <span className="find-password"><Link to="/reset-password">비밀번호 찾기</Link></span>
          <div className="vertical-line" />
          <span><Link to="/signup">회원가입</Link></span>
        </div>
      </div>

    </PageWrap>
  );
}

export default React.memo(Login);

/** css */
export const greyFont = css`
  ${props => props.theme.fonts.font14};
  color: ${props => props.theme.color.sub};
`;

export const PageWrap = styled.div`
  height: 100vh;
  padding: 20% 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .up-grid {
    margin-top: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .help {
      display: flex;
      justify-content: space-around;
      align-items: center;
      span a {
        ${greyFont};
      }
      .vertical-line {
        border-left: 1px solid ${props => props.theme.color.greyCCC};
        height: 20px;
      }
    }
  }
  .intro-title {
    margin-bottom: 8px;
    line-height: 1.6;
  }
  .intro-desc {
    ${greyFont};
  }

  .form-block {
    margin: 10px 0 30px 0;
  }
  
  .logo {
    margin-bottom: 48px;
  }
`;

