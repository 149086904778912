import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'assets/styles/theme';

import PropTypes from 'prop-types';

const UserTicketCard = ({ tickets, onClick }) => {
  const isFamilyTicket = tickets.family
  const isTutorialTicket = tickets.tutorial
  const ticketType = tickets.classType === '그룹' ? 'group' : 'private'
  const haveCustomColor = tickets.ticketColor.length ? 'custom-color' : ''
  const colorCodes = [('#'.concat(tickets.ticketColor[0])),('#'.concat(tickets.ticketColor[1]))]
  const checkHolding = tickets.active === false || tickets.ticketStatus === '이용만료'
  const isActive = tickets.active;
  const inActiveReason = tickets.inactive_reason;
  const isPaused = tickets.ticketStatus === '정지중'

  return (
    <WrapTicket 
      isCustomColor={tickets.ticketColor.length}
      checkHolding={checkHolding}
      colorSets={colorCodes}
      isPrivateTicket={ticketType !== 'group'}
      className={`${ticketType} ${haveCustomColor} ${checkHolding ? 'holding' : ''} ${isPaused ? 'paused' : ''}`}
      role="button" 
      tabIndex={0} 
      onClick={() => onClick(tickets)}
      isActive={isActive}
      inActiveReason={inActiveReason}
      isPaused={isPaused}>

      <p className="text-state">{tickets.ticketStatus}</p> {/* 사용중 */}

      <TopSection limitType={tickets.limitType}>
        <span>{tickets.ticketType}</span> {/* 횟수제/기간제 */}
        <span>{tickets.classType}형</span> {/* 그룹/프라이빗 */}
        <span>{tickets.maxTrainee}</span> {/* 10:1 */}
        {tickets.limitType !== '' ? <span>{tickets.limitType}</span> : null} {/* 월 10회 */}
      </TopSection>

      <NameSection>
        <p className="title">{tickets.title}</p> {/* 수강권 이름 */}
        <p>
          {isFamilyTicket && <span className="tag">{tickets.family}</span>}
          {isTutorialTicket && <span className="tag">{tickets.tutorial}</span>}
        </p>
      </NameSection>

      {tickets.active === true && tickets.ticketStatus !== '이용만료' ? (
        <PeriodSection>
          {tickets.period} {/* 2019.03.01 ~ 2020.09.01 */}
        </PeriodSection>
      )
      :
      null}

      {
        tickets.active === true && tickets.ticketStatus !== '이용만료' ? 
          (
            <InfoSection>
              {tickets.usableCoupon !== null ? <span>{tickets.usableCoupon}</span> : null} {/* 예약가능 */}
              {tickets.remainingCancel !== null ? <span>{tickets.remainingCancel}</span> : null} {/* 취소가능 */}
              {tickets.remainingCoupon !== null ? <span>{tickets.remainingCoupon}</span> : null} {/* 잔여 10회 */}
              {tickets.dailyBookingChangeLimit !== null ? <span>{tickets.dailyBookingChangeLimit}</span> : null} {/* 당일변경 */}
            </InfoSection>
          ) 
          : (
            <InfoSection>
              {tickets.inActiveReason !== null ? <span>{tickets.inactive_reason}</span> : null} {/* 사용불가 사유 */}
            </InfoSection>
        )}

    </WrapTicket>
  );
};

const flexStyle = css`
${props => props.theme.flexBox.displayFlex};
${props => props.theme.flexBox.flexDirection('row')};
${props => props.theme.flexBox.flexJustify('space-between')};
${props => props.theme.flexBox.flexAlign('center')};
`;

const WrapTicket = styled.div`
  position: relative;
  border-radius: 16px;
  padding: 12px;
  width: 100%;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-rows: 20px 80px 25px 1fr;
  color: #fff;
  min-height: 168px;
  max-width: 328px;
  margin: 0 auto;

  &.group {
    // background-image: linear-gradient(122deg, rgba(178,134,240, 0), rgba(178,134,240, 0.7)),
    background-image: linear-gradient(122deg, 
      ${theme.color.deepSkyBlue}, 
      ${theme.color.lightPurple}
    )
  }
  &.private {
    // background-image: linear-gradient(to bottom, rgba(255,153,185, 0), rgba(255,153,185, 0.7)),
      background-image: linear-gradient(to bottom, 
        ${theme.color.lightPurple}, 
        ${theme.color.pink}
      )
  }
  &.custom-color {
    background-image: linear-gradient(to bottom,
      ${props => {
        if (props.isCustomColor) {
          return props.colorSets[0];
        }
      }},
      ${props => {
        if (props.isCustomColor) {
          return props.colorSets[1];
        }
      }}
    )
  }
  &.holding {
    // background-image: linear-gradient(122deg, rgba(000,000,000, 0.3), rgba(000,000,000, 0.7)),
    background-image: linear-gradient(to bottom, ${theme.color.black4d}, ${theme.color.black000});
  }
  &.paused {
    background-image: linear-gradient(to bottom, rgba(136, 136, 136, 0.4), #888888);
  }
  .text-state {
    position:absolute;
    right: 12px;
    top: 0px;
    background: #fff;
    border-radius: 9px;
    color: ${props => (props.checkHolding || props.isPaused ? theme.color.black300 : (props.isCustomColor ? props.colorSets[1] : (props.isPrivateTicket? theme.color.lightPurple: theme.color.blue)))};
    width: 55px;
    text-align:center;
    ${theme.fonts.font12};
    line-height:1.5
  }
`;

const TopSection = styled.div`
  ${theme.fonts.font14};
  span::after {
    content: ' · ';
  }
  span:last-child::after {
    content: ''
  }
  @media (max-width: 320px) {
    letter-spacing: ${props => props.limitType !== '' ? '-0.13rem' : ''};
  }
`;

const NameSection = styled.div`
/* ${flexStyle} */
  display: grid;
  grid-template-columns: 1fr 50px;
  .title {
    font-size: 18px;
    font-weight: bold;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    max-height: 41px;
    word-break: break-all;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .tag {
    display:block;
    border-radius: 2px;
    border: 1px solid #fff;
    height: 16px;
    line-height: 1.3;
    ${theme.fonts.font10};
    text-align:center;
    margin-bottom: 4px;
  }
`;

const PeriodSection = styled.div`
  ${theme.fonts.font14};
  letter-spacing: -0.06rem;
`;

const InfoSection = styled.div`
  ${theme.fonts.font14};
  letter-spacing: -0.2rem;
  span::after {
    content: ' · ';
  }
  span:last-child::after {
    content: ''
  }
`;


export default UserTicketCard;

UserTicketCard.propTypes = {
  tickets: PropTypes.oneOfType([PropTypes.object]),
  onClick: PropTypes.func,
};

UserTicketCard.defaultProps = {
  tickets: {},
  onClick: () => {},
};
