import React, { useState, useContext, useCallback } from 'react';
import _ from 'lodash-es';
import { useHistory } from 'react-router-dom';
import Store from 'contexts/store/store';

import MainLayout from 'components/MainLayout';
import StepOnePresenter from 'pages/FindPassword/StepOnePresenter';
import StepTwoPresenter from 'pages/FindPassword/StepTwoPresenter';
import { AUTH_TYPES, ERROR_TYPES, SUCCESS_MESSAGE, HEADER_TITLE } from 'constants/text';
import { passwordProps, textProps, validType } from './types';

const FindPasswordContainer = () => {
  /** hook */
  const history = useHistory();
  const { apis, utils } = useContext(Store);

  /** state */
  const [passwordData, setPasswordData] = useState<passwordProps>({
    validation_id: null,
    validation_code: null,
    user_account: {
      password: null,
    },
    user_id: null,
    step: 1,
  });
  const [text, setText] = useState<textProps>({
    smsValid: false,
    email: '',
    mobile: '',
    validateCode: '',
    isClickValidButton: false,
    password: '',
    confirmPassword: '',
  });
  const { email, mobile, validateCode, isClickValidButton, smsValid } = text;
  const [valid, validate] = useState<validType>({
    email: true,
    mobile: true,
    validateCode: false,
    password: true,
    confirmPassword: true,
  });
  const [loading, setLoading] = useState<boolean>(false);

  /** func */
  const confirmSmsCode = async () => {
    try {
      const res = await apis.auth.validateSmSCode(passwordData.validation_id, validateCode);

      if (res.data.result) {
        alert({
          message: '인증번호가 확인되었습니다.',
          callback: () =>
            setText({
              ...text,
              smsValid: true,
            }),
        });
      } else {
        alert({
          message: '인증번호를 다시 확인해주세요.',
        });
      }
    } catch (err) {
      alert({
        message: '인증번호가 확인되지 않습니다.',
      });
    }
  };

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | { target: { value: string; name: string } }) => {
      const { value, name } = e.target;
      let isValid = true;

      if (name === AUTH_TYPES.email) {
        isValid = utils.validate.email(value);
      } else if (name === AUTH_TYPES.password) {
        isValid = utils.validate.password(value);
      } else if (name === AUTH_TYPES.mobile) {
        isValid = value.length > 9;
      }

      validate(inputs => ({
        ...inputs,
        [name]: isValid,
      }));

      setText(inputs => ({
        ...inputs,
        [name]: value,
      }));
    },
    [utils.validate],
  );

  const onSubmit = async () => {
    if (mobile && email && valid.email && valid.mobile) {
      try {
        setLoading(true);
        const loginRes = await apis.auth.getValidateCode({ email, mobile });
        if (loginRes.status === 200) {
          await setPasswordData({
            ...passwordData,
            user_id: loginRes.data.user_account_id,
            validation_id: loginRes.data.validation_id,
          });
          setText({ ...text, isClickValidButton: true });
          alert({ message: '인증 번호가 발송되었습니다.' });
        }
      } catch {
        alert({ message: ERROR_TYPES.noAccount });
      } finally {
        setLoading(false);
      }
    }
  };

  const changePassword = async () => {
    const params = {
      ...passwordData,
      user_account: {
        password: text.confirmPassword,
      },
    };
    try {
      setLoading(true);
      const res = await apis.auth.findPassword(passwordData.user_id, _.omit(params, ['step', 'user_id']));
      setLoading(false);
      if (res.status === 200) {
        history.push({
          pathname: '/notlogin-success',
          state: SUCCESS_MESSAGE.findPassword,
        });
      }
    } catch (err) {
      alert({
        message: err?.response?.data.message,
        callback: () => setLoading(false),
      });
    }
  };

  const backFunc = () => {
    if (passwordData.step !== 1) {
      setPasswordData({ ...passwordData, step: 1 });
    } else {
      return history.goBack();
    }
  };

  const goToNextStep = () => {
    setPasswordData({ ...passwordData, step: 2, validation_code: validateCode });
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.findPassword, backFunc, titleNormal: true }} loading={loading} invisibleFooter scroll>
      {passwordData.step === 1 ? (
        <StepOnePresenter
          goToNextStep={goToNextStep}
          email={email}
          mobile={mobile}
          validateCode={validateCode}
          valid={valid}
          isClickValidButton={isClickValidButton}
          onChange={onChange}
          onSubmit={onSubmit}
          confirmSmsCode={confirmSmsCode}
          smsValid={smsValid}
        />
      ) : (
        <StepTwoPresenter text={text} onChange={onChange} valid={valid} changePassword={changePassword} />
      )}
    </MainLayout>
  );
};

export default FindPasswordContainer;
