import React, { useMemo } from 'react';

const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})\b/;

const UrlToLink = ({ text }: any) => {
  const linkTo = (url: string) => {
    if (window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_URL', url: url[0] !== 'h' ? `https://${url}` : url }));
  };

  const splitText = useMemo(() => (!text ? null : text.split(URL_REGEX)), [text]);

  return (
    <>
      {!splitText && ''}
      {splitText &&
        splitText.map((content: any, idx: number) => {
          return content.includes('http') || content.includes('www') ? (
            <a key={idx} onClick={() => linkTo(content)}>
              {content}
            </a>
          ) : (
            content
          );
        })}
    </>
  );
};

export default UrlToLink;
