import moment from 'moment';
import ping from 'api/modules/ping.ts';

export default {
  async getServetTime() {
    const serverTime = await ping?.getTime();
    if ( serverTime ) {
      const diffTime = moment.duration(moment().diff(moment(serverTime.data.timestamp.date))).asSeconds();
      if (diffTime < 60) {
        localStorage.setItem('diff-time', 0);
      } else {
        localStorage.setItem('diff-time', Math.floor(diffTime));
      }
    }
  },

  localTime(value) {
    const localTime = localStorage.getItem('diff-time');
    return moment(value).add(localTime, 'm').format('YYYY-MM-DD HH:mm:ss');
  },

  getTimeStampString(value) {
    const localTimes = moment(this.localTime(value), 'YYYY-MM-DD HH:mm:ss');
    const standardTime = moment.duration(moment().diff(localTimes)).asSeconds();

    let time = '';
    if (standardTime < 60) {
      time = '지금';
    } else if (standardTime >= 60 && standardTime < 3600) {
      time = `${Math.floor(standardTime / 60)}분 전`;
    } else if (standardTime >= 3600 && standardTime < 86400) {
      time = `${Math.floor(standardTime / 3600)}시간 전`;
    } else if (standardTime >= 86400 && standardTime < 604800) {
      time = `${Math.floor(standardTime / 86400)}일 전`;
    } else {
      time = localTimes.format('MMMM Do');
    }
    return time;
  },

  getFullDateTime(value) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY. M. D. (ddd) HH:mm');
  },

  getHyperDate(value) {
    return moment(value).format('YYYY-MM-DD');
  },

  dateTime(value) {
    return value ? moment(value).format('YYYY. M. D. HH:mm') : null;
  },
};
