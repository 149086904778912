import React, { useRef, useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import styled from 'styled-components';

import { ReactComponent as Left } from 'assets/icons/icon_circle_arrow_left_23.svg';
import { ReactComponent as Right } from 'assets/icons/icon_circle_arrow_rignt_23.svg';

const HeaderSwiper = ({ studios, setStudio, setShow, history, tabIndex, setTabIndex, currentStudioData }) => {
  /** state */
  const ref = useRef(null);
  const [rebuild, setRebuild] = useState(false);

  /** func */
  useEffect(() => {
    setRebuild(true);

    // 현재 저장된 스튜디오 유 & 스튜디오 데이터 유
    if (currentStudioData?.studio?.id !== 0 && studios.length !== 0) {
      setStudio(studios[tabIndex]);
      if (studios.length === 1) {
        setTabIndex(0);
        setStudio(studios[0]);
      }
    }
  }, [studios]);

  const params = {
    rebuildOnUpdate: rebuild,
    observer: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    renderPrevButton: () => <Left className="swiper-button-prev" />,
    renderNextButton: () => <Right className="swiper-button-next" />,
    on: {
      slideChangeTransitionStart: () => {
        setRebuild(false);

        if (ref.current) {
          const idx = ref.current.swiper.realIndex;
          setStudio(studios[idx]);
          setTabIndex(idx);
        }
      },
    },
    initialSlide: tabIndex,
  };

  const goToStudioInfo = () => {
    setShow(true);
    history.push(`/select-ticket/#`);
  };

  return (
    <Container length={studios?.length}>
      <Swiper {...params} ref={ref}>
        {studios.map(studio => {
          return (
            <div key={studio.id} onClick={goToStudioInfo} role="button" tabIndex={0}>
              {studio.name}
            </div>
          );
        })}
      </Swiper>
    </Container>
  );
};

const Container = styled.div`
  overflow: hidden;
  line-height: 2;

  .swiper-container {
    width: 100%;
    text-align: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 18px;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }

  ${props => {
    if (props.length > 1) {
      return `
      .swiper-slide {
        padding: 0 30px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; 
      }
      `;
    }
  }}
`;

export default HeaderSwiper;
