import axios from '../axios';

const BASE_URL = '/v2/member/board';
const S_BASE_URL = '/v2/anonymous/notice';

type getNotiType = {
  page: number;
  limit: number;
  all: number;
  type: string;
  studio_id: number;
  title: number | string;
};

type getNotiRes = {
  data: {
    attachments: { id: number }[];
    contents: string;
    created_at: string;
    id: number;
    is_comment: number;
    is_notice: number;
    title: string;
    updated_at: string;
    type: string;
    search: any;
  }[];
  meta: { current_page: number; last_page: number; total: number };
};

export default {
  // 공지사항 & 1:1문의
  getNoti: (params: getNotiType) => axios.get<getNotiRes>(BASE_URL, { params }),

  // 스튜디오메이트 공지
  getSNoti: (params: getNotiType) => axios.get<getNotiRes>(S_BASE_URL, { params }),

  // 문의 생성
  setQna: (data: any) => axios.post(BASE_URL, data),

  // 문의 삭제
  deleteQna: (id: number) => axios.delete(`${BASE_URL}/${id}`),

  // 문의 수정
  patchQna: (id: number, data: any) => axios.patch(`${BASE_URL}/${id}`, data),

  // 댓글 추가
  setComment: (data: any) => axios.post('/v2/member/comment', data),

  // 댓글 삭제
  deleteComment: (id: number) => axios.delete(`/v2/member/comment/${id}`),

  // 댓글 수정
  patchComment: (id: number, data: any) => axios.patch(`/v2/member/comment/${id}`, data),

  // 사진 업로드
  upload: (formData: any) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return axios.post('/v2/member/attachment', formData, {
      headers,
    });
  },
};
