/** 사가 이펙트 */
import { all, fork } from 'redux-saga/effects';

import studioSaga from 'store/studio/sagas';
import authSaga from 'store/auth/sagas';
import boardSaga from 'store/board/sagas';
import usageSaga from 'store/usage/sagas';
import bookingSaga from 'store/booking/sagas';

export default function* rootSaga() {
  yield all([
    fork(studioSaga), 
    fork(authSaga), 
    fork(boardSaga), 
    fork(usageSaga), 
    fork(bookingSaga)
  ]);
}
