import React from 'react';
import styled from 'styled-components';
import List from 'antd-mobile/lib/list';
import { useHistory } from 'react-router-dom';

import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';
import { theme } from 'assets/styles/theme';

const ServiceContainer = () => {

  // IOS 14.6 버젼에 mailto 버그로 인한 임시 가이드메세지.
  const MAIL_TO = 'mailto:support@studiomate.kr?body=문의내용 작성 시 성함과 휴대폰 번호를 함께 기재해 주시면 정확한 답변이 가능합니다.';
  // const MAIL_TO = 'mailto:support@studiomate.kr?body=빠른답변을 위해 필요한 정보입니다. 정확하게 입력해 주시기 바랍니다. \n \n 1. 시설명: \n 2. 회원명: \n 3. 휴대폰 번호: \n 4. 어플 로그인용 이메일: \n 5. 문의 내용: \n \n 서비스 오류관련 문의 시 아래 항목도 기입 해 주세요. \n \n * 휴대폰 모델명: \n * 소프트웨어버전:';

  /** hooks */
  const history = useHistory();

  const mailTo = () => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_URL', url: MAIL_TO }));
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.serviceCenter, titleNormal: true, backFunc: history.goBack }} contentsGrid>

      <StyledList>
        <List>
          <List.Item 
            arrow="horizontal" 
            multipleLine 
            onClick={mailTo}  
          >
            이메일 문의하기
          </List.Item>
          <List.Item arrow="horizontal" multipleLine onClick={() => history.push('/settings/service-center/term-of-service')}>
            이용약관
          </List.Item>
          <List.Item arrow="horizontal" multipleLine onClick={() => history.push('/settings/service-center/privacy-policy')}>
            개인정보처리방침
          </List.Item>
          {/* <List.Item arrow="horizontal" multipleLine>
            마케팅 활용 동의
          </List.Item> */}
          {/* <List.Item arrow="horizontal" multipleLine>
            오픈소스 라이센스
          </List.Item> */}
        </List>
      </StyledList>
    </MainLayout>
  );
};

const StyledList = styled.div`
  .am-list-item {
    min-height: 57px;
    padding-left: 24px;
  }
  .am-list-item .am-list-line .am-list-content, .am-list-content a {
    color: ${theme.color.black300};
    font-weight: 500;
    font-size: 16px;
  }
  .am-list-body::after {
    margin-left: 24px;
  }
`;


export default ServiceContainer;
