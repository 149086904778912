/** 로그인 한 유저가 가진 티켓 & 스튜디오 지보(티켓 내부에 스튜디오 정보 있음) */
import produce from 'immer';
import dayjs from 'dayjs';
import filter from 'utils/filters';
import getDaysDiff from 'utils/getDaysDiff';
import getRemainingDaysText from 'utils/getRemainingDaysText';
import {
  GET_USER_TICKET_REQUEST,
  GET_USER_TICKET_SUCCESS,
  GET_USER_TICKET_FAILURE,
  GET_USER_STUDIO_REQUEST,
  GET_USER_STUDIO_SUCCESS,
  GET_USER_STUDIO_FAILURE,
  SET_STUDIO,
  SET_TICKET,
  SET_SELECT_TAB_INDEX,
  SET_SELECT_CURRENT_IDX,
  SET_SELECT_DETAIL_IDX,
  actionProps,
  studioProps,
  ticketRawType,
  lockerProps,
  stateProps,
  ticketProps,
} from './types';

export const getUserTicket = () => ({ type: GET_USER_TICKET_REQUEST });

export const getUserStudio = () => ({ type: GET_USER_STUDIO_REQUEST });

export const setStudio = (studio: studioProps) => ({ type: SET_STUDIO, studio });

export const setTicket = (ticket: ticketProps, ticketRaw: ticketRawType) => ({ type: SET_TICKET, ticket, ticketRaw });

export const setTabIndex = (payload: number) => ({ type: SET_SELECT_TAB_INDEX, payload });

export const setCurrentIdx = (payload: number) => ({ type: SET_SELECT_CURRENT_IDX, payload });

export const setDetailIdx = (payload: number) => ({ type: SET_SELECT_DETAIL_IDX, payload });

export const initalState: stateProps = {
  data: [],
  loading: false,
  tabIndex: 0,
  currentIdx: 0,
  detailIdx: 0,
  studios: [],
  currentStudioData: {
    studio: {
      id: 0,
      locker: [],
      name: '',
      address: { id: 0, address: '', detail_address: '' },
      policy: {
        group_booking_limit_date: '',
        group_booking_limit_day_term: 0,
        group_booking_limit_day_term_time: '',
        private_booking_limit_date: '',
        private_booking_limit_day_term: 0,
        private_booking_limit_day_term_time: '',
        private_booking_deadline: 0,
        is_visible_all_lectures: true,
        is_locker: 0,
        private_start_interval: 30,
        weekly_waiting_limit: 0,
      },
      contactInfos: [{ contact: '0', is_representative: true }],
      holidays: [
        {
          id: 0,
          studio_id: 0,
          date: '',
          not_action_auto_balance: 0,
          color: '',
          reason: '',
          studio_user_id: 0,
          created_at: '',
          deleted_at: '',
        },
      ],
    },
    ticket: { id: 0, period: '' },

    /* ticketRawType */
    ticketRaw: {
      id: 0,
      status: '',
      active: true,
      inactive_reason: null,
      availability_start_at: '',
      change_daily_booking_count: 0,
      booking_limit_per_month: 0,
      booking_limit_per_week: 0,
      expire_at: '',
      is_holding: false,
      now_holding: null,
      holdings: null,
      is_shared: 0,
      remaining_cancel: 0,
      remaining_coupon: 0,
      max_coupon: 0,
      usable_coupon: 0,
      ticket_usable: true,
      member: {
        lockers: [],
      },

      /* ticketProps */
      ticket: {
        id: 0,
        active: true,
        class_period: 0,
        available_class_type: 'G',
        colors: [],
        title: '',
        type: 'P',
        use_weekly_auto_coupon_balance: 0,
        max_trainee: 0,
        is_show_cancel_count: false,
        is_ignore_new_payment: false,
        daily_booking_change_limit: 0,
        period: '',
        ticketStatus: '',
        classType: '',
      },
      staffs: [],
    },
  },
};

export const studio = (state: stateProps = initalState, action: actionProps) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_SELECT_TAB_INDEX:
        draft.tabIndex = action.payload;
        break;
      case SET_SELECT_CURRENT_IDX:
        draft.currentIdx = action.payload;
        break;
      case SET_SELECT_DETAIL_IDX:
        draft.detailIdx = action.payload;
        break;
      case GET_USER_TICKET_REQUEST:
      case GET_USER_STUDIO_REQUEST:
        draft.loading = true;
        break;
      case GET_USER_TICKET_SUCCESS:
        action.data.forEach((data: { ticket: ticketRawType[]; ticketCardData: any; studio: { locker: lockerProps[] } }) => {
          let locker: any = [];

          const res = data.ticket.map((userTicket, index) => {
            if (index === data.ticket.length - 1) {
              locker = userTicket.member.lockers;
            }

            const {
              ticket,
              usable_coupon,
              remaining_cancel,
              remaining_coupon,
              change_daily_booking_count,
              is_holding,
              expire_at,
              availability_start_at,
              booking_limit_per_month,
              booking_limit_per_week,
              is_shared,
              active,
              inactive_reason,
              status,
              ticket_usable,
            } = userTicket;

            const {
              available_class_type,
              title,
              type,
              use_weekly_auto_coupon_balance,
              max_trainee,
              is_show_cancel_count,
              is_ignore_new_payment,
              colors,
            } = userTicket.ticket;

            let ticketStatus = '';
            if (availability_start_at && expire_at) {
              const remaining_days = getDaysDiff(expire_at);
              if (status === 'refund') {
                ticketStatus = '환불';
              } else if (active === false) {
                ticketStatus = '사용불가';
              } else if (is_holding) {
                ticketStatus = `정지중`;
              } else if (remaining_days < 0 || remaining_coupon <= 0) {
                ticketStatus = '이용만료';
              } else if (dayjs().startOf('day').isBefore(availability_start_at)) {
                ticketStatus = '사용예정';
              } else {
                ticketStatus = `사용중`;
              }
            }

            return {
              id: userTicket.id,
              title,
              active,
              inactive_reason,
              ticketColor: colors,
              classType: available_class_type === 'P' ? '프라이빗' : '그룹',
              ticketType: type === 'P' ? '기간제' : !use_weekly_auto_coupon_balance ? '횟수제' : '차감제',
              maxTrainee: `${max_trainee}:1`,
              limitType: booking_limit_per_month ? `월${booking_limit_per_month}회` : booking_limit_per_week ? `주${booking_limit_per_week}회` : '',
              ticketStatus,
              usableCoupon: type === 'P' ? null : `예약가능 ${usable_coupon}`,
              remainingCancel: type === 'P' ? (is_show_cancel_count ? `취소가능 ${remaining_cancel}` : null) : `취소가능 ${remaining_cancel}`,
              remainingCoupon: type === 'P' ? null : `잔여 ${remaining_coupon}`,
              dailyBookingChangeLimit: ticket.daily_booking_change_limit
                ? `당일변경 ${ticket.daily_booking_change_limit - change_daily_booking_count}`
                : null,
              period: `${filter.date(availability_start_at)} ~ ${filter.date(expire_at)} (${getRemainingDaysText(expire_at, availability_start_at)})`,
              family: is_shared ? '패밀리' : null,
              tutorial: is_ignore_new_payment ? '체험권' : null,
              ticketUsable: ticket_usable,
            };
          });

          data.ticketCardData = res;
          data.studio.locker = locker;
        });

        draft.data = action.data;
        draft.loading = false;
        break;
      case GET_USER_TICKET_FAILURE:
        draft.loading = false;
        break;
      case GET_USER_STUDIO_SUCCESS:
        draft.studios = action.data;
        draft.loading = false;
        break;
      case GET_USER_STUDIO_FAILURE:
        draft.loading = false;
        break;
      case SET_STUDIO:
        draft.currentStudioData.studio = action.studio;
        // if (state.tabIndex === 0) {
        //   draft.currentStudioData.ticket = { id: 0, period: '' };
        // }
        break;
      case SET_TICKET:
        draft.currentStudioData.ticket = action.ticket;
        draft.currentStudioData.ticketRaw = action.ticketRaw;
        break;
      default:
        return state;
    }
  });

export default studio;
