/* eslint-disable func-names */
/* eslint-disable dot-notation */
import axios from 'axios';
import qs from 'qs';
import localStorage from 'utils/localStorage';
// import { isMobile } from 'react-device-detect';

const headers = {
  ...axios.defaults.headers,
  Authorization: `Bearer ${localStorage.get('access_token')}`,
  // Authorization: `Bearer 1231312`,
  'Cache-control': 'no-cache',
  'Content-Encoding': 'gzip',
  'web-version': process.env.REACT_APP_VERSION,
};

const paramsSerializer = (params: any) => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    encodeValuesOnly: true,
    skipNulls: true,
  });
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers,
  paramsSerializer,
  timeout: 1000 * 10,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // 오류 응답을 처리
    if (error && error.response.data.code == 50003 && window.location.pathname !== '/service-inspection') {
      window.location.href = '/service-inspection';
    }
    return Promise.reject(error);
  },
);

export default instance;
