import React from 'react';

export default (
  <div style={{ lineHeight: '1.5' }}>
    로그인 세션이 만료되었습니다.
    <br />
    다시 로그인 해주세요. <br />
    <br />
    스튜디오메이트는 <b>개인 정보보호</b>를 위해
    <br />
    180일 간격으로 자동 로그아웃 기능을
    <br />
    제공하고 있습니다.
    <br />
  </div>
);

// const errorMessage = (
//   <div style={{ lineHeight: '1.5' }}>
//     시설 정보를 불러올 수 없습니다.
//     <br />
//     기기종류 별로 아래와 같이 조치 후 <br /> 로그인해주세요. <br />
//     <br />
//     [아이폰 사용자]
//     <br />
//     - 앱을 삭제후 재설치 해주세요.
//     <br /> <br />
//     [안드로이드 사용자]
//     <br />
//     - 아래처럼 데이터 삭제해 주시기 바랍니다.
//     <br />
//     * 모바일 자체 설정 &#8594; 애플리케이션 &#8594; <br /> Studiomate 검색 및 선택 &#8594; 저장공간 &#8594; 데이터 삭제 *
//   </div>
// )
