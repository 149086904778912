export const CHANGE_CALENDAR_DATA = 'BOOKING/CHANGE_CALENDAR_DATA' as const;
export const CHANGE_USAGE_CALENDAR_DATA = 'BOOKING/CHANGE_USAGE_CALENDAR_DATA' as const;
export const AVAILABLE_BOOKING_LIST_R = 'AVAILABLE_BOOKING_LIST_R' as const;
export const AVAILABLE_BOOKING_LIST_S = 'AVAILABLE_BOOKING_LIST_S' as const;
export const AVAILABLE_BOOKING_LIST_F = 'AVAILABLE_BOOKING_LIST_F' as const;

export const AVAILABLE_PRIVATE_LIST_R = 'AVAILABLE_PRIVATE_LIST_R' as const;
export const AVAILABLE_PRIVATE_LIST_S = 'AVAILABLE_PRIVATE_LIST_S' as const;
export const AVAILABLE_PRIVATE_LIST_F = 'AVAILABLE_PRIVATE_LIST_F' as const;

export const POST_GROUP_BOOKING_R = 'POST_GROUP_BOOKING_R' as const;
export const POST_GROUP_BOOKING_S = 'POST_GROUP_BOOKING_S' as const;
export const POST_GROUP_BOOKING_F = 'POST_GROUP_BOOKING_F' as const;

export const DELETE_GROUP_BOOKING_R = 'DELETE_GROUP_BOOKING_R' as const;
export const DELETE_GROUP_BOOKING_S = 'DELETE_GROUP_BOOKING_S' as const;
export const DELETE_GROUP_BOOKING_F = 'DELETE_GROUP_BOOKING_F' as const;

export const POST_PRIVATE_BOOKING_R = 'POST_PRIVATE_BOOKING_R' as const;
export const POST_PRIVATE_BOOKING_S = 'POST_PRIVATE_BOOKING_S' as const;
export const POST_PRIVATE_BOOKING_F = 'POST_PRIVATE_BOOKING_F' as const;

export type IBookingState = {
  calendarData: {
    selectDate: string | Date;
    weeks: number;
  };
  usageCalendarData: {
    selectDate: string | Date;
    weeks: number;
  };
  availableBookingList: { data: any; reason: null | string };
  availablePrivateList: { data: any; reason: null | string };
  loading: boolean;
  done: { title?: string; body?: string };
};

export type LectureType = {
  title: string;
  room?: { name: string };
  instructor: { name: string; representative_avatar: any };
  start_on: string;
  end_on: string;
  booking_end_at?: string;
  type: string;
  max_trainee: number;
  current_trainee_count: number;
  booking_waiting_count: number;
  booking_waiting_order?: number;
};
