import React from 'react';
// import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Iframe from 'react-iframe';
import { useHistory } from 'react-router-dom';
import Checkbox from 'antd-mobile/lib/checkbox';
import PlainButton from 'components/PlainButton';

import { theme } from 'assets/styles/theme';
import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';
import { signUpOneType } from './types';

const SignUpOne = ({ signUpData, setSignUpData, checked, onCheckBox }: signUpOneType) => {
  const history = useHistory();
  const routeChange = () => {
    const path = `/login`;
    history.push(path);
  };
  return (
    <MainLayout header={{ title: HEADER_TITLE.signup, backFunc: history.goBack }} contentsGrid invisibleFooter>
      <SectionLayout>
        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
          <Checkbox.AgreeItem checked={checked.useAgree} onChange={() => onCheckBox('useAgree')}>
            <CheckboxText>이용약관 동의 (필수)</CheckboxText>
          </Checkbox.AgreeItem>
          <div className="iframe">
            <Iframe url="https://studiomate.kr/terms-of-service.html" className="wrap-iframe" />
          </div>
        </div>

        <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
          <Checkbox.AgreeItem checked={checked.infoAgree} onChange={() => onCheckBox('infoAgree')}>
            <CheckboxText>개인 정보 수집 및 이용 동의 (필수)</CheckboxText>
          </Checkbox.AgreeItem>
          <div className="iframe">
            <Iframe url="https://studiomate.kr/privacy-policy.html" className="wrap-iframe" />
          </div>
        </div>

        {/* <Checkbox.AgreeItem><CheckboxText>마케팅 광고 활용 동의 (선택)</CheckboxText></Checkbox.AgreeItem>
        <Iframe url="https://studiomate.kr/marketing-agreement.html" className="wrap-iframe" /> */}
      </SectionLayout>

      <ButtonGroup>
        <PlainButton color="dark" outline onClick={routeChange}>
          <span className="btn cancel">취소</span>
        </PlainButton>
        <PlainButton color="primary" disabled={!(checked.useAgree && checked.infoAgree)} onClick={() => setSignUpData({ ...signUpData, step: 2 })}>
          <b className="btn">동의</b>
        </PlainButton>
      </ButtonGroup>
    </MainLayout>
  );
};

const SectionLayout = styled.div`
  overflow-y: scroll;
  padding: 16px;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;

  .iframe {
    max-height: 300px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    border: 1px solid #cccccc;
    iframe {
      border: none;
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
`;

const CheckboxText = styled.div`
  font-weight: bold;
  color: ${theme.color.black300};
  margin: 0 0 10px 8px;
`;

const ButtonGroup = styled.div`
  padding: 8px 16px 16px 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  .btn {
    font-size: 18px;
  }
  .cancel {
    font-weight: 500;
  }
`;

export default SignUpOne;
