import React from 'react';
import TagButton from 'components/TagButton';
import { theme } from 'assets/styles/theme';

const convertTypeToString = (status: string, count?: any): JSX.Element => {

  switch (status) {
    case 'noshow':
      return <TagButton text='노쇼' color={theme.tagColor.lightPurple} />; // 노쇼
    case 'booking_confirmed':
      return <TagButton text='예약확정' color={theme.tagColor.blue} />; // 예약(확정) // 예약 가능 시간이 넘어갔을 때
    case 'absence':
      return <TagButton text='결석' color={theme.tagColor.orange} />; // 결석
    case 'booked':
      return <TagButton text='예약완료' color={theme.tagColor.blue} />; // 예약완료 // 취소 가능 시간이 지나면
    case 'attendance':
      return <TagButton text='출석' color={theme.tagColor.teal} />; // 출석
    case 'cancel':
      return <TagButton text='예약취소' color={theme.tagColor.red} />; // 예약취소
    case 'booking_waiting':
      return <TagButton text='예약대기' color={theme.tagColor.cyan} count={count} />; // 예약대기
    default:
      return <TagButton text='예약' color={null} />;
  }
};

export default convertTypeToString;
