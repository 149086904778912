import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { HEADER_TITLE, AUTH_TYPES, LABEL, PLACEHOLDER } from 'constants/text';
import Store from 'contexts/store/store';
import MainLayout from 'components/MainLayout';
import PlainButton from 'components/PlainButton';
import { theme } from 'assets/styles/theme';
import TextInput from 'components/TextInput';

const DeleteAccountDetail = () => {
  /** state */
  const { apis } = useContext(Store);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  /** hooks */
  const history = useHistory();


  const onSubmit = async () => {
    try {
      const res = await apis.auth.deleteAccount({ password });
      if (res) {
        alert({ message: LABEL.deleteAccount });
        localStorage.clear();
        history.push('/login');
      }
    } catch (err) {
      alert({ message: "현재 비밀번호가 일치하지 않습니다." });
    }
  }

  const onChange = (e) => {
    setPassword(e.target.value);
  }

  return (
    <MainLayout header={{ title: HEADER_TITLE.deleteAccount, titleNormal: true, backFunc: history.goBack }} loading={loading} contentsGrid>
      <Wrap>
        <div className='desc'>
          안전을 위해 비밀번호를 입력해 주세요. <br />
          비밀번호가 기억나지 않으면 아래 비밀번호 찾기를 통해 비밀번호를 변경하고 회원 탈퇴를 다시 해주세요.
        </div>

        <TextInput
          id={AUTH_TYPES.currentPassword}
          name={AUTH_TYPES.currentPassword}
          value={password}
          onChange={onChange}
          type="password"
          label={LABEL.password}
          placeholder={PLACEHOLDER.passwordGuide}
        />

        <a href='/reset-password' className='changePassword'>비밀번호 찾기</a>
      </Wrap>

      <FooterWrap>
        <div className="mbsc-btn-group-block">
          <PlainButton
            disabled={!password}
            color="danger"
            onClick={onSubmit}
        >
            회원 탈퇴
          </PlainButton>
        </div>
      </FooterWrap>
      
    </MainLayout>
  );
}

const Wrap = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: normal;

  .desc {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: ${theme.color.black300};
    margin-bottom: 32px;
  }

  .changePassword {
    color: ${theme.color.blue};
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    margin-top: 36px;
  }
`;

const FooterWrap = styled.div`
  border-top: 1px solid #eee;
  padding: 0 16px;

  button {
    font-size: 18px;
    font-weight: bold;
  }
`;

export default DeleteAccountDetail;