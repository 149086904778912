import { useState, useCallback } from 'react';

export default (initialValue: any) => {
  const [data, setData] = useState(initialValue);
  const handler = useCallback(
    e => {
      if (e.initValue) {
        const newObj: { [key: string]: boolean } = {};
        for (let i = 0; i < e.initValue.length; i++) {
          newObj[e.initValue[i].name] = e.initValue[i].value;
        }
        setData({
          ...data,
          ...newObj,
        });
      } else {
        const { value, name } = e.target;

        setData({
          ...data,
          [name]: value,
        });
      }
    },
    [data],
  );
  return [data, handler];
};
