import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';

import MainLayout from 'components/MainLayout';
import { HEADER_TITLE } from 'constants/text';
import { theme } from 'assets/styles/theme';
import filters from 'utils/filters';
// import { ReactComponent as LockerRoom } from 'assets/icons/icon_lockeroom_40.svg';
import LockerRoom from 'assets/icons/icon_lockeroom_40@3x.png';

import { ReactComponent as Studio } from 'assets/icons/icon_centerinfo_18.svg';
import { ReactComponent as Clock } from 'assets/icons/icon_schedule_18.svg';


const { Item } = List;


const MyPageLocker = ({ location }) => {

  const locker = location.state;

  /** hooks */
  const history = useHistory();
  const { loading } = useSelector(state => state.usage);
  
  return (
    <MainLayout header={{ title: HEADER_TITLE.locker, titleNormal: true, titleBorder: false, backFunc: history.goBack }} loading={loading}>

      <ListStyle>
        <List>
          {locker.map((el, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={idx}>
              <img src={LockerRoom} className='locker-icon' alt='locker' />
              <div className='container'>
                <div className='flex-first'>
                  <div className='no'>{el[0].no}번</div>
                  <div className='name ellipse'>{el[0]?.name}</div>
                </div>

                <div className='flex second'><Studio /><span className='title'>시설 정보</span><span className='content ellipse'>{el[1]}</span></div>
                <div className='flex'><Clock /><span className='title'>사용 기한</span><span className='content'>{filters.date(el[0].start_on)}~{filters.date(el[0].end_on)}</span></div>
              </div>
            </Item>
          ))}
        </List>
      </ListStyle>

    </MainLayout>
  );
};

const ListStyle = styled.div`
  .locker-icon {
    width: 40px;
    height: 40px;
  }
  .am-list-item {
    padding-left: 0px;

    .am-list-line {
      padding: 16px;
    }
  }
  .am-list-content {
    display: flex;
  }
  .container {
    margin-left: 10px;

    .ellipse {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; 
    }
    .no {
      font-size: 13px;
      font-weight: bold;
      color: ${theme.color.black000};
      margin-right: 10px;
    }
    .name {
      font-size: 13px;
      font-weight: bold;
      color: ${theme.color.newGray};
    }
    .flex-first {
      display: flex;
      margin-bottom: 11px;
      max-width: 280px;

      @media (max-width: 320px) {
        max-width: 230px;
      }
    }
    .second {
      margin-bottom: 6px;
    }
    .flex {
      display: flex;
      align-items: center;

      .title {
        font-size: 13px;
        font-weight: normal;
        color: #bcbcbc;
        margin: 0 10px 0 5px;
      }
      .content {
        font-size: 13px;
        font-weight: normal;
        color: #818181;
        max-width: 190px;

        @media (max-width: 320px) {
          max-width: 140px;
        }
      }

    }
    
  }
`;

export default MyPageLocker;