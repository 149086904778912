export default (policy: any) => {
  const typeText: any = { group: { booking: '', cancel: '' }, private: { booking: '', cancel: '' } };

  if (!policy) return typeText;

  const parseTime = (value: number): { hour: number | string; minute: number } => {
    let hour: string | number = Math.floor(value / 60);
    if (hour >= 24) {
      hour = `${Math.floor(hour / 24)}일 ${hour % 24}`;
    }
    const minute: number = value % 60;
    return { hour, minute };
  };

  const types = [
    {
      type: policy.private_booking_cancel_rule_type,
      bookingType: 'private',
      reservationType: 'cancel',
      cancelStartDay: policy.private_booking_cancel_start_days,
      cancelStartTime: policy.private_booking_cancel_start_time.slice(0, -3),
      cancelEndDay: policy.private_booking_cancel_end_days,
      cancelEndTime: policy.private_booking_cancel_end_time.slice(0, -3),
      startLine: policy.private_booking_cancel_startline,
      endLine: policy.private_booking_cancel_deadline,
    },
    {
      type: policy.private_booking_rule_type,
      bookingType: 'private',
      reservationType: 'booking',
      cancelStartDay: policy.private_booking_start_days,
      cancelStartTime: policy.private_booking_start_time.slice(0, -3),
      cancelEndDay: policy.private_booking_end_days,
      cancelEndTime: policy.private_booking_end_time.slice(0, -3),
      startLine: policy.private_booking_startline,
      endLine: policy.private_booking_deadline,
    },
    {
      type: policy.group_booking_cancel_rule_type,
      bookingType: 'group',
      reservationType: 'cancel',
      cancelStartDay: policy.group_booking_cancel_start_days,
      cancelStartTime: policy.group_booking_cancel_start_time.slice(0, -3),
      cancelEndDay: policy.group_booking_cancel_end_days,
      cancelEndTime: policy.group_booking_cancel_end_time.slice(0, -3),
      startLine: policy.group_booking_cancel_startline,
      endLine: policy.group_booking_cancel_deadline,
    },
    {
      type: policy.group_booking_rule_type,
      bookingType: 'group',
      reservationType: 'booking',
      cancelStartDay: policy.group_booking_start_days,
      cancelStartTime: policy.group_booking_start_time.slice(0, -3),
      cancelEndDay: policy.group_booking_end_days,
      cancelEndTime: policy.group_booking_end_time.slice(0, -3),
      startLine: policy.group_booking_startline,
      endLine: policy.group_booking_deadline,
    },
  ];
  for (let i = 0; i < types.length; i++) {
    switch (types[i].type) {
      case 'A':
        typeText[types[i].bookingType][types[i].reservationType] = `${types[i].cancelEndDay === 0 ? '당일' : `${types[i].cancelEndDay}일 전`} ${
          types[i].cancelEndTime
        }까지 ${types[i].reservationType === 'booking' ? '<b>예약가능</b>' : '<b>취소가능</b>'}`;
        break;
      case 'R':
        typeText[types[i].bookingType][types[i].reservationType] = `${parseTime(types[i].endLine).hour}시간 ${
          parseTime(types[i].endLine).minute
        }분 전 까지 ${types[i].reservationType === 'booking' ? '<b>예약가능</b>' : '<b>취소가능</b>'}`;
        break;
      case 'RR':
        typeText[types[i].bookingType][types[i].reservationType] = ` ${parseTime(types[i].startLine).hour}시간 ${
          parseTime(types[i].startLine).minute
        }분 전부터 ${parseTime(types[i].endLine).hour}시간 ${parseTime(types[i].endLine).minute}분 전 까지 ${
          types[i].reservationType === 'booking' ? '<b>예약가능</b>' : '<b>취소가능</b>'
        }`;
        break;
      case 'AR':
        typeText[types[i].bookingType][types[i].reservationType] = `${types[i].cancelStartDay === 0 ? '당일' : `${types[i].cancelStartDay}일 전`} ${
          types[i].cancelStartTime
        }부터 ${types[i].cancelEndDay === 0 ? '당일' : `${types[i].cancelEndDay}일 전`} ${types[i].cancelEndTime}까지 ${
          types[i].reservationType === 'booking' ? '<b>예약가능</b>' : '<b>취소가능</b>'
        }`;
        break;
      default:
        return (typeText[types[i].bookingType][types[i].reservationType] = ``);
    }
  }

  return typeText;
};
