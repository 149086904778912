import React, { useCallback, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSBoard, setCurrentSBoard, setBoardKeyword } from 'store/board/reducer';
import Store from 'contexts/store/store';
import NoticePresenter from 'pages/Board/NoticePresenter';

const SNoticeContainer = () => {
  /** hooks */
  const dispatch = useDispatch();
  const { utils } = useContext(Store);

  /** state */
  const { studioNotice, loading } = useSelector(state => state.board);
  const isLastNoti = useRef(false);
  const observer = useRef();

  /** func */
  const getInitData = useCallback(
    (searchKeyword, isforce) => {
      if (studioNotice.data.length && !isforce && !searchKeyword) return;

      dispatch(getSBoard({ page: 1, limit: 20, all: 1, sort_type: 'desc', search: searchKeyword }));

      isLastNoti.current = false;
    },
    [dispatch, studioNotice.data]
  );

  const getNewData = useCallback(async () => {
    if (studioNotice.params.page <= studioNotice.lastPage) {
      dispatch(getSBoard({ ...studioNotice.params }));
    } else {
      isLastNoti.current = true;
    }
  }, [dispatch, studioNotice]);

  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isLastNoti.current) {
          getNewData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, getNewData]
  );

  const getTime = useCallback(
    time => {
      return utils.getTime.getFullDateTime(time);
    },
    [utils.getTime]
  );

  const setDetailBoard = useCallback(data => dispatch(setCurrentSBoard(data)), [dispatch]);
  const setKeyword = useCallback(data => dispatch(setBoardKeyword(data)), [dispatch]);
  return (
    <NoticePresenter 
      getInitData={getInitData}
      lastElementRef={lastElementRef}
      getTime={getTime}
      noticeData={studioNotice.data}
      type={studioNotice.params}
      setCurrentBoard={setDetailBoard}
      setBoardKeyword={setKeyword}
    />
  );
};

export default SNoticeContainer;