import React from 'react';
import styled from 'styled-components';
import List from 'antd-mobile/lib/list';
import { theme } from 'assets/styles/theme';

type EditAccountType = {
  goToEditAccount: (path: string) => void;
};


const SettingsPresenter = ({ goToEditAccount }: EditAccountType) => {

  return (
    <Wrap>
      <List>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToEditAccount('profile')}>
          프로필 변경
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToEditAccount('mobile')}>
          휴대폰 번호 변경
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToEditAccount('email')}>
          이메일 변경
        </List.Item>
        <List.Item arrow="horizontal" multipleLine onClick={() => goToEditAccount('password')}>
          비밀번호 변경
        </List.Item>
      </List>
    </Wrap>
  );
};

export const Wrap = styled.div`
  .am-list-item {
    padding-left: 24px;
    .am-list-line {
      padding: 16px 24px 16px 0;
      .am-list-content {
        color: ${theme.color.black300};
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
  .am-list-body::after {
    margin-left: 24px;
  }
`;

export default SettingsPresenter;
