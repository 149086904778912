import React, { useEffect, useRef, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import getImageUrl from 'utils/getImageUrl';

import { theme } from 'assets/styles/theme';
import PullRefresh from 'react-pullrefresh'
import Store from 'contexts/store/store';

import Icon from 'antd-mobile/lib/icon';
import List from 'antd-mobile/lib/list';

import { ReactComponent as NotiUnread } from 'assets/icons/noti-unread.svg';
import { ReactComponent as QnaAddBtn } from 'assets/icons/icon-board-qna-add.svg';
import { NOTICE_TYPE } from 'constants/text';

import { resetNotice } from 'store/board/reducer';

const { Item } = List;
const { Brief } = Item;

const NoticePresenter = ({ getInitData, noticeData, getTime, lastElementRef, setCurrentBoard, setBoardKeyword, type, loading }) => {
  /** hook */
  const history = useHistory();
  const dispatch = useDispatch();
  const { utils } = useContext(Store);
  const visitTime = useRef(utils.getTime.localTime(new Date()));

  /** state */
  const searchBar = useRef();
  const keyword = useRef();
  const ptr = useRef();
  const { currentStudioData } = useSelector(state => state.studio);

  /** func */
  useEffect(() => {
    console.log('type.title', type.title);

    if (type.title) {
      keyword.current = type.title;
      getInitData(type.title, true);
    } else {
      getInitData('', true);
    }

    return () => {
      localStorage.setItem(NOTICE_TYPE.notice, visitTime.current);
      // PullToRefresh.destroyAll();
    };
  }, []);

  const searchKeyword = e => {
    e.preventDefault();
    searchBar.current.blur();
    if (type.type === NOTICE_TYPE.snotice) {
      getInitData(type.search, true);
    } else {
      getInitData(type.title, true);
    }
  };

  const handleRefresh = async () => {
    if (!loading && ptr.current.scrollTop < 1) {
      await getInitData(keyword.current, true);
    }
  };

  const goToDetil = useCallback(
    data => {

      const imageData = [];
      if (data?.attachments?.length) {
        data.attachments.forEach(el => {
          imageData.push({ id: el.id, url: getImageUrl(el.path, '0x0') });
        });
      }

      setCurrentBoard({
        id: data.id,
        studio_id: data.studio_id,
        staff: data.staff || data.member || { name: 'studiomate' },
        title: data.title,
        type: data.type,
        created: data.created_at,
        // 스튜디오 메이트 공지는 내용이 context로 옴
        contents: data?.context ? data?.context : data.contents,
        attachments: imageData,
        comments: data.comments || [],
      });

      return history.push({ pathname: '/board-detail' });
    },
    [history, setCurrentBoard, utils],
  );

  const goToAddQna = () => {
    return history.push({ pathname: '/board/qna/create' });
  };


  const NoticeView = useCallback (
    () =>
      noticeData.map((notice, index) => {

        return (
          <NoticeViewList key={notice.id} ref={noticeData.length === index + 1 ? lastElementRef : null}>
            <List className="my-list" onClick={() => goToDetil(notice)}>

              <Item extra={notice.unRead ? <NotiUnread /> : undefined}>
                
                <Brief><div className='title'>{notice.title}</div></Brief>

                <div className='dc-grid'>
                  <Brief><div className='date'>{getTime(notice.created_at)}</div></Brief>

                  {/* only 문의 */}
                  {type.type === 'qna' && notice.comments.length ? <Brief><div className='comment'>댓글 {notice.comments.length}개</div></Brief> : null}
                </div>
              </Item>

            </List>
          </NoticeViewList>
        );
      }),
    [getTime, lastElementRef, noticeData, goToDetil, type.type],
  );


  return (
    <NoticeWrap dataLength={noticeData.length}>

      {/* 1:1 문의 add */}
      {currentStudioData?.studio?.id > 0 && type.type === 'qna' && (
        <QnaAddButton onClick={goToAddQna}>
          <QnaAddBtn />
        </QnaAddButton>
      )}

      {/* search */}
      <SearchBarWrap onSubmit={searchKeyword}>
        <Icon type="search" size="sm" color={theme.color.black500} style={{ marginRight: '10px' }} />
        <input
          disabled={!(currentStudioData?.studio?.id > 0)}
          placeholder="검색"
          className="full-width"
          value={type.title}
          onChange={e => {
            keyword.current = e.target.value;
            setBoardKeyword({ type: type.type, keyword: e.target.value });
          }}
          ref={searchBar}
        />
      </SearchBarWrap>
      
      <div ref={ptr} className='pullrefresh'>
        <PullRefresh onRefresh={handleRefresh} zIndex={1}>
          {currentStudioData?.studio?.id > 0 ? (
              noticeData.length ? (
                <NoticeView />
                ) : (
                  <NoDataWrap>
                    <span>
                      {type.type === 'qna' ? '문의' : '공지사항'} 내역이 없습니다.
                    </span>
                  </NoDataWrap>
                )
            ) : (
              <NoDataWrap>
                <span>
                  시설 정보가 없습니다. 시설을 선택한 후 {type.type === 'qna' ? '1:1 문의를' : '공지사항을'} 확인하세요.
                  시설 선택에서 등록된 시설이 없는 경우 시설에 문의하세요.
                </span>
              </NoDataWrap>
            )}
        </PullRefresh>
      </div>

    </NoticeWrap>
  );
};

export default NoticePresenter;

NoticePresenter.propTypes = {
  getInitData: PropTypes.func,
  noticeData: PropTypes.arrayOf(PropTypes.object),
  getTime: PropTypes.func,
  lastElementRef: PropTypes.func,
  setCurrentBoard: PropTypes.func,
  setBoardKeyword: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  type: PropTypes.oneOfType([PropTypes.object]),
};

NoticePresenter.defaultProps = {
  getInitData: {},
  noticeData: [],
  getTime: {},
  lastElementRef: {},
  setCurrentBoard: {},
  setBoardKeyword: {},
  type: {},
};

/** css */
const SearchBarWrap = styled.form`
  display: flex;
  align-items: center;
  padding: 10px 14px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  height: 48px;
  z-index: 1;

  input {
    border-bottom: none;
  }
`;

const NoticeListWrap = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const NoticeWrap = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  position: relative;

  .pullrefresh {
    overflow: auto;
    position: relative;
    height: 100%;
  }

  ${props => {
    if (props.dataLength === 0) {
      return `
        .pullrefresh > div, .pullrefresh > div > div:first-child {
          height: 100%;
        }
      `;
    }
  }}
  
`;

const QnaAddButton = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: white;
  z-index: 2;
`;

const NoDataWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: ${theme.color.greyBBB};
  padding: 24px;
  line-height: normal;
`;

const NoticeViewList = styled.div`
  .am-list-body .am-list-item .am-list-line .am-list-content {
    padding: 12px 0 16px 0;
  }
  .am-list-extra {
    position: absolute;
    top: 0;
    right: 16px;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.black300};
    white-space: pre-wrap;
  }
  .dc-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr auto;
    margin-top: 8px;
  }
  .date {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black500};
  }
  .comment {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black500};
  }
`;

