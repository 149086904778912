/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import './actionSheetStyle.scss';

import React from 'react';

function noop() {}

class index extends React.Component {
  static defaultProps = {
    onRequestClose: noop,
    onCancel: noop,
    cancelText: '닫기',
    show: false,
    menus: [],
  };

  render() {
    const { onRequestClose, onCancel, cancelText, menus, show } = this.props;

    return (
      <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`} onClick={onRequestClose}>
        <div className="react-actionsheet-mask" />
        <div className="react-actionsheet-wrap-original">
          <div className="react-actionsheet-menu">
            {menus.map((menu, i) => {
              const { value, type, content, count, current, onClick = noop } = menu;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div
                  key={i}
                  data-index={i}
                  data-value={value}
                  data-type={type}
                  className={current ? 'react-actionsheet-menu-item current' : 'react-actionsheet-menu-item else'}
                  onClick={onClick}>
                  {content} {count || count === 0 ? <span>({count})</span> : null}
                </div>
              );
            })}
          </div>
          <div className="react-actionsheet-action">
            <div className="react-actionsheet-action-item" onClick={onCancel}>
              {cancelText}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default index;
