import React from 'react';

import PlainButton from 'components/PlainButton';
import { LABEL } from 'constants/text';
import { theme } from 'assets/styles/theme';
import styled from 'styled-components';

type FooterButtonsTypes = {
  cancelClick: () => void,
  confirmClick: () => void,
  disabled?: boolean,
  confirmText?: string,
  cancelText?: string
};

const FooterButtons = ({cancelClick, confirmClick, disabled = true, confirmText = '저장', cancelText = '취소'}: FooterButtonsTypes) => {

  return (
    <Wrap>
      <PlainButton onClick={cancelClick} color="dark" outline>
        <span className='cancel'>{cancelText}</span>
      </PlainButton>
      <PlainButton onClick={confirmClick} disabled={disabled} color="primary">
        <span className='confirm'>{confirmText}</span>
      </PlainButton>
    </Wrap>
  );
};

/** css */
const Wrap = styled.div`
  border-top: 1px solid #eee;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 0 16px;

  .cancel {
    font-weight: 500;
    font-size: 18px;

    .mbsc-ios.mbsc-btn-outline.mbsc-btn.mbsc-btn-dark {
      color: ${theme.color.black500};
    }
  }

  .confirm {
    font-weight: bold;
    font-size: 18px;
    color: #fff;
  }
`;


export default FooterButtons;
