import React from 'react';

type StoreType = {
  apis: {
    auth: {
      login: Function;
      requestSmSCode: Function;
      validateSmSCode: Function;
      checkDuplicationMobile: Function;
      checkDupEmail: Function;
      checkDuplicationEmail: Function;
      signUp: Function;
      changeUserAccount: Function;
      changePassword: Function;
      changeAccount: Function;
      findEmail: Function;
      findPassword: Function;
      getValidateCode: Function;
      deleteAccount: Function;
      patchFCMToken: Function;
    };
    notification: {
      getNotice: Function;
    };
  };
  utils: {
    localStorage: { get: Function; set: Function };
    validate: { mobile: Function; email: Function; password: Function };
    getTime: { localTime: Function; getFullDateTime: Function; getTimeStampString: Function; getServetTime: Function; getHyperDate: Function; dateTime: Function; };
    getImageUrl: Function;
    imageUpload: { imageCompressor: Function; covertFormData: Function };
  };
};

const Store = React.createContext<StoreType>({
  apis: {
    auth: {
      checkDuplicationMobile: () => {},
      login: () => {},
      requestSmSCode: () => {},
      validateSmSCode: () => {},
      checkDupEmail: () => {},
      checkDuplicationEmail: () => {},
      signUp: () => {},
      changeUserAccount: () => {},
      changePassword: () => {},
      changeAccount: () => {},
      findEmail: () => {},
      findPassword: () => {},
      getValidateCode: () => {},
      deleteAccount: () => {},
      patchFCMToken: () => {}
    },
    notification: {
      getNotice: () => {},
    },
  },
  utils: {
    localStorage: { get: () => {}, set: () => {} },
    validate: { mobile: () => {}, email: () => {}, password: () => {} },
    getTime: { localTime: () => {}, getFullDateTime: () => {}, getTimeStampString: () => {}, getServetTime: () => {}, getHyperDate: () => {},  dateTime: () => {}},
    getImageUrl: () => {},
    imageUpload: { imageCompressor: () => {}, covertFormData: () => {} },
  },
});
export default Store;
