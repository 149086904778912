import axios from '../axios';

export default {
  getUserTicket: () =>
    axios.get(
      `/v2/member/userTicket?is_all=1&hasHolding=1&sortBy=usableTicket&ignore_available_class_type=I&with=studio.policy;studio.contactInfos.type;studio.address;ticket;member.lockers;changeDailyBookings;studio.openEvents;studio.holidays;staffs`,
    ),

  getUserStudio: () => axios.get(`/v2/member/studios?with=policy;contactInfos.type;address;openEvents;holidays;`),

  // 유저티켓 상세
  // https://api.qa.studiomate.kr/v2/member/userTicket/264646?with=studio.policy;studio.contactInfos.type;studio.address;ticket;member.lockers;changeDailyBookings;studio.openEvents
};
