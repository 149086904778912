import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useGlobalUI from 'hooks/useGlobalUI';

/** route */
import Menu from 'components/Menu';
import FcmComponent from 'components/FcmListener';
import ScrollToTop from 'components/ScrollToTop';
import PrivateRoute from 'components/PrivateRoute';
import Login from 'pages/Login';
import FindEmail from 'pages/FindEmail';
import FindPassword from 'pages/FindPassword';
import SignUp from 'pages/SignUp/index';
import Notification from 'pages/Notification';
import NotificationDetail from 'pages/Notification/NotificationDetail';
import Board from 'pages/Board';
import BoardDetail from 'pages/Board/Detail';
import PostImagesDetail from 'components/PostImagesDetail';
import QnaCreate from 'pages/Board/Qna/QnaCreate';
import QnaCommentEdit from 'pages/Board/Qna/QnaCommentEdit';
import Settings from 'pages/Settings/SettingContainer';
import DeleteAccount from 'pages/Settings/components/DeleteAccount';
import DeleteAccountDetail from 'pages/Settings/components/DeleteAccountDetail';
import ServiceCenter from 'pages/Settings/ServiceCenter';
import Policy from 'pages/Settings/ServiceCenter/Policy';
import PushSetting from 'pages/Settings/components/PushSetting';
import BookingGroup from 'pages/Booking/Group/BookingGroup';
import BookingGroupDetail from 'pages/Booking/Group/BookingGroupDetail';
import BookingPrivate from 'pages/Booking/Private/BookingPrivate';
import BookingPrivateStaffList from 'pages/Booking/Private/BookingPrivateStaffList';
import BookingPrivateDetail from 'pages/Booking/Private/BookingPrivateDetail';
import BookingList from 'pages/BookingList';
import BookingDetail from 'pages/BookingList/BookingDetail';
import BookingChange from 'pages/BookingList/BookingChange';
import NoMatch from 'components/NoMatch';
import Success from 'components/Success';
import MyPage from 'pages/MyPage/MyPageContainer';
import MyPageLocker from 'pages/MyPage/MyPageLocker';
import EditAccount from 'pages/MyPage/EditAccount';
import EditEmail from 'pages/MyPage/EditAccount/components/Email';
import EditMobile from 'pages/MyPage/EditAccount/components/Mobile';
import EditPassword from 'pages/MyPage/EditAccount/components/Password';
import EditProfile from 'pages/MyPage/EditAccount/components/Profile';
import SelectTicket from 'pages/SelectTicket';
import ServiceInspection from 'pages/ServiceInspection';

export default function Routes() {
  const { isMenuShow } = useSelector((state: RootState) => state.globalUI);
  const { closeGlobalMenu } = useGlobalUI(false);

  const handleDocumentClick = (e: any) => {
    if (isMenuShow) {
      e.stopPropagation();
      closeGlobalMenu();
    }
  };

  return (
    <>
      <FcmComponent />
      <ScrollToTop />
      {isMenuShow && <OverlayContents role="button" onClick={handleDocumentClick} />}
      <Menu />

      <Switch>
        {/* auth */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/find-email" component={FindEmail} />
        <Route exact path="/reset-password" component={FindPassword} />
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/service-inspection" component={ServiceInspection} />

        {/* 업데이트 수강권 */}
        <PrivateRoute exact path="/" component={SelectTicket} />
        <PrivateRoute exact path="/select-ticket" component={SelectTicket} />

        {/* 예약내역 */}
        <PrivateRoute exact path="/booking-group/:id" component={BookingGroup} />
        <PrivateRoute exact path="/booking-group/:id/course-detail" component={BookingGroupDetail} />
        <PrivateRoute exact path="/booking-private/:id" component={BookingPrivate} />
        <PrivateRoute exact path="/booking-private/:id/staff-detail" component={BookingPrivateStaffList} />
        <PrivateRoute exact path="/booking-private/:id/course-detail" component={BookingPrivateDetail} />
        <PrivateRoute exact path="/booking/list" component={BookingList} />
        <PrivateRoute exact path="/booking/detail" component={BookingDetail} />
        <PrivateRoute exact path="/booking/change" component={BookingChange} />

        {/* 공지 */}
        <PrivateRoute exact path="/board" component={Board} />
        <PrivateRoute exact path="/board-detail" component={BoardDetail} />
        <PrivateRoute exact path="/board-detail/image" component={PostImagesDetail} />
        <PrivateRoute exact path="/board/qna/create" component={QnaCreate} />
        <PrivateRoute exact path="/board/qna/edit" component={QnaCreate} />
        <PrivateRoute exact path="/board/qna/comment/edit" component={QnaCommentEdit} />

        {/* 알림 */}
        <PrivateRoute exact path="/notification" component={Notification} />
        <PrivateRoute exact path="/notification/:id" component={NotificationDetail} />

        {/* 설정 */}
        <PrivateRoute exact path="/settings" component={Settings} />
        <PrivateRoute exact path="/settings/push-setting" component={PushSetting} />
        <PrivateRoute exact path="/settings/service-center" component={ServiceCenter} />
        <PrivateRoute exact path="/settings/service-center/term-of-service" component={Policy} />
        <PrivateRoute exact path="/settings/service-center/privacy-policy" component={Policy} />
        <PrivateRoute exact path="/settings/delete-account" component={DeleteAccount} />
        <PrivateRoute exact path="/settings/delete-account-detail" component={DeleteAccountDetail} />

        {/* 마이페이지 */}
        <PrivateRoute exact path="/mypage" component={MyPage} />
        <PrivateRoute exact path="/mypage/locker" component={MyPageLocker} />

        <PrivateRoute exact path="/mypage/edit" component={EditAccount} />
        <PrivateRoute exact path="/mypage/edit/profile" component={EditProfile} />
        <PrivateRoute exact path="/mypage/edit/mobile" component={EditMobile} />
        <PrivateRoute exact path="/mypage/edit/email" component={EditEmail} />
        <PrivateRoute exact path="/mypage/edit/password" component={EditPassword} />

        {/* 기타 */}
        <Route exact path="/notlogin-success" render={props => <Success {...props} />} />
        <PrivateRoute exact path="/success" component={Success} />
        <Route component={NoMatch} />
      </Switch>
    </>
  );
}

export const OverlayContents = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background: black;
  z-index: 101;
`;
