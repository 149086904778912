import React, { Component } from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';

class EmptyLayout extends Component {
    render() {
        return (
          <EmptyLayoutStyle style={{ color: this.props.color ? '#cc3311' : theme.color.black500 }}>
            <div className='text' dangerouslySetInnerHTML={{ __html: this.props.text }} />
          </EmptyLayoutStyle>
        );
    }
}

const EmptyLayoutStyle = styled.div`
  position: relative;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-weight: normal;

  .text {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
  }
`;

export default EmptyLayout;