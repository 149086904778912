import React, { useCallback, useContext, useRef } from 'react';
import Store from 'contexts/store/store';
import { useSelector, useDispatch } from 'react-redux';

import { getBoard, setCurrentBoard, setBoardKeyword } from 'store/board/reducer';
import NoticePresenter from 'pages/Board/NoticePresenter';
import { NOTICE_TYPE } from 'constants/text';

const NoticeContainer = () => {
  /** hooks */
  const { utils } = useContext(Store);
  const dispatch = useDispatch();

  /** state */
  const { notice, loading } = useSelector(state => state.board);
  const { currentStudioData } = useSelector(state => state.studio);
  const isLastNoti = useRef(false);
  const observer = useRef();

  /** func */
  const getInitData = useCallback(
    (title, isforce) => {
      if (notice.data.length && !title && !isforce) return;

      dispatch(getBoard({ page: 1, limit: 20, all: 1, type: NOTICE_TYPE.notice, studio_id: currentStudioData?.studio?.id, title }));
      isLastNoti.current = false;
    },
    [dispatch, currentStudioData?.studio?.id, notice.data],
  );

  const getNewData = useCallback(async () => {
    if (notice.params.page <= notice.lastPage) {
      dispatch(getBoard({ ...notice.params, studio_id: currentStudioData?.studio?.id }));
    } else {
      isLastNoti.current = true;
    }
  }, [dispatch, notice, currentStudioData?.studio?.id]);

  const lastElementRef = useCallback(
    node => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && !isLastNoti.current) {
          getNewData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, getNewData],
  );

  const getTime = useCallback(
    time => {
      return utils.getTime.getFullDateTime(time);
    },
    [utils.getTime],
  );

  const setDetailBoard = useCallback(data => dispatch(setCurrentBoard(data)), [dispatch]);
  const setKeyword = useCallback(data => dispatch(setBoardKeyword(data)), [dispatch]);

  return (
    <NoticePresenter
      getInitData={getInitData}
      noticeData={notice.data}
      loading={loading}
      getTime={getTime}
      lastElementRef={lastElementRef}
      setCurrentBoard={setDetailBoard}
      setBoardKeyword={setKeyword}
      type={notice.params}
    />
  );
};

export default NoticeContainer;
