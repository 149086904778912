import dayjs from 'dayjs';
import getDaysDiff from './getDaysDiff';

/**
 * 잔여일 문구 생성
 *
 * @param {Date | string} expire_on
 */
export default (expire_on, start_on) => {
  const start = dayjs(start_on).format('YYYYMMDD') <= dayjs().format('YYYYMMDD') ? dayjs() : dayjs(start_on).subtract(1, 'd');
  const diff = getDaysDiff(expire_on, start);

  return diff === 0 ? '오늘 만료' : diff < 0 ? `${(diff * -1).toLocaleString()}일 지남` : `${diff.toLocaleString()}일 남음`;
};
