import React from 'react';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import PlainButton from 'components/PlainButton';
import { ReactComponent as NoTicket } from 'assets/icons/icon-no-ticket.svg';

class NoSelectionBox extends React.Component {    

    render() {
        return (
          <>
            <NoStudio>
              <NoTicket />
              <div className='no_studio_text'>
                <div>보유한 수강권이 없습니다.</div>
                <div className='light-gray'>시설에 수강권 등록을 요청하세요.</div>
              </div>
            </NoStudio>

            <BtnStyle>
              <PlainButton onClick={this.props.onClick}>새로고침</PlainButton>
            </BtnStyle>
          </>
        );
    }
    
};

const NoStudio = styled.div`

  @media (max-width: 320px) {
    width: 256px;
  }

  border-radius: 16px;
  width: 296px;
  min-height: 168px;
  text-align: center;
  margin: 40px auto 20px;
  padding: 16px;

  // object-fit: contain;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;

  .no_studio_text {
    line-height: 1.5;
    font-size: 14px;
    color: ${theme.color.black500};

    .light-gray {
      color: #818181;
    }
  }
`;

const BtnStyle = styled.div`
  margin: 0 16px;

  .mbsc-ios.mbsc-btn {
    font-size: 14px;
    font-weight: bold;
  }
`;

export default NoSelectionBox;