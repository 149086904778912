import React, { useState } from 'react';
import { TextareaItem } from 'antd-mobile';
import styled from 'styled-components';

import PlainButton from 'components/PlainButton';
import { LABEL } from 'constants/text';
import { theme } from 'assets/styles/theme';

type FooterCommentTypes = {
  saveComment: (comment: string) => void
};

const FooterComment = ({ saveComment }: FooterCommentTypes) => {
  const [comment, setComment] = useState<any>('');

  const handleSaveComment = () => {
    saveComment(comment);
    setComment('');
  };

  return (
    <CommentInputWrap>
      <TextareaItem
        rows={comment ? 3 : 1}
        placeholder={LABEL.commentPlaceholder}
        onChange={setComment}
        value={comment}
        className="full-width comment"
      />

      <div className='saveBtn'>
        <PlainButton className='flex-both-center' onClick={handleSaveComment} color="primary" disabled={!comment.length}>저장</PlainButton>
      </div>
    </CommentInputWrap>
  );
};

/** css */
const CommentInputWrap = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${theme.color.sub}; 
  max-height: 100px;
  padding: 5px 16px 5px 5px;
  z-index: 1;

  textarea {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black300};
  }
  .comment {
    flex: 1;
    padding-right: 15px;
  }
  .saveBtn {
    z-index: 2;

    button {
      width: 47px;
      height: 33px;
      color: #fff !important;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;


export default React.memo(FooterComment);
