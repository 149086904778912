import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import localStorage from 'utils/localStorage';
import { useEffect } from 'react';
import { setAuthHeaders, logout, getUserAccount } from 'store/auth/actions';
import authApi from 'api/modules/auth';
import { renderToString } from 'react-dom/server';
import RequestErrorMarkup from 'components/RequestErrorMarkup';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (localStorage.get('access_token')) {
      authApi
        .refreshToken()
        .then(result => {
          const accessToken = result?.data?.access_token;
          if (accessToken) {
            setAuthHeaders(accessToken);
            localStorage.asyncSet('access_token', accessToken).then(() => {
              setIsLoaded(true);
            });

            dispatch(getUserAccount());
          }
        })
        .catch(() => {
          alert({
            message: renderToString(RequestErrorMarkup),
            callback: () => {
              dispatch(logout());
            },
          });
        });
    }
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.get('access_token') ? (
          isLoaded && <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
