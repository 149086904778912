import React from 'react';
import Modal from 'antd-mobile/lib/modal';

type confirmModalProps = {
  title: string;
  show: boolean;
  setShow: (value: boolean) => void;
  action: any;
  children: React.ReactNode;
};

const ConfirmModal = ({ title, show, setShow, action, children }: confirmModalProps) => {

  return (
    <Modal
      className='modal-style-detail'
      title={title}
      transparent
      maskClosable={false}
      visible={show}
      onClose={() => setShow(false)}
      footer={[
        {
          text: '아니오',
          onPress: () => setShow(false),
        },
        {
          text: '예',
          onPress: () => {
            action();
            setShow(false);
          },
        },
      ]}>
      {children}
    </Modal>
  );
};

export default ConfirmModal;
