import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as mobiscroll from '@mobiscroll/react';
import 'assets/styles/global-mobiscroll.scss';
import 'antd-mobile/dist/antd-mobile.css';
import moment from 'moment';
import 'moment/locale/ko';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import GlobalStyle from 'assets/styles/global-styles.ts';
import ContextProvider from 'contexts/ContextProvider';
import Routes from 'pages/Routes';
import rootReducer from 'store';
import rootSaga from 'store/rootSaga';
import { CookiesProvider } from 'react-cookie';
import axios from 'api/axios';
import { logout } from 'store/auth/actions';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import { theme } from 'assets/styles/theme';
import RequestErrorMarkup from 'components/RequestErrorMarkup';
import { renderToString } from 'react-dom/server';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import socket from './socket';

dayjs.locale('ko');

mobiscroll.settings = {
  lang: 'ko',
  theme: 'ios',
  themeVariant: 'light',
  okText: '확인',
  cancelText: '취소',
};

const errorInfo = {
  isError: false,
};

const customHistory = createBrowserHistory();

window.alert = mobiscroll.alert;
window.mobilsConfirm = mobiscroll.confirm;

/** store */
moment.locale('ko');
const sagaMiddleware = createSagaMiddleware({
  context: {
    history: customHistory,
  },
});

const enhancer = composeWithDevTools(
  applyMiddleware(
    sagaMiddleware,
    // logger
  ),
);

export const store = createStore(rootReducer, enhancer);

const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

const UNAUTHORIZED = 401;

const { dispatch } = store; // direct access to redux store.

// axios interceptor 코드를 여기 쓴 이유는 axios 파일에서는 store dispatch가 불가능해서이다.
axios.interceptors.response.use(
  response => response,
  async error => {
    if (
      (!errorInfo.isError && error?.response?.data?.message === 'Unauthenticated.' && error?.response?.config?.url !== '/me/instance-token') ||
      (error?.code === 'ECONNABORTED' && !errorInfo.isError)
    ) {
      if (error?.code === 'ECONNABORTED') {
        alert({
          message: '이용자가 많아 처리가 지연되고 있습니다. 잠시후 다시 시도해주세요.',
        });
      } else {
        switch (error?.response?.status) {
          case UNAUTHORIZED:
            if (customHistory?.location?.pathname !== '/login') {
              alert({
                message: renderToString(RequestErrorMarkup),
                callback: () => {
                  dispatch(logout());
                },
              });
            }
            break;
          default:
            alert({ message: '서버와의 통신에 실패 하였습니다. \n 앱을 재 실행 해 주세요.' });
            break;
        }
      }
    }

    // 1초동안에 여러개의 공통 error가 들어오면 1개의 에러를 표시해줌
    if (!errorInfo.isError) {
      errorInfo.isError = true;
      setTimeout(() => {
        errorInfo.isError = false;
      }, 1000);
    }

    return Promise.reject(error);
  },
);

const tracesSampleRate = process.env.REACT_APP_ENV === 'production' ? 0.01 : 1.0;
const sampleRate = process.env.REACT_APP_ENV === 'production' ? 0.1 : 1.0;

Sentry.init({
  dsn: 'https://862abc17aff5461d8cc846cc3b9be8b2@o1118829.ingest.sentry.io/6168321',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  release: process.env.REACT_APP_GIT_VERSION || null,
  sampleRate,
  tracesSampleRate,
});

socket();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <CookiesProvider>
        <ContextProvider>
          <GlobalStyle />
          <ThemeProvider theme={theme}>
            <Router history={customHistory}>
              <Routes />
            </Router>
          </ThemeProvider>
        </ContextProvider>
      </CookiesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);
