import React, { useCallback, useEffect } from 'react';

import styled from 'styled-components';
import { ReactComponent as Edit } from 'assets/icons/icon-24-edit.svg';
import AvatarDefault from 'assets/icons/avatar-default.png';
import StudiomateDefault from 'assets/icons/studiomate-logo.png';
import MessageIconDefault from 'assets/icons/message-icon.png';
import { PermissionStatus } from 'store/permission/types';

type AvatarProps = {
  imgUrl?: any;
  // 기본이미지가 스튜디오 로고일때
  defaultType?: 'avatar' | 'message' | 'studio';
  size?: string;
  edit?: boolean;
  onChange?: (file: any) => void;
  className?: any;
  cameraPermission?: PermissionStatus;
  photoLibraryPermission?: PermissionStatus;
};

const Avatar = ({
  className,
  imgUrl,
  size = 'small',
  edit = false,
  onChange = () => {},
  defaultType = 'avatar',
  cameraPermission,
  photoLibraryPermission,
}: AvatarProps) => {
  const convertIcon = () => {
    return {
      avatar: AvatarDefault,
      studio: StudiomateDefault,
      message: MessageIconDefault,
    }[defaultType];
  };

  const [currentFile, setCurrentFile] = React.useState();

  let fileReader: undefined | FileReader;

  const fileRef = React.useRef<any>(null);

  const fileSelect = useCallback(() => {
    if (edit) {
      if (cameraPermission === 'granted' || photoLibraryPermission === 'granted') {
        fileRef.current.click();
      } else {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'REQUEST_PERMISSION' }));
        } else {
          alert({ message: 'window.ReactNativeWebView.postMessage가 없습니다' });
        }
      }
    }
  }, [cameraPermission, photoLibraryPermission]);

  const handleFileRead = (e: any) => {
    if (fileReader) {
      setCurrentFile(e.target.result);
    }
  };

  const handleFileUpdate = (e: any) => {
    return new Promise((resolve, reject) => {
      const file = e.target.files[0];
      fileReader = new FileReader();
      if (!file) {
        reject();
        return;
      }
      fileReader.onload = event => {
        handleFileRead(event);
        resolve(file);
      };
      fileReader.readAsDataURL(file);
    });
  };

  return (
    <Wrap onClick={fileSelect} size={size} className={className}>
      <AvatarImg src={currentFile || imgUrl || convertIcon()} imgUrl={imgUrl} />
      {edit && (
        <>
          <EditIcon size={size} />
          <InputFile
            type="file"
            ref={fileRef}
            onChange={e => {
              handleFileUpdate(e).then(onChange);
            }}
            accept="image/gif,image/jpeg,image/jpg,image/png"
          />
        </>
      )}
    </Wrap>
  );
};

/** css */

const handleSize = (size: string = 'small') => {
  switch (size) {
    case 'small':
      return { avatar: '24px', edit: '12px' };
    case 'small-2':
      return { avatar: '32px', edit: '12px' };
    case 'medium':
      return { avatar: '40px', edit: '12px' };
    case 'large':
      return { avatar: '64px', edit: '12px' };
    case 'x-large':
      return { avatar: '124px', edit: '32px' };
    default:
      return { avatar: '24px', edit: '12px' };
  }
};

const Wrap = styled.div<AvatarProps>`
  display: inline-block;
  position: relative;
  width: ${props => handleSize(props.size).avatar};
  height: ${props => handleSize(props.size).avatar};
`;

const InputFile = styled.input`
  display: none;
`;

const AvatarImg = styled.img.attrs((props: any) => ({
  imgUrl: props.imgUrl,
  size: props.size,
}))`
  ${(props: any) => {
    return {
      borderRadius: '50%',
      border: props.imgUrl ? '1px solid lightgrey' : 'none',
      width: '100% !important',
      height: '100% !important',
    };
  }}
`;

const EditIcon = styled(Edit)<AvatarProps>`
  background: lightgray;
  color: #ffffff;
  border-radius: 50%;
  border: 2px solid white;
  width: ${props => handleSize(props.size).edit};
  height: auto;
  position: absolute;
  bottom: 5%;
  right: 5%;
`;

export default React.memo(Avatar);
