import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import List from 'antd-mobile/lib/list';
import styled from 'styled-components';
import moment from 'moment';
import dayjs from 'dayjs';

import { getAvailablePrivateList, getAvailablePrivateListS } from 'store/booking/actions';
import { theme } from 'assets/styles/theme';
import MainLayout from 'components/MainLayout';
import Avatar from 'components/Avatar';
import { HEADER_TITLE, ERROR_TYPES } from 'constants/text';
import { _ } from 'utils/fx';
import getImageUrl from 'utils/getImageUrl';
import BookingActionPrivate from 'components/BookingAction/BookingActionPrivate';
import filters from 'utils/filters';

const { Item } = List;
const { Brief } = Item;

type LocationType = {
  state: { id: number; timeTableId: number };
};

export type staff = {
  avatar: { host: string; name: string; path: string };
  careers: { career: string; id: number }[];
  id: number;
  name: string;
};

type StaffType = {
  data: staff[];
  id: number;
  viewText: string | null;
};

const BookingPrivateStaffList = ({ location }: { location: LocationType }) => {
  const history = useHistory();
  const { availablePrivateList, calendarData } = useSelector((state: RootState) => state.booking);
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [holding] = useState<any>([]);

  /** booking */
  const [booking, setBooking] = useState<any>({
    show: false,
    time: {},
    staff: {},
    id: Number,
  });

  const [limit, setLimit] = useState<any>({
    currentLimit: '',
    startLimit: '',
    endLimit: '',
    whenToShow: false,
  });

  const { ticketRaw } = useSelector((state: RootState) => state.studio).currentStudioData;
  const { private_booking_limit_day_term, private_booking_limit_day_term_time, private_booking_limit_date } = useSelector(
    (state: RootState) => state.studio,
  ).currentStudioData?.studio?.policy;

  /** func */

  // 안드로이드 goback
  useEffect(() => {
    if (history.location.pathname === `/booking-private/${location.state.id}/staff-detail`) {
      setBooking({ ...booking, show: false });
    }
  }, [history.location.pathname, booking.show]);

  const goToBooking = (timeTable: StaffType, staff: staff) => {
    if (dayjs(calendarData.selectDate).format('YYYY-MM-DD') !== dayjs(availablePrivateList?.data[0]?.start_on).format('YYYY-MM-DD')) {
      return history.push(`/booking-private/${ticketRaw.id}`);
    }

    const time = _.omit(['data'], timeTable);
    setBooking({ show: true, time, staff, id: location.state.id });
    history.push(`/booking-private/${location.state.id}/staff-detail/#`, location.state);

    // return history.push({
    //   pathname: `/booking-private/${location.state.id}/course-detail`,
    //   state: { time, staff, id: location.state.id },
    // });
  };

  const dispatchBookingList = (current: string, start: string, end: string, whenToShow: boolean, date: string) => {
    // 수강권 시작일 전 날짜일때
    if (moment(date).isBefore(ticketRaw.availability_start_at)) {
      return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.beforeTicket, data: [] }, null));
    }

    // 정지
    if (holding.includes(date)) {
      return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    }
    // if (ticketRaw.now_holding && moment(date).isBetween(ticketRaw.now_holding?.start_on, ticketRaw.now_holding?.end_on, null, '[]')) {
    //   return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    // }

    // 예약가능기한
    // 고급설정 === null
    if (!private_booking_limit_date) {
      if (private_booking_limit_day_term >= 0 && moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
        return dispatch(
          getAvailablePrivateListS(
            {
              reason: `수업일 기준 ${private_booking_limit_day_term}일 전 ${private_booking_limit_day_term_time.slice(
                0,
                5,
              )}부터 <br />예약 가능합니다.`,
              data: [],
            },
            null,
          ),
        );
      }
      if (private_booking_limit_day_term >= 0 && whenToShow && moment(date).isSame(current) && current <= end && beforeOpen(date)) {
        return dispatch(
          getAvailablePrivateListS(
            {
              reason: `수업일 기준 ${private_booking_limit_day_term}일 전 ${private_booking_limit_day_term_time.slice(
                0,
                5,
              )}부터 <br />예약 가능합니다.`,
              data: [],
            },
            null,
          ),
        );
      }
    }

    // 고급설정 !== null
    else if (moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
      const reason =
        private_booking_limit_day_term === 0
          ? `${filters.dateWithWeekdayKor(current, 'zeroValue')}까지<br />예약이 가능합니다`
          : `${filters.dateWithWeekdayKor(current)}부터<br />예약이 가능합니다`;

      return dispatch(getAvailablePrivateListS({ reason, data: [] }, null));
    }

    dispatch(getAvailablePrivateList(Number(id), date));
  };

  const beforeOpen = (selectedDate: string) => {
    const boundary = moment(`${selectedDate} ${private_booking_limit_day_term_time}`).subtract(private_booking_limit_day_term, 'days');
    const today = moment();
    return today.isBefore(boundary);
  };

  const StaffList: Function = () => {
    const res: StaffType = _.go(
      availablePrivateList.data,
      _.filter((_res: { id: number }) => _res.id === location.state.timeTableId),
    )[0] || { data: [] };

    return res.data.map(el => (
      <ListStyle key={el.id} onClick={() => goToBooking(res, el)}>
        <div className="item">
          {/* 날짜 */}
          <div className="date">{res.viewText}</div>

          {/* 강사이름 */}
          <div className="user-info">
            <Avatar className="user_img" size="small-2" imgUrl={getImageUrl(el?.avatar, '32x32')} />
            <span className="content">{el.name} 강사</span>
          </div>

          {/* 룸 */}
          {/* {el.room && <Brief style={{ whiteSpace: 'pre-wrap' }}>{el.room.name} 룸} */}
        </div>
      </ListStyle>
    ));
  };

  return (
    <MainLayout header={{ title: HEADER_TITLE.privateBooking, titleNormal: true, backFunc: history.goBack }} scroll>
      <StaffList style={{ overflowY: 'auto' }} />

      <BookingActionPrivate
        show={booking.show}
        time={booking.time}
        staff={booking.staff}
        id={booking.id}
        onRequestClose={() => {
          setBooking({ ...booking, show: false });
          history.goBack();
        }}
        dispatchBookingList={dispatchBookingList}
        beforeOpen={beforeOpen}
        setLimit={setLimit}
      />
    </MainLayout>
  );
};

const ListStyle = styled.div`
  .date {
    font-size: 18px;
    font-weight: 500;
    color: ${theme.color.black300};
    margin-bottom: 8px;
  }
  .content {
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black500};
  }
  .user-info {
    display: flex;
    align-items: center;
  }
  .user_img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
    color: #555;
    font-size: 14px;
  }
  .item {
    margin: 12px 16px;
    margin-bottom: 24px;
    border-radius: 16px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    padding: 12px;
    height: 146px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default BookingPrivateStaffList;
