import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import checkNewNotification from 'utils/checkNewNotification';

import useGlobalUI from 'hooks/useGlobalUI';
import { theme } from 'assets/styles/theme';
import NavBar from 'antd-mobile/lib/nav-bar';
import { ReactComponent as Notice } from 'assets/icons/icon-24-noti.svg';
import { ReactComponent as Badge } from 'assets/icons/icon-16-badge.svg';
import { ReactComponent as Menu } from 'assets/icons/icon-24-menu.svg';
import { ReactComponent as Back } from 'assets/icons/icon-24-arrow-left.svg';
import { ReactComponent as Edit } from 'assets/icons/icon-24-edit.svg';

type TitleType = {
  titleNormal?: boolean;
  titleBorder?: boolean;
  length?: any;
};

type HeaderType = {
  title: string;
  backFunc?: (e: any) => void;
  params?: any;
  notification?: boolean;
  noBackBtn?: boolean;
  noLeftIcon?: boolean;
  children?: React.ReactNode;
} & TitleType;

function Header({ title, backFunc, params, notification, noBackBtn, noLeftIcon = false, titleNormal, titleBorder = true, children, length }: HeaderType) {
  const [isNewNoti, setIsNewNoti] = useState<boolean>(false);
  /** hooks */
  const history = useHistory();
  const { openGlobalMenu } = useGlobalUI(true);

  useEffect(() => {
    if (notification) {
      checkNewNotification()
      .then((result) => {
        if (result) {
          setIsNewNoti(result);
        }
      })
    }
  }, [])

  const rightContent = () => {
    if (notification) {
      return (
        <NotiIcon onClick={() => history.push('/notification')}>
          <Notice className='notice' />
          {isNewNoti && <Badge className='badge' />}
        </NotiIcon>
      )
    } 

    if (history.location.pathname === '/mypage') {
      return (
        <NotiIcon onClick={() => history.push('/mypage/edit')}>
          <Edit className='edit' />
        </NotiIcon>
      )
    }
  };

  return (
    <WrapHeader titleNormal={titleNormal} titleBorder={titleBorder} length={length}>
      <NavBar
        mode="light"
        icon={!noLeftIcon ? (!noBackBtn ? <Back /> : <Menu />) : null}
        onLeftClick={backFunc ? () => backFunc(params) : openGlobalMenu}
        rightContent={rightContent()}>
        {/* {length > 1 ? children : <div onClick={goToStudioInfo}>{title}</div>} */}
        {children || <div>{title}</div>}
      </NavBar>
    </WrapHeader>
  );
}

export default React.memo(Header);

/** css */
const NotiIcon = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  align-items: center;

  .notice, .edit {
    position: absolute;
  }

  .badge {
    margin-right: -13px;
    margin-top: -10px;
    z-index: 1;
  }

  svg g g {
    fill: ${theme.color.black500};
  }
`;

const WrapHeader = styled.div<TitleType>`
  z-index: 2;
  box-shadow: ${props => props.titleBorder ? '0 2px 4px rgba(6, 6, 6, 0.3)' : 'none'};

  ${props => {
    if (props.length > 1) {
      return `
        .am-navbar {
          display: grid;
          grid-template-rows: 1fr;
          grid-template-columns: auto 1fr auto;
        }

        .am-navbar-left, .am-navbar-title, .am-navbar-right {
          display: grid;
        }

        .am-navbar-left {
          padding-right: 15px;
        }

        .am-navbar-right {
          padding-left: 15px;
        }
      `;
    }
  }}

  .am-navbar-title {
    font-size: 18px;
    font-weight: bold;
    color: ${theme.color.black300};
  }

  .am-navbar-left-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    .studio {
      font-size: 14px;
      font-weight: normal;
      color: ${theme.color.black500};
    }
  }

  ${props => {
    if (props.titleNormal) {
      return `
        .am-navbar-title {
          font-size: 16px;
          font-weight: normal;
        }
      `;
    }
  }}
`;
