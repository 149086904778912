import React  from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface PostImagesProps {
  images: {
    url: string,
    id: number
  }[]
}

type countProps = {
  count: number
}

const handleImgError = (e: any) => {
  e.target.src = require('assets/images/board-dummy-img.png');
}

function PostImages({ images }: PostImagesProps) {
  const history = useHistory();

  return (
    <PostImagesWrap count={images.length}>
      {
          images.map((image, idx) => {
            return (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <img 
                key={image.id} 
                onClick={() => history.push({
                  pathname: '/board-detail/image',
                  state: { images, idx }
                })} 
                className="post-image" 
                src={image.url} 
                alt={image.url} 
                onError={(e) => handleImgError(e)} 
              />
            )
          })
        }
    </PostImagesWrap>
  );
}

/** css */
const PostImagesWrap = styled.div<countProps>`
  padding: 8px 24px;
  text-align: left;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;

  .post-image {
    width: 80px;
    height: 80px;
    border-radius: 10%;
    align-self: center;
    justify-self: center;
  }

  img:first-child {
    align-self: start;
    justify-self: start;
  }

    ${props => {
      if (props.count === 2) {
        return (
          `img:last-child {
            align-self: center;
            justify-self: center;
          }`
        );
      }
      if (props.count === 3) {
        return (
          `img:nth-child(2) {
            align-self: center;
            justify-self: center;
          }
          
          img:last-child {
            align-self: end;
            justify-self: end;
          }`
        );
      }
    }  
  }
`;

export default PostImages;
