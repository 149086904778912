import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import studio from 'api/modules/studio';

import { GET_USER_TICKET_REQUEST, GET_USER_TICKET_SUCCESS, GET_USER_TICKET_FAILURE, GET_USER_STUDIO_REQUEST, GET_USER_STUDIO_SUCCESS, GET_USER_STUDIO_FAILURE } from './types';

function* getUserTicketSaga() {
  try {
    
    const result = yield call(studio.getUserTicket);
    const companies = [];

    if (result.data !== 'undefined') {
      result.data.forEach((el) => {
        /** 시설 만료된 경우 시설 선택 및 수강권 선택에서 제외 */
        if (el.studio.disable) return;

        const checkDup = companies.findIndex((i) => i.studio.id === el.studio_id);
        if (checkDup === -1) {
          companies.push({ studio: el.studio, ticket: [el] });
        } else {
          companies[checkDup].ticket.push(el);
        }
      });
    }

    yield put({ type: GET_USER_TICKET_SUCCESS, data: companies });
  } catch (err) {
    console.log(err);
    yield put({ type: GET_USER_TICKET_FAILURE, data: err });
  }
}

function* watchGetUserTickets() {
  yield takeLatest(GET_USER_TICKET_REQUEST, getUserTicketSaga);
}

function* getUserStudioSaga() {
  try {
    const result = yield call(studio.getUserStudio);
    yield put({ type: GET_USER_STUDIO_SUCCESS, data: result.data });
  } catch (err) {
    console.log(err);
    yield put({ type: GET_USER_STUDIO_FAILURE, data: err });
  }
}

function* watchGetUserStudios() {
  yield takeLatest(GET_USER_STUDIO_REQUEST, getUserStudioSaga);
}

export default function* studioSaga() {
  yield all([
    fork(watchGetUserTickets),
    fork(watchGetUserStudios),
  ]);
}
