import React, { useState, useContext, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash-es';

import useInput from 'hooks/useInput';
import useValid from 'hooks/useValid';
import Store from 'contexts/store/store';
import StepOnePresenter from 'pages/SignUp/StepOnePresenter';
import StepTwoPresenter from 'pages/SignUp/StepTwoPresenter';
import { VALIDATE_MESSAGE, SUCCESS_MESSAGE, ERROR_TYPES, MODAL_TEXT } from 'constants/text';
import { signupType } from './types';

const SignUpContainer = () => {
  /** hook */
  const history = useHistory();
  const { apis } = useContext(Store);

  /** state */
  const [signUpData, setSignUpData] = useState<signupType>({
    step: 1,
    user_account: {
      email: null,
      mobile: null,
      password: null,
      name: null,
    },
    validation_id: null,
    validation_code: null,
  });
  const [checked, setCheckBox] = useState<{ [key: string]: boolean }>({
    useAgree: false,
    infoAgree: false,
  });
  const [text, setText] = useInput({
    mobile: '',
    validateCode: '',
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [valid, validate] = useValid({
    mobile: false,
    validateCode: false,
    name: true,
    email: true,
    password: true,
    confirmPassword: true,
  });
  const [isClickValied, setClick] = useState(false);
  const [loading, setLoading] = useState(false);

  /** func */
  const onCheckBox = (target: string) => {
    setCheckBox({
      ...checked,
      [target]: !checked[target],
    });
  };

  const onChange = useCallback(
    e => {
      setText(e);
      validate(e);
    },
    [setText, validate],
  );

  const setValidCode = (event: any) => {
    onChange({ target: { value: event, name: 'validateCode' } });
  };

  const sendValidCode = async () => {
    try {
      // 휴대폰 중복 체크
      const duplicationCheck = await apis.auth.checkDuplicationMobile({ value: text.mobile });
      if (duplicationCheck.data.count) {
        alert({ message: '중복된 휴대폰 번호가 있습니다.' });
      } else {
        const res = await apis.auth.requestSmSCode({ mobile: text.mobile });
        if (res?.status === 200) {
          setSignUpData({ ...signUpData, validation_id: res.data.id });
          alert({
            message: MODAL_TEXT.sendValidCode,
            callback: () => setClick(true),
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkCode = async () => {
    try {
      const res = await apis.auth.checkDupEmail({
        ..._.omit(signUpData, ['step', 'user_account']),
        validation_code: text.validateCode,
        mobile: text.mobile,
      });

      if (res.data.message === 'success') {
        alert({ message: '휴대폰 번호 인증이 완료되었습니다.' });
        setSignUpData({
          ...signUpData,
          user_account: { ...signUpData.user_account, password: text.mobile },
          validation_code: text.validateCode,
          step: 3,
        });
      }
    } catch (error) {
      if (error?.response?.data?.data) {
        alert({ message: `중복된 휴대폰 번호입니다. \n해당 번호로 가입된 이메일을 확인해주세요. \n${error?.response?.data?.data[0]}` });
      } else {
        alert({ message: error?.response?.data?.message });
      }
    }
  };

  const checkEmail = async () => {
    try {
      const res = await apis.auth.checkDuplicationEmail({
        value: text.email,
      });
      if (res.data.count > 0) {
        alert({
          message: '중복된 이메일 주소입니다.',
          callback: () => {},
        });
      } else {
        alert({
          message: '사용 가능한 이메일입니다.',
        });
      }
    } catch (error) {
      alert({
        message: '이메일 중복 확인 에러',
      });
      console.log(error);
    }
  };

  const onSignUp = async () => {
    if (text.confirmPassword !== text.password) return alert(VALIDATE_MESSAGE.incorrectPassword);

    try {
      const checkRes = await apis.auth.checkDuplicationEmail({
        value: text.email,
      });

      if (checkRes.data.count) return alert({ message: '중복된 이메일 주소입니다.' });

      setLoading(true);
      const params = {
        ..._.omit(signUpData, ['step']),
        user_account: _.omit(text, ['validateCode', 'confirmPassword']),
      };
      const res = await apis.auth.signUp(params);
      if (res.status === 201) {
        setLoading(false);
        return history.push({
          pathname: '/notlogin-success',
          state: { ...SUCCESS_MESSAGE.signUp },
        });
      }
    } catch (error) {
      alert({ message: ERROR_TYPES.serverError, callback: setLoading(false) });
    }
    return null;
  };

  const disabled = useMemo(() => {
    return !(
      valid.name &&
      text.name &&
      valid.email &&
      text.email &&
      valid.password &&
      text.password &&
      text.password === text.confirmPassword &&
      signUpData.step === 3 &&
      !loading
    );
  }, [valid, text, loading, signUpData]);

  return (
    <>
      {signUpData.step === 1 ? (
        <StepOnePresenter signUpData={signUpData} setSignUpData={setSignUpData} checked={checked} onCheckBox={onCheckBox} />
      ) : (
        <StepTwoPresenter
          text={text}
          valid={valid}
          loading={loading}
          onChange={onChange}
          sendValidCode={sendValidCode}
          setValidCode={setValidCode}
          checkCode={checkCode}
          onSignUp={onSignUp}
          signUpData={signUpData}
          setSignUpData={setSignUpData}
          disabled={disabled}
          isClickValied={isClickValied}
          checkEmail={checkEmail}
        />
      )}
    </>
  );
};

export default SignUpContainer;
