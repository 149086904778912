import React from 'react';
import { ReactComponent as BookingIcon } from 'assets/icons/icon-16-check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/icon-16-check-red.svg';
import { ReactComponent as BookingChangeIcon } from 'assets/icons/icon-16-check-purple.svg';
import filters from 'utils/filters';

const convertAvailableTime = (startOn, endOn, type) => {
  switch (type) {
    case 'booking':
      return (
        <>
          <BookingIcon />&nbsp;&nbsp;
          {!!startOn && `${filters.datetime(startOn)} 부터 `}
          {filters.datetime(endOn)} 까지 &nbsp;
          <b>예약 가능</b>
        </>
      );
    case 'cancel':
      return (
        <>
          <CancelIcon />&nbsp;&nbsp;
          {!!startOn && `${filters.datetime(startOn)} 부터 `}
          {filters.datetime(endOn)} 까지 &nbsp;
          <b>취소 가능</b>
        </>
      );
    case 'daily':
      return (
        <>
          <BookingChangeIcon />&nbsp;&nbsp;
          {filters.datetime(endOn)} 까지 &nbsp;
          <b>예약 변경 가능</b>
        </>
      );
    default:
      
  }
};

export default convertAvailableTime;