/** 계정 */
// import dayjs from 'dayjs';
import produce from 'immer';
import localStorage from 'utils/localStorage';
import { LOGOUT, SET_USERS, DELETE_TOKEN, GET_ACCOUNT_REQUEST, GET_ACCOUNT_SUCCESS, GET_ACCOUNT_FAILURE, IAuthState } from 'store/auth/types';
import { setAuthHeaders, ActionRequest } from 'store/auth/actions';
// import storage from 'redux-persist/lib/storage';

export const initialState: IAuthState = {
  accessToken: localStorage.get('access_token'),
  expired_in: localStorage.get('expired_in'),
  user: null,
  fcmToken: null,
  loading: false,
  isLogin: localStorage.get('access_token'),
};

export const auth = (state: IAuthState = initialState, action: ActionRequest) =>
  produce(state, draft => {
    switch (action.type) {
      case SET_USERS:
        localStorage.set('access_token', action.payload.data.access_token);
        // localStorage.set('expired_in', dayjs().add(action.payload.data.expires_in, 's'));
        // localStorage.set('expired_in', dayjs().add(120, 's'));
        setAuthHeaders(action.payload.data.access_token);
        draft.accessToken = action.payload.data.access_token;
        // draft.expired_in = action.payload.data.expires_in;
        draft.user = action.payload.data.user_account;
        draft.isLogin = true;
        break;
      case DELETE_TOKEN:
        localStorage.remove();
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'DELETE_TOKEN' }));
        }
        draft.accessToken = null;
        draft.user = null;
        draft.expired_in = null;
        draft.isLogin = false;
        break;
      case GET_ACCOUNT_REQUEST:
        draft.loading = true;
        break;
      case GET_ACCOUNT_SUCCESS:
        draft.loading = false;
        draft.user = action.payload.data;
        draft.isLogin = true;
        break;
      case GET_ACCOUNT_FAILURE:
        draft.loading = false;
        draft.user = {};
        break;
      case LOGOUT:
        // if (window.ReactNativeWebView) {
        //   window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'DELETE_TOKEN' }));
        // };
        // localStorage.remove();
        // storage.removeItem('persist:root');
        // draft.isLogin = false;
        break;
      default:
        return state;
    }
  });

export default auth;
