import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { openMenu, closeMenu } from 'store/globalUI/actions';

export default (show: boolean) => {
  const dispatch = useDispatch();

  const openGlobalMenu = useCallback(() => dispatch(openMenu(show)), [dispatch]);
  const closeGlobalMenu = useCallback(() => dispatch(closeMenu(show)), [dispatch]);


  return {
    openGlobalMenu,
    closeGlobalMenu,
  }
};