import React from 'react';
import styled from 'styled-components';

import TextInput from 'components/TextInput';
import VerficationInput from 'components/VerficationInput';
import PlainButton from 'components/PlainButton';
import { AUTH_TYPES, VALIDATE_MESSAGE, LABEL, PLACEHOLDER } from 'constants/text';
import { stopOnePresenterProps } from './types';

/** step1 - email, 모바일, 인증번호 입력 창 */
const StepOnePresenter = ({ goToNextStep, email, mobile, validateCode, valid, isClickValidButton, onChange, onSubmit, confirmSmsCode, smsValid }: stopOnePresenterProps) => {
  return (
    <WrapLayout>
      <section className="wrap-scroll">
        <InfoTxt>
          <span>카카오톡 또는 문자 메시지로 비밀번호 찾기 정보를 보내드립니다.</span>
          <span>이메일과 휴대폰 번호를 입력하고 휴대폰 인증을 해주세요.</span>
        </InfoTxt>

        <div style={{ paddingBottom: '10px' }}>
          <TextInput
            id={AUTH_TYPES.email}
            name={AUTH_TYPES.email}
            type="email"
            value={email}
            label={LABEL.email}
            placeholder={LABEL.emailPlaceholder}
            onChange={onChange}
            error={!valid.email}
            errorMessage={VALIDATE_MESSAGE.incorrectEmail}
            elseMessage={LABEL.emailGuild}
        />
        </div>
        
        <StyledSection>
          <TextInput
            id={AUTH_TYPES.mobile}
            name={AUTH_TYPES.mobile}
            type="number"
            value={mobile}
            label={LABEL.mobile}
            placeholder={LABEL.mobilePlaceholder}
            onChange={onChange}
            error={!valid.mobile}
            errorMessage={VALIDATE_MESSAGE.incorrectNumber}
          />
          <StyledBtnArea>
            <PlainButton className='flex-both-center' color="primary" disabled={!(valid.mobile && valid.email && mobile && email)} onClick={onSubmit}>
              인증번호받기
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>
        
        <StyledSection>
          <TextInput
            id={AUTH_TYPES.validateCode}
            name={AUTH_TYPES.validateCode}
            type="number"
            value={validateCode}
            label={LABEL.validateCode}
            placeholder={PLACEHOLDER.validateCode}
            onChange={onChange}
            error={!valid.validateCode}
          />
          <StyledBtnArea>
            <PlainButton className='flex-both-center' color="primary" onClick={confirmSmsCode} disabled={!isClickValidButton}>
              인증번호확인 
            </PlainButton>
          </StyledBtnArea>
        </StyledSection>

        {/* <div className="flex-row-between">
          <VerficationInput placeholder="0" onChange={e => onChange({ target: { value: e, name: AUTH_TYPES.validateCode } })} length={6} /> */}
        {/* </div> */}

      </section>
      <BtmButton>
        <PlainButton color="primary" disabled={!smsValid} onClick={goToNextStep}>
          <b style={{ fontSize: '18px' }}>다음</b>
        </PlainButton>
      </BtmButton>
    </WrapLayout>
  );
};

export default StepOnePresenter;

export const StyledSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 107px;
  grid-column-gap: 10px;

  margin-top: 25px;
`;

export const WrapLayout = styled.div`
  height: calc(100vh - 45px - 24px); /** 45px : header height, 24px : top padding */
  display: grid;
  grid-template-rows: 1fr 60px;
  margin: 24px 16px;
  .flex-row-between > *:first-child {
    flex: 1;
    margin-right: 10px;
  }
`;
export const BtmButton = styled.div`
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    display: inline-block;
    margin: 0;
  }
`;

export const InfoTxt = styled.div`
  span {
    ${props => props.theme.fonts.font14};
    display: block;
    &:last-child {
      margin: 10px 0 32px;
    }
  }
`;

export const StyledBtnArea = styled.div`
  button.mbsc-ios.mbsc-btn {
    width: 100%;
    height: 33px;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    margin: 27px 0 0;
  }
`;
