import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { getAvailablePrivateList, getAvailablePrivateListS } from 'store/booking/actions';
import { RootState } from 'store';
import moment from 'moment';
import filters from 'utils/filters';
import _ from 'lodash-es';
import MainLayout from 'components/MainLayout';
import Calendar from 'components/Calendar/Calendar';
import { HEADER_TITLE, ERROR_TYPES } from 'constants/text';
import EmptyLayout from 'components/EmptyLayout';
import { theme } from 'assets/styles/theme';
import BookingActionPrivate from 'components/BookingAction/BookingActionPrivate';
import Accordion from 'components/Accordion';
import { getUserStudio, setStudio } from 'store/studio/reducer';

type BookingListType = { id: number; startTime: string; endTime: string; data: string[] };
type bookingContainerType = { step: number };

const BookingPrivate = () => {
  /** hooks */
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { loading, availablePrivateList, calendarData, usageCalendarData } = useSelector((state: RootState) => state.booking);
  const { ticketRaw } = useSelector((state: RootState) => state.studio).currentStudioData;
  const studio = useSelector((state: RootState) => state.studio);
  const prevCurrentStudio = useSelector((state: RootState) => state.studio.currentStudioData.studio);

  useEffect(() => {
    if (window.navigator.onLine) dispatch(getUserStudio());
  }, [calendarData.selectDate]);

  useEffect(() => {
    const currentStudio = studio.studios.find(studio => studio.id === prevCurrentStudio.id);
    if (currentStudio) dispatch(setStudio(currentStudio));
    else history.replace('/select-ticket');
  }, [studio.studios]);

  const { private_booking_limit_day_term, private_booking_limit_day_term_time, private_booking_limit_date } = useSelector(
    (state: RootState) => state.studio,
  ).currentStudioData?.studio?.policy;

  const [holding] = useState<any>([]);
  const [limit, setLimit] = useState<any>({
    currentLimit: '',
    startLimit: '',
    endLimit: '',
    whenToShow: false,
  });

  /** booking */
  const [booking, setBooking] = useState<any>({
    show: false,
    time: {},
    staff: {},
    id: Number,
  });

  const standardTime = ['오전', '오후', '저녁'];
  // 안드로이드 goback
  useEffect(() => {
    if (history.location.pathname === `/booking-private/${ticketRaw.id}`) {
      setBooking({ ...booking, show: false });
    }
  }, [history.location.pathname, booking.show]);

  // 수강권 정지
  useEffect(() => {
    if (ticketRaw.holdings) {
      ticketRaw.holdings.map(el => {
        let startDate = el.start_on;
        const endDate = el.end_on;

        holding.push(startDate);

        while (moment(startDate).isBefore(endDate)) {
          startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
          holding.push(startDate);
        }
      });
    }
  }, []);

  const beforeOpen = (selectedDate: string) => {
    const boundary = moment(`${selectedDate} ${private_booking_limit_day_term_time}`).subtract(private_booking_limit_day_term, 'days');
    const today = moment();
    return today.isBefore(boundary);
  };

  const dispatchBookingList = (current: string, start: string, end: string, whenToShow: boolean, date: string) => {
    // 수강권 시작일 전 날짜일때
    if (moment(date).isBefore(ticketRaw.availability_start_at)) {
      return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.beforeTicket, data: [] }, null));
    }

    // 정지
    if (holding.includes(date)) {
      return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    }
    // if (ticketRaw.now_holding && moment(date).isBetween(ticketRaw.now_holding?.start_on, ticketRaw.now_holding?.end_on, null, '[]')) {
    //   return dispatch(getAvailablePrivateListS({ reason: ERROR_TYPES.pauseTicket, data: [] }, null));
    // }

    // 예약가능기한
    // 고급설정 === null
    if (!private_booking_limit_date) {
      if (private_booking_limit_day_term >= 0 && moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
        return dispatch(
          getAvailablePrivateListS(
            {
              reason: `수업일 기준 ${private_booking_limit_day_term}일 전 ${private_booking_limit_day_term_time.slice(
                0,
                5,
              )}부터 <br />예약 가능합니다.`,
              data: [],
            },
            null,
          ),
        );
      }
      if (private_booking_limit_day_term >= 0 && whenToShow && moment(date).isSame(current) && current <= end && beforeOpen(date)) {
        return dispatch(
          getAvailablePrivateListS(
            {
              reason: `수업일 기준 ${private_booking_limit_day_term}일 전 ${private_booking_limit_day_term_time.slice(
                0,
                5,
              )}부터 <br />예약 가능합니다.`,
              data: [],
            },
            null,
          ),
        );
      }
    }

    // 고급설정 !== null
    else if (moment(date).isBetween(start, end, null, '[]') && moment(end).isAfter(current)) {
      const reason =
        private_booking_limit_day_term === 0
          ? `${filters.dateWithWeekdayKor(current, 'zeroValue')}까지<br />예약이 가능합니다`
          : `${filters.dateWithWeekdayKor(current)}부터<br />예약이 가능합니다`;

      return dispatch(getAvailablePrivateListS({ reason, data: [] }, null));
    }

    dispatch(getAvailablePrivateList(Number(id), date));
  };

  // 시설웹 > 설정 > 6. 예약가능기한
  useEffect(() => {
    // 고급설정 === null
    if (!private_booking_limit_date) {
      const limitTerm = private_booking_limit_day_term; // 7
      const diffTime = Math.abs(+moment() - +moment(ticketRaw.expire_at)); // 2968315324
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // 35

      const currentDate = moment()
        .add(limitTerm >= diffDays ? diffDays : limitTerm, 'days')
        .format('YYYY-MM-DD');
      const startDate = moment(currentDate)
        .add(beforeOpen(currentDate) ? 0 : 1, 'days')
        .format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      const whenToShow = moment(new Date(), 'hh:mm:ss').isBefore(moment(private_booking_limit_day_term_time, 'hh:mm:ss')); // 현재 시각 < 12:00:00 ? true

      dispatchBookingList(currentDate, startDate, endDate, whenToShow, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow });
    }

    // 고급설정 !== null
    else {
      const limitTerm = private_booking_limit_day_term; // 7
      let currentDate = private_booking_limit_date; // 2021-04-27 12:00:00

      if (moment(new Date()).isAfter(moment(currentDate))) {
        currentDate = moment(currentDate).add(limitTerm, 'days').format('YYYY-MM-DD hh:mm:ss'); // limitTerm 주기로 업데이트
      }

      const startDate = moment(currentDate).add(1, 'days').format('YYYY-MM-DD');
      const endDate = moment(ticketRaw.expire_at).format('YYYY-MM-DD');

      dispatchBookingList(currentDate, startDate, endDate, false, moment(calendarData.selectDate).format('YYYY-MM-DD'));
      setLimit({ currentLimit: currentDate, startLimit: startDate, endLimit: endDate, whenToShow: false });
    }
  }, [studio.currentStudioData.studio]);

  const getList = (date: string) => {};

  // 예약가능기한 회색 표시 날짜
  const bookingMaxDate = () => {
    return { start: limit.startLimit, end: limit.endLimit, background: theme.color.greyCCC };
  };

  // 수강권 시작 전 기간
  const beforeAvailable = () => {
    return {
      start: moment().format('YYYY-MM-DD'),
      end: moment(ticketRaw.availability_start_at).subtract(1, 'days').format('YYYY-MM-DD'),
      background: theme.color.greyDDD,
    };
  };

  // 예약가능기한 월 이동 안되게
  const overDeadline = () => {
    const over = moment(limit.startLimit).endOf('month').format('YYYY-MM-DD');
    return over;
  };

  // studio, 예약리스트 불러오는동안 로딩
  const [totalLoading, setTotalLoading] = useState<boolean>(false);

  useEffect(() => {
    setTotalLoading(true);
  }, [calendarData.selectDate]);

  useEffect(() => {
    setTotalLoading(false);
  }, [availablePrivateList, window.navigator.onLine, calendarData.selectDate]);

  return (
    <MainLayout
      header={{ title: HEADER_TITLE.privateBooking, titleNormal: true, titleBorder: false, notification: true, noBackBtn: true }}
      loading={totalLoading}
      notiGrid>
      <Calendar
        getList={getList}
        // startDate={!!moment().startOf('day').isBefore(ticketRaw.availability_start_at) && ticketRaw.availability_start_at}
        deadline={bookingMaxDate()}
        beforeAvailable={beforeAvailable()}
        customMax={overDeadline()}
      />

      <Container>
        {availablePrivateList.reason ? (
          availablePrivateList.reason === ERROR_TYPES.pauseTicket || availablePrivateList.reason === ERROR_TYPES.vacation ? (
            <EmptyLayout text={availablePrivateList.reason} color={theme.color.red} />
          ) : (
            <EmptyLayout text={availablePrivateList.reason} />
          )
        ) : (
          // map -> filter
          <>
            {availablePrivateList.data ? (
              availablePrivateList.data.filter((el: any) => el.time.length !== 0).length !== 0 ? (
                availablePrivateList.data
                  .filter((el: any) => el.time.length !== 0)
                  .map((el: any) => {
                    return (
                      <Accordion
                        key={el.staff.id}
                        staff={el.staff}
                        time={el.time}
                        history={history}
                        setBooking={setBooking}
                        _={_}
                        ticketRaw={ticketRaw}
                      />
                    );
                  })
              ) : (
                <EmptyLayout text={ERROR_TYPES.noAvailableBookingClass} />
              )
            ) : (
              <EmptyLayout text={ERROR_TYPES.noAvailableBookingClass} />
            )}
          </>
        )}
      </Container>

      <BookingActionPrivate
        show={booking.show}
        time={booking.time}
        staff={booking.staff}
        id={booking.id}
        onRequestClose={() => {
          setBooking({ ...booking, show: false });
          history.goBack();
        }}
        dispatchBookingList={dispatchBookingList}
        beforeOpen={beforeOpen}
        setLimit={setLimit}
      />
    </MainLayout>
  );
};

const Container = styled.div`
  overflow-y: auto;

  .am-accordion-item {
    height: 64px;
    margin: 16px;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
`;

const PrivateListContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 100%;
`;

const BookingListContainer = styled.div<bookingContainerType>`
  .standardTime {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.color.black500};
    padding-top: 8px;
  }
  border-right: ${props => {
    if (props.step !== 2) {
      return '1px solid #bbb';
    }
  }};
`;

const BookingListItem = styled.div`
  color: #555;
  font-size: 13px;
  text-align: center;

  margin: 16px 13px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
`;

export default BookingPrivate;
