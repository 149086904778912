/** auth */

/* eslint-disable */
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN' as const;

export const DELETE_TOKEN = 'DELETE_TOKEN' as const;

export const CHANGE_TAB = 'BOARD/CHANGE_TAB' as const;

export const SEND_VALID_CODE_REQUEST = 'SEND_VALID_CODE_REQUEST' as const;
export const SEND_VALID_CODE_SUCCESS = 'SEND_VALID_CODE_SUCCESS' as const;
export const SEND_VALID_CODE_FAILURE = 'SEND_VALID_CODE_FAILURE' as const;

export const CHANGE_EMAIL_REQUEST = 'CHANGE_EMAIL_REQUEST' as const;
export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS' as const;
export const CHANGE_EMAIL_FAILURE = 'CHANGE_EMAIL_FAILURE' as const;

export const CHANGE_USER_DATA = 'CHANGE_USER_DATA' as const;

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST' as const;
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS' as const;
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE' as const;

/** board */
export const GET_NOTICE_REQUEST = 'BOARD/GET_NOTICE_REQUEST' as const;
export const GET_NOTICE_SUCCESS = 'BOARD/GET_NOTICE_SUCCESS' as const;
export const GET_NOTICE_FAILURE = 'BOARD/GET_NOTICE_FAILURE' as const;

export const GET_CURRENT_BOARD = 'BOARD/GET_CURRENT_BOARD' as const;

export const GET_QNA_REQUEST = 'GET_QNA_REQUEST' as const;
export const GET_QNA_SUCCESS = 'GET_QNA_SUCCESS' as const;
export const GET_QNA_FAILURE = 'GET_QNA_FAILURE' as const;

export const SET_BOARD_KEYWORD = 'SET_BOARD_KEYWORD' as const;

export const RESET_NOTICE = 'RESET_NOTICE' as const;

export const DELETE_QNA_REQUEST = 'DELETE_QNA_REQUEST' as const;
export const DELETE_QNA_SUCCESS = 'DELETE_QNA_SUCCESS' as const;
export const DELETE_QNA_FAILURE = 'DELETE_QNA_FAILURE' as const;

export const PATCH_QNA_REQUEST = 'PATCH_QNA_REQUEST' as const;
export const PATCH_QNA_SUCCESS = 'PATCH_QNA_SUCCESS' as const;
export const PATCH_QNA_FAILURE = 'PATCH_QNA_FAILURE' as const;

export const GET_S_NOTICE_REQUEST = 'BOARD/GET_S_NOTICE_REQUEST' as const;
export const GET_S_NOTICE_SUCCESS = 'BOARD/GET_S_NOTICE_SUCCESS' as const;
export const GET_S_NOTICE_FAILURE = 'BOARD/GET_S_NOTICE_FAILURE' as const;

export const GET_CURRENT_S_BOARD = 'BOARD/GET_CURRENT_S_BOARD' as const;

/** board - comment */
export const INITIALIZE_COMMENT = 'INITIALIZE_COMMENT' as const;

export const SET_COMMENT_REQUEST = 'SET_COMMENT_REQUEST' as const;
export const SET_COMMENT_SUCCESS = 'SET_COMMENT_SUCCESS' as const;
export const SET_COMMENT_FAILURE = 'SET_COMMENT_FAILURE' as const;

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT_REQUEST' as const;
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS' as const;
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE' as const;

export const PATCH_COMMENT_REQUEST = 'PATCH_COMMENT_REQUEST' as const;
export const PATCH_COMMENT_SUCCESS = 'PATCH_COMMENT_SUCCESS' as const;
export const PATCH_COMMENT_FAILURE = 'PATCH_COMMENT_FAILURE' as const;

export const CHANGE_EDIT_DONE = 'CHANGE_EDIT_DONE' as const;

export const SET_BOTTOM_TAB = 'SET_BOTTOM_TAB' as const;

/** Usage */
export const GET_BOOKING_COUNT_REQUEST = 'USAGE/GET_BOOKING_COUNT_REQUEST' as const;
export const GET_BOOKING_COUNT_SUCCESS = 'USAGE/GET_BOOKING_COUNT_SUCCESS' as const;
export const GET_BOOKING_COUNT_FAILURE = 'USAGE/GET_BOOKING_COUNT_FAILURE' as const;

export const GET_BOOKING_LIST_REQUEST = 'USAGE/GET_BOOKING_LIST_REQUEST' as const;
export const GET_BOOKING_LIST_SUCCESS = 'USAGE/GET_BOOKING_LIST_SUCCESS' as const;
export const GET_BOOKING_LIST_FAILURE = 'USAGE/GET_BOOKING_LIST_FAILURE' as const;

export const GET_BOOKING_DETAIL_REQUEST = 'USAGE/GET_BOOKING_DETAIL_REQUEST' as const;
export const GET_BOOKING_DETAIL_SUCCESS = 'USAGE/GET_BOOKING_DETAIL_SUCCESS' as const;
export const GET_BOOKING_DETAIL_FAILURE = 'USAGE/GET_BOOKING_DETAIL_FAILURE' as const;

export const SET_BOOKING_FILTER = 'USAGE/SET_BOOKING_FILTER' as const;
export const INITIALIZE_FILTER = 'USAGE/INITIALIZE_FILTER' as const;

export const INITIALIZE_BOOKING = 'USAGE/INITIALIZE_BOOKING' as const;

export const PATCH_BOOKING_STATUS_REQUEST = 'USAGE/PATCH_BOOKING_STATUS_REQUEST' as const;
export const PATCH_BOOKING_STATUS_SUCCESS = 'USAGE/PATCH_BOOKING_STATUS_SUCCESS' as const;
export const PATCH_BOOKING_STATUS_FAILURE = 'USAGE/PATCH_BOOKING_STATUS_FAILURE' as const;

export const CANCEL_BOOKING_REQUEST = 'USAGE/CANCEL_BOOKING_REQUEST' as const;
export const CANCEL_BOOKING_SUCCESS = 'USAGE/CANCEL_BOOKING_SUCCESS' as const;
export const CANCEL_BOOKING_FAILURE = 'USAGE/CANCEL_BOOKING_FAILURE' as const;

export const CHANGE_CANCEL_DONE = 'USAGE/CHANGE_CANCEL_DONE' as const;


export const GET_NOTICE_POPUP_REQUEST = 'USAGE/GET_NOTICE_POPUP_REQUEST' as const;
export const GET_NOTICE_POPUP_SUCCESS = 'USAGE/GET_NOTICE_POPUP_SUCCESS' as const;
export const GET_NOTICE_POPUP_FAILURE = 'USAGE/GET_NOTICE_POPUP_FAILURE' as const;


