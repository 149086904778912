import imageCompression from 'browser-image-compression';

export default {
  async imageCompressor(fileSrc, type) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(fileSrc, options);

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);

        reader.onloadend = async () => {
          const base64data = reader.result;
          resolve(this.covertFormData(base64data, type));
        };
      });
    } catch (error) {
      return error;
    }
  },

  async covertFormData(dataURI, type) {
    if (dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ia], {
        type: 'image/jpeg',
      });
      const file = new File([blob], 'image.jpg');

      const formData = new FormData();
      formData.append(type, file);
      return formData;
    }
  },
};
