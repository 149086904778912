import React from 'react';
import Modal from 'antd-mobile/lib/modal';
import 'assets/styles/antd.css';

type noticeModalProps = {
  className?: string;
  title: string;
  show: any;
  children: React.ReactNode;
  footer: Array<{text: string, onPress: () => void}>
};


const NoticeModal = ({ className, title, show, footer, children }: noticeModalProps) => {
  return (
    <Modal
      className={`${className} modal-style`}
      title={title}
      transparent
      maskClosable={false}
      visible={show}
      footer={footer}>
      {children}
    </Modal>
  );
};

NoticeModal.defaultProps = {
  className: ''
};

export default NoticeModal;
