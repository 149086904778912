import React, { useState, useContext } from 'react';
import Store from 'contexts/store/store';
import { useHistory } from 'react-router-dom';
import { ERROR_TYPES, SUCCESS_MESSAGE } from 'constants/text';
import FindEmailPresenter from 'pages/FindEmail/FindEmailPresenter';

function FindEmail() {
  /** hook */
  const history = useHistory();
  const { apis, utils } = useContext(Store);

  /** state */
  const [mobile, setmobile] = useState<string>('');
  const [validNumber, validate] = useState<boolean>(true);

  /** func */
  const onSubmit = async () => {
    try {
      const findEmailRes = await apis.auth.findEmail({ mobile });
      if (findEmailRes) {
        history.push({
          pathname: '/notlogin-success',
          state: { title: SUCCESS_MESSAGE.findEmail.title, body: SUCCESS_MESSAGE.findEmail.body },
        });
      }
    } catch {
      alert({ message: ERROR_TYPES.noAccount });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setmobile(e.target.value);
    if (!e.target.value.length) {
      validate(true);
      return;
    }
    validate(utils.validate.mobile(e.target.value));
  };

  return <FindEmailPresenter mobile={mobile} validNumber={validNumber} onSubmit={onSubmit} onChange={onChange} />;
}

export default FindEmail;
