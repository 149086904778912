/* eslint-disable no-undef */
import { getUserTicket, getUserStudio, setStudio, setTicket, setTabIndex, setCurrentIdx, setDetailIdx } from './reducer';

export const SET_SELECT_TAB_INDEX = 'SET_SELECT_TAB_INDEX' as const;
export const SET_SELECT_CURRENT_IDX = 'SET_SELECT_CURRENT_IDX' as const;
export const SET_SELECT_DETAIL_IDX = 'SET_SELECT_DETAIL_IDX' as const;
export const GET_USER_TICKET_REQUEST = 'USER_TICKET/GET_USER_TICKET_REQUEST' as const;
export const GET_USER_TICKET_SUCCESS = 'USER_TICKET/GET_USER_TICKET_SUCCESS' as const;
export const GET_USER_TICKET_FAILURE = 'USER_TICKET/GET_USER_TICKET_FAILURE' as const;
export const SET_STUDIO = 'USER_TICKET/SET_STUDIO' as const;
export const SET_TICKET = 'USER_TICKET/SET_TICKET' as const;
export const GET_USER_STUDIO_REQUEST = 'USER_TICKET/GET_USER_STUDIO_REQUEST' as const;
export const GET_USER_STUDIO_SUCCESS = 'USER_TICKET/GET_USER_STUDIO_SUCCESS' as const;
export const GET_USER_STUDIO_FAILURE = 'USER_TICKET/GET_USER_STUDIO_FAILURE' as const;

const getUserTicketSuccess = (data: any) => ({ type: GET_USER_TICKET_SUCCESS, data });
const getUserTicketFailure = () => ({ type: GET_USER_TICKET_FAILURE });

const getUserStudioSuccess = (data: any) => ({ type: GET_USER_STUDIO_SUCCESS, data });
const getUserStudioFailure = () => ({ type: GET_USER_STUDIO_FAILURE });

export type actionProps =
  | ReturnType<typeof setTabIndex>
  | ReturnType<typeof setCurrentIdx>
  | ReturnType<typeof setDetailIdx>
  | ReturnType<typeof getUserTicket>
  | ReturnType<typeof getUserTicketSuccess>
  | ReturnType<typeof getUserTicketFailure>
  | ReturnType<typeof getUserStudio>
  | ReturnType<typeof getUserStudioSuccess>
  | ReturnType<typeof getUserStudioFailure>
  | ReturnType<typeof setStudio>
  | ReturnType<typeof setTicket>;

export type lockerProps = {
  id: number;
  no: number;
  name: string | null;
  etc: string | null;
  start_on: string;
  status: string;
  end_on: string;
};

export type studioProps = {
  id: number;
  address: {
    id: number;
    address: string;
    detail_address: string;
  };
  name: string;
  contactInfos: {
    // id: number;
    contact: string;
    // created_at: string;
    is_representative: boolean;
    // updated_at: string | null;
    // type: {
    //   created_at: string;
    //   display: string;
    //   id: number;
    //   type: string;
    //   updated_at: string | null;
    // };
  }[];
  // created_at: string;
  // disable: boolean;
  // expire_on: string;
  // grade: number;
  locker: lockerProps[];
  // usable_enter: boolean;
  policy: {
    group_booking_limit_date: string | null;
    group_booking_limit_day_term: number;
    group_booking_limit_day_term_time: string;
    private_booking_limit_date: string | null;
    private_booking_limit_day_term: number;
    private_booking_limit_day_term_time: string;
    private_booking_deadline: number;
    is_visible_all_lectures: boolean;
    is_locker: number;
    private_start_interval: number;
    is_show_current_count?: number;
    is_show_booking_waiting_count?: boolean;
    is_absence_by_user?: boolean;
    weekly_waiting_limit: number;
  };
  holidays: {
    id: number;
    studio_id: number;
    date: string;
    not_action_auto_balance: number | null;
    color: string | null;
    reason: string | null;
    studio_user_id: number;
    created_at: string;
    deleted_at: string | null;
  }[];
};

export type memberProps = {
  // account_id: number;
  // grade: null | string;
  // has_user_account: boolean;
  // id: number;
  // inactiveMember: boolean;
  lockers: lockerProps[];
  // mobile: string;
  // name: string;
  // registered_at: string;
  // user_grade: null | { id: string; name: string; representative_color: string; updated_at: string };
  // userTickets: any;
};

export type ticketProps = {
  active: boolean;
  available_class_type: string;
  // available_period: number;
  // booking_end_time: null | string;
  // booking_limit_per_month: number;
  // booking_limit_per_week: number;
  // booking_start_time: null | string;
  class_period: number;
  colors: string[];
  // created_at: string;
  daily_booking_change_limit: number;
  id: number;
  is_ignore_new_payment: boolean;
  is_show_cancel_count: boolean;
  // max_cancel: number;
  // max_coupon: number;
  max_trainee: number;
  // min_trainee: number;
  title: string;
  type: string;
  // updated_at: string;
  use_weekly_auto_coupon_balance: number;
  period: string;
  ticketStatus: string;
  classType: string;
};

export type ticketRawType = {
  active: boolean;
  availability_start_at: string;
  // booking_count_per_week: number;
  booking_limit_per_month: number;
  booking_limit_per_week: number;
  change_daily_booking_count: number;
  // deleted_at: null | string;
  expire_at: string;
  holdings: null | { id: number; auto_calculation: number; start_on: string; end_on: string }[];
  id: number;
  inactive_reason: null | string;
  is_holding: boolean;
  is_shared: number;
  // is_show_cancel_count: boolean;
  // max_cancel: number;
  max_coupon: number;
  member: memberProps;
  now_holding: null | { start_on: ''; end_on: '' };
  remaining_cancel: number;
  remaining_coupon: number;
  status: string;
  // studio: studioProps;
  // studio_id: number;
  // ticket_id: number;
  usable_coupon: number;
  ticket: ticketProps;
  staffs: string[];
  ticket_usable: boolean;
};

export type currentStudioProps = {
  studio: studioProps;
  ticket: { id: number; period: string };
  ticketRaw: ticketRawType;
};

export type stateProps = {
  data: { studio: studioProps; ticketCardData: ticketProps[]; ticket: { [key: string]: ticketRawType } }[];
  loading: boolean;
  tabIndex: number;
  currentIdx: number;
  detailIdx: number;
  currentStudioData: currentStudioProps;
  studios: studioProps[];
};
