import axios from '../axios';
const key =
  'dG74iJRHy0VGqFcRnCi2RM:APA91bExsI_V56Bxz6oZ-R-K673ATJXdMS-ZBuOTAZZeDOGFCxZg6yMvnXIn2-syF5t9VnNLfE06Ngp02Et6t47WbbYrteVDbxBAMBZWiYjUyEwMMgdmuEjjyt9ktrRR-8CUynxM17DL';
const authKey =
  'key=AAAAhDmS-bY:APA91bFjF4dIAkSA-GWSFBgxRmQVwFudM1MII40rlqKSGG9eMWtH7lhQDVCrdv7-Ji7rID6eqOxhPFeHFo5Mb8wBr7SzPv_yUqntcNwATg6MP-vXkZovj7MyB4WPBXhNfW0JzFSU7oBq';
export default {
  getTime: () => axios.get('/ping'),

  sendFcm: () =>
    axios.post(
      'https://fcm.googleapis.com/fcm/send',
      { to: key, priority: 'high', notification: { body: 'Background Message', title: 'BG Title' }, data: { title: 1, message: 2 } },
      { headers: { Authorization: authKey, 'Content-Type': 'application/json' } },
    ),
};

// {"to" : "dG74iJRHy0VGqFcRnCi2RM:APA91bExsI_V56Bxz6oZ-R-K673ATJXdMS-ZBuOTAZZeDOGFCxZg6yMvnXIn2-syF5t9VnNLfE06Ngp02Et6t47WbbYrteVDbxBAMBZWiYjUyEwMMgdmuEjjyt9ktrRR-8CUynxM17DL",
// "priority" : "high",
// "notification" : {    "body" : "Background Message",    "title" : "BG Title"  },
// "data" : {    "title" : "FG Title",    "message" : "Foreground Message"  }}
// curl -X POST --header "Authorization: key=AAAAhDmS-bY:APA91bFjF4dIAkSA-GWSFBgxRmQVwFudM1MII40rlqKSGG9eMWtH7lhQDVCrdv7-Ji7rID6eqOxhPFeHFo5Mb8wBr7SzPv_yUqntcNwATg6MP-vXkZovj7MyB4WPBXhNfW0JzFSU7oBq" --header "Content-Type: application/json" https://fcm.googleapis.com/fcm/send -d '{"to" : "dG74iJRHy0VGqFcRnCi2RM:APA91bExsI_V56Bxz6oZ-R-K673ATJXdMS-ZBuOTAZZeDOGFCxZg6yMvnXIn2-syF5t9VnNLfE06Ngp02Et6t47WbbYrteVDbxBAMBZWiYjUyEwMMgdmuEjjyt9ktrRR-8CUynxM17DL",  "priority" : "high",  "notification" : {    "body" : "Background Message",    "title" : "BG Title"  },  "data" : {    "title" : "FG Title",    "message" : "Foreground Message"  }}'

// curl -H "Content-Type: application/json" -H "Authorization: key=" https://fcm.googleapis.com/fcm/send -d '{"registration_ids":["cjxfvqFZAUwGm69md2OH6c:APA91bGKYrhGVbzs29TY6LpicrfY92J9o_WRIKuKV9a1GQOOV6QO4xCXdybuAiyEpg1iSqmvcyyfWtayfDsMHUMQ_jWeOEv6Ogm2g1gosmKGujOrrPsoSL4eJtFqnoMSiG__Ev5oBamq"], "dry_run": true}'
