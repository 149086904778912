import {
  OPEN_MENU,
  CLOSE_MENU,
  OPEN_NOTI_MODAL,
  CLOSE_NOTI_MODAL
} from './types';

export const openMenu = (show: boolean) => ({
  type: OPEN_MENU,
  payload: show
});

export const closeMenu = (show: boolean) => ({
  type: CLOSE_MENU,
  payload: show
});

export type menuActions = 
  | ReturnType<typeof openMenu>
  | ReturnType<typeof closeMenu>;

