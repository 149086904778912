import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import dayjs from 'dayjs';
import Tabs from 'antd-mobile/lib/tabs';

import { theme } from 'assets/styles/theme';
import bookingCancelAvailableTime from 'utils/bookingCancelAvailableTime';
import BookingListPartContainer from 'pages/BookingList/BookingListPartContainer';
import Actionsheet from 'components/ActionSheet';
import { setBookingFilter } from 'store/usage/actions';
import { setDetailIdx } from 'store/studio/reducer';

import { ReactComponent as Period } from 'assets/icons/icon-16-ticket-detail-1.svg';
import { ReactComponent as Check } from 'assets/icons/icon-16-ticket-detail-2.svg';
import { ReactComponent as Cancel } from 'assets/icons/icon-16-ticket-detail-3.svg';
import { ReactComponent as Max } from 'assets/icons/icon-16-ticket-detail-4.svg';
import { ReactComponent as DDay } from 'assets/icons/icon-16-ticket-detail-5.svg';
import { ReactComponent as BookingIcon } from 'assets/icons/icon-16-check.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/icon-16-check-red.svg';

const TicketDetail = ({ currentStudioData }) => {
  /** state */
  const { ticket, ticketRaw, studio } = currentStudioData;
  const { sortedBy, status } = useSelector(state => state.usage.filters);
  const { detailIdx } = useSelector(state => state.studio);
  const [show, setShow] = useState({ sort: false, status: false });

  /** hooks */
  const dispatch = useDispatch();

  /** func */
  const handlePeriodDate = date => {
    if (ticket.period !== '') {
      const splitDate = ticket.period.split('(');
      return splitDate[date === 'date' ? 1 : 0].slice(0, -1);
    }
  };

  const dailyChangeBooking = (limit, count) => {
    if (!limit) return null;
    return (
      <>
        <DDay />
        <label>당일 예약변경</label>
        <span>{limit - count}회 제한</span>
        {/* <p>당일 예약변경 : {limit}회 제한</p> */}
      </>
    );
  };

  const checkLimitBooking = (booking_limit_per_week, booking_limit_per_month) => {
    if (booking_limit_per_month) {
      return (
        <>
          <Max />
          <label>월간 최대 이용 횟수</label>
          <span>{ticketRaw.booking_limit_per_month}회 제한</span>
        </>
      );
    }
    if (booking_limit_per_week) {
      return (
        <>
          <Max />
          <label>주간 최대 이용 횟수</label>
          <span>{ticketRaw.booking_limit_per_week}회 제한</span>
        </>
      );
    }
    return (
      <>
        <Max />
        <label>주간/월간 최대 이용 횟수</label>
        <span>제한 없음</span>
      </>
    );
  };

  const holding = nowHolding => {
    if (!nowHolding) return;
    const endDate = dayjs(nowHolding.end_on).add(1, 'day');
    const startDate = dayjs(nowHolding.start_on);
    return (
      <TicketPaused>
        <div className="title">이용 정지중인 수강권입니다.</div>
        <WrapItemsPaused>
          <DetailItem>
            <label>정지 기간</label>
            <span className="info-text">{endDate.diff(startDate, 'days')}일</span>
          </DetailItem>
          <DetailItem>
            <label>정지 일자</label>
            <span className="info-text">{startDate.format('YY. MM. DD.')}</span>
          </DetailItem>
          <DetailItem>
            <label>재개 일자</label>
            <span className="info-text">{endDate.format('YY. MM. DD.')}</span>{' '}
          </DetailItem>
        </WrapItemsPaused>
      </TicketPaused>
    );
  };

  const changeType = event => {
    if (Object.keys(event.target.dataset).length) dispatch(setBookingFilter(event.target.dataset));
    setShow({ sort: false, status: false });
  };

  /** component */
  const PeriodGroupTicket = () => {
    return (
      <LayoutDetail>
        {ticketRaw.is_holding && holding(ticketRaw.now_holding)}
        <WrapItems>
          <DetailItem>
            <label>잔여일</label>
            <span className="info-text">{handlePeriodDate('date')}</span>
          </DetailItem>
        </WrapItems>
        <WrapLineItemsNoBtm>
          <li>
            <Period />
            <label>{handlePeriodDate('period')}</label>
          </li>
          <li>
            {ticketRaw.ticket.is_show_cancel_count && (
              <>
                <Cancel />
                <label>취소 가능 횟수</label>
                <span>{ticketRaw.remaining_cancel}회 제한</span>
              </>
            )}
          </li>
          <li>{dailyChangeBooking(ticketRaw.ticket.daily_booking_change_limit, ticketRaw.change_daily_booking_count)}</li>
          <li>{checkLimitBooking(ticketRaw.booking_limit_per_week, ticketRaw.booking_limit_per_month)}</li>
        </WrapLineItemsNoBtm>
      </LayoutDetail>
    );
  };

  const CountGroupTicket = () => {
    return (
      <LayoutDetail>
        {ticketRaw.is_holding && holding(ticketRaw.now_holding)}
        <WrapItems>
          <DetailItem>
            <label>예약가능횟수</label>
            <span className="info-text">{ticketRaw.usable_coupon}회 남음</span>
          </DetailItem>
          <DetailItem>
            <label>잔여횟수</label>
            <span className="info-text">{ticketRaw.remaining_coupon}회 남음</span>
          </DetailItem>
          <DetailItem>
            <label>잔여일</label>
            <span className="info-text">{handlePeriodDate('date')}</span>
          </DetailItem>
          {ticketRaw.ticket.daily_booking_change_limit !== 0 && (
            <DetailItem>
              <label>당일예약변경</label>
              <span className="info-text">{ticketRaw.ticket.daily_booking_change_limit}회 남음</span>
            </DetailItem>
          )}
        </WrapItems>

        <WrapLineItemsNoBtm>
          <li>
            <Period />
            <label>{handlePeriodDate('period')}</label>
          </li>
          <li>
            <Check />
            <label>{ticketRaw.max_coupon}회 이용권</label>
          </li>
          <li>
            <Cancel />
            <label>취소 가능 횟수</label>
            <span>{ticketRaw.remaining_cancel}회 제한</span>
          </li>
          <li>{dailyChangeBooking(ticketRaw.ticket.daily_booking_change_limit, ticketRaw.change_daily_booking_count)}</li>
          <li>{checkLimitBooking(ticketRaw.booking_limit_per_week, ticketRaw.booking_limit_per_month)}</li>
        </WrapLineItemsNoBtm>

        {/* <WrapBottoms>
          <div className='booking_cancel'>예약·취소 가능 시간</div>
          <ul>
            <li>수업 시작 {bookingCancelAvailableTime(studio.policy).group.booking}</li>
            <li className="check-red">수업 시작 {bookingCancelAvailableTime(studio.policy).group.cancel}</li>
          </ul>
        </WrapBottoms> */}
      </LayoutDetail>
    );
  };

  const PrivTicket = () => {
    return (
      <LayoutDetail>
        {ticketRaw.is_holding && holding(ticketRaw.now_holding)}
        <WrapItems>
          {ticketRaw.ticket.type !== 'P' && (
            <>
              <DetailItem>
                <label>예약가능횟수</label>
                <span className="info-text">{ticketRaw.usable_coupon}회 남음</span>
              </DetailItem>
              <DetailItem>
                <label>잔여횟수</label>
                <span className="info-text">{ticketRaw.remaining_coupon}회 남음</span>
              </DetailItem>
            </>
          )}
          <DetailItem>
            <label>잔여일</label>
            <span className="info-text">{handlePeriodDate('date')}</span>
          </DetailItem>
        </WrapItems>

        <WrapLineItems>
          <li>
            <Period />
            <label>{handlePeriodDate('period')}</label>
          </li>
          {ticketRaw.ticket.type !== 'P' && (
            <>
              <li>
                <Check />
                <label>{ticketRaw.max_coupon}회 이용권</label>
              </li>
              <li>
                {!!ticketRaw.remaining_cancel && (
                  <>
                    <Cancel />
                    <label>취소 가능 횟수</label>
                    <span>{ticketRaw.remaining_cancel}회 제한</span>
                  </>
                )}
              </li>
            </>
          )}

          <li>
            {ticketRaw.ticket.is_show_cancel_count && (
              <>
                <Cancel />
                <label>취소 가능 횟수</label>
                <span>{ticketRaw.remaining_cancel}회 제한</span>
              </>
            )}
          </li>
          <li>{dailyChangeBooking(ticketRaw.ticket.daily_booking_change_limit, ticketRaw.change_daily_booking_count)}</li>
          <li>{checkLimitBooking(ticketRaw.ticket.booking_limit_per_week, ticketRaw.ticket.booking_limit_per_month)}</li>
        </WrapLineItems>

        <WrapBottoms>
          {studio?.policy && (
            <>
              <div className="booking_cancel">예약·취소 가능 시간</div>
              <ul>
                <li>
                  <BookingIcon className="check-icon" />
                  수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy)?.private?.booking }} />
                </li>
                <li>
                  <CancelIcon className="check-icon" />
                  수업 시작 <span dangerouslySetInnerHTML={{ __html: bookingCancelAvailableTime(studio?.policy)?.private?.cancel }} />
                </li>
              </ul>
            </>
          )}
        </WrapBottoms>
      </LayoutDetail>
    );
  };

  const tabs = [
    {
      title: '수강권 정보',
    },
    {
      title: '이용 내역',
    },
  ];

  return (
    <>
      <TabStyle>
        <Tabs tabs={tabs} initialPage={detailIdx} onTabClick={(tab, index) => dispatch(setDetailIdx(index))}>
          {/* 수강권 정보 */}
          <ContainerStyle active={ticketRaw.active}>
            {/* 기간/횟수제/프라이빗 */}
            {ticketRaw.ticket.available_class_type === 'G' ? (
              ticketRaw.ticket.type === 'P' ? (
                <PeriodGroupTicket />
              ) : (
                <CountGroupTicket />
              )
            ) : (
              <PrivTicket />
            )}
          </ContainerStyle>

          {/* 이용 내역 */}
          <BookingListPartContainer show={show} setShow={setShow} sortedBy={sortedBy} status={status} />
        </Tabs>
      </TabStyle>

      <Actionsheet show={show.sort} menus={sortedBy} onRequestClose={changeType} />
      <Actionsheet show={show.status} menus={status} onRequestClose={changeType} />
    </>
  );
};

const ContainerStyle = styled.div`
  overflow-y: auto;

  ${props => {
    if (props.active === true) {
      return `
        padding: 0 16px 16px 16px;
      `;
    }
    return `
        padding: 0 16px 16px 16px;
      `;
  }}

  li svg {
    margin-right: 8px;
    position: relative;
    top: 2.5px;
  }
`;

const TabStyle = styled.div`
  height: 100vh;
  overflow-y: scroll;

  .am-tabs-default-bar-tab {
    color: ${theme.color.black500};
  }
  .am-tabs-default-bar-tab-active {
    color: ${theme.color.main};
    font-weight: bold;
  }
  .am-tabs-default-bar-underline {
    border: 1px ${theme.color.main} solid;
  }
`;

const SectionStyle = css`
  border-bottom: 1px solid ${theme.color.greyBBB};
  margin-bottom: 16px;
`;

const LayoutDetail = styled.section`
  text-align: center;
  line-height: 1.5;
  margin: 10px 0 10px;

  /* hr {
  border: 0;
  height: 1px;
  background: ${theme.color.greyBBB};
} */
  label {
    color: ${theme.color.black500};
    display: block;
    font-weight: normal;
    font-size: 14px;
  }
  span.info-text {
    display: block;
    font-weight: bold;
    color: ${theme.color.black300};
    font-size: 16px;
  }
`;

const DetailItem = styled.div`
  margin: 10px 0;
  border-right: 1px solid ${theme.color.greyBBB};
  width: 100%;
  &:last-child {
    border-right: none;
  }
`;

const WrapItemsPaused = styled.div`
  ${theme.flexBox.displayFlex};
  ${theme.flexBox.flexJustify('center')};
  letter-spacing: -0.15rem;
`;

const WrapItems = styled.div`
  ${theme.flexBox.displayFlex};
  ${theme.flexBox.flexJustify('center')};
  ${SectionStyle};
  letter-spacing: -0.15rem;
`;

const WrapLineItems = styled.ul`
  text-align: left;
  font-size: 16px;

  label,
  span {
    display: inline-block;
    font-size: 16px;
  }
  label {
    padding-right: 8px;
  }
  span {
    font-weight: normal;
  }
  ${SectionStyle};
  li:last-child {
    margin-bottom: 8px;
  }
`;

const WrapLineItemsNoBtm = styled.ul`
  text-align: left;
  font-size: 16px;

  label,
  span {
    display: inline-block;
    font-size: 16px;
  }
  label {
    padding-right: 8px;
  }
  span {
    font-weight: normal;
  }
  li:last-child {
    margin-bottom: 8px;
  }
`;

const WrapBottoms = styled.div`
  color: ${theme.color.black500};
  text-align: left;

  .booking_cancel {
    margin-bottom: 5px;
    font-weight: normal;
    color: ${theme.color.black300};
    font-size: 16px;
  }

  li {
    .check-icon {
      position: unset !important;
    }
    font-size: 16px;
    line-height: 2;
  }
`;

const TicketPaused = styled.div`
  border-radius: 8px;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.11);
  border: solid 0.5px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  padding: 16px 0;
  margin: 16px 0;

  .title {
    color: ${theme.color.black300};
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    margin-bottom: 16px;
  }
`;

export default TicketDetail;
