import dayjs from 'dayjs';

/**
 * 날짜 차이 계산
 *
 * @param {Date | string} start_on
 * @param {Date | string} end_on
 */

export default (end_on) => {
    return dayjs(end_on) < dayjs();
}
