import Echo from 'laravel-echo';

const socket = data => {
  const echo = new Echo({
    broadcaster: 'socket.io',
    authEndpoint: 'api.qa.studiomate.kr/api/broadcasting/auth',
    host: 'https://echo.qa.studiomate.kr',
    auth: {
      headers: {
        Authorization: `Bearer ${data?.token}`,
      },
    },
    transports: ['websocket', 'polling', 'flashsocket'],
  });

  if (data?.lectureId) {
    echo
      .private(`lecture.${data?.lectureId}`)
      .listen('.booking', data => {
        window.console.log('.booking :>> ', data);
      })
      .listen('.count', data => {
        window.console.log('count :>> ', data);
      })
      .listen('.exception', data => {
        window.console.log('exception :>> ', data);
      })
      .listen('.test', () => {
        window.alert('확인');
      });
  }

  // window.Echo.private(`lecture.${lectureId}`)
  //   .listen('.booking', data => {
  //     window.console.log('.booking :>> ', data);
  //   })
  //   .listen('.count', data => {
  //     window.console.log('count :>> ', data);
  //   })
  //   .listen('.exception', data => {
  //     window.console.log('exception :>> ', data);
  //   });

  // window.Echo.private(`user.${userId}`)
  //   .listen('.message', data => {
  //     window.console.log('.Message :>> ', data);
  //     window.console.log('socketId', window.Echo.socketId());
  //   })
  //   .listen('.redirect', data => {
  //     window.console.log('.Redirect :>> ', data);
  //   })
  //   .listen('.exception', data => {
  //     window.console.log('.Exception :>> ', data);
  //   })
  //   .listen('.booking', data => {
  //     window.console.log('.Booking :>> ', data);
  //   });

  /** 대기열에 넣는 방식으로 하려고 소켓 도입... */
  /** 대기열 팝업 생성 */

  /** 예약 시도 > 대기열(순번) > 예약 */
  /** Booking 신호 받으면 lecture는 아웃 */
};

export default socket;

/** 기존 echo 코드 (미사용 상태였음) */
// window.io = io;
// const echo = new Echo({
//   broadcaster: 'socket.io',
//   host: window.location.hostname + ':6001',
// });
// echo
//   .channel('user_api_database_user-event')
//   .listen('UserBooking', e => {
//     console.log(e);
//   })
//   .listen('UserEvent', e => {
//     console.log(e);
//   });
// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
