import React from 'react';
import styled from 'styled-components';

const TagButton = ({ text, color, count='' }) => {
    return (
      <StyledButton color={color}>{text}{count ? <span> {count}</span> : ''}</StyledButton>
    )
  }

const StyledButton = styled.button`
    height: 26px;
    padding: 0 8px;
    border-radius: 8px;
    border: solid 1px ${(props) => props.color || "gray"};
    background-color: #ffffff;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;

    color: ${(props) => props.color || "gray"};

    span {
      font-weight: bold;
    }
`;

export default TagButton;
