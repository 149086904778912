/* eslint-disable no-undef */
import { currentStudioProps, studioProps } from 'store/studio/types';
import {
  CHANGE_CALENDAR_DATA,
  CHANGE_USAGE_CALENDAR_DATA,
  AVAILABLE_BOOKING_LIST_R,
  AVAILABLE_BOOKING_LIST_S,
  AVAILABLE_BOOKING_LIST_F,
  POST_GROUP_BOOKING_R,
  POST_GROUP_BOOKING_S,
  POST_GROUP_BOOKING_F,
  DELETE_GROUP_BOOKING_R,
  DELETE_GROUP_BOOKING_S,
  DELETE_GROUP_BOOKING_F,
  AVAILABLE_PRIVATE_LIST_R,
  AVAILABLE_PRIVATE_LIST_S,
  AVAILABLE_PRIVATE_LIST_F,
  POST_PRIVATE_BOOKING_R,
  POST_PRIVATE_BOOKING_S,
  POST_PRIVATE_BOOKING_F,
} from './types';

export const changeCalendarData = (payload: { weeks: number } | { selectDate: string | Date } | { done: { title?: string; body?: string } }) => ({
  type: CHANGE_CALENDAR_DATA,
  payload,
});
export const changeUsageCalendarData = (
  payload: { weeks: number } | { selectDate: string | Date } | { done: { title?: string; body?: string } },
) => ({
  type: CHANGE_USAGE_CALENDAR_DATA,
  payload,
});
export const getAvailableBookingList = (id: number, date: string) => ({ type: AVAILABLE_BOOKING_LIST_R, id, date });
export const getAvailableBookingListS = (data: any, studio: studioProps | null) => ({ type: AVAILABLE_BOOKING_LIST_S, data, studio });
// 에러 확인
export const getAvailableBookingListF = (error: any) => ({ type: AVAILABLE_BOOKING_LIST_F, error });

export const postGroupBooking = (types: string, userTicketId: number, lectureId: number) => ({
  type: POST_GROUP_BOOKING_R,
  types,
  userTicketId,
  lectureId,
});
export const postGroupBookingS = (bookingType: string, status: number) => ({ type: POST_GROUP_BOOKING_S, bookingType, status });
export const postGroupBookingF = (error: string) => ({ type: POST_GROUP_BOOKING_F, error });

export const deleteGroupBooking = (bookingType: string, id: number) => ({ type: DELETE_GROUP_BOOKING_R, bookingType, id });
export const deleteGroupBookingF = (error: string) => ({ type: DELETE_GROUP_BOOKING_F, error });
export const deleteGroupBookingS = (bookingType: string, status: number) => ({ type: DELETE_GROUP_BOOKING_S, bookingType, status });

export const getAvailablePrivateList = (id: number, date: string) => ({ type: AVAILABLE_PRIVATE_LIST_R, id, date });
export const getAvailablePrivateListS = (data: any, studio: currentStudioProps | null) => ({ type: AVAILABLE_PRIVATE_LIST_S, data, studio });
export const getAvailablePrivateListF = (error: string) => ({ type: AVAILABLE_PRIVATE_LIST_F, error });

export const postPrivateBooking = (
  userTicketId: number,
  staffId: number,
  startOn: string,
  types: string,
  studioPrivateInterval: number | null,
  staffPrivateIntervalAm: number | null,
  staffPrivateIntervalPm: number | null,
) => ({
  type: POST_PRIVATE_BOOKING_R,
  userTicketId,
  staffId,
  startOn,
  types,
  studioPrivateInterval,
  staffPrivateIntervalAm,
  staffPrivateIntervalPm,
});
export const postPrivateBookingS = (bookingType: string, data: any) => ({ type: POST_PRIVATE_BOOKING_S, bookingType, data });
export const postPrivateBookingF = (error: string) => ({ type: POST_PRIVATE_BOOKING_F, error });

export type ActionRequest =
  | ReturnType<typeof changeCalendarData>
  | ReturnType<typeof changeUsageCalendarData>
  | ReturnType<typeof getAvailableBookingList>
  | ReturnType<typeof getAvailableBookingListS>
  | ReturnType<typeof getAvailableBookingListF>
  | ReturnType<typeof postGroupBooking>
  | ReturnType<typeof postGroupBookingS>
  | ReturnType<typeof postGroupBookingF>
  | ReturnType<typeof deleteGroupBooking>
  | ReturnType<typeof deleteGroupBookingS>
  | ReturnType<typeof deleteGroupBookingF>
  | ReturnType<typeof getAvailablePrivateList>
  | ReturnType<typeof getAvailablePrivateListS>
  | ReturnType<typeof getAvailablePrivateListF>
  | ReturnType<typeof postPrivateBooking>
  | ReturnType<typeof postPrivateBookingS>
  | ReturnType<typeof postPrivateBookingF>;
