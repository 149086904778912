import React from 'react';
import PropTypes from 'prop-types';
import { ImagePicker, WingBlank } from 'antd-mobile';
import styled from 'styled-components';

import { theme } from 'assets/styles/theme';
import FooterButtons from 'components/Footer/FooterButtons';
import { AUTH_TYPES, PLACEHOLDER } from 'constants/text';

const QuestionCreatePresenter = ({
  data,
  cameraPermission,
  photoLibraryPermission,
  createQna,
  onClickImageUpload,
  requestImage,
  setData,
  loading,
  cancelCreateQna,
}) => {
  return (
    <>
      <Wrap>
        {/* TODO: 이미지 불러올때까지 로딩 */}

        <StyledImagePicker
          files={data.avatar}
          onAddImageClick={requestImage}
          onChange={onClickImageUpload}
          selectable={data.avatar.length < 3}
          accept="image/gif,image/jpeg,image/jpg,image/png"
          cameraPermission={cameraPermission}
          photoLibraryPermission={photoLibraryPermission}
        />

        <Title id={AUTH_TYPES.title} name={AUTH_TYPES.title} value={data.title} onChange={setData} placeholder={PLACEHOLDER.title} />

        <Content id={AUTH_TYPES.content} name={AUTH_TYPES.content} value={data.content} onChange={setData} placeholder={PLACEHOLDER.content} />
      </Wrap>

      <FooterButtons
        cancelClick={cancelCreateQna}
        disabled={!data.title || !data.content}
        confirmClick={createQna}
        confirmText="등록"
        cancelText="취소"
      />
      {/* <div>
        <div>취소</div>
        <div>등록</div>
      </div> */}
    </>

    // {/* <PlainButton onClick={createQna} color="primary" disabled={!(!loading && data.title.length && data.content.length)}>
    //   저장
    // </PlainButton>
    // <PlainButton onClick={cancelCreateQna} color="secondary" outline>
    //   취소
    // </PlainButton> */}
  );
};

export default QuestionCreatePresenter;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  grid-template-rows: auto auto 1fr;
  overflow: hidden;
  .am-image-picker-list {
    padding: 16px;
    margin: 0;
  }
`;
const Title = styled.input`
  border: none;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  color: ${theme.color.black500};
`;
const Content = styled.textarea`
  border: none;
  padding: 16px;
  line-height: 1.71;
  font-weight: normal;
  font-size: 14px;
  color: ${theme.color.black300};
  resize: none;
  height: 100%;
`;

const StyledImagePicker = styled(ImagePicker)`
  input {
    display: ${props => (props.cameraPermission === 'granted' || props.photoLibraryPermission === 'granted' ? 'block' : 'none')};
  }
`;

QuestionCreatePresenter.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  createQna: PropTypes.func,
  onClickImageUpload: PropTypes.func,
  requestImage: PropTypes.func,
  setData: PropTypes.func,
  loading: PropTypes.bool,
  cancelCreateQna: PropTypes.func,
};

QuestionCreatePresenter.defaultProps = {
  data: {},
  createQna: {},
  onClickImageUpload: {},
  requestImage: {},
  setData: {},
  loading: false,
  cancelCreateQna: {},
};
