import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Tabs from 'antd-mobile/lib/tabs';
import Badge from 'antd-mobile/lib/badge';
import styled from 'styled-components';

import MainLayout from 'components/MainLayout';
import { NOTICE_TYPE, HEADER_TITLE } from 'constants/text';
import { theme } from 'assets/styles/theme';
import NoticeContainer from './Notice/NoticeContainer';
import QuestionContainer from './Qna/QuestionContainer';
import SNoticeContainer from './SNotice/SNoticeContainer';
import 'assets/styles/antd.css';

const BoardPresenter = ({ tabIndex, changeTab, count }) => {
  const [tabs, setTabs] = useState([{
    title: <div />,
    name: ''
  }]);

  const { loading } = useSelector(state => state.board);
  const { studio } = useSelector(state => state.studio.currentStudioData);
 
  const badgeStyle = {
    marginLeft: 12,
    width: 15,
    height: 15,
    fontSize: 10,
    lineHeight: '15px',
    padding: 0,
    fontWeight: 400
  };

  useEffect(() => {
    
    if (studio?.policy?.is_visible_qna) {
      setTabs([
        {
            title: (
              <Badge
                // text={count.notice}
                style={badgeStyle}>
                공지사항
              </Badge>
            ),
            name: NOTICE_TYPE.notice,
          },
          {
            title: (
              <Badge
                // text={count.qna}
                style={badgeStyle}>
                1:1 문의
              </Badge>
            ),
            name: NOTICE_TYPE.qna,
          },
          {
            title: (
              <Badge
                // text={count.snotice}
                style={badgeStyle}>
                스튜디오메이트 공지
              </Badge>
            ),
            name: NOTICE_TYPE.snotice,
          },
      ]);
    } else {
      setTabs([
        {
            title: (
              <Badge
                // text={count.notice}
                style={badgeStyle}>
                공지사항
              </Badge>
            ),
            name: NOTICE_TYPE.notice,
          },
          {
            title: (
              <Badge
                // text={count.snotice}
                style={badgeStyle}>
                스튜디오메이트 공지
              </Badge>
            ),
            name: NOTICE_TYPE.snotice,
          },
      ]);
    }

  }, []);

  return (
    <MainLayout header={{ title: HEADER_TITLE.board, notification: true, titleNormal: true, titleBorder: false, noBackBtn: true }} loading={loading} contentsGrid>
      <TabStyle tabIndex={tabIndex} visibleQna={studio?.policy?.is_visible_qna}>

        {studio?.policy?.is_visible_qna ? (
          <Tabs
            tabs={tabs}
            initialPage={tabIndex}
            prerenderingSiblingsNumber={tabs.length-1}
            onChange={(tab, index) => {
            changeTab(tab, index);
          }}
            swipeable={false}>

            {/* 공지사항 */}
            <NoticeContainer />

            {/* 1:1 문의 */}
            <QuestionContainer />

            {/* 스튜디오메이트 공지 */}
            <SNoticeContainer />

          </Tabs>
      ) : (
        <Tabs
          tabs={tabs}
          initialPage={tabIndex}
          prerenderingSiblingsNumber={tabs.length-1}
          onChange={(tab, index) => {
            changeTab(tab, index);
          }}
          swipeable={false}>

          {/* 공지사항 */}
          <NoticeContainer />

          {/* 스튜디오메이트 공지 */}
          <SNoticeContainer />

        </Tabs>
      )}

      </TabStyle>
    </MainLayout>
  );
};

/** prop-type 정의  */
BoardPresenter.propTypes = {
  tabIndex: PropTypes.number,
  changeTab: PropTypes.func,
  // count: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

BoardPresenter.defaultProps = {
  tabIndex: 0,
  changeTab: {}
};

const TabStyle = styled.div`
  overflow-y: auto;

  .am-tabs-default-bar-tab {
    color: ${theme.color.black500};
  }
  .am-tabs-default-bar-tab-active {
    color: ${theme.color.main};
    font-weight: bold !important;
  }
  .am-tabs-default-bar-underline {
    border: 1px ${theme.color.main} solid;
  }

  ${props => {
    if (props.visibleQna) {
        return `
        .am-tabs-default-bar-tab:first-child, .am-tabs-default-bar-tab:nth-child(3) {
          width: 40% !important;
        }
        .am-tabs-default-bar-tab:nth-child(2) {
          width: 20% !important;
        }
      `;
    } 
      return `
      .am-tabs-default-bar-tab:first-child, .am-tabs-default-bar-tab:last-child {
        width: 50% !important;
      }
      `;
  }}

  .am-tabs-default-bar-underline {
    ${props => {
      if (props.visibleQna) {
        switch (props.tabIndex) {
          case 0:
            return `width: 40% !important;`;
          case 1:
            return `width: 20% !important; left: 40% !important;`;
          case 2:
            return `width: 40% !important; left: 60% !important;`;
        }
      } else {
        switch (props.tabIndex) {
          case 0:
            return `width: 50% !important;`;
          case 1:
            return `width: 50% !important; left: 50% !important;`;
        }
      }
      
    }}
  }

`;

export default React.memo(BoardPresenter);
