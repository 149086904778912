/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import '../BookingAction/bookingActionStyle.scss';
import React from 'react';
import styled from 'styled-components';
import List from 'antd-mobile/lib/list';

import { ReactComponent as Close } from 'assets/icons/icon-24-close-white.svg';
import { ReactComponent as Address } from 'assets/icons/icon-24-address.svg';
import { ReactComponent as Phone } from 'assets/icons/icon-24-phone.svg';
import { ReactComponent as Schedule } from 'assets/icons/icon-24-schedule.svg';
import { ReactComponent as Call } from 'assets/icons/button-phonecall.svg';
import { ReactComponent as Studio } from 'assets/icons/studio.svg';
import { theme } from 'assets/styles/theme';

const { Item } = List;
const { Brief } = Item;

const index = ({ show, data, onRequestClose }) => {
  const telTo = number => {
    if (window.ReactNativeWebView) window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'OPEN_URL', url: `tel:${number}` }));
  };

  const phoneAddDash = number => {
    switch (true) {
      case number.length === 8:
        return number.replace(/(\d{4})(\d{4})/, '$1-$2');
      case number.length === 9:
        return number.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      case number.length === 11:
        return number.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      case number.length === 12:
        return number.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
      default:
        if (number.indexOf('02') === 0) {
          return number.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        }
        return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
  };

  const schedule = events => {
    const days = ['월', '화', '수', '목', '금', '토', '일'];
    const scheduleInfos = Array.from(new Array(7), (_, idx) => ({ day_of_week: (idx + 1) % 7, day_of_week_kor: days[idx] }));
    if (events) {
      events.forEach(event => {
        const index = (event.day_of_week + 6) % 7;
        scheduleInfos[index].start_time = event.start_time;
        scheduleInfos[index].end_time = event.end_time;
      });
    }

    return scheduleInfos.map((scheduleInfo, idx) => {
      return (
        <div key={idx} className="address gap">
          <span className="day">{scheduleInfo?.day_of_week_kor}</span>
          {scheduleInfo?.start_time ? (
            <span>
              {scheduleInfo?.start_time?.slice(0, -3)}~{scheduleInfo?.end_time?.slice(0, -3)}
            </span>
          ) : (
            <span>휴무일</span>
          )}
        </div>
      );
    });
  };

  return (
    <div className={`react-actionsheet${show ? ' react-actionsheet-show' : ''}`}>
      <div className="react-actionsheet-mask" onClick={onRequestClose} />
      <div className="react-actionsheet-wrap">
        <ContainerStyle>
          <ListStyle>
            <List>
              <Item>
                {/* 닫기 버튼 */}
                <Close className="close_icon" onClick={onRequestClose} />

                {/* 시설정보 */}
                <Brief>
                  <div className="info">시설 정보</div>
                </Brief>

                {/* 연락처 */}
                <Brief>
                  <div className="title">
                    <Studio />
                    상호명
                  </div>
                </Brief>
                {/* 이름 */}
                <Brief>
                  <div className="name">{data?.name}</div>
                </Brief>
              </Item>

              <div className="contact">
                <Item>
                  {/* 연락처 */}
                  <Brief>
                    <div className="title">
                      <Phone />
                      연락처
                    </div>
                  </Brief>

                  {/* 전화걸기 */}
                  {data?.contactInfos.map(number => {
                    if (number?.type?.type === 'telephone' || number?.type?.type === 'cellphone') {
                      return (
                        <Brief key={number.id}>
                          <div className="phone">
                            <span>{phoneAddDash(number?.contact)}</span>
                            <span onClick={() => telTo(number.contact)}>
                              <Call />
                            </span>
                          </div>
                        </Brief>
                      );
                    }
                  })}
                </Item>
              </div>

              {(data?.address?.address || data?.address?.detail_address) && (
                <Item>
                  {/* 주소 */}
                  <Brief>
                    <div className="title">
                      <Address />
                      주소
                    </div>
                  </Brief>

                  {/* 스튜디오 주소 */}
                  <Brief>
                    <div className="address">
                      {data?.address?.address} {data?.address?.detail_address}
                    </div>
                  </Brief>
                </Item>
              )}

              <Item>
                {/* 영업시간 */}
                <Brief>
                  <div className="title">
                    <Schedule />
                    영업시간
                  </div>
                </Brief>

                {/* 스케쥴 */}
                <Brief>{schedule(data?.openEvents)}</Brief>
              </Item>
            </List>
          </ListStyle>
        </ContainerStyle>
      </div>
    </div>
  );
};

const ContainerStyle = styled.div`
  padding: 0 16px 5px 2px;
  overflow-y: scroll;
`;

const ListStyle = styled.div`
  .am-list-body::before,
  .am-list-body::after {
    background-color: white !important;
  }
  .am-list-body .am-list-item .am-list-line .am-list-content {
    padding: 16px 0;
  }
  .info {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    color: ${theme.color.black500};
  }
  .name {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    color: ${theme.color.black500};
    white-space: break-spaces;
  }
  .title {
    margin-bottom: 16px;
    display: flex;
    font-size: 16px;
    font-weight: normal;
    line-height: normal;
    color: #646464;

    svg {
      margin-right: 4px;
    }
  }
  .phone {
    margin-bottom: 15px;
    display: flex;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: ${theme.color.black000};

    svg {
      font-weight: normal;
      position: absolute;
      right: 0;
    }
  }
  .address {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.5;
    color: ${theme.color.black000};
    white-space: break-spaces;
  }
  .day {
    margin: 0 8px 0 4px;
  }
  .gap {
    margin-bottom: 8px;
  }
  .contact {
    .am-list-content {
      padding: 16px 0 1px 0 !important;
    }
  }
`;

export default index;
