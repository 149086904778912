import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as SuccessIcon } from 'assets/icons/success-icon.svg';

import { theme } from 'assets/styles/theme';

function Success({ location }: any) {
  const history = useHistory();

  const goToPath = () => {
    if (location.state.routePath) {
      history.replace({ pathname: location.state.routePath });
    } else {
      history.push('/login');
    }
  };

  return (
    <StyledWrap>
      <div className="wrap">
        <div className="flex_row_center">
          <SuccessIcon />
        </div>

        <div className="title">{location.state.title}</div>
        <div className="content" dangerouslySetInnerHTML={{ __html: location.state.body }} />
      </div>

      <button type="button" onClick={goToPath} className="close-btn">
        닫기
      </button>
    </StyledWrap>
  );
}

export const StyledWrap = styled.div`
  background-image: linear-gradient(151deg, rgba(178, 134, 240, 0), #b286f0), linear-gradient(to bottom, #4398ef, #4398ef);
  height: 100vh;
  color: #fff;

  display: grid;
  grid-template-rows: 1fr auto;

  .wrap {
    padding: 24px;

    display: grid;
    grid-template-rows: 40% 15% 45%;
  }

  .am-icon-lg {
    width: 90px;
    height: 90px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
  }

  .content {
    font-size: 18px;
    font-weight: normal;
    line-height: normal;
  }

  .close-btn {
    border: solid 2px #fff;
    border-radius: 8px;
    background: transparent;
    color: #fff;
    height: 43px;
    margin: 16px 16px 24px 16px;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
  }

  .close-btn:active {
    background-color: white;
    color: ${theme.color.lightPurple};
  }
`;
export default Success;
