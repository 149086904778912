import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux'; 
import { useHistory } from 'react-router-dom';

import MyPagePresenter from 'pages/MyPage/MyPagePresenter';
import Store from 'contexts/store/store';

const MyPageContainer = () => {
    /** state */
    const { apis } = useContext(Store);
    const { user } = useSelector(state => state.auth);
    const { data } = useSelector(state => state.studio);
    const { loading } = useSelector(state => state.board);

    return (
      <MyPagePresenter 
        apis={apis}
        user={user}
        studios={data}
        loading={loading}
      />
    );
};

export default MyPageContainer;