import React, { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { ReactComponent as Open } from 'assets/icons/icon-24-circle-arrow-up.svg';
import { ReactComponent as Close } from 'assets/icons/icon-24-circle-arrow-down.svg';
import getImageUrl from 'utils/getImageUrl';
import { theme } from 'assets/styles/theme';
import Avatar from 'components/Avatar';

const Accordion = ({ staff, time, history, setBooking, _, ticketRaw }) => {
  /** state */
  const [expanded, setExpanded] = useState(false);

  const { calendarData } = useSelector(state => state.booking);

  /** func */
  const goToBooking = timeTable => {
    if (dayjs(calendarData?.selectDate).format('YYYY-MM-DD') !== dayjs(timeTable.startTime).format('YYYY-MM-DD')) {
      return history.push(`/booking-private/${ticketRaw.id}`);
    }

    setBooking({ show: true, time: _.omit(timeTable, ['data']), staff, id: ticketRaw.id });
    history.push(`/booking-private/${ticketRaw.id}/#`);
  };

  return (
    <Container>
      <StaffStyle onClick={() => setExpanded(!expanded)}>
        <div className="user-div">
          <Avatar className="user-img" size="small-2" imgUrl={getImageUrl(staff.avatar, '32x32')} />
          <span className="content">{staff.name} 강사</span>
        </div>

        <div className="user-div">
          <span className="content gap">시간 선택</span>
          {expanded ? <Open /> : <Close />}
        </div>
      </StaffStyle>

      {expanded && (
        <TimeStyle>
          <div className="hr" />
          {time && time.length > 0 ? (
            <div className="time-grid">
              {time.map((el, index) => {
                // eslint-disable-next-line react/no-array-index-key
                return (
                  <div key={index} className="box" onClick={() => goToBooking(el)}>
                    {dayjs(el.startTime).format('HH:mm')}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="content">가능한 시간이 없습니다</div>
          )}
        </TimeStyle>
      )}
    </Container>
  );
};

const Container = styled.div`
  // height: 64px;
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .content {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: normal;
    color: ${theme.color.black500};
  }
`;

const StaffStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user-div {
    display: flex;
    align-items: center;

    .user-img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }

  .gap {
    margin-right: 8px;
  }
`;

const TimeStyle = styled.div`
  .hr {
    height: 1px;
    background-color: ${theme.color.greyBBB};
    margin: 16px 0;
  }

  .time-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: center;
    grid-gap: 12px;
  }

  .box {
    padding: 4px 0;
    border-radius: 8px;
    border: solid 1px ${theme.color.black500};

    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    color: ${theme.color.black500};
  }

  .box:active,
  .box:focus {
    background: rgba(51, 145, 237, 0.5);
  }
`;

export default Accordion;
