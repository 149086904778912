import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import board from 'api/modules/board.ts';
import getImageUrl from 'utils/getImageUrl';
import { _ } from 'utils/fx';

import {
  GET_QNA_REQUEST,
  GET_QNA_SUCCESS,
  GET_QNA_FAILURE,
  GET_NOTICE_REQUEST,
  GET_NOTICE_SUCCESS,
  GET_NOTICE_FAILURE,
  DELETE_QNA_REQUEST,
  DELETE_QNA_SUCCESS,
  DELETE_QNA_FAILURE,
  SET_COMMENT_REQUEST,
  SET_COMMENT_SUCCESS,
  SET_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  PATCH_COMMENT_REQUEST,
  PATCH_COMMENT_SUCCESS,
  PATCH_COMMENT_FAILURE,
  GET_S_NOTICE_SUCCESS,
  GET_S_NOTICE_FAILURE,
  GET_S_NOTICE_REQUEST,
  GET_NOTICE_POPUP_REQUEST,
  GET_NOTICE_POPUP_SUCCESS,
  GET_NOTICE_POPUP_FAILURE,
  GET_CURRENT_S_BOARD,
  GET_CURRENT_BOARD
} from 'constants/actionTypes';
import { ERROR_TYPES, NOTICE_TYPE } from 'constants/text';

function* patchQnaCommentSaga(action) {
  try {
    const res = yield call(() => board.patchComment(action.id, action.data));
    yield put({ type: PATCH_COMMENT_SUCCESS, data: res.data });
  } catch (err) {
    yield put({ type: PATCH_COMMENT_FAILURE, data: ERROR_TYPES.noComment });
  }
}

function* watchPatchQnaComment() {
  yield takeLatest(PATCH_COMMENT_REQUEST, patchQnaCommentSaga);
}

function* deleteQnaCommentSaga(action) {
  try {
    yield call(() => board.deleteComment(action.id));
    yield put({ type: DELETE_COMMENT_SUCCESS, id: action.id });
  } catch (err) {
    yield put({ type: DELETE_COMMENT_FAILURE, data: ERROR_TYPES.noComment });
  }
}

function* watchDeleteQnaComment() {
  yield takeLatest(DELETE_COMMENT_REQUEST, deleteQnaCommentSaga);
}

function* setQnaCommentSaga(action) {
  try {
    const res = yield call(() => board.setComment(action.data));
    yield put({ type: SET_COMMENT_SUCCESS, data: res.data.board });
  } catch (err) {
    yield put({ type: SET_COMMENT_FAILURE, data: err?.response?.data });
  }
}

function* watchSetQnaComment() {
  yield takeLatest(SET_COMMENT_REQUEST, setQnaCommentSaga);
}

function* deleteQnaSaga(action) {
  try {
    yield call(() => board.deleteQna(action.id));
    yield put({ type: DELETE_QNA_SUCCESS, data: 'success' });
  } catch (err) {
    yield put({ type: DELETE_QNA_FAILURE });
  }
}

function* watchDelteQna() {
  yield takeLatest(DELETE_QNA_REQUEST, deleteQnaSaga);
}

function* getQnaSaga(action) {
  try {
    const result = yield call(() => board.getNoti(action.params));
    yield put({ type: GET_QNA_SUCCESS, data: result.data });
  } catch (err) {
    yield put({ type: GET_QNA_FAILURE });
  }
}

function* watchGetQna() {
  yield takeLatest(GET_QNA_REQUEST, getQnaSaga);
}

function* getNotiSaga(action) {
  try {
    const result = yield call(() => board.getNoti(action.params));
    yield put({ type: GET_NOTICE_SUCCESS, data: result.data });
  } catch (err) {
    yield put({ type: GET_NOTICE_FAILURE });
  }
}

function* watchGetNotice() {
  yield takeLatest(GET_NOTICE_REQUEST, getNotiSaga);
}

function* getSNotiSaga(action) {
  try {
    const result = yield call(() => board.getSNoti(action.params));
    yield put({ type: GET_S_NOTICE_SUCCESS, data: result.data });
  } catch (err) {
    yield put({ type: GET_S_NOTICE_FAILURE });
  }
}

function* watchGetSNotice() {
  yield takeLatest(GET_S_NOTICE_REQUEST, getSNotiSaga);
}

const studioSelector = state => {
  return state.studio.currentStudioData?.studio?.id;
};

function* getNoticePopupSaga() {
  try {
    const id = yield select(studioSelector)
    const [notice, stmNotice] = yield all([
      call(() => board.getNoti({
        page: 1, limit: 1, all: 1, type: NOTICE_TYPE.notice, studio_id: id
      })),
      call(() => board.getSNoti({
        page: 1, limit: 1, all: 1, sort_type: 'desc'
      }))
    ])

    yield put({ type: GET_NOTICE_POPUP_SUCCESS, data: {
      notice: notice?.data?.data[0],
      stmNotice: stmNotice?.data?.data[0]
    }});

  } catch (err) {
    yield put({ type: GET_NOTICE_POPUP_FAILURE, data: err?.response?.data });
  }
}

function* watchgetNoticePopup() {
  yield takeLatest(GET_NOTICE_POPUP_REQUEST, getNoticePopupSaga);
}



export default function* boardSaga() {
  yield all([
    fork(watchGetQna),
    fork(watchGetNotice),
    fork(watchGetSNotice),
    fork(watchDelteQna),
    fork(watchSetQnaComment),
    fork(watchgetNoticePopup),
    fork(watchDeleteQnaComment),
    fork(watchPatchQnaComment)
  ]);
}
