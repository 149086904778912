import axios from '../axios';

const BASE_URL = '/public/account';

type userAccount = {
  created_at: string;
  deleted_at: null;
  email: string;
  id: number;
  lang: string;
  mobile: string;
  mobile_invalid_at: null | string | boolean;
  name: string;
  recent_password_changed_at: null | string;
  updated_at: string;
};

type loginType = {
  email: string | null;
  mobile: string | null;
  password: string;
};

type loginRes = userAccount & {
  access_token: string;
  expires_in: number;
  token_type: string;
};

type findPasswordType = {
  user_account: { password: string };
  validation_code: string;
  validation_id: string;
};

type changePasswordType = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

type getAccountRes = userAccount & {
  representative_avatar: {
    created_at: string;
    deleted_at: null | string;
    id: number;
    image: { host: string; name: string; path: string };
    updated_at: string;
  };
  updated_at: string;
};

export default {
  login: (params: loginType) => axios.post<loginRes>(`${BASE_URL}/login`, params),

  findEmail: (mobile: { mobile: string }) => axios.post(`${BASE_URL}/find_email`, mobile),

  // 인증코드 발급 - 가입 되어있는 회원용
  getValidateCode: (params: { mobile: string; email: string }) =>
    axios.patch<{ user_account_id: number; validation_id: string }>(`${BASE_URL}/validation_code`, params),

  // 인증코드 발급 - 가입 안된 비회원용
  // requestSmSCode: (params: { mobile: string }) => axios.post<{ data: { validation_id: string } }>(`${BASE_URL}/validation_code`, params),

  requestSmSCode: (params: { mobile: string }) => axios.post<{ data: { validation_id: string } }>(`v2/anonymous/sms`, params),

  // 휴대폰 번호 중복 체크 - 회원가입
  checkDuplicationMobile: (params: { value: string }) => axios.post<{ data: { count: number } }>(`v2/anonymous/check-duplication/mobile`, params),

  // 휴대폰 인증번호 확인
  validateSmSCode: (id: any, code: any) => axios.get(`v2/anonymous/sms/${id}/${code}`),

  // 비로그인 상태에서 비밀번호 찾기/변경
  findPassword: (id: number, params: findPasswordType) => axios.patch(`${BASE_URL}/${id}`, params),

  // 로그인 상태에서 비밀번호 찾기
  changePassword: (params: changePasswordType) => axios.patch<{ status: number }>(`/me/password`, params),

  // 회원 정보 변경
  changeUserAccount: (params: { representative_avatar: any; name: string }) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    return axios.post<{ status: number }>(`/me`, params, { headers });
  },

  checkDuplicationEmail: (params: { value: string }) => axios.post(`/v2/anonymous/check-duplication/email`, params),

  // 회원가입 - 이메일 중복 확인
  checkDupEmail: (params: { mobile: string; validation_code: string; validation_id: string }) =>
    axios.post<{ data: { message: string } }>(`/v2/anonymous/join/validation`, params),

  // 유저 정보 변경
  changeAccount: (params: any) => axios.patch('/member/account', params),

  // Access token 재갱신
  refreshToken: () => axios.post('/user/refresh'),

  // 회원가입
  signUp: (params: any) => axios.post(`${BASE_URL}`, params),

  // 회원탈퇴
  deleteAccount: (params: { password: string }) => axios.delete(`/member/account/withdraw`, { params }),

  // 계정 정보 가져오기
  getAccount: () => axios.get<getAccountRes>('/member/account'),

  // fcm 토큰 변경
  patchFCMToken: (params: { instance_token: string }) => axios.post('/me/instance-token', params),
  // instance_token: 'awdzvb'

  checkUnderServiceInspection: () => axios.get('/under'),
};
