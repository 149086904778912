import produce from 'immer';

import { NOTI_ENROLL_TICKET, INotiState } from './types';
import { ActionRequest } from './actions';

export const initalState: INotiState = {
  enrollBooking: false,
};

export const fcm = (state: INotiState = initalState, action: ActionRequest) =>
  produce(state, draft => {
    switch (action.type) {
      case NOTI_ENROLL_TICKET:
        draft.enrollBooking = action.payload;
    }
  });

export default fcm;
