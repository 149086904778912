import React from 'react';
import styled from 'styled-components';
import InfoImage from '../../assets/images/img_service-inspection221011.png';

const ServiceInspection = () => {
  return (
    <Container>
      <img src={InfoImage} alt="서비스 점검 이미지" />
    </Container>
  );
};

export default ServiceInspection;

const Container = styled.div`
  img {
    position: absolute;
    top: 10%;
    left: 5%;
    width: 90%;
    background-size: cover;
  }
`;
