/* eslint-disable array-callback-return */
import produce from 'immer';
import dayjs from 'dayjs';
import {
  CHANGE_TAB,
  GET_NOTICE_REQUEST,
  GET_NOTICE_SUCCESS,
  GET_NOTICE_FAILURE,
  GET_S_NOTICE_REQUEST,
  GET_S_NOTICE_SUCCESS,
  GET_S_NOTICE_FAILURE,
  GET_CURRENT_S_BOARD,
  GET_CURRENT_BOARD,
  GET_QNA_REQUEST,
  GET_QNA_SUCCESS,
  GET_QNA_FAILURE,
  SET_BOARD_KEYWORD,
  DELETE_QNA_REQUEST,
  DELETE_QNA_SUCCESS,
  DELETE_QNA_FAILURE,
  INITIALIZE_COMMENT,
  SET_COMMENT_REQUEST,
  SET_COMMENT_SUCCESS,
  SET_COMMENT_FAILURE,
  DELETE_COMMENT_REQUEST,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_FAILURE,
  PATCH_COMMENT_REQUEST,
  PATCH_COMMENT_SUCCESS,
  PATCH_COMMENT_FAILURE,
  CHANGE_EDIT_DONE,
  IS_NEW_WATCH_STUDIO_BOARD,
  RESET_NOTICE,
  GET_NOTICE_POPUP_REQUEST,
  GET_NOTICE_POPUP_SUCCESS,
  GET_NOTICE_POPUP_FAILURE
} from 'constants/actionTypes';
import { NOTICE_TYPE } from 'constants/text';

export const resetNotice = () => ({ type: RESET_NOTICE });
export const changeTab = data => ({ type: CHANGE_TAB, data });
export const getBoard = params => ({ type: GET_NOTICE_REQUEST, params });
export const getSBoard = params => ({ type: GET_S_NOTICE_REQUEST, params });
export const getQna = params => ({ type: GET_QNA_REQUEST, params });
export const setCurrentBoard = data => ({ type: GET_CURRENT_BOARD, data });
export const setCurrentSBoard = data => ({ type: GET_CURRENT_S_BOARD, data });
export const setBoardKeyword = data => ({ type: SET_BOARD_KEYWORD, data });
export const deleteQna = id => ({ type: DELETE_QNA_REQUEST, id });
export const initializeComment = () => ({ type: INITIALIZE_COMMENT });
export const setQnaComment = data => ({ type: SET_COMMENT_REQUEST, data });
export const deleteQnaComment = id => ({ type: DELETE_COMMENT_REQUEST, id });
export const patchQnaComment = (id, data) => ({ type: PATCH_COMMENT_REQUEST, id, data });
export const changeEditDone = typeName => ({ type: CHANGE_EDIT_DONE, typeName });
export const getNoticePopup = () => ({ type: GET_NOTICE_POPUP_REQUEST });

export const initialState = {
  tabIndex: 0,
  boardList: [],
  currentBoard: {
    attachments: [],
    comments: [],
    contents: '',
    created: '2020-07-02 17:17:26',
    id: 1,
    staff: {},
    studio_id: null,
    title: '',
    type: NOTICE_TYPE.notice,
  },
  loading: false,
  notice: { params: { page: 1, limit: 20, all: 1, type: NOTICE_TYPE.notice, title: '' }, lastPage: 1, data: [], unReadCount: 0 },
  qna: { params: { page: 1, limit: 20, all: 1, type: NOTICE_TYPE.qna, title: '' }, lastPage: 1, data: [], unReadCount: 0 },
  studioNotice: { params: { page: 1, limit: 20, all: 1, type: NOTICE_TYPE.snotice, search: '' }, lastPage: 1, data: [], unReadCount: 0 },
  done: false,
  editDone: false,
  noticePopup: {

  },
  noticePopupLoading: false
};

export const board = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_NOTICE:
        Object.assign(draft.notice, initialState.notice);
        break;
      case CHANGE_TAB:
        draft.tabIndex = action.data;
        break;
      case GET_NOTICE_REQUEST:
      case GET_QNA_REQUEST:
      case GET_S_NOTICE_REQUEST:
        draft.loading = true;
        break;
      case GET_NOTICE_SUCCESS:
      case GET_QNA_SUCCESS:
        let type = NOTICE_TYPE.notice;
        if (action.type === GET_QNA_SUCCESS) type = NOTICE_TYPE.qna;

        let count = action.data.meta.current_page === 1 ? 0 : state[type].unReadCount;
        const time = localStorage.getItem(NOTICE_TYPE.notice);

        action.data.data.map(el => {
          if (!time) el.unRead = false;
          if (dayjs(el.updated_at) > dayjs(time)) {
            el.unRead = true;
            count++;
          } else {
            el.unRead = false;
          }
        });

        draft[type].unReadCount = count;

        let newNotice = [];
        if (action.data.meta.current_page === 1) {
          newNotice = action.data.data;
          draft[type].params.page = 2;
        } else {
          newNotice = state[type].data.concat(action.data.data);
          draft[type].params.page++;
        }
        draft[type].data = newNotice;
        draft[type].lastPage = action.data.meta.last_page;
        draft.loading = false;
        break;
      case GET_S_NOTICE_SUCCESS:
        const s_type = NOTICE_TYPE.snotice;
        let s_count = action.data.current_page === 1 ? 0 : state[s_type].unReadCount;
        const s_time = localStorage.getItem(NOTICE_TYPE.snotice);

        action.data.data.map(el => {
          if (!s_time) el.unRead = false;
          if (dayjs(el.updated_at) > dayjs(s_time)) {
            el.unRead = true;
            s_count++;
          } else {
            el.unRead = false;
          }
        });

        draft[s_type].unReadCount = s_count;

        let s_newNotice = [];
        if (action.data.current_page === 1) {
          s_newNotice = action.data.data;
          draft[s_type].params.page = 2;
        } else {
          s_newNotice = state[s_type].data.concat(action.data.data);
          draft[s_type].params.page++;
        }
        draft[s_type].data = s_newNotice;
        draft[s_type].lastPage = action.data.last_page;
        draft.loading = false;
        break;
      case GET_NOTICE_FAILURE:
      case GET_QNA_FAILURE:
      case GET_S_NOTICE_FAILURE:
        let typeName = NOTICE_TYPE.notice;
        if (action.type === GET_QNA_FAILURE) typeName = NOTICE_TYPE.qna;
        if (action.type === GET_S_NOTICE_FAILURE) typeName = NOTICE_TYPE.snotice;

        draft[typeName] = initialState[typeName];
        draft.loading = false;
        break;
      case GET_CURRENT_BOARD:
      case GET_CURRENT_S_BOARD:
        draft.loading = true;
        draft.done = false;
        draft.editDone = false;
        draft.currentBoard = action.data;
        draft.loading = false;
        break;
      case SET_BOARD_KEYWORD:
        if (action.data.type === NOTICE_TYPE.snotice) {
          draft[action.data.type].params.search = action.data.keyword;
        } else {
          draft[action.data.type].params.title = action.data.keyword;
        }
        break;
      case DELETE_QNA_REQUEST:
        draft.loading = true;
        draft.done = false;
        break;
      case DELETE_QNA_SUCCESS:
        draft.loading = false;
        draft.done = true;
        break;
      case DELETE_QNA_FAILURE:
        draft.loading = false;
        draft.done = false;
        break;
      case INITIALIZE_COMMENT:
        draft.editDone = false;
        break;
      case SET_COMMENT_REQUEST:
        draft.loading = true;
        draft.editDone = false;
        break;
      case SET_COMMENT_SUCCESS:
        draft.loading = false;
        draft.currentBoard.comments = action.data.comments;
        draft.editDone = 'addCommit';
        break;
      case SET_COMMENT_FAILURE:
        draft.loading = false;
        draft.editDone = false;
        break;
      case DELETE_COMMENT_REQUEST:
        draft.loading = true;
        draft.editDone = false;
        break;
      case DELETE_COMMENT_SUCCESS:
        draft.loading = false;
        const index = draft.currentBoard.comments.findIndex(({ id }) => id === action.id);
        draft.currentBoard.comments.splice(index, 1);
        draft.editDone = 'deleteCommit';
        break;
      case DELETE_COMMENT_FAILURE:
        draft.loading = false;
        draft.editDone = false;
        break;
      case PATCH_COMMENT_REQUEST:
        draft.loading = true;
        draft.editDone = false;
        break;
      case PATCH_COMMENT_SUCCESS:
        draft.currentBoard.comments.map(el => {
          if (el.id === action.data.id) {
            el.contents = action.data.contents;
          }
        });
        draft.editDone = 'editCommit';
        draft.loading = false;
        break;
      case PATCH_COMMENT_FAILURE:
        draft.loading = false;
        draft.editDone = false;
        break;
      case CHANGE_EDIT_DONE:
        draft.editDone = action.type;
        break;
      case GET_NOTICE_POPUP_REQUEST:
        draft.noticePopupLoading = true;
        break;
      case GET_NOTICE_POPUP_SUCCESS:
        draft.noticePopup = action.data;
        draft.noticePopupLoading = false;
        break;
      case GET_NOTICE_POPUP_FAILURE:
        console.error(action.data);
        draft.noticePopupLoading = false;
        break;
      default:
        return state;
    }
  });

export default board;
