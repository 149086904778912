import axios from '../axios';

type getBookingCountRes = {
  absence_count: number;
  attendance_count: number;
  booked_count: number;
  booking_waiting_count: number;
  cancel_count: number;
  noshow_count: number;
  total_count: number;
};

export default {
  // 예약 내역
  getBookingsByUserTicketId: (id: number, params: any, date?: any) => axios.get(`/me/tickets/${id}/bookings${date ? `?date=${date}` : ''}`, { params }),

  // 예약 내역 디테일
  getBookingDetail: (id: number) => axios.get(`/v2/member/booking/${id}`),

  // 예약, 예약대기, 출석, 결석, 노쇼, 예약취소, 전체내역 count get / 수강권 id
  getBookingCount: (id: number) => axios.get<getBookingCountRes>(`/me/tickets/count/${id}`),

  // 예약 취소
  deleteBooking: (id: number) => axios.delete(`/bookings/${id}`),

  // 예약 변경 - 결석
  patchBooking: (id: number, payload: any) => axios.patch(`/v2/member/booking/${id}`, payload),

  // 당일 예약 변경용
  getBookingList: (id: number, date: string) =>
    axios.get(`/v2/member/userTicket/group?date=${date}&userTicket=${id}&with=staff.careers&daily_booking=1`),

  changeBooking: (prev: number, next: number) => axios.post(`/daily-booking/${prev}/${next}`)
    .then((data) => data)
    .catch(error=> ({
      ...error.response
    })),

  // 예약 가능한 그룹 수업 리스트
  getAvailableBookingList: (id: number, date: string) => axios.get(`/v2/member/userTicket/group?date=${date}&userTicket=${id}&with=staff.careers`),

  // type = bookings -> 그룹수업예약, waitings -> 그룹 대기 예약
  postGroupBooking: (type: string, userTicketId: number, lectureId: number) =>
    axios.post(`/${type}?user_ticket_id=${userTicketId}&lecture_id=${lectureId}`),

  // 프라이빗 수업 리스트
  getAvailablePrivateList: (id: number, date: string) => axios.get(`/v2/member/userTicket/private?sortName=asc&date=${date}&userTicket=${id}`),

  // 프라이빗 수업 예약
  postPrivateBooking: (
    userTicketId: number,
    staffId: number,
    startOn: string,
    studioPrivateInterval: number | null,
    staffPrivateIntervalAm: number | null,
    staffPrivateIntervalPm: number | null,
  ) => {
    return axios.post(`/member/user_ticket/${userTicketId}/book`, {
      staff_id: staffId,
      start_on: startOn,
      private_start_interval: studioPrivateInterval,
      private_start_interval_am: staffPrivateIntervalAm,
      private_start_interval_pm: staffPrivateIntervalPm,
    });
  },

  // 마이페이지 예약, 예약대기, 출석, 결석, 노쇼, 예약취소, 전체내역 count
  getBookingCountAll: () => axios.get<getBookingCountRes>(`/me/tickets/count`),

  // 마이페이지 예약 내역
  getBookingListAll: (params: any) => axios.get(`/me/bookings`, { params })
};

// 프라이빗 수업 리스트 (담당강사 설정시 서버단에서 필터링)
// 강사 근무시간 이내, { events: restHour, lectureHours 제외 }
// https://api.qa.studiomate.kr/v2/member/userTicket/private?userTicket=264679&date=2020-9-7

// policy.is_visible_all_lectures가 true이면
// 현재 시간 이전 수업 자르고, 룸상관없고
// 수업구분 챙기기 -> 티켓에 수업구분이 있으면, 티켓의 수업구분 값이 수업의 수업구분에 포함되어야함
// 최대수강인원 맞춰야함.
// https://api.qa.studiomate.kr/v2/member/userTicket/group?with=staff.careers&userTicket=264646&date=2020-9-7

// 그룹 수업 예약 - post : 201, res = {Mon, 07 Sep 2020 04:25:09 GMT} -> 받은 후 그룹수업리스트 api 다시소기
// https://api.qa.studiomate.kr/bookings?user_ticket_id=264646&lecture_id=2118106
/**
 * body ={user_ticket_id: 264646 (user ticket id), lecture_id: 2118122}
 */

// 유저티켓 상세
// https://api.qa.studiomate.kr/v2/member/userTicket/264646?with=studio.policy;studio.contactInfos.type;studio.address;ticket;member.lockers;changeDailyBookings;studio.openEvents

// 그룹 수업 예약 또는 취소시
// 예약 / 취소 -> 그룹수업리스트 api -> 유저티켓 상세 api

// 그룹 수업 취소 (course id) res: {status: 202}
// delete -> https://api.qa.studiomate.kr/bookings/3967688
