import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import mobiscroll from '@mobiscroll/react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { changeCalendarData, changeUsageCalendarData } from 'store/booking/actions';
import { theme } from 'assets/styles/theme';

import { ReactComponent as Today } from 'assets/icons/icon-24-calendar-today.svg';
import { ReactComponent as Month } from 'assets/icons/icon-24-calendar-month.svg';
import { ReactComponent as Week } from 'assets/icons/icon-24-calendar-week.svg';

type CalendarStyleProps = {
  week: number;
};

type CalendarProps = {
  getList: (date: string) => void;
  eventDates?: { d: string; color: string }[];
  startDate?: any; // { string: string }
  deadline?: { start: string; end?: string; background?: string };
  beforeAvailable?: { start: string; end?: string; background?: string };
  customMax?: any;
};

const Calendar = ({ getList, eventDates, startDate, deadline, beforeAvailable, customMax }: CalendarProps) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { studio, ticketRaw } = useSelector((state: RootState) => state.studio).currentStudioData;
  const { calendarData, usageCalendarData, done } = useSelector((state: RootState) => state.booking);

  const [studioHolidays] = useState<any>([]);
  const [holdingDate] = useState<any>([]);
  const [weeksCount] = useState<number>(6);
  const bookingPage = location.pathname.split('/')[1] === 'booking-group' || location.pathname.split('/')[1] === 'booking-private';
  const calendars = bookingPage
    ? { data: calendarData, changeFunc: changeCalendarData }
    : { data: usageCalendarData, changeFunc: changeUsageCalendarData };
  const [crntDate, setCrntDate] = useState({
    year: dayjs(calendars.data.selectDate).year(),
    month: dayjs(calendars.data.selectDate).month() + 1,
    day: dayjs(calendars.data.selectDate).date(),
  });
  const local = bookingPage ? localStorage.getItem('cal_book') : localStorage.getItem('cal_list');
  const [todayClicked, setTodayClicked] = useState(false);

  useEffect(() => {
    // 수강권 정지 기간 회색
    if ((bookingPage || location.pathname === '/booking/list') && ticketRaw.holdings) {
      // const { start_on, end_on }: { start_on: string; end_on: string } = ticketRaw.now_holding;

      ticketRaw.holdings.map(el => {
        // setHoldingDate([...holdingDate, { start: el.start_on, end: el.end_on, background: '#ccc' } ]);
        holdingDate.push({ start: el.start_on, end: el.end_on, background: '#ccc' });
      });
    }

    // 시설 휴일
    studio.holidays.map((holiday: any) => {
      if (!holiday.deleted_at) studioHolidays.push({ start: holiday.date, background: theme.color.red });
    });

    // 수강권 마감날짜 < 현재시각
    // if (dayjs(ticketRaw.expire_at).isBefore(dayjs())) {
    //   setCrntDate({ year: dayjs(ticketRaw.expire_at).year(), month: dayjs(ticketRaw.expire_at).month()+1, day: dayjs(ticketRaw.expire_at).date() });
    //   dispatch(calendars.changeFunc({ selectDate: ticketRaw.expire_at }));
    // }

    // local 월/주 저장
    if (!local) {
      if (bookingPage) localStorage.setItem('cal_book', '1');
      else localStorage.setItem('cal_list', '1');
    } else {
      dispatch(calendars.changeFunc({ weeks: Number(local) }));
    }
  }, []);

  const changeView = () => {
    dispatch(calendars.changeFunc({ weeks: local === String(weeksCount) ? 1 : weeksCount }));
    localStorage.setItem(bookingPage ? 'cal_book' : 'cal_list', local === String(weeksCount) ? '1' : String(weeksCount));
    // 주 1
    // 월 6
  };

  const setDate = (e: any, inst: { getVal: () => string }) => {
    // if (calendars.data.weeks === 1) setCrntDate({ year: dayjs(inst.getVal()).year(), month: dayjs(inst.getVal()).month()+1, day: dayjs(inst.getVal()).date() });
    setCrntDate({ year: dayjs(inst.getVal()).year(), month: dayjs(inst.getVal()).month() + 1, day: dayjs(inst.getVal()).date() });
    dispatch(calendars.changeFunc({ selectDate: inst.getVal() }));
    getList(dayjs(inst.getVal()).format('YYYY-MM-DD'));
  };

  const goToday = () => {
    setTodayClicked(true);
    setCrntDate({ year: dayjs().year(), month: dayjs().month() + 1, day: dayjs().date() });
    dispatch(calendars.changeFunc({ selectDate: new Date() }));
    getList(dayjs().format('YYYY-MM-DD'));
  };

  const colorDate = () => {
    // 수업예약
    if (deadline || beforeAvailable) return [...holdingDate, ...studioHolidays, deadline, beforeAvailable];

    // 이용내역
    if (studioHolidays) {
      return [...holdingDate, ...studioHolidays];
    }
  };

  const onMonthChange = (day: { firstDay: any }) => {
    let selectedDate = day.firstDay;
    if (done.title) {
      selectedDate = calendarData.selectDate;
      dispatch(calendars.changeFunc({ done: {} }));
    }

    const year = Number(dayjs(selectedDate).format('YYYY'));
    const month = Number(dayjs(selectedDate).format('MM'));
    const date = Number(dayjs(selectedDate).format('DD'));

    if (calendars.data.weeks === 6) {
      if (month !== crntDate.month) {
        // 이용내역 | startDate < 해당 달 첫날
        if (startDate) {
          if (dayjs(startDate).isBefore(day.firstDay)) dispatch(calendars.changeFunc({ selectDate: day.firstDay }));
          else dispatch(calendars.changeFunc({ selectDate: dayjs(startDate).format('YYYY-MM-DD') }));
        }
        // 수업예약 | 현재시각 < 해당 달 첫날
        else {
          // eslint-disable-next-line no-lonely-if
          if (dayjs().isBefore(day.firstDay)) dispatch(calendars.changeFunc({ selectDate: day.firstDay }));
          else dispatch(calendars.changeFunc({ selectDate: new Date() }));
        }

        setCrntDate({ year, month, day: 1 });
      }
    } else if (date !== crntDate.day) {
      // 이용내역 | startDate < 해당 달 첫날
      if (startDate) {
        if (dayjs(startDate).isBefore(day.firstDay)) dispatch(calendars.changeFunc({ selectDate: day.firstDay }));
        else dispatch(calendars.changeFunc({ selectDate: dayjs(startDate).format('YYYY-MM-DD') }));
      }
      // 수업예약 | 현재시각 < 해당 달 첫날
      else {
        // eslint-disable-next-line no-lonely-if
        if (dayjs().isBefore(day.firstDay)) dispatch(calendars.changeFunc({ selectDate: day.firstDay }));
        else dispatch(calendars.changeFunc({ selectDate: new Date() }));
      }

      if (!todayClicked) setCrntDate({ year, month, day: date });
    }

    setTodayClicked(false);
  };

  return (
    <CalendarContainer week={calendars.data.weeks}>
      <CustomHeader>
        <span className="span">{crntDate.year}년</span>
        {crntDate.month}월
      </CustomHeader>
      <TodayStyle onClick={goToday} />
      <DateTypeContainer onClick={changeView}>{local === '6' ? <Month /> : <Week />}</DateTypeContainer>
      <mobiscroll.Calendar
        onPageChange={onMonthChange}
        showOuterDays={calendars.data.weeks === 1} // 주 단위는 true
        display="inline"
        type="hidden"
        weeks={Number(local)}
        value={`${crntDate.year}-${crntDate.month}-${crntDate.day}`}
        min={startDate || new Date()}
        max={bookingPage && dayjs(customMax).isBefore(ticketRaw.expire_at) ? dayjs(customMax) : dayjs(ticketRaw.expire_at).format('YYYY-MM-DD')}
        onSet={setDate}
        colors={colorDate()}
        // colors={[
        //     { start: '2021-04-28', end: '2021-04-29', background: '#8dec7d' }
        //   ]}
        // invalid={['w0']}
        // marked={[
        //   new Date(2012, 5, 4),
        //   new Date(2012, 5, 13),
        //   'w0',
        //   'w6',
        //   { d: '5/1', color: 'red' },
        //   { d: '2020/12/24', color: '#007bff' },
        //   { d: '2020/12/25', color: 'red' },
        // ]}
        // marked={[
        //     { d: '6/10', color: 'red' },
        //     { d: '6/10', color: 'blue' },
        //     { d: '6/10', color: 'purple' },
        //     { d: '6/10', color: 'black' },
        //     { d: '6/10', color: 'pink' },
        //     { d: '6/10', color: 'orange' },
        //   ]}
        marked={eventDates}
      />
    </CalendarContainer>
  );
};

const CalendarContainer = styled.div<CalendarStyleProps>`
  padding: 5px 12px 0 12px;

  .mbsc-ios .mbsc-fr-btn {
    color: ${theme.color.sub};
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-fr-w {
    border-top: none;
  }

  // custom header
  .mbsc-cal-month,
  .mbsc-cal-year {
    visibility: hidden;
  }

  .mbsc-cal-btn-w:nth-child(1) {
    .mbsc-cal-year {
      margin-left: 2.086956em;
      text-align: left;
      padding-left: 14%;
      padding-right: 0 !important;
    }
    .mbsc-ic {
      display: ${({ week }) => week !== 1 && 'none !important'};
    }
    .mbsc-cal-month {
      text-align: left;
      padding-left: 7%;
    }
  }

  .mbsc-cal-btn-w:nth-child(2) {
    .mbsc-cal-prev {
      position: ${({ week }) => week !== 1 && 'absolute'};
      left: ${({ week }) => week !== 1 && '0px'};
    }
    .mbsc-cal-month {
      text-align: left;
      margin-left: -20%;
      margin-right: 100px;
    }
  }

  // 점 위치 약간 위로
  .mbsc-ios .mbsc-cal-marks {
    margin-top: -0.09em;
  }

  // 원 크기 줄이기
  .mbsc-ios .mbsc-cal-day-date {
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
  }

  .mbsc-ios .mbsc-cal-day-scroll-c .mbsc-cal-slide {
    padding-bottom: 0;
  }

  // 달력 화살표 버튼
  .mbsc-ios .mbsc-cal .mbsc-cal-btn {
    font-size: 16px;
    margin: 0 -0.173913em;
    margin-top: 4px;
  }

  // 오늘 날짜
  .mbsc-ios .mbsc-cal-today {
    color: ${theme.color.blue};
  }

  // 선택된 날짜
  .mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    border-color: ${theme.color.blue};
    background: ${theme.color.blue};
  }

  // 날짜 사이 선 제거
  .mbsc-ios .mbsc-cal-cell {
    border-top: 0px;
  }
  .mbsc-disabled {
    .mbsc-cal-cell-txt {
      border: none;
    }
  }
`;

const CustomHeader = styled.div`
  font-size: 16px;
  font-weight: 500;

  position: absolute;
  top: 68px;
  z-index: 100;
  left: 20%;

  .span {
    margin-right: 6px;
  }
`;

const TodayStyle = styled(Today)`
  position: absolute;
  top: 65px;
  z-index: 100;
  right: 20%;
`;

const DateTypeContainer = styled.div`
  position: absolute;
  top: 65px;
  z-index: 100;
  right: 33%;
`;

export default Calendar;
